import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import { Grid,Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FondAwesome from 'react-fontawesome';
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {Carousel} from 'react-responsive-carousel';
import {GLOBAL} from '../../../global';

const useStyles = makeStyles((theme) => ({
  root: {
    //maxWidth: 345,
  },
  media: {
    //height: 0,
    //paddingTop: '56.25%', // 16:9 se calcula (9/16*100) = (ALTO/ANCHO*100%) = 2569px;1080px
    minHeight:'95vh',
  },
  customTitle:{
    position: 'absolute',
    bottom: '60%',
    left: '0px',
    right:'0px',
    marginLeft: 'auto',
    marginRight: 'auto',    
    width: '100%',
    //borderRadius: '0px',
    backgroundColor:'rgba(212,32,35,0.5)',
    color: '#fff',
    padding: theme.spacing(3,0),
    //fontSize: '12px',
    textAlign: 'center',
    opacity: '1',
  },
  customSubTitle:{
    position: 'absolute',
    bottom: '50%',
    left: '0px',
    right:'0px',
    marginLeft: 'auto',
    marginRight: 'auto',    
    width: '50%',
    borderRadius: '0px',
    //background: '#ff0000',
    color: '#fff',
    //padding: theme.spacing(3,0),
    //fontSize: '12px',
    textAlign: 'center',
    //opacity: '.7',
  },  
  buttonBiografia: {
    position: 'absolute',
    bottom: '43%',
    left: '0px',
    right:'0px',
    marginLeft: 'auto',
    marginRight: 'auto',    
    width: '100%',
    textAlign: 'center',    
  },
  button:{
      color:'white',
      borderColor:'white',
      width:'150px',
  },


  mainContainer:{
    display: 'flex',
    //backgroundImage:`URL("./images/portada-ci.jpg")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: '100%',
    minHeight: '100vh',
    margin: '0px',
    alignItems:'center',
    justifyContent:'center',
    },    
    customTitle2:{
        textAlign:'center',
        color:'white',
        backgroundColor:'rgba(212,32,35,0.5)',
        padding:theme.spacing(2,2),
    },
    customDescription:{
        textAlign:'center',
        color:'white',
        fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
        padding:theme.spacing(2,2),
    },
    customTextToHtml:{
        //color:'white',
        fontSize: '0.875rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
        fontWeight: 400,
        lineHeight: 1.43,
        letterSpacing: '0.01071em',
        padding:theme.spacing(1,1),
    },
    animationItem:{
        animation:`$myEffect 0.8s infinite linear`
    },
    "@keyframes myEffect": {
        "0%": {
        top: 0,
        
        },
        "50%": {
        top: 10,          
        },
        "100%": {
        top: 0,          
        }
    },
}));

const socialIcons = {
    facebook:{
        color:'#4d4d4d',
        margin:'10px'
    },
    twitter:{
        color:'#4d4d4d',
        margin:'10px'
    }
  };

const MyCarousel = (props) => {
    const classes = useStyles();
    return(
        <Carousel centerMode centerSlidePercentage={100} emulateTouch infiniteLoop useKeyboardArrows autoPlay showThumbs={false} stopOnHover interval={5000}>            
            <div>
                <Box className={classes.mainContainer} style={{backgroundImage:`URL(${GLOBAL.assetsURL+'/carousel/principal/1.jpg'})`}}>
                    <Grid container>
                        <Grid item xs={12} sm={1} md={2} lg={3} xl={3}></Grid>
                        <Grid item xs={12} sm={10} md={8} lg={6} xl={6}>
                            <Typography variant="h3" className={classes.customTitle2}>INFRAESTRUCTURA</Typography>
                            <br/>
                            <Box>
                                <Typography variant="h4" className={classes.customDescription}>
                                    SALUD
                                </Typography>
                            </Box>                                
                        </Grid>
                        <Grid item xs={12} sm={1} md={2} lg={3} xl={3}></Grid>
                    </Grid>                    
                </Box>                
            </div>
            
            <div>               
                <Box className={classes.mainContainer} style={{backgroundImage:`URL(${GLOBAL.assetsURL+'/carousel/principal/3.jpg'})`}}>
                    <Grid container>
                        <Grid item xs={12} sm={1} md={2} lg={3} xl={3}></Grid>
                        <Grid item xs={12} sm={10} md={8} lg={6} xl={6}>
                            <Typography variant="h3" className={classes.customTitle2}>TRABAJOS DE</Typography>
                            <br/>
                            <Box>
                                <Typography variant="h4" className={classes.customDescription}>
                                    FUMIGACIÓN
                                </Typography>
                            </Box>                                
                        </Grid>
                        <Grid item xs={12} sm={1} md={2} lg={3} xl={3}></Grid>
                    </Grid>                    
                </Box>
            </div>

            <div>
                <Box className={classes.mainContainer} style={{backgroundImage:`URL(${GLOBAL.assetsURL+'/carousel/principal/4.jpg'})`}}>
                    <Grid container>
                        <Grid item xs={12} sm={1} md={2} lg={3} xl={3}></Grid>
                        <Grid item xs={12} sm={10} md={8} lg={6} xl={6}>                                                     
                        </Grid>
                        <Grid item xs={12} sm={1} md={2} lg={3} xl={3}></Grid>
                    </Grid>                    
                </Box>
            </div>
        </Carousel>                
    );
}
export default withRouter(MyCarousel);