import React from 'react';
import {Grid,Typography,Paper,Button,Box} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Carousel} from 'react-responsive-carousel';

const useStyles = makeStyles((theme) => ({
    customTitle2:{
        padding:theme.spacing(1)
    },
    customTitle:{
        textAlign:'center'
    },
    box:{
        maxWidth:'100%',
        margin:'0 auto'
    },
    media: {
        //height: 0,
        //paddingTop: '56.25%', // 16:9
    }, 
}));

const DestacadosCopmonent = () => {
    const classes = useStyles();
    return (
        <Paper>
            <Grid container justify="center">
                <Grid item xs={12} md={8} lg={8}>
                    <Box my={5} textAlign="center">
                        <Typography variant="h4" className={classes.customTitle2}>TRÁMITES DISPONIBLES PARA INICIAR EN LÍNEA</Typography>
                    </Box>
                    <Carousel centerMode centerSlidePercentage={100} emulateTouch infiniteLoop useKeyboardArrows autoPlay showThumbs={true} stopOnHover interval={5000}>
                        <div>
                            <img src="https://images.freeimages.com/images/large-previews/daa/folder-1144379.jpg" className={classes.media}/>
                            <div className="legend" style={{opacity:1}}>
                                <h3>CERTIFICACIÓN CATASTRAL<br/>DISPONIBLE PARA INICIAR DESDE CUALQUIER LUGAR</h3>
                                <Button color="secondary" variant="contained" onClick={()=>window.open("http://portalwebgamc.cochabamba.bo", "_blank")}>Iniciar trámite en línea</Button>
                            </div>
                        </div>
                        <div>
                            <img src="https://images.freeimages.com/images/large-previews/210/palm-1528743.jpg" className={classes.media}/>
                            <div className="legend" style={{opacity:1}}>
                                <h3>Ya puedes iniciar el trámite <strong>SOLICITUD PLACAS</strong> desde casa!</h3>
                                <Button color="secondary" variant="contained" onClick={()=>window.open("http://portalwebgamc.cochabamba.bo", "_blank")}>Iniciar trámite en línea</Button>
                            </div>
                        </div>
                        <div>
                            <img src="https://images.freeimages.com/images/large-previews/b1e/paperwork-2-1198357.jpg" className={classes.media}/>
                            <div className="legend" style={{opacity:1}}>
                                <h3>Ya puedes iniciar el trámite <strong>CERTIFICACIÓN DE LOTE DE PLANO</strong> desde casa!</h3>
                                <Button color="secondary" variant="contained" onClick={()=>window.open("http://portalwebgamc.cochabamba.bo", "_blank")}>Iniciar trámite en línea</Button>
                            </div>
                        </div>                        
                    </Carousel>
                </Grid>
                
            </Grid>        
        </Paper>
    )
}
export default DestacadosCopmonent;