import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Parallax} from 'react-parallax';
import convert from 'htmr';
import { GLOBAL } from '../../global';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      padding:theme.spacing(5,1),
      //backgroundColor:theme.palette.secondary.main
    },    
    customContaimer:{
      color:theme.palette.sextary.light,
      fontSize:theme.typography.body1.fontSize
    },
    customSubTitle:{
      color:theme.palette.sextary.main,      
      paddingTop:theme.spacing(3),
      paddingBottom:theme.spacing(0),
      textAlign:'center',
    },
    customLogo:{
      maxWidth:'250px'
    }
  }));
/*
  const data = {
    "img":"",
    "style": {
      "title": {
        "color": "white"
      },
      "description": {
        "color": "white"
      }
    },
    "parallax": {
      "enabled": true,
      "bgimg": "/img/secciones/bg-default4-01.png",
      "blur": 0,
      "strength": 200
    },
    "items": [
      {
        "title": "",
        "description": "<p><p>"
      }
    ]
  }
*/

const MyComponent = (props) => {
    const {data=[]} = props;    
    const classes = useStyles();
    return(
        <Grid container justify='center' className={classes.root}>
            <Grid item xs={12} sm={12} md={10} lg={8} xl={7}>
              {
                data.img?
                <Box textAlign="center">
                  <img className={classes.customLogo} src={`${GLOBAL.assetsBack}${data.img}`} alt="Logo Cochabamba"/>
                </Box>:null
              }
              {
                data.items?
                data.items.map((item,index) => (
                    <Box textAlign="justify" px={2} key={index}>
                        <Typography className={classes.customSubTitle} variant="h4" style={data.style && data.style.title?data.style.title:null}>{item.title}</Typography>
                        <Box className={classes.customContaimer} style={data.style && data.style.description?data.style.description:null}>{convert(item.description)}</Box>
                    </Box>
                )):null
              }
            </Grid>
        </Grid>
    );
}

const InfoMisionVisionComponent = (props) => {
    const {data=[]} = props;    
    const classes = useStyles();    
    return data.parallax && data.parallax.enabled?
        <Parallax blur={data.parallax.blur?data.parallax.blur:0} bgImage={`${GLOBAL.assetsBack}${data.parallax.bgimg}`} bgImageAlt="Background image" strength={data.parallax.strength?data.parallax.strength:0}>
            <MyComponent data={data}/>
        </Parallax>:<MyComponent data={data}/>    
}
export default InfoMisionVisionComponent;