import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { Map, TileLayer, Marker, Popup } from 'react-leaflet';

import SimplePortada from '../../components/genericos/portadas/portada-material-ui/SimplePortada';
import {GLOBAL} from '../../global';

const data = {
  map:null,
  markers:[],
  featureGroup:null,
  centros:[
      {
          marcador:null,
          nombre:"C.S. PACATA",
          posicion:[-17.373556,-66.124194],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Tunari",
          direccion:"Av. Circunvalación y Pio Hermosa (Zona Mesadilla)",
          ambulatorio:"AMBULATORIO (12 HRS)",
          atencion: ["Medicina General","Odontología","Ecografía"]
      },
      {
          marcador:null,
          nombre:"C.S CONDEBAMBA",
          posicion:[-17.357182,-66.177832],                
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Tunari",
          direccion:"C. Luqui Yupanqui. Av. Circunvalación",
          ambulatorio:"AMBULATORIO (6 HRS)",
          atencion: ["Medicina General","Odontología","Laboratorio"]
      },
      {
          marcador:null,
          nombre:"C.S BEATO SALOMÓN",
          posicion:[-17.360811,-66.174444],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Tunari",
          direccion:"C. Damian Rejas entre Manuel Carrasco y Nicolás Ortiz",
          ambulatorio:"AMBULATORIO (6 HRS)",
          atencion: ["Medicina General","Odontología","Laboratorio"]
      },
      {
          marcador:null,
          nombre:"C.S TICTI NORTE",
          posicion:[-17.349812,-66.166615],                
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Tunari",
          direccion:"C. Qhochapampa y Av. Circunvalación II",
          ambulatorio:"AMBULATORIO (6 HRS)",
          atencion: ["Medicina General","Odontología"]
      },
      {
          marcador:null,
          nombre:"C.S. EDGAR MONTAÑO",
          posicion:[-17.346770,-66.174962],                
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Tunari",
          direccion:"C. Innominada (Zona Ticti Norte)",
          ambulatorio:"INTEGRAL (24 HRS)",
          atencion: ["Medicina General","Odontología","Laboratorio","Ginecología","Sala de partos","Camas de internación","Ecografía"]
      },
      {
          marcador:null,
          nombre:"C.S. TEMPORAL",
          posicion:[-17.360309,-66.158139],                
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Tunari",
          direccion:"C. Juan F. Bedregal y Jack London",
          ambulatorio:"AMBULATORIO (12 HRS)",
          atencion: ["Medicina General","Odontología","Laboratorio"]
      },
      {
          marcador:null,
          nombre:"C.S. CERRO VERDE",
          posicion:[-17.413883,-66.144300],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Valle Hermoso",
          direccion:"Av. Los Andes y C. Yawar Mallcu",
          ambulatorio:"AMBULATORIO (12 HRS)",
          atencion: ["Medicina General","Odontología","Fisioterapia","Laboratorio","Ecografía"]
      },
      {
          marcador:null,
          nombre:"C.S SEBASTIÁN PAGADOR",
          posicion:[-17.441755,-66.116328],                
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Valle Hermoso",
          direccion:"Av. Humberto Asín (Zona Sebastián Pagador)",
          ambulatorio:"INTEGRAL (24 HRS)",
          atencion: ["Medicina General","Odontología","Laboratorio","Ginecología","Sala de partos","Camas de internación","Rayos X","Ecografía"]
      },        
      {
          marcador:null,
          nombre:"C.S. SENNFELD",
          posicion:[-17.406470,-66.152589],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Valle Hermoso",
          direccion:"C. 1 de Mayo y Riberalta (Zona Alalay Norte)",
          ambulatorio:"AMBULATORIO (6 HRS)",
          atencion: ["Medicina General","Odontología","Fisioterapia","Laboratorio"]
      },
      {
          marcador:null,
          nombre:"C.S. ALTO COCHABAMBA",
          posicion:[-17.424682,-66.149130],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Valle Hermoso",
          direccion:"Zona Alalay Norte, C. Sofía Rosell y 17 de octubre (Zona Villa Cosmos)",
          ambulatorio:"AMBULATORIO (12 HRS)",
          atencion: ["Medicina General","Odontología","Laboratorio"]
      },
      {
          marcador:null,
          nombre:"C.S. ALALAY",
          posicion:[-17.421842,-66.137369],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Valle Hermoso",
          direccion:"Av. Siglo XX #100 (Barrio Minero)",
          ambulatorio:"INTEGRAL (24 HRS)",
          atencion: ["Medicina General","Odontología","Laboratorio","Ginecología","Sala de Partos","Camas de internación","Rayos X","Ecografía"]
      },
      {
          marcador:null,
          nombre:"C.S. VILLA VENEZUELA",
          posicion:[-17.426653,-66.127260],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Valle Hermoso",
          direccion:"Av. Huanchaca entre C. Pablo de Meneses y Av. Dionisio Bobadilla",
          ambulatorio:"AMBULATORIO (6 HRS)",
          atencion: ["Medicina General","Odontología"]
      },
      {
          marcador:null,
          nombre:"C.S. ALTO PAGADOR",
          posicion:[-17.450160,-66.114226],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Valle Hermoso",
          direccion:"C. Barcelona entre Av. Silala y C. La Joya",
          ambulatorio:"AMBULATORIO (6 HRS)",
          atencion: ["Medicina General","Odontología","Laboratorio"]
      },
      {
          marcador:null,
          nombre:"C.S KANATA",
          posicion:[-17.413255,-66.159228],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Alejo Calatayud",
          direccion:"Av. Mártires de la Democracia y 6 de Agosto (Zona Jaihuayco)",
          ambulatorio:"AMBULATORIO (12 HRS)",
          atencion: ["Medicina General","Odontología","Fisioterapia","Laboratorio"]
      },
      {
          marcador:null,
          nombre:"C.S. MINEROS SAN JUAN",
          posicion:[-17.483013,-66.105251],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Alejo Calatayud",
          direccion:"Av. Petrolera Km 10 (Zona Uspa Uspa)",
          ambulatorio:"AMBULATORIO (6 HRS)",
          atencion: ["Medicina General","Odontología","Laboratorio"]
      },
      {
          marcador:null,
          nombre:"C.S. LACMA",
          posicion:[-17.434453,-66.155998],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Alejo Calatayud",
          direccion:"C. Virgen del Carmen y Sr. De Burgos (Zona Lacma)",
          ambulatorio:"AMBULATORIO (12 HRS)",
          atencion: ["Medicina General","Odontología","Laboratorio"]
      },
      {
          marcador:null,
          nombre:"C.S. JAIHUAYCO",
          posicion:[-17.416836,-66.162924],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Alejo Calatayud",
          direccion:"C. Chimoré y Mamoré (Zona Jaihuayco)",
          ambulatorio:"AMBULATORIO (12 HRS)",
          atencion: ["Medicina General","Odontología"]
      },
      {
          marcador:null,
          nombre:"C.S VALLE HERMOSO",
          posicion:[-17.447913,-66.125783],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Alejo Calatayud",
          direccion:"C. Granado y Siglo XX. Zona Valle Hermoso",
          ambulatorio:"INTEGRAL (24 HRS)",
          atencion: ["Medicina General","Odontología","Laboratorio","Ginecología","Sala de partos","Camas de internación","Rayos X","Ecografía"]
      },
      {
          marcador:null,
          nombre:"C.S. CHIMBA",
          posicion:[-17.405785,-66.181648],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Molle",
          direccion:"C. Guzmán de Rojas, Parque Mariscal Braun",
          ambulatorio:"AMBULATORIO (12 HRS)",
          atencion: ["Medicina General","Odontología","Ecografía","Laboratorio"]
      },        
      {
          marcador:null,
          nombre:"C.S SARCOBAMBA",
          posicion:[-17.378970,-66.188428],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Molle",
          direccion:"Av. Tunupa y Villavicencio.",
          ambulatorio:"INTEGRAL (12 HRS)",
          atencion: ["Medicina General","Odontología","Laboratorio","Ginecología","Sala de partos","Camas de internación","Rayos X","Ecografía"]
      },
      {
          marcador:null,
          nombre:"C.S CHAVEZ RANCHO",
          posicion:[-17.394918,-66.190364],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Molle",
          direccion:"C. Enrico Fermi. Zona Villa Bush",
          ambulatorio:"AMBULATORIO (12 HRS)",
          atencion: ["Medicina General","Odontología"]
      },
      {
          marcador:null,
          nombre:"C.S ROJAS MEJÍA",
          posicion:[-17.400811,-66.172073],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Molle",
          direccion:"C. Nanawa y Chorete, Parque Mariscal Santa Cruz",
          ambulatorio:"AMBULATORIO (6 HRS)",
          atencion: ["Medicina General","Odontología","Fisioterapia"]
      },
      {
          marcador:null,
          nombre:"C.S K’ARA K’ARA",
          posicion:[-17.451742, -66.201450],            
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Itocta",
          direccion:"Camino Botadero Municipal",
          ambulatorio:"INTEGRAL (12 HRS)",
          atencion: ["Medicina General","Odontología","Laboratorio","Ginecología","Sala de Partos","Camas de internación","Rayos X","Ecografía"]
      },
      {
          marcador:null,
          nombre:"C.S. PUCARITA",
          posicion:[-17.451938,-66.201349],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Itocta",
          direccion:"C. Alegre (Zona Pucarita Chica)",
          ambulatorio:"INTEGRAL (12 HRS)",
          atencion: ["Medicina General","Odontología","Laboratorio","Ginecología","Sala de Partos","Camas de internación","Rayos X","Ecografía"]
      },
      {
          marcador:null,
          nombre:"C.S MAICA",
          posicion:[-17.422266,-66.202287],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Itocta",
          direccion:"Parada 10. Zona Maica Quenamari",
          ambulatorio:"AMBULATORIO (6 HRS)",
          atencion: ["Medicina General","Odontología"]
      },
      {
          marcador:null,
          nombre:"C.S. ESPAÑA",
          posicion:[-17.457507,-66.156141],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Itocta",
          direccion:"Zona Pucara Grande",
          ambulatorio:"AMBULATORIO (6 HRS)",
          atencion: ["Medicina General","Odontología","Fisioterapia","Laboratorio"]
      },
      {
          marcador:null,
          nombre:"C.S VILLA ISRAEL",
          posicion:[-17.486145,-66.172546],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Itocta",
          direccion:"Av. Panamericana, carretera a Santiváñez",
          ambulatorio:"AMBULATORIO (12 HRS)",
          atencion: ["Medicina General","Odontología","Fisioterapia","Laboratorio"]
      },
      {
          marcador:null,
          nombre:"C.S GLORIA",
          posicion:[-17.469792, -66.156061],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Itocta",
          direccion:"Zona Pucara Grande,  Alto de Buena Vista",
          ambulatorio:"AMBULATORIO (12 HRS)",
          atencion: ["Medicina General","Odontología"]
      },
      {
          marcador:null,
          nombre:"C.S. 1 DE MAYO",
          posicion:[-17.468828,-66.204970],
          nivel:"Primer Nivel",
          subalcaldia:"Subalcaldía Itocta",
          direccion:"A una cuadra de la Unidad Educativa 1 de Mayo",
          ambulatorio:"INTEGRAL (12 HRS)",
          atencion: ["Medicina General","Odontología","Laboratorio","Ginecología","Sala de Partos","Camas de internación","Rayos X","Ecografía"]
      },
      {
          marcador:null,
          nombre:"HOSPITAL COCHABAMBA",
          posicion:[-17.400577,-66.156058],
          nivel:"Segundo Nivel",
          subalcaldia:"Subalcaldía Adela Zamudio",
          direccion:"C. Nataniel Aguirre #834",
          ambulatorio:"",
          atencion: ["Ginecología y obstetricia","Nutrición","Cirugía general","Medicina interna","Traumatología","Partos normales","Cesáreas","Odontología","Fisioterapia","Medicina General","Pediatría"]
      },
      {
          marcador:null,
          nombre:"HOSPITAL DEL SUD",
          posicion:[-17.450215,-66.149969],
          nivel:"Segundo Nivel",
          subalcaldia:"Subalcaldía Itocta",
          direccion:"Calle Cuatro, OTB La Joya",
          ambulatorio:"",
          atencion: ["Ginecología y obstetricia","Nutrición","Cirugía general","Medicina interna","Traumatología","Partos normales","Cesáreas","Odontología","Fisioterapia","Medicina General","Rayos X","Ecografía 4D","Pediatría"]
      }
  ],
  subalcaldias:[
      {id:"s1",nombre:'Subalcaldía Tunari'},
      {id:"s2",nombre:'Subalcaldía Valle Hermoso'},
      {id:"s3",nombre:'Subalcaldía Alejo Calatayud'},
      {id:"s4",nombre:'Subalcaldía Molle'},
      {id:"s5",nombre:'Subalcaldía Itocta'},
      {id:"s6",nombre:'Subalcaldía Adela Zamudio'}
  ],
  subalcaldiasSelected:[],
  atenciones:[
      {id:"a1",nombre:'Medicina General'},
      {id:"a2",nombre:'Odontología'},
      {id:"a3",nombre:'Ecografía'},
      {id:"a4",nombre:'Laboratorio'},
      {id:"a5",nombre:'Ginecología'},
      {id:"a6",nombre:'Sala de partos'},
      {id:"a7",nombre:'Camas de internación'},
      {id:"a8",nombre:'Fisioterapia'},
      {id:"a9",nombre:'Rayos X'},
      {id:"a10",nombre:'Ginecología y obstetricia'},
      {id:"a11",nombre:'Nutrición'},
      {id:"a12",nombre:'Cirugía general'},
      {id:"a13",nombre:'Medicina interna'},
      {id:"a14",nombre:'Traumatología'},
      {id:"a15",nombre:'Partos normales'},
      {id:"a16",nombre:'Cesáreas'},
      {id:"a17",nombre:'Pediatría'},
      {id:"a18",nombre:'Ecografía 4D'}
  ],
  atencionesSelected:[],
  niveles:[
      {id:"n1",nombre:'Primer Nivel'},
      {id:"n2",nombre:'Segundo Nivel'}
  ],
  nivelesSelected:[]
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:"rgb(79,185,168)",
    color:theme.palette.common.white,
    textAlign:"center"
  },
  customPanel:{
    width:'100%',    
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  customFilterContainer:{
    padding:theme.spacing(5,0),
  },
  customItemContainer:{    
    padding:theme.spacing(0,3),
  },
  leafletContainer:{    
    height: "480px",
  },
  customPaper:{
    padding:theme.spacing(1,1),
    margin:theme.spacing(1,1),    
  },
  customTitle:{
    textAlign:'center',
    marginTop:theme.spacing(10),
    marginBottom:theme.spacing(10),
    color:'#FFFFFF',
    //color:'#3A4961',
  },
  customDescription:{
      textAlign:'center',
      color:'#FFFFFF',
      //color:'#73839C',
      marginBottom:theme.spacing(5),
  }
}));

const dataPortada = {
  background:GLOBAL.assetsURL+'/images/salud/fondo2.jpg',
  title:{line1:'Secretaría de',line2:'SECRETARÍA DE SALUD'},
  description:{
      config:{
          html:false,
      },
      style:{
          textAlign:'justify',
          color:'white',
          backgroundColor:'rgba(0, 0, 0, 0)',
      },
      content:[
          'El objetivo de la Secretaría de salud es de implementar políticas, programas y proyectos de salud, fortaleciendo la Red Municipal de Salud mejorando sus recursos y servicios, a través de la prevención, control de riesgos y daños a la salud pública; así como garantizar una administración eficiente, transparente y responsable de los recursos en salud.',
      ],            
  },
  phone:{//******AQUI CAMBIO
      enabled:true,
      config:{
          color:'white'
      },
      data:{
          address:'Av. Ayacucho y Colombia',
          phone:'4502414',
          schedule:'Mañana: 08:00 - 12:00 Tarde: 14:30 - 18:30',
      }
  },
  button:{
      enabled:false,
      label:'Continuar'
  }
}

const SaludComponent = (props) => {
  const classes = useStyles();
  const [subalcaldias,setSubalcaldias] = React.useState({});
  const [atenciones,setAtenciones] = React.useState({});
  const [niveles,setNiveles] = React.useState({});

  const handleChangeSubalcaldias = (event) => {
    setSubalcaldias({ ...subalcaldias, [event.target.name]: event.target.checked });    
  };
  const handleChangeAtenciones = (event) => {
    setAtenciones({ ...atenciones, [event.target.name]: event.target.checked });    
  };
  const handleChangeNiveles = (event) => {
    setNiveles({ ...niveles, [event.target.name]: event.target.checked });
  };
  const customMarkers = () => {
    let newArray = [];    
    data.centros.map(element => {
      for(var clave in niveles){
        if(niveles.hasOwnProperty(clave)){
          let nivelaux = data.niveles.find(n => n.id==clave);
          if(element.nivel == nivelaux.nombre && niveles[clave]){
            newArray.push(element);
          }
        }
      }
      for(var clave in subalcaldias){
        if(subalcaldias.hasOwnProperty(clave)){
          let subalcaldiaaux = data.subalcaldias.find(n => n.id==clave);
          if(element.subalcaldia == subalcaldiaaux.nombre && subalcaldias[clave]){
            if((newArray.find(item => item.nombre==element.nombre))==undefined){
              newArray.push(element);
            }
          }
        }
      }
      for(var clave in atenciones){
        if(atenciones.hasOwnProperty(clave)){
          let atencionesaux = data.atenciones.find(n => n.id==clave);
          if(element.atencion.find(valor => valor==atencionesaux.nombre)!=undefined  && atenciones[clave]){
            if((newArray.find(item => item.nombre==element.nombre))==undefined){
              newArray.push(element);
            }
          }
        }
      }
    });
    //console.log("newArray=",newArray);
    return newArray.length==0?data.centros:newArray;
  };

  return (
    <div className={classes.root}>      
      <Grid container>
        <Grid item xs={12} sm={12} md={1} lg={1} xl={3}></Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={6} className={classes.customFilterContainer}>
          <Typography variant="h3" className={classes.customTitle}>Direcciones de los Centros de Salud de Cochabamba</Typography>
          <Typography variant="h6">FILTRAR CENTROS DE SALUD POR:</Typography>          
        </Grid>
        <Grid item xs={12} sm={12} md={1} lg={1} xl={3}></Grid>
      </Grid>
      
      <Grid container>
        <Grid item xs={12} sm={12} md={1} lg={1} xl={3}></Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={6}>
          <Paper className={classes.customPaper}>
            <FormControl component="fieldset">
              <FormLabel component="legend">SUBALCALDÍAS</FormLabel>
              <FormGroup aria-label="position" row>
                {
                  data.subalcaldias.map((item,index) => (
                    <FormControlLabel
                      key={item.nombre}
                      value={item.nombre}
                      control={<Checkbox color="secondary" checked={subalcaldias[item.id]?subalcaldias[item.id]:false} name={item.id} onChange={handleChangeSubalcaldias}/>}
                      label={item.nombre}
                      labelPlacement="end"
                    />
                  ))                  
                }
              </FormGroup>
            </FormControl>
          </Paper>
          
          <Paper className={classes.customPaper}>
            <FormControl component="fieldset">
              <FormLabel component="legend">TIPOS DE ATENCIÓN</FormLabel>
              <FormGroup aria-label="position" row>
                {
                  data.atenciones.map((item,index) => (
                    <FormControlLabel
                      key={item.nombre}
                      value={item.nombre}
                      control={<Checkbox color="secondary" checked={atenciones[item.id]?atenciones[item.id]:false} name={item.id} onChange={handleChangeAtenciones}/>}
                      label={item.nombre}
                      labelPlacement="end"
                    />
                  ))                  
                }
              </FormGroup>
            </FormControl>
          </Paper>

          <Paper className={classes.customPaper}>
            <FormControl component="fieldset">
              <FormLabel component="legend">NIVEL</FormLabel>
              <FormGroup aria-label="position" row>
                {
                  data.niveles.map((item,index) => (
                    <FormControlLabel
                      key={item.nombre}
                      value={item.nombre}
                      control={<Checkbox color="secondary" checked={niveles[item.id]?niveles[item.id]:false} name={item.id} onChange={handleChangeNiveles}/>}
                      label={item.nombre}
                      labelPlacement="end"
                    />
                  ))                  
                }
              </FormGroup>
            </FormControl>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={1} lg={1} xl={3}></Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={12} md={1} lg={1} xl={3}></Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={6}>
          <Paper className={classes.customPaper}>
            <Map center={[-17.406470,-66.152589]} zoom={12} zoomControl={true} className={classes.leafletContainer}>
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {
                customMarkers().map((item,index) => (
                  <Marker key={item.nombre} position={item.posicion}>
                    <Popup>
                      <strong>{item.nombre}</strong> ({item.nivel})<br/>
                      {item.subalcaldia}<br/>
                      {item.ambulatorio}{item.ambulatorio?<br/>:null}
                      {item.direccion}<br/>
                      <strong>Atención en:</strong><br/>
                      {
                        item.atencion.map((valor,indice) => (
                          <div key={valor}>
                            <span>{valor}</span><br/>
                          </div>
                        ))
                      }
                    </Popup>
                  </Marker>
                ))                
              }
            </Map>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={1} lg={1} xl={3}></Grid>
      </Grid>
    </div>
  );
}

export default SaludComponent;