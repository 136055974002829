import React,{ useRef,useEffect,createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography,Grid,InputLabel,Button,Box, Card, CardContent, List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Skeleton from '@material-ui/lab/Skeleton';
import Select from '@material-ui/core/Select';
//import useAxios from 'axios-hooks';
import axios from 'axios';

import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import CategoryIcon from '@material-ui/icons/Category';
import { useScreenshot,createFileName } from 'use-react-screenshot';

import Alert from '@material-ui/lab/Alert';
import convert from 'htmr';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../../../App.css';

import ModalComponentViewer from '../../../components/genericos/modal/ModalComponentViewer';
import ErrorRequestComponent from '../../../components/genericos/error/ErrorRequestComponent';

import CampoBuscar from '../../../components/genericos/CampoBuscar';
import CardEmprendedurismo from './components/CardEmprendedurismo';
import CardEmprendedurismo2 from './components/CardEmprendedurismo2';
import CardServicios from './components/CardServicios';
import { GLOBAL } from '../../../global';

const useStyles = makeStyles(theme => ({
    root:{
        padding:theme.spacing(3,1)
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    title:{
        textAlign:'center',
        margin:theme.spacing(3,1),
    },
    customPadding:{
        marginTop:theme.spacing(3),
        marginBottom:theme.spacing(3),
        paddingleft:theme.spacing(2),
        paddingRight:theme.spacing(2),
    },
    customPadding2:{
        marginTop:theme.spacing(2),
    },    
    container: {
      maxHeight: 640,      
    },    
    customTitle:{
        textAlign:'center',
        color:theme.palette.primary.main
    },
    customSubTitle:{
        color:theme.palette.sextary.main,
        //paddingBottom:theme.spacing(3),
        textAlign:'justify',
        fontSize:theme.typography.h6.fontSize
    },
    customTabStyles:{
        marginTop:theme.spacing(1)
    },
}));

const useStyles2Card = makeStyles(theme => ({
    root:{
        padding:theme.spacing(3,1),
        maxWidth:'350px'
    },
    media: {
        maxWidth:'250px',
        width:'100%'
    },
    title:{
        fontWeight:'bold'
    }
}));

const CardGenerator = (props) => {   
    const { data,qr,logo } = props.data;
    const classes = useStyles2Card();    

    const ref = createRef(null);
    const [image, takeScreenShot] = useScreenshot({
        type: "image/jpeg",
        quality: 1.0
    });
    const download = (image, { name = "tarjeta", extension = "jpg" } = {}) => {
        let a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
        a=null;
    };
    const downloadScreenshot = () => takeScreenShot(ref.current).then(download);    

    return (
        <>
            <Box
                display="flex"
                justifyContent="center">
                <Card ref={ref} className={classes.root}>
                    <Box alignContent="center" textAlign="center">
                        <Grid container>
                            <Grid item xs={10}>
                                <img
                                    className={classes.media}
                                    id='logoCocha'
                                    src={ logo }
                                    alt="Cocha Somos Progreso"/>
                            </Grid>
                            <Grid item xs={2}>
                                <Box alignContent="center" textAlign="center">
                                    <img 
                                        src={`data:image/svg+xml;base64,${qr}`} 
                                        alt="QR" 
                                        width='100%'/>
                                </Box>
                            </Grid>
                        </Grid>                    
                    </Box>                
                    <Divider className={classes.customDivider} />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                            {data.nombre_productor}
                        </Typography>                    
                        <List>
                            <ListItem aria-label="delete">
                                <ListItemIcon>
                                    <PersonIcon className={classes.customIconColor} />
                                </ListItemIcon>
                                <ListItemText primary="Representante" secondary={data.representante}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CategoryIcon className={classes.customIconColor} />
                                </ListItemIcon>
                                <ListItemText primary="Rubro" secondary={data.descripcion}/>
                            </ListItem>
                            
                            <ListItem>
                                <ListItemIcon>
                                    <PhoneIcon className={classes.customIconColor} />                                
                                </ListItemIcon>
                                <ListItemText primary="Contactos" secondary={data.contacto}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <EmailIcon className={classes.customIconColor} />                                
                                </ListItemIcon>
                                <ListItemText primary="Email" secondary={data.correo_electronico}/>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Box>
            <Box 
                px={1}
                py={2}
                display="flex"
                justifyContent="center">
                <Button variant='contained' size="large" color="primary" onClick={() => downloadScreenshot()}>
                    Descargar tarjeta
                </Button>                
            </Box>            
        </>
    );
}


const useStylesCardServices = makeStyles(theme => ({
    root:{
        padding:theme.spacing(3,1),
        maxWidth:'350px'
    },
    media: {
        maxWidth:'250px',
        width:'100%'
    },
    title:{
        fontWeight:'bold'
    }
}));

const CardServicioGenerator = (props) => {    
    const classes = useStylesCardServices();
    const { data,logo,qr } = props.data;
    const ref = createRef(null);
    const [image, takeScreenShot] = useScreenshot({
        type: "image/jpeg",
        quality: 1.0
    });
    const download = (image, { name = "tarjeta", extension = "jpg" } = {}) => {
        let a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
        a=null;
    };
    const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

    return (
        <>
            <Box
                py={1   }
                display="flex"
                justifyContent="center">
                <Card ref={ref} className={classes.root}>
                    <CardContent>
                        <Box alignContent="center" textAlign="center">
                        <img
                            className={classes.media}
                            id='logoCocha'
                            src={ logo }
                            alt="Cocha Somos Progreso"/>
                        </Box>
                        <br />
                        <Divider/>
                        <br />
                        <Typography gutterBottom variant="h5" component="h2">
                            {data.descripcion_area}
                        </Typography>                        
                        <List>
                            <ListItem aria-label="delete">
                                <ListItemIcon>
                                    <PersonIcon className={classes.customIconColor} />
                                </ListItemIcon>
                                <ListItemText primary="Representante" secondary={ `${data.nombres} ${data.apellido_paterno}` }/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <PhoneIcon className={classes.customIconColor} />
                                </ListItemIcon>
                                <ListItemText primary="Contactos" secondary={ data.contacto }/>
                            </ListItem>
                        </List>
                        <Divider/>
                        <Box pt={3} alignContent="center" textAlign="center">                            
                            <Box>                    
                                <img src={`data:image/svg+xml;base64,${qr}`} style={{maxWidth:'100px'}}/>
                            </Box>                            
                        </Box>                        
                    </CardContent>
                </Card>
            </Box>
            <Divider/>            
            <Button variant='contained' size="large" color="primary" fullWidth onClick={()=>downloadScreenshot()}>Descargar tarjeta</Button>
        </>
    );
}

var banData = false;
const EmprendedurismoComponent2 = (props) => {
    //const [{ data, loading,error }, refetch] = useAxios('https://produce.cochabamba.bo/utils/request/productores2');
    //const [{ data:dataServicios, loading:loadingServicios,error:errorServicios }, refetchServicios] = useAxios('https://produce.cochabamba.bo/utils/request/servicios');
    const [ordenarpor,setOrdenarpor] = React.useState([{value:0,text:"Productor"},{value:1,text:"Representante"}]);
    const [data,setData] = React.useState(null);
    const [data2,setData2] = React.useState(null);
    const [loading,setLoading] = React.useState(true);
    const [error,setError] = React.useState(null);    
    
    const [currentProductorItems,setCurrentProductorItems] = React.useState(null);
    const [currentServiciosItems,setCurrentServiciosItems] = React.useState(null);

    const [tipoinformacion, setTipoinformacion] = React.useState('productos');
    const [opentipoinformacion, setOpentipoinformacion] = React.useState(false);

    const [categoria, setCategoria] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const [orden, setOrden] = React.useState(0);
    const [openOrden, setOpenOrden] = React.useState(false);

    const [orden2, setOrden2] = React.useState('asc');
    const [openOrden2, setOpenOrden2] = React.useState(false);

    const [txtSearch,setTxtSearch] = React.useState('');
    const [data2component,setData2component] = React.useState(null);
    const {datos={}} = props;
    const childRef = useRef();
    const logoRef = useRef();
    
    const classes = useStyles();   

    //VARIABLES PARA TIPO INFORMACION
    const handleChangeTipoinformacion = (event) => {
        setTipoinformacion(event.target.value);
        setOrdenarpor([{value:0,text:event.target.value=="productos"?"Productor":"Servicio"},{value:1,text:"Representante"}]);
        setOrden(0);
    };
    const handleCloseTipoinformacion = () => {
        setOpentipoinformacion(false);
    };
    const handleOpenTipoinformacion = () => {
        setOpentipoinformacion(true);
    };
    //END VARIABLES PARA TIPO INFORMACION

    //VARIABLES PARA RUBROS
    const handleChangeCategory = (event) => {
        //console.log("handleChangeCategory");
        setCategoria(event.target.value);
        //setDataWithFilter();
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    //END VARIABLES PARA RUBROS

    //VARIABLES PARA ORDENAR
    const handleChangeOrden = (event) => {
        setOrden(event.target.value);
    };
    const handleCloseOrden = () => {
        setOpenOrden(false);
    };
    const handleOpenOrden = () => {
        setOpenOrden(true);
    };
    //END VARIABLES PARA ORDENAR

    //VARIABLES PARA DIRECCIÓN
    const handleChangeOrden2 = (event) => {
        setOrden2(event.target.value);
    };
    const handleCloseOrden2 = () => {
        setOpenOrden2(false);
    };
    const handleOpenOrden2 = () => {
        setOpenOrden2(true);
    };
    //END VARIABLES PARA DIRECCIÓN

    const handleChange = (event) => {
        setTxtSearch(event.target.value);
    }
    const handleClickToClear = () => {
        setTxtSearch('');
    };

    const computedProductorData = (auxCurrentProductorItems) => {        
        let auxItems = auxCurrentProductorItems?auxCurrentProductorItems:[];
        let text2Search = String(txtSearch).toUpperCase();
        if(txtSearch.length>0){
            auxItems = auxItems.filter(element => String(element.nombre_productor).toUpperCase().indexOf(text2Search) != -1 || String(element.representante).toUpperCase().indexOf(text2Search) != -1 || String(element.contacto).toUpperCase().indexOf(text2Search) != -1 || String(element.correo_electronico).toUpperCase().indexOf(text2Search) != -1);            
        }

        if(categoria && categoria.toString().trim().length>0){
            auxItems = auxItems.filter(element => element.id_rubro==categoria);
        }

        if(orden == 0){
            if(orden2 == "asc"){
                auxItems.sort((a,b) => a.nombre_productor.localeCompare(b.nombre_productor));                
            }else{
                auxItems.sort((a,b) => b.nombre_productor.localeCompare(a.nombre_productor));                
            }
            
        }else if(orden == 1){
            if(orden2 == "asc"){
                auxItems.sort((a,b) => a.representante.localeCompare(b.representante));
            }else{
                auxItems.sort((a,b) => b.representante.localeCompare(a.representante));
            }
            
        }        
        return auxItems;
    }

    const computedSearchServicio = (items) => {
        let auxItems = items?items:[];
        let auxSearch = String(txtSearch).toUpperCase();
        if(txtSearch.length>1){            
            auxItems = items.filter(element => String(element.nombres).toUpperCase().indexOf(auxSearch) != -1 || String(element.descripcion_area).toUpperCase().indexOf(auxSearch) != -1 || String(element.contacto).toUpperCase().indexOf(auxSearch) != -1 || String(element.apellido_paterno).toUpperCase().indexOf(auxSearch) != -1);
        }

        if(orden == 0){
            if(orden2 == "asc"){
                auxItems.sort((a,b) => a.descripcion_area.localeCompare(b.descripcion_area));                
            }else{
                auxItems.sort((a,b) => b.descripcion_area.localeCompare(a.descripcion_area));                
            }
            
        }else if(orden == 1){
            if(orden2 == "asc"){
                auxItems.sort((a,b) => a.nombres.localeCompare(b.nombres));
            }else{
                auxItems.sort((a,b) => b.nombres.localeCompare(a.nombres));
            }
            
        }
        return auxItems;
    }
    const handleShow = (datos,qrImg) => {
        let img = logoRef.current;
        let canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        let base64Logo;
        try {            
            base64Logo = canvas.toDataURL('image/png');
        } catch (e) {
            console.error('Error:', e);            
        }

        childRef.current.openModal();
        setData2component({data:datos,qr:qrImg,logo:base64Logo});
    }
    const onShowServiceCardGenerator = (datos,qrBase64) => {
        let img = logoRef.current;
        let canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        let base64Logo;
        try {
            base64Logo = canvas.toDataURL('image/png');
        } catch (e) {
            console.error('Error:', e);
        }

        childRef.current.openModal();
        setData2component({data:datos,logo:base64Logo,qr:qrBase64});
    }
    const getRubro = (id_rubro) => {
        let result = null;
        if(data){
            result = data.rubros.find(element => element.id_rubro==id_rubro);
        }        
        return result?result.descripcion:'';
    }
    if(!banData && data && data.productores){
        banData=!banData;           
        data.productores.map(element => {
            let productos = data.productos.filter(item => item.id_productor===element.id_productor);
            element.productos = productos;
            return element;
        });        
    }
    
    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData = async () => {
            try {
                const [dataProductores, data2Servicios] = await Promise.all([
                    axios.get('https://produce.cochabamba.bo/utils/request/productores2'),
                    axios.get('https://produce.cochabamba.bo/utils/request/servicios')
                ]);
                //console.log("dataProductores.data.productos=",dataProductores.data.productores);
                //console.log("data2Servicios.data.servicios=",data2Servicios.data.servicios);
                setData(dataProductores.data);
                setData2(data2Servicios.data.servicios);
                setCurrentProductorItems(dataProductores.data.productores);
                setCurrentServiciosItems(data2Servicios.data.servicios);
                setLoading(false);
            } catch (error) {
                console.log("Error=",error);
                setError(error);
                setLoading(false);
            }
          };
        fetchData();
    }, []);

    return(
        <Box className={classes.root} style={datos && datos.style && datos.style.container?datos.style.container:null}>
            <img style={{display:'none'}} ref={logoRef} src={`${GLOBAL.assetsBack}/img/cocha-somos-innovacion.png`} alt="Cocha Somos Progreso"/>
            <ModalComponentViewer ref={childRef} size='sm' title={'DESCARGA DE TARJETA'} body={tipoinformacion==='productos'?<CardGenerator data={data2component}/>:<CardServicioGenerator data={data2component} />}/>
            <Grid container className={classes.customTabStyles} justify="center">
                <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                    <Box mb={3}>
                        {
                            datos.title?
                            <Typography className={classes.customTitle} variant="h3" style={datos.style.title}>{datos.title?datos.title:''}</Typography>:null
                        }                            
                        {
                            datos.description?                            
                            <Box className={classes.customSubTitle} px={1}>
                                {convert(datos.description?datos.description:'<p></p>')}
                            </Box>:null
                        }                        
                    </Box>
                </Grid>
            </Grid>
            
            <Grid container className={classes.customPadding} style={{paddingBottom:'20px',minHeight:'768px'}} justify="center">                
                <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>                    
                    <Grid container className={classes.customPadding}>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className={classes.customPadding}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="select1">Información a mostrar</InputLabel>
                                <Select
                                    labelId="select1"
                                    id="select1"
                                    open={opentipoinformacion}
                                    onClose={handleCloseTipoinformacion}
                                    onOpen={handleOpenTipoinformacion}
                                    value={tipoinformacion}
                                    onChange={handleChangeTipoinformacion}
                                >
                                    <MenuItem value="productos">
                                        Productos
                                    </MenuItem>
                                    <MenuItem value="servicios">
                                        Servicios
                                    </MenuItem>                                    
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className={classes.customPadding}>
                            <CampoBuscar
                                placeholder = 'Buscar'
                                txtSearch = {txtSearch}
                                handleChange = {handleChange}
                                handleClickToClear = {handleClickToClear}/>
                        </Grid>                        
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className={classes.customPadding}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="select-label-ordenar">Ordenar por:</InputLabel>
                                <Select
                                    labelId="select-label-ordenar"
                                    id="select-label-ordenar"
                                    open={openOrden}
                                    onClose={handleCloseOrden}
                                    onOpen={handleOpenOrden}
                                    value={orden}
                                    onChange={handleChangeOrden}
                                >
                                    {
                                        ordenarpor.map((item,index)=>(
                                            <MenuItem key={`ordenarpor${index}`} value={item.value}>{item.text}</MenuItem>       
                                        ))
                                    }                                    
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className={classes.customPadding}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="select-label-ordenar2">Dirección:</InputLabel>
                                <Select
                                    labelId="select-label-ordenar2"
                                    id="select-label-ordenar2"
                                    open={openOrden2}
                                    onClose={handleCloseOrden2}
                                    onOpen={handleOpenOrden2}
                                    value={orden2}
                                    onChange={handleChangeOrden2}
                                >
                                    <MenuItem value="asc">Ascendente</MenuItem>
                                    <MenuItem value="desc">Descendente</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className={classes.customPadding}>
                            {
                                data && tipoinformacion==='productos'?
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="demo-controlled-open-select-label">Filtrar por rubro</InputLabel>
                                        <Select
                                            labelId="demo-controlled-open-select-label"
                                            id="demo-controlled-open-select"
                                            open={open}
                                            onClose={handleClose}
                                            onOpen={handleOpen}
                                            value={categoria}
                                            onChange={handleChangeCategory}
                                        >
                                            <MenuItem value="">
                                                <em>Ninguno</em>
                                            </MenuItem>
                                            {
                                                data && data.rubros?data.rubros.map((item,index)=>(
                                                    <MenuItem key={item.id_rubro} value={item.id_rubro}>{item.descripcion}</MenuItem>
                                                )):null
                                            }
                                        </Select>
                                    </FormControl>:null
                            }
                            {
                                loading?
                                <Skeleton />:null
                            }
                        </Grid>
                    </Grid>
                    {
                        tipoinformacion==='productos'?
                        <Grid container className={classes.customPadding2} spacing={2}>
                            {
                                currentProductorItems?computedProductorData(currentProductorItems).map((row, index) => (
                                    <Grid key={'as'+index} item xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <CardEmprendedurismo2 data={row} onHandleChange={handleShow}/>
                                    </Grid>
                                )):null
                            }
                            {
                                loading ? Array.from(new Array(3)).map((row, index) => (
                                    <Grid key={'as'+index} item xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <CardEmprendedurismo2 key={index} loading/>
                                    </Grid>
                                )):null
                            }
                            {
                                error ?
                                    <ErrorRequestComponent variante="standard" /> :
                                    data && data.length == 0 ? <Alert severity="info">No hay ítems activos</Alert> : ''
                            }
                            
                        </Grid>:null
                    }
                    {
                        tipoinformacion==='servicios'?
                        <Grid container className={classes.customPadding2} spacing={2}>                        
                            {
                                currentServiciosItems?computedSearchServicio(currentServiciosItems).map((row, index) => (
                                    <Grid key={'as'+index} item xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <CardServicios data={row} onHandleChange={ onShowServiceCardGenerator } url={datos.url}/>
                                    </Grid>
                                )):
                                <Alert severity="error">No hay servicios registrados</Alert>
                            }
                            {
                                loading ? Array.from(new Array(4)).map((row, index) => (
                                    <CardServicios key={index} loading />)):null
                            }
                            {
                                error ?
                                    <ErrorRequestComponent variante="standard"/> :
                                    data && data.length == 0 ? <Alert severity="info">No hay ítems activos</Alert> : ''
                            }
                            
                        </Grid>:null
                    }
                </Grid>
            </Grid>
        </Box>
    );
}
export default EmprendedurismoComponent2;
