import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
//import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
//import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    customReloadStyle:{
        marginLeft:theme.spacing(1)
    }
  }));
/*
    PROPS:
    variante = enum['filled','outlined','standard']
*/
  const ErrorRequestComponent = (props) => {
    const classes = useStyles();
    const {severity="error" ,text = 'ERROR EN LA OBTENCIóN DE DATOS' } = props;      
    return(
            <>
                <Alert variant={props.variante?props.variante:'standard'} severity={severity}>
                    {text}
                    <IconButton className={classes.customReloadStyle} aria-label="delete" size="small" onClick={props.handleClick}>
                        <RefreshIcon fontSize="inherit" />
                    </IconButton>
                </Alert>
            </>
    );
}
ErrorRequestComponent.propTypes = {
    text:PropTypes.string,
}

export default ErrorRequestComponent;
