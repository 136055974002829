import React from "react";
import Box from "@material-ui/core/Box";
import Area from "./area";
import Cuadros from "./cuadros";
import { GLOBAL } from "../../../../../../global";
//import imagen from '../../images/lineagratuita.png';
const Areas = () => {
  return (
    <div>
      <Box m={2} textAlign="center">
        <Box m={2} p={1}>
          <Area />
        </Box>
        <Cuadros />
      </Box>
      <div className="lineaGratuita">
        <img src={`${GLOBAL.assetsBack}/img/secciones/defensoria-nines-adolescencia/lineagratuita.png`} width="100%" alt="Línea Gratuita" />
      </div>
    </div>
  );
}
export default Areas;
