import React from 'react';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CodeIcon from '@material-ui/icons/Code';
import GridOnIcon from '@material-ui/icons/GridOn';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Parallax} from 'react-parallax';
import {Carousel} from 'react-responsive-carousel';
import { withRouter } from 'react-router-dom';
import Helper from '../../Helper';
import {GLOBAL} from '../../global';
import CustomCardButtonComponent from '../genericos/buttons/custom-card-button/CustomCardButtonComponent';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,        
        padding:theme.spacing(5,1),
    },
    paper: {    
        padding: theme.spacing(4,1),    
        marginTop:theme.spacing(5),
        textAlign: 'center',
        height:'70%',   
        cursor:'pointer',
    },
    bigAvatar: {    
        marginTop:theme.spacing(-6),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: 60,
        height: 60,
    },
    customTitle:{
        textAlign:'center',
        color:theme.palette.primary.main
    },
    customSubTitle:{
        color:theme.palette.primary.main,
        //paddingBottom:theme.spacing(3),
        textAlign:'center',
    },
    rounded: {
        color: '#fff',
        backgroundColor: theme.palette.tertiary.main,
        width:theme.spacing(7),
        height:theme.spacing(7)
    },
    customMobile:{
        textAlign:'center',
        color:theme.palette.sextary.main
      }
}));
/*
const data2 = 
    {        
        title:"Otras secciones",
        description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita incidunt culpa earum ea doloremque, optio accusamus, ipsa saepe numquam distinctio adipisci consequatur ipsum nisi quae possimus cum laboriosam. Accusamus, dolorum!",
        style:{
            container:null,
            title:{color:'white'},
            subtitle:{color:'white'}
        },
        parallax:{
            enabled:true,
            bgimg:'/img/secciones/bg-default4-01.png',
            blur:0,
            strength:200
        },
        buttons:[
            {
                icon:"/img/secciones/card_proveedores.png",
                title:"BIENES Y SERVICIOS REQUERIDOS POR EL GAMC",
                description:"Para ofertar productos a GAMC, ingrese a este enlace",
                url: "/proveedores",
                type: "interno",
                data: null
            },
            {
                icon:"/img/secciones/card_atm.png",
                title:"ADMINISTRACIÓN TRIBUTARIA MUNICIPAL",
                description:"Registro e información sobre los trámites y acciones tributarias que se realizan en el Gobierno Municipal.",
                url: "https://atm.cochabamba.bo/",
                type: "externo",
                data: null
            },
            {
                icon:"/img/secciones/card_mapa2.png",
                title:"MAPA DIGITAL DE COCHABAMBA",
                description:"Obtenga su código catastral a través de nuestro Mapa Digital",
                url: "http://mapadigital.cochabamba.bo/public/generado2",
                type: "externo",
                data: null
            },            
            {
                icon:"/img/secciones/card_miplano.png",
                title:"MI PLANO",
                description:"Ley Municipal de Regularización de Lotes y Edificaciones",
                url: "/mi-plano",
                type: "interno",
                data: null
            },
            {
                icon:"/img/secciones/card_auditoria-interna.png",
                title:"AUDITORÍA INTERNA",
                description:"Actividad independiente y objetiva de supervisión, una consultoría diseñada para agregar valor y mejorar las operaciones de una organización.",
                url: "/auditoria-interna",
                type: "interno",
                data: null
            },
            {
                icon:"/img/secciones/card_gaceta.png",
                title:"GACETA MUNICIPAL",
                description:"Acceso a información precisa acerca de la legalidad y la transparencia en la que nos enmarcamos.",
                url: "/gaceta",
                type: "interno",
                data: null
            }
        ]
    };
*/
const MyComponent = (props) => {
    const { data = {} } = props;
    const matches = useMediaQuery('(min-width:600px)');
    const classes = useStyles();
    const [grid, setGrid] = useState(data && data.options?data.options.grid:false);
    const doSomething = (dato) => {        
        props.onHandleClick(dato);
    }

    return(
        <Grid container className={classes.root} justify='center' style={data.style.container}>
            <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                <Box mb={3}>
                    <Typography className={classes.customTitle} variant="h3" style={data.style.title}>{data.title?data.title:''}</Typography>
                    <Typography className={classes.customSubTitle} variant="h6" style={data.style.subtitle}>{data.description?data.description:''}</Typography>
                </Box>
                {
                    matches?
                    <Grid container spacing={2} justify="center">
                        {
                            data.buttons?data.buttons.map((item,index)=>(
                                <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={3} align="center">
                                    <CustomCardButtonComponent info={item}/>                                
                                </Grid>
                            )):null
                        }
                    </Grid>:
                    <>                        
                        {
                            data.options && data.options.showOptions?
                            <Box alignContent="center" pb={3}>
                                <Button size="small" variant="contained" color="secondary" onClick={()=>setGrid(!grid)} startIcon={grid?<CodeIcon/>:<GridOnIcon/>}>{grid?"Modo carrusel":"Modo grid"}</Button>
                            </Box>:null
                        }
                        {
                            !grid?
                            <Carousel centerMode centerSlidePercentage={100} emulateTouch infiniteLoop useKeyboardArrows autoPlay showThumbs={false} stopOnHover interval={5000} showIndicators={false}>
                                {
                                    data && data.buttons?
                                    data.buttons.map((item,index)=>(
                                        <div key={`j${index}`}>
                                            <CustomCardButtonComponent info={item}/>                                
                                        </div>
                                    )):null
                                }
                            </Carousel>:                                                                                                
                            <Grid container spacing={1} justify="center">
                                {
                                    data.buttons?data.buttons.map((item,index)=>(
                                        <Grid key={`i${index}`} item xs={3} align="center" onClick={()=>doSomething({url:item.url,type:item.type})} style={{cursor:'pointer'}}>                                            
                                            <Avatar variant="rounded" className={classes.rounded} style={data.options && data.options.iconStyle?data.options.iconStyle:null}>
                                                <img src={`${GLOBAL.assetsBack}${item.icon}`} alt={item.title} style={{maxWidth:'50px'}}/>
                                            </Avatar>                                            
                                            <Typography variant="caption" className={classes.customMobile}>
                                                {item.title}
                                            </Typography>
                                        </Grid>
                                    )):null
                                }
                            </Grid>                            
                        }                        
                    </>                    
                }
            </Grid>
        </Grid>
    );
}

const TitleWithCards2 = (props) =>{    
    const { data={} } = props;
    const doSomething = (dato) => {        
        Helper.checkLink(props,dato);
    }
    return data.parallax.enabled?
    <Parallax blur={data.parallax.blur?data.parallax.blur:0} bgImage={`${GLOBAL.assetsBack}${data.parallax.bgimg}`} bgImageAlt="Background image" strength={data.parallax.strength?data.parallax.strength:0}>
        <MyComponent data={data} onHandleClick={(dato)=>doSomething(dato)}/>
    </Parallax>:<MyComponent data={data} onHandleClick={(dato)=>doSomething(dato)}/>
    
}

export default withRouter(TitleWithCards2);