import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import FontAwesome from 'react-fontawesome';
import useAxios from 'axios-hooks';
import convert from 'htmr';
import Paper from '@material-ui/core/Paper';

import ErrorRequestComponent from '../../../../components/genericos/error/ErrorRequestComponent';
//COMPONENTES PERSONALIZADOS
import Lista from './Lista';
import Pasos from './Pasos';
import Temporizador from './Temporizador';

import {GLOBAL} from '../../../../global';
import { Grid } from '@material-ui/core';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    customMainTitle:{
        marginLeft:theme.spacing(1),
        color:theme.palette.primary.main,
        fontWeight:'bold'
    },
    customTitle:{
        padding:theme.spacing(2,1),
        fontWeight:'bold',
        color:theme.palette.primary.main
    },
    customTabColor:{
        color:'white'
    },
    customIconFab:{
        color:theme.palette.secondary.main
    }
}));

const DetalleComponent = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [{ data, loading, error }, refetch] = useAxios(
        {
            //url:`http://localhost:8100/api/detalle-info-tramite`,
            url:`https://innova.cochabamba.bo/api/detalle-info-tramite`,
            method:'POST',
            data:{
                nro:props.nro
            }
        },{useCache:false});
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Paper>
            {
                error?
                <ErrorRequestComponent 
                    variante="standard" 
                    handleClick={() => refetch}
                />:null
            }
            {
                loading?
                <Skeleton/>:null
            }
            {
                data && data.data?
                <>
                    <Paper>
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={12} md={12}>
                                <Box my={3} px={1}>
                                    <Typography variant="h5" className={classes.customTitle}>{String(props.nombre).toUpperCase()}</Typography>
                                </Box>
                            </Grid>
                            {
                                false?
                                <Grid item xs={12} sm={4} md={2} style={{textAlign:'center'}}>
                                    <Button variant="contained" size="large" color="secondary" onClick={()=>window.open("https://innova.cochabamba.bo", "_blank")}>INICIAR TRÁMITE</Button>
                                </Grid>:false
                            }
                            
                        </Grid>
                    </Paper>
                    <AppBar position="static" color="primary">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="on"
                            indicatorColor="secondary"
                            textColor="secondary"
                            aria-label="scrollable force tabs example"
                            
                        >
                            {
                                data.data.map((item,index)=>(
                                    <Tab key={`tab${index}`} label={item.nombre} className={classes.customTabColor} icon={<FontAwesome name={item.icono} size="2x"/>} {...a11yProps(index)} />        
                                ))
                            }                            
                        </Tabs>
                    </AppBar>                    
                    {
                        data.data.map((item,index)=>(
                            <TabPanel key={`tabpanel${index}`} value={value} index={index}>
                                <React.Fragment>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td><FontAwesome name={item.icono} size="2x" className={classes.customIconFab}/></td>
                                                <td>
                                                <Typography variant="h6" gutterBottom component="span" className={classes.customMainTitle}>
                                                    {String(item.descripcion).toUpperCase()}
                                                </Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>                                    
                                    {
                                        item.componente=='lista'?
                                        <Lista data={JSON.parse(item.data)}/>:null
                                    }
                                    {
                                        item.componente=='html'?
                                        convert(item.data?item.data:'<p></p>'):null
                                    }
                                    {
                                        item.componente=='pasos'?
                                        <Pasos data={JSON.parse(item.data)}/>:null
                                    }
                                    {
                                        item.componente=='temporizador'?
                                        <Temporizador data={JSON.parse(item.data)}/>:null
                                    }
                                </React.Fragment>
                            </TabPanel>
                        ))
                    }
                </>:null
            }            
        </Paper>
    );
}

export default DetalleComponent;