import React,{useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import GetAppIcon from '@material-ui/icons/GetApp';
//import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import MovieIcon from '@material-ui/icons/Movie';
import LinkIcon from '@material-ui/icons/Link';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Drawer from '@material-ui/core/Drawer';
import { pink } from '@material-ui/core/colors';
import clsx from 'clsx';

import ListItemText from '@material-ui/core/ListItemText';
import { Divider } from '@material-ui/core';

import ReactPlayer from 'react-player';
import { Parallax } from 'react-parallax';

import PortadaMaterialUi from '../../../components/genericos/portadas/portada-material-ui/PortadaMaterialUi';
import ModalPdfViewerComponent from '../../../components/genericos/modal/ModalPdfViewerComponent';
import ModalComponentViewer from '../../../components/genericos/modal/ModalComponentViewer';
import GraficaComponent from './component/GraficaComponent';
import ModalComunicadoComponent from './component/ModalComunicadoComponente';

import {GLOBAL} from '../../../global';

const { useRef } = React;
const useStyles = makeStyles((theme) => ({
  root: {
    width: 'fit-content',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
  customBoxContainer:{
    border:'1px solid #a71e1e',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  firstSecction:{
    margin:theme.spacing(5,1)
  },
  secondSection:{
    padding:theme.spacing(5,1),
    color:theme.palette.common.white,
  },
  secondSectionA:{
    padding:(0,10)
  },
  customTableHeader:{
    padding:theme.spacing(1,1),
    color:theme.palette.common.white,
    backgroundColor:"#a71e1e",
  },
  customImageContainer:{
    padding:theme.spacing(0,2),
  },
  customBox:{
    width:'100%'
  },
  customList:{
    width:'100%'
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  customListSubtitle:{
    lineHeight:'20px',
    textAlign:'center',
    margin:theme.spacing(2,1),
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
}));

const mapaCatastroUrl = 'http://mapadigital.cochabamba.bo/public/generado2';

const dataPortada = {
    bgImg:GLOBAL.assetsBack+'/img/secciones/mi-plano/miplano.jpg',    
    logoImg:null,
    style:{
        title:{
            textAlign:'center'
        },
        description:{
            textAlign:'center'
        }
    },
    title:'',
    description:[
        ''
    ],
    btnData:{
        label:'CONTINUAR',
        href:''
    }
}

const MiPlanoComponent = (props) => {
  //const [data2component,setData2component] = React.useState(null);
  const [modalcomponent,setModalcomponent] = React.useState({titulo:'',url:''});
  const [state, setState] = React.useState({show:false,anchor: "left",opcion:""});
  const classes = useStyles();
  const childRef = useRef();
  const pdfViewerRef = useRef();
  
  const handleChange = (archivo) => (event, isExpanded) => {
    //setExpanded(isExpanded ? panel : false);
    //console.log("Debe abrir el documento panel=",archivo);
    pdfViewerRef.current.openModal({title:"",archivo:GLOBAL.assetsBack+archivo});
  };
  const showModalComponent = (data) => {
    setModalcomponent({titulo:data.titulo,url:data.url});
    childRef.current.openModal();
  };

  const onHandleDownload = (event,patharchivo,archivo) => {
    let atag = document.createElement('a');
    atag.setAttribute('href',GLOBAL.assetsBack+patharchivo);
    atag.setAttribute('download',archivo?archivo:'archivo');
    atag.click();
    atag = null;
  };

  const toggleDrawer = (data) => (event) => {
    //console.log("data=",data);
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState(data);
  };
  
  const onHandleOptions = (data) => {
    //console.log("data=",data);
    switch(data.opcion){
      case 'leyes_decretos':
        setState(data);
        break;
      case 'codigo_catastral':
        window.open(mapaCatastroUrl,'_blank');
        break;
      case 'transparentando':
        setState(data);
        break;
      case 'actualizacion_datos_tecnicos':
        setState(data);
        break;
      case 'consulta_deuda_inmueble':
        window.open('https://www.ruat.gob.bo/inmuebles/consultageneral/InicioBusquedaInmueble.jsf','_blank');
        break;
      case 'sectoriales':
        setState(data);
        break;
    }
    
  }

  const showPdf = (archivo) => {    
    pdfViewerRef.current.openModal({title:"",archivo:GLOBAL.assetsBack+archivo});
  };

  const list = (anchor,opcion) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer({show:false,anchor:anchor,opcion:''})}
      onKeyDown={toggleDrawer({show:false,anchor:anchor,opcion:''})}
    >
    {
      opcion === 'leyes_decretos'?
      <>        
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader" className={classes.customListSubtitle}>
              LEYES Y DECRETOS MUNICIPALES
            </ListSubheader>
          }
        >
          <ListItem button onClick={() => showPdf('/front/documents/mi-plano/decretos-leyes-municipales/1-Ley_Municipal_0204-2017.pdf')}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <PictureAsPdfIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="LEY MUNICIPAL" secondary="NRO 0204/2017" />
          </ListItem>
          <ListItem button onClick={() => showPdf('/front/documents/mi-plano/decretos-leyes-municipales/LEY_MUNICIPAL_274-2018.pdf')}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <PictureAsPdfIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="LEY MUNICIPAL" secondary="NRO 0274/2018" />
          </ListItem>
          <ListItem button onClick={() => showPdf('/front/documents/mi-plano/decretos-leyes-municipales/Ley_Municipal_0316-2018.pdf')}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <PictureAsPdfIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="LEY MUNICIPAL" secondary="NRO 0316/2018" />
          </ListItem>
          <ListItem button onClick={() => showPdf('/front/documents/mi-plano/decretos-leyes-municipales/Ley_Municipal_0425-2019.pdf')}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <PictureAsPdfIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="LEY MUNICIPAL" secondary="NRO 0425/2019" />
          </ListItem>
          <ListItem button onClick={() => showPdf('/front/documents/mi-plano/decretos-leyes-municipales/566_ley.pdf')}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <PictureAsPdfIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="LEY MUNICIPAL" secondary="NRO 0566/2019" />
          </ListItem>
          <ListItem button onClick={() => showPdf('/front/documents/mi-plano/decretos-leyes-municipales/LEY_MUNICIPAL_662-2020.pdf')}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <PictureAsPdfIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="LEY MUNICIPAL" secondary="NRO 0662/2020" />
          </ListItem>         
          <ListItem button onClick={() => showPdf('/front/documents/mi-plano/decretos-leyes-municipales/LEY_856_2020.pdf')}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <PictureAsPdfIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="LEY MUNICIPAL" secondary="NRO 0856/2020" />
          </ListItem>          
        </List>
        <Divider />
        <List>
          <ListItem button onClick={() => showPdf('/front/documents/mi-plano/decretos-leyes-municipales/Decreto_Municipal_117_2018.pdf')}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <PictureAsPdfIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="DECRETO MUNICIPAL" secondary="NRO 0117/2018" />
          </ListItem>
          <ListItem button onClick={() => showPdf('/front/documents/mi-plano/decretos-leyes-municipales/DECRETO_MUNICIPAL_185-2020.pdf')}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <PictureAsPdfIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="DECRETO MUNICIPAL" secondary="NRO 0185/2020" />
          </ListItem>
        </List>
      </>:null      
    }
    {
      opcion === 'transparentando'?
      <>        
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader" className={classes.customListSubtitle}>
              TRANSPARENTANDO
            </ListSubheader>
          }
        >
          <ListItem button onClick={() => alert("Documento no disponible.")}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <PictureAsPdfIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="REPORTE GENERAL"/>
          </ListItem>
          <ListItem button onClick={() => showPdf('/front/documents/mi-plano/reportes/Reporte_hasta_el_29_de_junio_2018-MI_PLANO.pdf')}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <PictureAsPdfIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="REPORTE HASTA EL 29/06/2018"/>
          </ListItem>
          <ListItem button onClick={() => showPdf('/front/documents/mi-plano/reportes/Reporte_desde_el_9-10-2017_hasta_el_6-07-2018-MI_PLANO.pdf')}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <PictureAsPdfIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="REPORTE DEL 09/10/2017 HASTA EL 06/07/2018" />
          </ListItem>
          <ListItem button onClick={() => showPdf('/front/documents/mi-plano/reportes/Reporte_del_9_de_julio_2018-MI_PLANO.pdf')}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <PictureAsPdfIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="REPORTE DEL 09/07/2018"/>
          </ListItem>
          <ListItem button onClick={() => showPdf('/front/documents/mi-plano/reportes/Reporte_del_10_de_julio_2018-MI_PLANO.pdf')}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <PictureAsPdfIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="REPORTE DEL 10/07/2018"/>
          </ListItem>
          <ListItem button onClick={() => showPdf('/front/documents/mi-plano/reportes/Reporte_del_11_de_julio_2018-MI_PLANO.pdf')}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <PictureAsPdfIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="REPORTE DEL 11/07/2018"/>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={() => showPdf('/front/documents/mi-plano/reportes/MAPAS_DE_ACOGIMIENTOS_HASTA_EL_6_JULIO_DE_2018-MI PLANO.pdf')}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <PictureAsPdfIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="MAPA DE ACOGIMIENTO HASTA EL 06/07/2018"/>
          </ListItem>          
        </List>
      </>:null
    }
    {
      opcion === 'actualizacion_datos_tecnicos'?
      <>        
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader" className={classes.customListSubtitle}>
              ACTUALIZACIÓN DE DATOS TÉCNICOS
            </ListSubheader>
          }
        >
          <ListItem button onClick={() => window.open('http://www.catastrocbba.com/form-login','_blank')}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <LinkIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="FORMULARIO ACTUALIZACIÓN DE DATOS"/>
          </ListItem>
          <ListItem button onClick={() => showModalComponent({titulo:'TUTORIAL AVALÚO CATASTRAL',url:'/front/documents/mi-plano/videos/tutorial_avaluo_catastral.mp4'})}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <MovieIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="TUTORIAL AVALÚO CATASTRAL"/>
          </ListItem>
          <ListItem button onClick={() => showModalComponent({titulo:'VERIFICACIÓN DE DATOS TÉCNICOS DEL INMUEBLE MEDIANTE EL SISTEMA RUAT',url:'/front/documents/mi-plano/videos/RUAT.mp4'})}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <MovieIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="VERIFICACIÓN DE DATOS TÉCNICOS DEL INMUEBLE MEDIANTE EL SISTEMA RUAT" />
          </ListItem>
        </List>
      </>:null
    }
    {
      opcion === 'sectoriales'?
      <>
        <Typography variant="h6" align="center">SECTORIALES</Typography>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader" className={classes.customListSubtitle}>
              1. QGIS
            </ListSubheader>
          }
        >
          <ListItem button onClick={() => window.open('https://www.qgis.org/es/site/forusers/download.html','_blank')}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <GetAppIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="DESCARGAR SOFTWARE"/>
          </ListItem>          
        </List>
        <Divider/>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader" className={classes.customListSubtitle}>
              2. LINK'S
            </ListSubheader>
          }
        >
          <ListItem button onClick={(event) => onHandleDownload(event,'/front/documents/mi-plano/links.txt','links.txt')}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <GetAppIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="DESCARGAR LINK'S"/>
          </ListItem>          
        </List>
        <Divider/>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader" className={classes.customListSubtitle}>
              3. TUTORIALES
            </ListSubheader>
          }
        >
          <ListItem button onClick={() => showModalComponent({titulo:'1. INSTALACIÓN DEL QGIS',url:'/front/documents/mi-plano/videos/Instalacion_QGIS.mp4'})}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <GetAppIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="1. INSTALACIÓN DEL QGIS"/>
          </ListItem>
          <ListItem button onClick={() => showModalComponent({titulo:'2. LAYERS Y MEDICIONES',url:'/front/documents/mi-plano/videos/Layers_y_Mediciones.mp4'})}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <GetAppIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="2. LAYERS Y MEDICIONES"/>
          </ListItem>
        </List>
        <Divider/>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader" className={classes.customListSubtitle}>
              4. PLANO ZONAS HOMOGENEAS
            </ListSubheader>
          }
        >
          <ListItem button onClick={() => showPdf('/front/documents/mi-plano/ZONAS_HOMOGENEAS.pdf')}>
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <GetAppIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="PLANO ZONAS HOMOGENEAS"/>
          </ListItem>          
        </List>
      </>:null
    }
    </div>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <ModalComunicadoComponent show/>
      <PortadaMaterialUi data={dataPortada}/>
      <ModalComponentViewer ref={childRef} title={modalcomponent.titulo} body={<ReactPlayer align="center" url={GLOBAL.assetsBack+modalcomponent.url} controls={true} width="100%"/>}/>
      <ModalPdfViewerComponent btnclose={'Cerrar'} ref={pdfViewerRef}/>
      <Drawer anchor={state.anchor} open={state.show} onClose={toggleDrawer({show:false,anchor:state.anchor,opcion:''})}>
        {list(state.anchor,state.opcion)}
      </Drawer>
      <Grid container className={classes.firstSecction}>
          <Grid item xs={12} sm={12} md={1} lg={1} xl={3}></Grid>
          <Grid item xs={12} sm={12} md={10} lg={10} xl={6}>
            <Grid container className={classes.firstSecction}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <br/>
                <Typography variant="h4" align="center">CÓDIGO CATASTRAL</Typography>
                <br/>            
                <Box align="center">              
                  <Button variant="contained" color="secondary" onClick={() => window.open(mapaCatastroUrl, "_blank")}>
                    INGRESAR AL MAPA DIGITAL DE COCHABAMBA
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <br/>
                <Typography variant="h4" align="center">SISTEMA DE INSCRIPCIÓN</Typography>
                <br/>            
                <Box align="center">              
                  <Button variant="contained" color="secondary" onClick={() => window.open("https://miplano.cochabamba.bo/Login/SegundaEtapaWeblogin", "_blank")}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SEGUNDA ETAPA ONLINE &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Button>
                </Box>
              </Grid>
            </Grid>            
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1} xl={3}></Grid>
      </Grid>
      <Parallax
          //blur={10}
          bgImage={GLOBAL.assetsBack+'/img/secciones/mi-plano/fondoRojo.jpg'}
          bgImageAlt="Mi Plano"
          strength={200}
          >
          <Grid container>
              <Grid item xs={12} sm={12} md={1} lg={1} xl={3}></Grid>
              <Grid item xs={12} sm={12} md={10} lg={10} xl={6} className={classes.secondSection}>
                <GraficaComponent onHandleOptions={onHandleOptions}/>
                <Typography variant="h4" align="center">REQUISITOS</Typography>
                <Grid container>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.secondSectionA}>
                    <br/>
                    <Typography variant="h4" align="center">1ra ETAPA</Typography>
                    <Typography variant="h6" align="center">REQUISITOS DE INSCRIPCIÓN</Typography>
                    <br/>
                    <ExpansionPanel expanded={false} onChange={handleChange('/front/documents/mi-plano/Requisitos_1_Etapa.pdf')}>
                      <ExpansionPanelSummary
                        expandIcon={<PictureAsPdfIcon color="secondary"/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography className={classes.heading}>1. CÓDIGO CATASTRAL</Typography>
                      </ExpansionPanelSummary>                      
                      <ExpansionPanelDetails>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel expanded={false} onChange={handleChange('/front/documents/mi-plano/Requisitos_1_Etapa.pdf')}>
                      <ExpansionPanelSummary
                        expandIcon={<PictureAsPdfIcon color="secondary"/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <Typography className={classes.heading}>2. COMPROBANTE DE PAGO ÚNICO (ORIGINAL) BANCO UNIÓN</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel expanded={false} onChange={handleChange('/front/documents/mi-plano/Requisitos_1_Etapa.pdf')}>
                      <ExpansionPanelSummary
                        expandIcon={<PictureAsPdfIcon color="secondary"/>}
                        aria-controls="panel3a-content"
                        id="panel3a-header">
                        <Typography className={classes.heading}>3. FOTOCOPIA SIMPLE C.I. DEL O LOS PROPIETARIOS, POSEEDOR O APODERADO</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>                        
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel expanded={false} onChange={handleChange('/front/documents/mi-plano/Requisitos_1_Etapa.pdf')}>
                      <ExpansionPanelSummary
                        expandIcon={<PictureAsPdfIcon color="secondary"/>}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                      >
                        <Typography className={classes.heading}>4. DOS FOTOGRAFÍAS DEL LOTE Y/O EDIFICACIÓN IMPRESAS.</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>                        
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </Grid>



                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.secondSectionA}>
                    <br/>
                    <Typography variant="h4" align="center">2da ETAPA</Typography>                    
                    <Typography variant="h6" align="center">ANEXOS - DECRETO MUNICIPAL Nº 117/2018</Typography>
                    <br/>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon color="secondary" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography className={classes.heading}>1. CARIMBOS, EJEMPLOS Y TUTORIALES</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Box className={classes.customBox}>
                          <ExpansionPanel>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon color="secondary" />}
                            >
                              <Typography className={classes.heading}>CARIMBOS</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              {
                                false?
                                <List component="nav" className={classes.customList}>
                                  <ListItem button onClick={(event) => onHandleDownload(event,'/front//documents/mi-plano/Divisiones_de_lotes_consolidados.dwg','Divisiones de lotes consolidados.dwg')}>
                                    <ListItemIcon>
                                      <GetAppIcon color="secondary"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Divisiones de lotes consolidados" />
                                  </ListItem>
                                  <ListItem button onClick={(event) => onHandleDownload(event,'/front//documents/mi-plano/Edificaciones.dwg','Edificaciones.dwg')}>
                                    <ListItemIcon>
                                      <GetAppIcon color="secondary"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Edificaciones" />
                                  </ListItem>
                                  <ListItem button onClick={(event) => onHandleDownload(event,'/front//documents/mi-plano/Grupales.dwg','Grupales.dwg')}>
                                    <ListItemIcon>
                                      <GetAppIcon color="secondary"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Grupales" />
                                  </ListItem>
                                  <ListItem button onClick={(event) => onHandleDownload(event,'/front//documents/mi-plano/Lotes.dwg','Lotes.dwg')}>
                                    <ListItemIcon>
                                      <GetAppIcon color="secondary"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Lotes" />
                                  </ListItem>
                                  <ListItem button onClick={(event) => onHandleDownload(event,'/front//documents/mi-plano/Propiedad_Horizontal.dwg','Propiedad Horizontal.dwg')}>
                                    <ListItemIcon>
                                      <GetAppIcon color="secondary"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Propiedad Horizontal" />
                                  </ListItem>
                                </List>:null
                              }

                          <Box>
                          <ExpansionPanel>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon color="secondary" />}
                            >
                              <Typography className={classes.heading}>LOTES</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <List component="nav" className={classes.customList}>
                                <ListItem button onClick={(event) => onHandleDownload(event, '/front/documents/mi-plano/carimbos/REFERENCIA_GRAFICA_PARA_LOTES.pdf', 'REFERENCIA GRAFICA PARA LOTES.pdf')}>
                                  <ListItemIcon>
                                    <GetAppIcon color="secondary" />
                                  </ListItemIcon>
                                  <ListItemText primary="REFERENCIA GRÁFICA PARA LOTES" />
                                </ListItem>
                                <ListItem button onClick={(event) => onHandleDownload(event, '/front/documents/mi-plano/carimbos/REGULARIZACION_PLANO_DE_ANEXION_DE_LOTE.pdf', 'REGULARIZACIÓN PLANO DE ANEXIÓN DE LOTE.pdf')}>
                                  <ListItemIcon>
                                    <GetAppIcon color="secondary" />
                                  </ListItemIcon>
                                  <ListItemText primary="REGULARIZACIÓN PLANO DE ANEXIÓN DE LOTE" />
                                </ListItem>
                                <ListItem button onClick={(event) => onHandleDownload(event, '/front/documents/mi-plano/carimbos/REGULARIZACION_PLANO_DE_LOTE_CON_APROBACION_PREVIA.pdf', 'REGULARIZACIÓN PLANO DE LOTE CON APROBACIÓN PREVIA.pdf')}>
                                  <ListItemIcon>
                                    <GetAppIcon color="secondary" />
                                  </ListItemIcon>
                                  <ListItemText primary="REGULARIZACIÓN PLANO DE LOTE CON APROBACIÓN PREVIA" />
                                </ListItem>
                                <ListItem button onClick={(event) => onHandleDownload(event, '/front/documents/mi-plano/carimbos/REGULARIZACION_PLANO_DE_LOTE_SIN_APROBACION_PREVIA.pdf', 'REGULARIZACIÓN PLANO DE LOTE SIN APROBACIÓN PREVIA.pdf')}>
                                  <ListItemIcon>
                                    <GetAppIcon color="secondary" />
                                  </ListItemIcon>
                                  <ListItemText primary="REGULARIZACIÓN PLANO DE LOTE SIN APROBACIÓN PREVIA" />
                                </ListItem>
                                <ListItem button onClick={(event) => onHandleDownload(event, '/front/documents/mi-plano/carimbos/REGULARIZACION_PLANO_DE_SUBDIVISION_DE_LOTE.pdf', 'REGULARIZACIÓN PLANO DE SUBDIVISIÓN DE LOTE.pdf')}>
                                  <ListItemIcon>
                                    <GetAppIcon color="secondary" />
                                  </ListItemIcon>
                                  <ListItemText primary="REGULARIZACIÓN PLANO DE SUBDIVISIÓN DE LOTE" />
                                </ListItem>
                                <ListItem button onClick={(event) => onHandleDownload(event, '/front/documents/mi-plano/carimbos/REGULARIZACION_PLANO_DE_SUBDIVISION_DE_LOTE_2.pdf', 'REGULARIZACIÓN PLANO DE SUBDIVISIÓN DE LOTE 2.pdf')}>
                                  <ListItemIcon>
                                    <GetAppIcon color="secondary" />
                                  </ListItemIcon>
                                  <ListItemText primary="REGULARIZACIÓN PLANO DE SUBDIVISIÓN DE LOTE 2" />
                                </ListItem>
                              </List>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                          <ExpansionPanel>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon color="secondary" />}
                            >
                              <Typography className={classes.heading}>EDIFICACIONES</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <List component="nav" className={classes.customList}>
                                <ListItem button onClick={(event) => onHandleDownload(event, '/front/documents/mi-plano/carimbos/REFERENCIA_GRAFICA_PARA_EDIFICACION.pdf', 'REFERENCIA GRÁFICA PARA EDIFICACIÓN.pdf')}>
                                  <ListItemIcon>
                                    <GetAppIcon color="secondary" />
                                  </ListItemIcon>
                                  <ListItemText primary="REFERENCIA GRÁFICA PARA EDIFICACIÓN" />
                                </ListItem>
                                <ListItem button onClick={(event) => onHandleDownload(event, '/front/documents/mi-plano/carimbos/REG_PLA_ARQ_DE_REMOD_Y-O_AMPLIACION EN_EDIF_MENOR_O_IGUALES_A_4.pdf', 'REG PLA ARQ DE REMOD Y-O AMPLIACIÓN EN EDIF MENOR O IGUALES A 4.pdf')}>
                                  <ListItemIcon>
                                    <GetAppIcon color="secondary" />
                                  </ListItemIcon>
                                  <ListItemText primary="REG PLA ARQ DE REMOD Y-O AMPLIACIÓN EN EDIF MENOR O IGUALES A 4" />
                                </ListItem>
                                <ListItem button onClick={(event) => onHandleDownload(event, '/front/documents/mi-plano/carimbos/REGULARIZACION_PLANO_ARQUITECTONICO_DE_REMODELACION_MENORES_O_IGUALES_A_4_PLANTAS.pdf', 'REGULARIZACIÓN PLANO ARQUITECTÓNICO DE REMODELACIÓN MENORES O IGUALES A 4 PLANTAS.pdf')}>
                                  <ListItemIcon>
                                    <GetAppIcon color="secondary" />
                                  </ListItemIcon>
                                  <ListItemText primary="REGULARIZACIÓN PLANO ARQUITECTÓNICO DE REMODELACIÓN MENORES O IGUALES A 4 PLANTAS" />
                                </ListItem>                                
                              </List>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                          </Box>
                         






                            </ExpansionPanelDetails>
                          </ExpansionPanel>                          
                          <ExpansionPanel>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon color="secondary" />}
                            >
                              <Typography className={classes.heading}>EJEMPLOS</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Box>
                                <ExpansionPanel>
                                  <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon color="secondary" />}
                                  >
                                    <Typography className={classes.heading}>LOTES</Typography>
                                  </ExpansionPanelSummary>
                                  <ExpansionPanelDetails>
                                    <List component="nav" className={classes.customList}>
                                      <ListItem button onClick={(event) => onHandleDownload(event, '/front/documents/mi-plano/ejemplos/PLANO_REG_ANEXION_DE_LOTE.pdf', 'PLANO REG ANEXION DE LOTE.pdf')}>
                                        <ListItemIcon>
                                          <GetAppIcon color="secondary" />
                                        </ListItemIcon>
                                        <ListItemText primary="PLANO REG. ANEXIÓN DE LOTE" />
                                      </ListItem>
                                      <ListItem button onClick={(event) => onHandleDownload(event, '/front/documents/mi-plano/ejemplos/PLANO_REG_LOTE_CON_APROBACION_PREVIA.pdf', 'PLANO REG LOTE CON APROBACIÓN PREVIA.pdf')}>
                                        <ListItemIcon>
                                          <GetAppIcon color="secondary" />
                                        </ListItemIcon>
                                        <ListItemText primary="PLANO REG LOTE CON APROBACIÓN PREVIA" />
                                      </ListItem>
                                      <ListItem button onClick={(event) => onHandleDownload(event, '/front/documents/mi-plano/ejemplos/PLANO_REG_LOTE_SIN_APROBACION_PREVIA.pdf', 'PLANO REG LOTE SIN APROBACIÓN PREVIA.pdf')}>
                                        <ListItemIcon>
                                          <GetAppIcon color="secondary" />
                                        </ListItemIcon>
                                        <ListItemText primary="PLANO REG LOTE SIN APROBACIÓN PREVIA" />
                                      </ListItem>
                                    </List>
                                  </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                  <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon color="secondary" />}
                                  >
                                    <Typography className={classes.heading}>EDIFICACIONES</Typography>
                                  </ExpansionPanelSummary>
                                  <ExpansionPanelDetails>
                                    <List component="nav" className={classes.customList}>
                                      <ListItem button onClick={(event) => onHandleDownload(event, '/front/documents/mi-plano/ejemplos/CONSTRUCCIONES_MI_PLANO.pdf', 'CONSTRUCCIONES MI PLANO.pdf')}>
                                        <ListItemIcon>
                                          <GetAppIcon color="secondary" />
                                        </ListItemIcon>
                                        <ListItemText primary="CONSTRUCCIONES MI PLANO" />
                                      </ListItem>
                                      <ListItem button onClick={(event) => onHandleDownload(event, '/front/documents/mi-plano/ejemplos/OCUPACION_FAJA_JARDIN_PARAGRAFO_III_ARTI_33.pdf', 'OCUPACIÓN FAJA JARDIN PARAGRAFO III ARTI 33.pdf')}>
                                        <ListItemIcon>
                                          <GetAppIcon color="secondary" />
                                        </ListItemIcon>
                                        <ListItemText primary="OCUPACIÓN FAJA JARDÍN PARÁGRAFO III ART 33" />
                                      </ListItem>
                                      <ListItem button onClick={(event) => onHandleDownload(event, '/front/documents/mi-plano/ejemplos/SUPERFICIE_CONSTRUIDA_NUMERAL_76_ART_8_1.pdf', 'SUPERFICIE CONSTRUIDA NUMERAL 76 ART 8_1.pdf')}>
                                        <ListItemIcon>
                                          <GetAppIcon color="secondary" />
                                        </ListItemIcon>
                                        <ListItemText primary="SUPERFICIE CONSTRUIDA NUMERAL 76 ART 8 1" />
                                      </ListItem>
                                      <ListItem button onClick={(event) => onHandleDownload(event, '/front/documents/mi-plano/ejemplos/SUPERFICIE_CONSTRUIDA_NUMERAL_76_ART_8_2.pdf', 'SUPERFICIE CONSTRUIDA NUMERAL 76_ART_8_2.pdf')}>
                                        <ListItemIcon>
                                          <GetAppIcon color="secondary" />
                                        </ListItemIcon>
                                        <ListItemText primary="SUPERFICIE CONSTRUIDA NUMERAL 76 ART 8 2" />
                                      </ListItem>
                                    </List>
                                  </ExpansionPanelDetails>
                                </ExpansionPanel>
                              
                              <List component="nav" className={classes.customList}>
                                <ListItem button onClick={(event) => onHandleDownload(event,'/front/documents/mi-plano/Ejemplo_Divisiones_de_lotes_consolidados.pdf','Ejemplo Divisiones de lotes consolidados.pdf')}>
                                  <ListItemIcon>
                                    <GetAppIcon color="secondary"/>
                                  </ListItemIcon>
                                  <ListItemText primary="DIVISIONES DE LOTES CONSOLIDADOS" />
                                </ListItem>                                
                                <ListItem button onClick={(event) => onHandleDownload(event,'/front/documents/mi-plano/Ejemplo_Grupales.pdf','Ejemplo Grupales.pdf')}>
                                  <ListItemIcon>
                                    <GetAppIcon color="secondary"/>
                                  </ListItemIcon>
                                  <ListItemText primary="GRUPALES" />
                                </ListItem>                                
                                <ListItem button onClick={(event) => onHandleDownload(event,'/front/documents/mi-plano/Ejemplo_Propiedad_Horizontal.pdf','Ejemplo Propiedad Horizontal.pdf')}>
                                  <ListItemIcon>
                                    <GetAppIcon color="secondary"/>
                                  </ListItemIcon>
                                  <ListItemText primary="PROPIEDAD HORIZONTAL" />
                                </ListItem>
                                <ListItem button onClick={(event) => onHandleDownload(event,'/front/documents/mi-plano/graficacion_edificaciones_D9_AUTOCAD.dwg','graficacion edificaciones D9 AUTOCAD.dwg')}>
                                  <ListItemIcon>
                                    <GetAppIcon color="secondary"/>
                                  </ListItemIcon>
                                  <ListItemText primary="EJEMPLOS EDIFICACIONES POLIGONOS AMPLIACIÓN URBANA LEY MUNICIPAL Nº 274/2018 (D8,D9,D14,D15)" />
                                </ListItem>
                                <ListItem button onClick={handleChange('/front/documents/mi-plano/ERRORES_COMUNES_INCONSISTENCIAS_EN EL_LLENADO_DEL_AVALUO_CATASTRAL.pdf')}>
                                  <ListItemIcon>
                                    <PictureAsPdfIcon color="secondary"/>
                                  </ListItemIcon>
                                  <ListItemText primary="ERRORES COMUNES E INCONSISTENCIAS EN EL LLENADO DEL AVALÚO CATASTRAL" />
                                </ListItem>
                                <ListItem button onClick={handleChange('/front/documents/mi-plano/PRESENTACION_COLEGIO_DE_ARQUITECTOS.pdf')}>
                                  <ListItemIcon>
                                    <PictureAsPdfIcon color="secondary"/>
                                  </ListItemIcon>
                                  <ListItemText primary="CAPACITACION COLEGIO DE ARQUITECTOS" />
                                </ListItem>
                                <ListItem button onClick={handleChange('/front/documents/mi-plano/P_COLEGIO_DE_ARQUITECTOS.pdf')}>
                                  <ListItemIcon>
                                    <PictureAsPdfIcon color="secondary"/>
                                  </ListItemIcon>
                                  <ListItemText primary="CAPACITACION COLEGIO DE ARQUITECTOS FEBRERO 2020" />
                                </ListItem>
                              </List>
                              </Box>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                          <ExpansionPanel>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon color="secondary" />}
                            >
                              <Typography className={classes.heading}>TUTORIALES DE EXPORTACIÓN A AUTOCAD</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <List component="nav" className={classes.customList}>
                                <ListItem button onClick={handleChange('/front/documents/mi-plano/ARCHICAD_A_AUTOCAD.mp4')}>
                                  <ListItemIcon>
                                    <PlayCircleFilledIcon color="secondary"/>
                                  </ListItemIcon>
                                  <ListItemText primary="ARCHICAD A AUTOCAD" />
                                </ListItem>
                                <ListItem button onClick={handleChange('/front/documents/mi-plano/VECTORWORKS_A_AUTOCAD.mp4')}>
                                  <ListItemIcon>
                                    <PlayCircleFilledIcon color="secondary"/>
                                  </ListItemIcon>
                                  <ListItemText primary="VECTORWORKS A AUTOCAD" />
                                </ListItem>                                
                              </List>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </Box>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon color="secondary" />}>
                        <Typography className={classes.heading}>2. FORMULARIOS TÉCNICOS DE DECLARACIONES JURADAS</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <List component="nav" className={classes.customList}>
                          <ListItem button onClick={(event) => onHandleDownload(event,'/front/documents/mi-plano/Declaraciones_Juradas_División_de_Lotes_Consolidados.xlsx','Declaraciones Juradas División de Lotes Consolidados.xlsx')}>
                            <ListItemIcon>
                              <GetAppIcon color="secondary"/>
                            </ListItemIcon>
                            <ListItemText primary="Declaraciones Juradas División de Lotes Consolidados" />
                          </ListItem>
                          <ListItem button onClick={(event) => onHandleDownload(event,'/front/documents/mi-plano/Declaraciones_Juradas_Edificaciones.xlsx','Declaraciones Juradas Edificaciones.xlsx')}>
                            <ListItemIcon>
                              <GetAppIcon color="secondary"/>
                            </ListItemIcon>
                            <ListItemText primary="Declaraciones Juradas Edificaciones" />
                          </ListItem>
                          <ListItem button onClick={(event) => onHandleDownload(event,'/front/documents/mi-plano/Declaraciones_Juradas_Lotes.xls','Declaraciones Juradas Lotes.xls')}>
                            <ListItemIcon>
                              <GetAppIcon color="secondary"/>
                            </ListItemIcon>
                            <ListItemText primary="Declaraciones Juradas Lotes" />
                          </ListItem>
                          <ListItem button onClick={(event) => onHandleDownload(event,'/front/documents/mi-plano/Declaraciones_Juradas_Mixtos.xls','Declaraciones Juradas Mixtos.xls')}>
                            <ListItemIcon>
                              <GetAppIcon color="secondary"/>
                            </ListItemIcon>
                            <ListItemText primary="Declaraciones Juradas Mixtos" />
                          </ListItem>
                          <ListItem button onClick={(event) => onHandleDownload(event,'/front/documents/mi-plano/Declaraciones_Juradas_Propiedad_Horizontal.xlsx','Declaraciones Juradas Propiedad Horizontal.xlsx')}>
                            <ListItemIcon>
                              <GetAppIcon color="secondary"/>
                            </ListItemIcon>
                            <ListItemText primary="Declaraciones Juradas Propiedad Horizontal" />
                          </ListItem>                          
                        </List>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel expanded={false} onClick={(event) => onHandleDownload(event,'/front/documents/mi-plano/PUBLICACIÓN_EN_MEDIO_DE_PRENSA.docx','PUBLICACIÓN EN MEDIO DE PRENSA.docx')}>
                      <ExpansionPanelSummary
                        expandIcon={<GetAppIcon color="secondary"/>}
                      >
                        <Typography className={classes.heading}>3. PUBLICACIÓN EN MEDIO DE PRENSA</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>                        
                      </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel expanded={false} onClick={(event) => onHandleDownload(event,'/front/documents/mi-plano/CERTIFICACIÓN_DE_ESTABILIDAD_ESTRUCTURAL.pdf','CERTIFICACIÓN DE ESTABILIDAD ESTRUCTURAL.pdf')}>
                      <ExpansionPanelSummary
                        expandIcon={<GetAppIcon color="secondary"/>}
                      >                        
                        <Typography className={classes.heading}>4. CERTIFICACIÓN DE ESTABILIDAD ESTRUCTURAL</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                          sit amet blandit leo lobortis eget.
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel expanded={false} onClick={(event) => onHandleDownload(event,'/front/documents/mi-plano/CERTIFICADO_DE_CORRECTO_FUNCIONAMIENTO_DE_LAS_INSTALACIONES_ELECTRICAS.pdf','CERTIFICADO DE CORRECTO FUNCIONAMIENTO DE LAS INSTALACIONES ELECTRICAS.pdf')}>
                      <ExpansionPanelSummary
                        expandIcon={<GetAppIcon color="secondary" />}
                      >                        
                        <Typography className={classes.heading}>5. CERTIFICADO DE CORRECTO FUNCIONAMIENTO DE LAS INSTALACIONES ELÉCTRICAS</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel expanded={false} onClick={(event) => onHandleDownload(event,'/front/documents/mi-plano/CERTIFICADO_DE_CORRECTO_FUNCIONAMIENTO_HIDRAULICO_DE_INSTALACIONES_HIDROSANITARIAS.pdf','CERTIFICADO DE CORRECTO FUNCIONAMIENTO HIDRAULICO DE INSTALACIONES HIDROSANITARIAS.pdf')}>
                      <ExpansionPanelSummary
                        expandIcon={<GetAppIcon color="secondary" />}
                      >                       
                        <Typography className={classes.heading}>6. CERTIFICADO DE CORRECTO FUNCIONAMIENTO HIDRÁULICO DE INSTALACIONES HIDROSANITARIAS</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>                        
                      </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel expanded={false} onClick={(event) => onHandleDownload(event,'/front/documents/mi-plano/DECLARACION_VOLUNTARIA.pdf','DECLARACION VOLUNTARIA.pdf')}>
                      <ExpansionPanelSummary
                        expandIcon={<GetAppIcon color="secondary" />}
                      >                        
                        <Typography className={classes.heading}>7. DECLARACIÓN VOLUNTARIA</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>                        
                      </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel expanded={false} onClick={(event) => onHandleDownload(event,'/front/documents/mi-plano/SOLICITUD_DE_CERTIFICACIÓN_DE_ALTURA_DGAC.docx','SOLICITUD DE CERTIFICACIÓN DE ALTURA DGAC.docx')}>
                      <ExpansionPanelSummary
                        expandIcon={<GetAppIcon color="secondary" />}
                      >                        
                        <Typography className={classes.heading}>8. SOLICITUD DE CERTIFICACIÓN DE ALTURA DGAC</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>                        
                      </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel expanded={false} onClick={(event) => onHandleDownload(event,'/front/documents/mi-plano/TABLA_DE_CESIONES.pdf','TABLA DE CESIONES.pdf')}>
                      <ExpansionPanelSummary
                        expandIcon={<GetAppIcon color="secondary" />}
                      >                        
                        <Typography className={classes.heading}>9. TABLA DE CESIONES</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>                        
                      </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel expanded={false} onClick={(event) => onHandleDownload(event,'/front/documents/mi-plano/OCUPACIÓN_TOTAL_FAJA_JARDIN.pdf','OCUPACIÓN TOTAL FAJA JARDIN.pdf')}>
                      <ExpansionPanelSummary
                        expandIcon={<GetAppIcon color="secondary" />}
                      >                        
                        <Typography className={classes.heading}>10. OCUPACIÓN TOTAL FAJA JARDIN</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>                        
                      </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel expanded={false} onClick={(event) => onHandleDownload(event,'/front/documents/mi-plano/PLANO_DE_ALTURAS_4100.pdf','PLANO DE ALTURAS 4100.pdf')}>
                      <ExpansionPanelSummary
                        expandIcon={<GetAppIcon color="secondary" />}
                      >                        
                        <Typography className={classes.heading}>11. PLANO DE ALTURAS 4100</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>                        
                      </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel expanded={false} onClick={(event) => onHandleDownload(event,'/front/documents/mi-plano/DECLARACIÓN_JURADA_N_300_Y_MANUAL_DE_PROCESOS.pdf','DECLARACIÓN JURADA N 300 Y MANUAL DE PROCESOS.pdf')}>
                      <ExpansionPanelSummary
                        expandIcon={<GetAppIcon color="secondary" />}
                      >                        
                        <Typography className={classes.heading}>12. DECLARACIÓN JURADA N 300 Y MANUAL DE PROCESOS</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>                        
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </Grid>
              </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={1} lg={1} xl={3}></Grid>
          </Grid>
      </Parallax>
      <Grid container className={classes.firstSecction}>
          <Grid item xs={12} sm={12} md={1} lg={1} xl={3}></Grid>
          <Grid item xs={12} sm={12} md={10} lg={10} xl={6}>
            <Typography variant="h4" align="center">CENTROS DE REGULARIZACIÓN</Typography>
            <br/>
            <Box align="center" className={classes.customBoxContainer}>
              <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Typography variant="h6" align="center" className={classes.customTableHeader}>TUNARI</Typography>
                  <br/>
                  <br/>
                  <Typography align="center">Av. Circunvalación esq. Av. Melchor Pérez de Olguín</Typography>
                  <br/>
                  <br/>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Typography variant="h6" align="center" className={classes.customTableHeader}>KANATA</Typography>
                  <br/>
                  <br/>
                  <Typography align="center">Av. 6 de Agosto esq. Av. Mártires de la Democracia</Typography>
                  <br/>
                  <br/>
                </Grid>
              </Grid>
            </Box>            
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1} xl={3}></Grid>
      </Grid>      
      <Grid container>
        <Grid item xs={12} sm={12} md={1} lg={1} xl={3}></Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={6}>
          <Grid container>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.customImageContainer}>
              <img src={`https://cochabamba.bo/front/images/mi-plano/regularizacion.png`} width="100%"/>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Typography variant="h6" align="center">HORARIO DE ATENCIÓN DE LUNES A VIERNES</Typography>
              <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Typography variant="h6" align="center">MAÑANA</Typography>
                  <Typography variant="h6" align="center">08:00 - 12:00</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Typography variant="h6" align="center">TARDE</Typography>
                  <Typography variant="h6" align="center">12:00 - 14:40</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <br/>
                  <Typography variant="h6" align="center">TELÉFONO: <strong>4000121</strong></Typography>
                </Grid>                
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={1} lg={1} xl={3}></Grid>
      </Grid>
    </div>
  );
}

export default MiPlanoComponent;