import React,{useState,useRef} from 'react';
import { Link } from 'react-router-dom';
import ReactFlow,{ Controls,Background } from 'react-flow-renderer';
import Chip from '@material-ui/core/Chip';
import ArbolComponent from '../../components/small-components/ArbolComponent';
import useAxios from 'axios-hooks';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Card,CardMedia,CardContent,Button,Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import Convert from 'htmr';
import { GLOBAL} from '../../global';
import ModalComponentViewer from '../../components/genericos/modal/ModalComponentViewer';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,      
      //padding:theme.spacing(10,1),
      //backgroundColor:theme.palette.primary.main
    },    
    customTitle:{
      marginTop:theme.spacing(5),
      textAlign:'center',
      color:theme.palette.primary.main

    },
    customSubTitle:{
        //color:'#ffffff',
        paddingBottom:theme.spacing(5),
        textAlign:'center',
    },
    customOrganigramaContainer:{
        height:'90vh',
        padding:theme.spacing(1),
        //backgroundColor:'white',
        //border:'0.5px solid gray'
    },
    customLevelItem:{
        //backgroundColor:'orange',
        textAlign:'center',
        padding:theme.spacing(1),
        border:'1px solid black'
    },
    customLevelContainer:{
      padding:theme.spacing(1,1),
      //border:'1px solid black'
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    customTableTitle:{
      //textAlign:'right',
      fontWeight:'bold',
      color:theme.palette.sextary.primary
    },
    customTitleCards:{
      textAlign:"center",
      paddingTop:theme.spacing(2)
    },
    customMsj:{
      padding:theme.spacing(1)
    },
    customLevelContainer2:{
      //fontSize:theme.typography.caption.fontSize
      fontSize:'0.6rem'
    },
    customIndicatorContainer:{
      marginTop:theme.spacing(0),
      paddingTop:theme.spacing(0)
    },
    indicadorStyle:{
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
      customChip:{
        fontSize:'0.5rem !important'
      }
    }
  }));

  const elements = [
    {
      id: 1,
      type: 'input', // input node
      data: { label: <div>Despacho<br/>Alcalde Municipal</div> },
      position: { x: 250, y: 25 },
      style:{backgroundColor:'#ffc000'}
    },
    // default node
    {
      id: 2,
      // you can also pass a React component as a label
      data: { label: 'Secretaría de Asuntos Juridicos' },
      position: { x: 100, y: 125 },
      style:{backgroundColor:'#d9680d'}
    },
    {
      id: 3,
      type: 'output', // output node
      data: { label: 'Secretaría General' },
      position: { x: 260, y: 125 },
      style:{backgroundColor:'#d9680d'}
    },
    {
      id: 4,
      type: 'output', // output node
      data: { label: 'Secretaría de Comunicación y Gobierno Electrónico' },
      position: { x: 420, y: 125 },
      style:{backgroundColor:'#d9680d'}
    },
    // animated edge
    { id: 'e1-2', source: '1', target: '2', animated: false },
    { id: 'e1-3', source: '1', target: '3' },
    { id: 'e1-4', source: '1', target: '4' },
  ];

const MediaCard = (props) => {
  const {data=null} = props;
  const classes = useStyles();

  return (
    <Card>      
      {
        data?
        <Box>
          {
            data.img?
            <CardMedia
              className={classes.media}
              image={`${GLOBAL.assetsBack}${data.img}`}
              title="Imagen de la unidad"
            />:null
          }
          <CardContent>
            <table>
              <tbody>
                <tr>
                  <td className={classes.customTableTitle}>Unidad:</td>
                  <td>{data.label}</td>
                </tr>
                <tr>
                  <td className={classes.customTableTitle}>Nivel:</td>
                  <td>{data.nivel}</td>
                </tr>
                <tr>
                  <td colSpan="2" className={classes.customTableTitle}>Contacto:</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    {Convert(data.descripcion?data.descripcion:'<p></p>')}
                  </td>
                </tr>
                <tr>
                  <td className={classes.customTableTitle}>
                    Página Web:
                  </td>
                  <td>
                    {data.url?<Link to={data.url==='/'?'/':`/unidad/${data.url}`} style={{textDecoration:'none'}}><Button size="small" variant="contained" color="primary">Visitar página web</Button></Link>:'Unidad sin página web'}
                  </td>
                </tr>
              </tbody>
            </table>
          </CardContent>
        </Box>:<Typography className={classes.customMsj}>Seleccine una unidad del organigrama para ver más información.</Typography>
      }
    </Card>
  );
}


const OrganigramaComponent = (props) => {
    const matches = useMediaQuery('(min-width:768px)');
    const childRef = useRef();
    const [ open, setOpen] = useState(false);
    const {data={}} = props;
    const [detalle, setDetalle] = useState(null);
    const [{ data:getData, loading }, refetch] = useAxios(
      {
        url:`${GLOBAL.assetsBack}/api/getorganigrama`,
        method:'POST'        
      },{ manual: false });
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      //console.log("newValue=",newValue);
      setValue(newValue);
    };
    const classes = useStyles();
    const onElementClick = (event, element) => {
      //console.log('onElementClick', element);
      setDetalle(element.data);
      childRef.current.openModal();      
    };
    const onHandleClick = (dato) => {
      //console.log("onHandleClick dato=",dato);
      setDetalle(dato.data);
      childRef.current.openModal();     
    }

    const getColor = (item) => {
      let dato = {};
      try {
        dato = JSON.parse(item.datos);  
      } catch (error) {
        dato = {};
      }
      return {color:'white',backgroundColor:dato.backgroundColor,fontSize:!matches?'0.5rem':null};      
    }
    return(
      <Box>
        <ModalComponentViewer ref={childRef} title={'INFORMACIÓN UNIDAD'} body={<MediaCard data={detalle}/>}/>
        <Typography className={classes.customTitle} variant="h3">{data.title}</Typography>
        <Typography className={classes.customSubTitle} variant="h6">{data.description}</Typography>
        {/* <Box p={1}>
          <Paper className={classes.root}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="secondary"              
              variant="scrollable"
              scrollButtons="auto"
            >
              {
                getData && getData.data?
                getData.data.niveles.map((item,index)=>(
                  <Tab key={`j${index}`} label={item.nombre}  icon={<StopRoundedIcon style={getColor(item)}/>}/>
                )):null
              }            
            </Tabs>
          </Paper>
        </Box> */}
        
        <Box>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
              //onClick={()=> setOpen(!open)}
            >
              <Typography align="center">Indicadores</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className={classes.indicadorStyle}>
                {
                    getData && getData.data?
                    getData.data.niveles.map((item,index)=>(                  
                      <Chip size="small" key={`j${index}`} label={item.nombre} style={getColor(item)}/>
                    )):null
                }                    
              </div>       
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Box>         
        <Grid container justify='center' className={classes.root} style={data.style?data.style:{}} spacing={1}>            
            <Grid item xs={12}>
              {
                matches?
                <Paper className={classes.customOrganigramaContainer}>
                  <ReactFlow
                    elements={getData && getData.elementos?getData.elementos:[]}
                    zoomOnScroll={false}
                    onElementClick={onElementClick}
                    defaultZoom={0.5}
                    defaultPosition={[900,-250]}
                  >
                    <img src={`${GLOBAL.assetsBack}/img/escudo-GAMC-vertical.png`} style={{maxWidth:'80px'}}/>
                    <Controls/>
                    <Background color="#aaa" gap={16} />
                  </ReactFlow>
                </Paper>:
                <Box p={1.5}>
                  <ArbolComponent onHandleClick={onHandleClick}/>
                </Box>
              }
            </Grid>
            {/* <Grid item xs={12} sm={12} md={2} lg={3} xl={2}>
              <MediaCard data={detalle}/>
            </Grid> */}
        </Grid>
      </Box>
    );
}
export default OrganigramaComponent;