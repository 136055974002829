import React from 'react';
import { Grid,Typography,Button,ButtonBase } from '@material-ui/core';
import { Parallax } from 'react-parallax';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root:{
        flexGrow:1,        
    },
    customBannerContainer:{
        minHeight:'95vh',
        //backgroundColor:'rgba(60, 0, 0, 0.3)'
    },
    customPadding:{
        padding:theme.spacing(0,2)
    },
    customImgSize:{
        maxWidth:"100%"
    },
    customColorText:{
        color:'#FFFFFF',
        padding:theme.spacing(2,2),
        //paddingRight:theme.spacing(5)
    },
    customContainerPadding:{
        padding:theme.spacing(10,0),        
    },
    customImgPadding:{
        padding:theme.spacing(0,2),        
    },
    customTextPadding:{
        padding:theme.spacing(0,2)
    },
    customColorBtn:{
        color:"#FFFFFF"        
    },
    customImgContainer:{
        verticalAlign:"center"
    },
    image: {
        width: "100%",
        height: "100%",
    },    
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

function PortadaMaterialUi(props){

    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Parallax
                //blur={10}
                bgImage = {props.data.bgImg}
                bgImageAlt = "Imagen de fondo"
                strength = {200}
                bgImageStyle={{}}
                >                
                <Grid container className={classes.customBannerContainer} alignItems="center" justify="center">
                    <Grid item xs={12} sm={12} md={2} lg={3} xl={3}></Grid>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                        <Grid container className={classes.customContainerPadding}>
                            {
                                props.data.logoImg?
                                <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className={classes.customImgPadding}>
                                    <ButtonBase className={classes.image}>
                                        <img src={props.data.logoImg} className={classes.img}/>
                                    </ButtonBase>
                                </Grid>:''
                            }
                            <Grid item xs={12} sm={props.data.logoImg?8:12} md={props.data.logoImg?8:12} lg={props.data.logoImg?8:12} xl={props.data.logoImg?8:12} className={classes.customTextPadding}>
                                <Typography variant="h3" className={classes.customColorText} style={props.data.style.title?props.data.style.title:{}}>{props.data.title}</Typography>
                                {
                                    props.data.description.map((item,j)=>(
                                        <Typography key={j} variant="h5" className={classes.customColorText} style={props.data.style.description?props.data.style.description:{}}>{item}</Typography>
                                    ))
                                }
                                {
                                    props.btnData?
                                        <Typography>
                                            <Button variant="contained" color="secondary">{props.btnData.label}</Button>
                                        </Typography>:null
                                }                                
                                
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={3} xl={3}></Grid>
                </Grid>
            </Parallax>
        </div>
    );
}

export default PortadaMaterialUi;