import React,{useState,useEffect} from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button  from '@material-ui/core/Button';
import TextField  from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';

import useAxios from 'axios-hooks';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Card from '@material-ui/core/Card';

import Pagination from '@material-ui/lab/Pagination';

import ErrorRequestComponent from '../../../components/genericos/error/ErrorRequestComponent';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import ModalWithMethodToCall from '../../../components/genericos/modal/ModalWithMethodToCall';

import Snackbar from '@material-ui/core/Snackbar';
import { useForm } from "react-hook-form";
import Axios from 'axios';
import {GLOBAL} from '../../../global';

//DEV
/*/
const url_pet_lost_report_new = `https://zoonosisdevapi.cochabamba.bo/api/v1/services-gamc-zoonosis/pet-lost-report/new`;
const url_reportPetLost = `https://zoonosisdevapi.cochabamba.bo/api/v1/services-gamc-zoonosis/petLost/reportPetLost`;
const url_pets = `https://zoonosisdevapi.cochabamba.bo/api/v1/services-gamc-zoonosis/pets/pets`;
const url_razas = `https://zoonosisdevapi.cochabamba.bo/api/v1/services-gamc-zoonosis/razas`;
const url_report = `https://zoonosisdevapi.cochabamba.bo/api/v1/services-gamc-zoonosis/report`;
*/

//PROD
const url_pet_lost_report_new = `https://zoonosisapi.cochabamba.bo/api/v1/services-gamc-zoonosis/pet-lost-report/new`;
const url_reportPetLost = `https://zoonosisapi.cochabamba.bo/api/v1/services-gamc-zoonosis/petLost/reportPetLost`;
const url_pets = `https://zoonosisapi.cochabamba.bo/api/v1/services-gamc-zoonosis/pets/pets`;
const url_razas = `https://zoonosisapi.cochabamba.bo/api/v1/services-gamc-zoonosis/razas?page=1&limit=10000`;
const url_report = `https://zoonosisapi.cochabamba.bo/api/v1/services-gamc-zoonosis/report`;


const { useRef } = React;

const useStyles = makeStyles(theme => ({
    root: {
        padding:theme.spacing(3,1)
    },
    customTabStyles:{
        marginTop:theme.spacing(1)
    },
    customTabStyles2:{
        margin:theme.spacing(2,0)
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    customTabStyles3:{
        margin:theme.spacing(3,0)
    },
    customButton:{
        width:'100%'
    },
    formControl: {
        margin: theme.spacing(0,2),
        minWidth: 120,
        width:'100%'
      },
    selectEmpty: {
        marginTop: theme.spacing(2),
      },
    customSwitch:{
        margin:theme.spacing(2,4)
    },
    customTitle:{
        textAlign:'center',
        color:theme.palette.primary.main
    },
    customSubTitle:{
        color:theme.palette.primary.main,
        //paddingBottom:theme.spacing(3),
        textAlign:'center',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
      },
  }));

const useStyles2Form = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding:theme.spacing(5,1),
        //backgroundColor:theme.palette.tertiary.main
    },
    customTitle:{
        textAlign:'center',
        //color:theme.palette.primary.main
        color:'white'

    },
    customSubTitle:{
        color:'#ffffff',
        paddingBottom:theme.spacing(5),
        textAlign:'center',
    },
    customWith:{
        width:'100%',
        margin:theme.spacing(2,0),
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const FormComponent = (props) => {

    const [loading,setLoading] = React.useState(false);
    const [data,setData] = React.useState(null);
    const [error,setError] = React.useState(false);    
    const [especies, setEspecies] = React.useState(null);
    const [especie, setEspecie] = React.useState('');
    const [raza, setRaza] = React.useState('');
    const [razas, setRazas] = React.useState(null);
    const [sexo, setSexo] = React.useState('');
    const [archivo, setArchivo] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const { register, handleSubmit, formState, errors, reset, getValues } = useForm({mode:'onChange'});
    
    const classes = useStyles2Form();   

    const handleChangeEspecie = (event) => {
        //console.log(event.target.value);
        let auxRazas = event.target.value?props.razas.filter(ele => ele.id_especie===event.target.value):[];
        setRazas(auxRazas);
        setRaza('');
        setEspecie(event.target.value);

    };
    const handleChangeRaza = (event) => {
        //console.log(event.target.value);
        setRaza(event.target.value);
    };
    const handleChangeSexo = (event) => {
        //console.log(event.target.value);
        setSexo(event.target.value);
    };
    const handleInputFileChange = event => {
        let obj = getValues('archivo');
        //console.log("OBJ=",obj);
        if(obj && obj.length>0){
            if(obj[0].size > 5242880){//muy pesado
                setArchivo(1);
            }else if(obj[0].type != "image/png" && obj[0].type != "image/jpeg"){//otro archivo
                setArchivo(2);
            }else{
                setArchivo(0);
            }
        }else{
            setArchivo(0);
        }
    }
    const handleClose = (event, reason) => {        
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };   

    const onSubmit = datos => {
        //console.log("datos form=",datos);
        setLoading(true);

        let data2Send = {
            id_pet_lost: props.idmascota,
            nro_rum: datos.nro_rum,
            id_raza:  raza,
            sexo: sexo,
            details_address: datos.descripcion,
            name_contact:datos.nombre,
            cellphone:datos.celular
        };
        //console.log("data2Send=",data2Send);
        
        Axios.request({
            method: "post",
            url: url_pet_lost_report_new,
            data: data2Send            
        })
        .then(function (response) {
            //setError(null);
            //setData(response.data);
            //console.log("response",response);
            
            let formData = new FormData();
            formData.append('img',datos.archivo[0]);

            Axios.request({
                method: "put",
                url: `${url_reportPetLost}/${response.data.newReportPets.id}`,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then(function (response2) {
                //console.log("response2=",response2);
                setError(null);
                setData(response.data);
            })
            .catch(function (error) {            
                setData(null);
                setError(true);
            })
            .then(function () {
                setLoading(false);
                setSexo('');
                setEspecie('');
                setRaza('');
                setOpen(true);
                reset();                
            });
        })
        .catch(function (error) {            
            setData(null);
            setError(true);
        })
        .then(function () {
            setLoading(false);
            setSexo('');
            setEspecie('');
            setRaza('');
            setOpen(true);
            reset();            
        });
        
    };

    if(props.razas && Array.isArray(props.razas) && especies==null){
        let auxEspecies = [];
        props.razas.map(element => {
            if(auxEspecies.length > 0){
                let auxEspecie = auxEspecies.find(el => el.id===element.id_especie);
                if(!auxEspecie){
                    auxEspecies.push(element.especies);
                }
            }else{
                auxEspecies.push(element.especies);
            }
        });
        //console.log("auxEspecies=",auxEspecies);
        setEspecies(auxEspecies);
    }

    return (
        <Grid container justify='center' className={classes.root}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container>
                    <Grid item xs={12} className={classes.customPadding}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                                id="nro_rum"
                                name="nro_rum"
                                label="NRO RUM (*)"
                                //helperText="Requerido"
                                className={classes.customWith}
                                //value={input.nombre}
                                //onChange={handleInputChange}
                                inputRef={register({
                                    required:true,
                                    minLength:3,
                                    maxLength:20,
                                    pattern: {
                                        value: /^([a-zA-Z0-9-])*$/,
                                    }
                                })}
                                error={errors.nro_rum?true:false}
                                />
                            <TextField
                                id="especie"
                                name="especie"
                                select
                                label="Especie (*)"
                                className={classes.customWith}
                                value={especie}
                                onChange={handleChangeEspecie}
                                //helperText="Seleccinar sexo"
                                variant="outlined"
                                error={errors.especie?true:false}>
                                    <MenuItem value="null" disabled>
                                        <i>Seleccionar</i>
                                    </MenuItem>
                                    {
                                        especies?especies.map(element => (
                                            <MenuItem key={element.id} value={element.id}>{element.name}</MenuItem>
                                        )):null
                                    }                                    
                            </TextField>                            
                            <TextField
                                id="raza"
                                name="raza"
                                select
                                label="raza (*)"
                                className={classes.customWith}
                                value={raza}
                                onChange={handleChangeRaza}
                                //helperText="Seleccinar sexo"
                                variant="outlined"
                                error={errors.raza?true:false}
                                >
                                    <MenuItem value="null" disabled>
                                        <i>Seleccionar</i>
                                    </MenuItem>
                                    {
                                        razas && Array.isArray(razas)?razas.map(ele=>(
                                            <MenuItem key={ele.id} value={ele.id}>{ele.name}</MenuItem>
                                        )):null
                                    }
                            </TextField>
                            <TextField
                                id="sexo"
                                name="sexo"
                                select
                                label="Sexo (*)"
                                className={classes.customWith}
                                value={sexo}
                                onChange={handleChangeSexo}
                                //helperText="Seleccinar sexo"
                                variant="outlined"
                                error={errors.sexo?true:false}
                                >
                                    <MenuItem value="null" disabled>
                                        <i>Seleccionar</i>
                                    </MenuItem>
                                    <MenuItem value="MACHO">
                                        MACHO
                                    </MenuItem>
                                    <MenuItem value="HEMBRA">
                                        HEMBRA
                                    </MenuItem>                       
                            </TextField>
                            <TextField
                                id="archivo"
                                name="archivo"
                                label="Adjuntar foto (5MB tamaño máximo)"
                                type="file"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    accept:"image/png, image/jpeg"
                                }}
                                //helperText="Requerido"
                                className={classes.customWith}
                                //value={input.email}
                                onChange={handleInputFileChange}
                                inputRef={register({
                                    required:true                                            
                                })}
                                error={errors.archivo || archivo===1 || archivo===2?true:false}
                                //helperText={archivo===1 || archivo===2?'Se requiere un archivo PDF de 5MB máximo':null}
                                />
                            <TextField
                                id="descripcion"
                                name="descripcion"
                                //value={input.mensaje}
                                label="Señas (*)"
                                className={classes.customWith}
                                multiline
                                rows={4}
                                //defaultValue=""
                                variant="outlined"
                                inputRef={register({
                                    required:true,
                                    minLength:3,
                                    maxLength:1500
                                })}
                                error={errors.mensaje?true:false}
                                helperText="1500 caracteres máximo"
                                />
                            <TextField
                                id="nombre"
                                name="nombre"
                                label="Nombre (*)"
                                //helperText="Requerido"
                                className={classes.customWith}
                                //value={input.nombre}
                                //onChange={handleInputChange}
                                inputRef={register({
                                    required:true,
                                    minLength:3,
                                    maxLength:50
                                })}
                                error={errors.nombre?true:false}
                                />
                            <TextField
                                id="celular"
                                name="celular"
                                label="celular (*)"
                                //helperText="Requerido"
                                className={classes.customWith}
                                //value={input.nombre}
                                //onChange={handleInputChange}
                                inputRef={register({
                                    required:true,
                                    minLength:8,
                                    maxLength:8,
                                    pattern: {
                                        value: /^([0-9])*$/,
                                    }
                                })}
                                error={errors.celular?true:false}
                            />                            
                            <Box>
                                <Typography variant="caption" className={classes.customInfo}>Los campos con (*) son obligatorios</Typography>
                            </Box>                            
                            {
                                !loading?
                                <Button disabled={!formState.isValid || especie==='' || raza==='' || sexo==='' || archivo===1 || archivo===2} variant="contained" color="secondary" onClick={() => handleSubmit(onSubmit)()}>Enviar sugerencia</Button>:
                                <CircularProgress color="secondary" />
                            }
                        </form>
                    </Grid>
                </Grid>
                            
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }} 
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}>
                    <Alert onClose={handleClose} severity={data && data.ok?"success":"error"}>
                        {
                            !error && data && !data.ok?                            
                            <Typography>{data.msg}</Typography>:null
                        } 
                        {
                            !error && data && data.data && !data.ok?                            
                            <Typography>Los datos no fueron enviados, vuelva a intentarlo más tarde</Typography>:null
                        }                        
                        {
                            !error && data && data.ok?
                            <Typography>{data.msg}</Typography>:null
                        }
                        {
                            error && !data?
                            <Typography>Se produjo un error, vuelva a intentarlo más tarde</Typography>:null
                        }
                    </Alert>
            </Snackbar>
        </Grid>
    );
}

const Cardmascota = (props) => {
    //return !props.loading?<img src={`https://zoonosisdevapi.cochabamba.bo/api/v1/services-gamc-zoonosis/reportPetLost/petLost/${props.data?props.data.img_pet:null}`} alt={`${props.data?props.data.name:null}`} width="100%"/>:<Skeleton/>
    const classes = useStyles();
    const onHandleClick = () => {
        props.onHandleClick(props.data.id);
    }    
    return(
        <Card className={classes.root}>
            <CardMedia
                className={classes.media}
                image={`${url_pets}/${props.data && props.data.pet?props.data.pet.img_pet:null}`}
                title={`${props.data && props.data.pet?props.data.pet.name:null}`}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2" style={{textAlign: 'center'}}>
                    {props.data && props.data.pet?props.data.pet.name:null}
                </Typography>
            </CardContent>
            <CardActions>
                <Button color="secondary" variant="contained" onClick={onHandleClick}>Registrar como encontrado</Button>
            </CardActions>
        </Card>);
}

const ZoonosisComponent = (props) => {
    const [raza,setRaza] = React.useState('');
    const [page, setPage] = React.useState(1);

    const [idmascota, setIdmascota] = React.useState(null);

    const { datos={        
        title:null,
        description:null,
        style:{
            container:null,
            title:null,
            subtitle:null
        },
        data:null
    }} = props;
    const [{ data:razasData, loading:razasLoading, error:razasError }] = useAxios(url_razas);
    const [{ data,loading,error },refetch] = useAxios(`${url_report}?page=${page}${raza?'&id_raza='+raza:''}&limit=10&limitDate=true`);
    const handleChangeRaza = (event) => {
        //console.log("CAMBIAR A LA PAGINA event.target.value=",event.target.value);
        setRaza(event.target.value);
        setPage(1);
    }
    const handleChangePage = (event, value) => {
        setPage(value);
    };
    
    const classes = useStyles();
    const childRef = useRef();
    
    const onHandleClick = (id) => {
        //console.log("data id=",id);
        setIdmascota(id);
        childRef.current.showDialog();
    }
    const onCallForm = () => {
        //console.log("onCallForm");
        setIdmascota(null);
        childRef.current.showDialog();
    }
    //if (loading) return <p>Loading...</p>
    //if (error) return <p>Error en la obtención de datos.</p>

    useEffect(() => {
       window.scrollTo(0, 0);
    }, []);
    
    return(
        <Box className={classes.root} style={datos && datos.style && datos.style.container?datos.style.container:null}>
            <ModalWithMethodToCall 
                ref={childRef}
                title="REGISTRO DE MASCOTAS ENCONTRADAS" 
                body={
                    <FormComponent 
                        razas={razasData && razasData.razas?razasData.razas.data:null} 
                        idmascota={idmascota}/>}
            />            
            <Grid container className={classes.customTabStyles} justify="center">
                <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                    <Box mb={3}>
                        {
                            datos.title?
                            <Typography className={classes.customTitle} variant="h3" style={datos.style.title}>{datos.title?datos.title:''}</Typography>:null
                        }                            
                        {
                            datos.description?
                            <Typography className={classes.customSubTitle} variant="h6" style={datos.style.subtitle}>{datos.description?datos.description:''}</Typography>:null
                        }
                        
                    </Box>
                </Grid>
            </Grid>
            {
                !error?
                (
                    <>
                        <Grid container justify="center">
                            <Grid item xs={12} sm={12} md={1} lg={2} xl={3}></Grid>
                            <Grid item xs={12} sm={12} md={10} lg={8} xl={6}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="raza">SELECCIONAR RAZA</InputLabel>
                                    <Select
                                        labelId="raza"
                                        id="raza"
                                        value={raza}
                                        onChange={handleChangeRaza}
                                        label="SIN FILTRO"
                                        >
                                        <MenuItem value="">
                                            <em>FILTRAR POR RAZA</em>
                                        </MenuItem>
                                        {
                                            razasData && razasData.ok?razasData.razas.data.map((item,index)=>(
                                                <MenuItem key={`ges${index}`} value={item.id}>{item.name}</MenuItem>
                                            )):null
                                        }                                                        
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={1} lg={2} xl={3}></Grid>                            
                        </Grid>
                        <Grid container className={classes.customTabStyles2} justify="center">
                            <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                                <Grid container spacing={3} justify="center">
                                    {
                                        loading?
                                        Array.from(new Array(4)).map((item,index) => (
                                            <Grid item xs={6} sm={4} md={3} key={index}>
                                                <Cardmascota loading/>
                                            </Grid>
                                        )):null
                                    }
                                    {   
                                        !error && data && data.ok && data.petsLost?data.petsLost.data.map((item,index) => (
                                            <Grid item xs={6} sm={4} md={3} key={index}>
                                                <Cardmascota data={item} onHandleClick={onHandleClick}/>
                                            </Grid>
                                        )):null
                                    }
                                </Grid>
                                <br/>
                                {
                                    data && data.petsLost && (Math.trunc(data.petsLost.total/data.petsLost.per_page))>0?<Pagination count={data && data.ok && data.petsLost?Math.trunc(data.petsLost.total/data.petsLost.per_page):1} page={page} onChange={handleChangePage} color="secondary"/>:null
                                }                                
                            </Grid>                            
                        </Grid>
                    </>
                ):
                (
                    <Grid container>
                        <Grid item xs={12}>
                            <ErrorRequestComponent handleClick={refetch}/>
                        </Grid>
                    </Grid>
                )
            }
            <Box align="center">
                <Button variant="contained" size="large" color="primary" className={classes.margin} onClick={onCallForm}>Registrar otra mascota encontrada</Button>
            </Box>
        </Box>
    );
}
export default ZoonosisComponent;
