import React from 'react';
import { withStyles,makeStyles } from '@material-ui/core/styles';
import {Button,Grid,Paper} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
const { forwardRef, useImperativeHandle } = React;

const mystyles = makeStyles((theme) => ({
    customDialogHeader:{
        backgroundColor:theme.palette.tertiary.main,
        color:'white'
    },
    customDialogContentStyle:{
        //backgroundColor:theme.palette.secondary.main
    }
}));

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon style={{color:'white'}}/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const ModalComponentViewer = (props, ref) => {
    const { size='md' } = props;
    const [open, setOpen] = React.useState(false);
    const classes = mystyles();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    useImperativeHandle(ref, () => ({
        openModal() {            
            setOpen(true);
        }    
      }));
    return (
        <Grid container>
            <Grid item xs={12}>
                <Dialog 
                    onClose={handleClose} 
                    aria-labelledby="customized-dialog-title" 
                    open={open}
                    fullWidth={true}
                    maxWidth={size}
                    >
                    <DialogTitle id="customized-dialog-title" onClose={handleClose} className={classes.customDialogHeader}>
                        {props.title?props.title:''}
                    </DialogTitle>
                    <DialogContent dividers className={classes.customDialogContentStyle}>
                        <Paper elevation={1}>
                            {props.body?props.body:''}
                        </Paper>
                    </DialogContent>
                    {
                        props.btnclose?
                        <DialogActions>
                            <Button color="secondary" onClick={handleClose}>{props.btnclose}</Button>
                        </DialogActions>:null
                    }
                </Dialog>
            </Grid>
        </Grid>
    );
}

export default forwardRef(ModalComponentViewer);