import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import "./style.css";

import imageTitulo from "../images/titulonuves.png";
import imageubicacion from "../images/icons/ubicacion.png";
import imageflota from "../images/icons/flota.png";
import imageavion from "../images/icons/avion.png";
import imagemaletas from "../images/maleta.png";

const nota = "LA SOLICITUD DE AUTORIZACIÓN DE VIAJE SE DEBE PRESENTAR UNA SEMANA ANTES DEL VIAJE";
const listDefensorias = [
  {
    id: 1,
    institution: "JEFATURA DE LA DEFENSORÍA",
    lugar: "PLAZA COLÓN, ACERA ESTE",
  },
  {
    id: 2,
    institution: "DEFENSORÍA DE LA SUBALCALDÍA ADELA ZAMUDIO",
    lugar: "CALLE COLOMBIA Y AYACUCHO",
  },
  {
    id: 3,
    institution: "DEFENSORÍA EPI NORTE",
    lugar: "AV. MELCHOR PÉREZ 2 CUADRAS AL SUD DE LA AV. CIRCUNVALACIÓN",
  },
  {
    id: 4,
    institution: "DEFENSORÍA VALLE HERMOSO",
    distrito: "D-6 Y D-7",
    lugar: "AV. HUANCHACA LADO CENTRO DE SALUD V. VENEZUELA",
  },
  {
    id: 5,
    institution: "DEFENSORÍA VALLE HERMOSO",
    distrito: "D-14",
    lugar: "AV. HUMBERTO ASÍN FRENTE AL HOSPITAL S. PAGADOR",
  },
  {
    id: 6,
    institution: "DEFENSORÍA MOLLE",
    lugar: "AV. BEIJING CASI AV. BLANCO GALINDO",
  },
  {
    id: 7,
    institution: "DEFENSORÍA ITOCTA",
    lugar: "AV. PETROLERA KM 41/2 FRENTE AL POLITÉCNICO MILITAR",
  },
  {
    id: 8,
    institution: "DEFENSORÍA ALEJO CALATAYUD",
    lugar: "AV. PETROLERA KM2 1/2",
  },
  {
    id: 9,
    institution: "DEFENSORÍA VILLA MÉXICO",
    lugar: "LADO MERCADO VILLA MÉXICO, TRAS LA IGLESIA SAN JUAN BOSCO",
  },
];
const listRequisitos = [
  {
    id: 1,
    name:
      "CARTA DIRIGIDA A EL/LA JEFE/A DE DEPARTAMENTO DE LA DEFENSORÍA DE LA NIÑEZ Y ADOLESCENCIA",
  },
  {
    id: 2,
    name:
      "ETALLAR EN LA CARTA EL LUGAR DE VIAJE, FECHA DE VIAJE, FECHA DE RETORNO , MOTIVO DEL VIAJE Y CON QUIEN REALIZARAN EL VIAJE",
  },
  {
    id: 3,
    name:
      "LISTA CON LOS NOMBRES COMPLETOS DE LAS NIÑAS, NIÑOS Y/O ADOLESCENTES",
  },
  {
    id: 4,
    name:
      "SI ES UNA DELEGACIÓN MIXTA DEBEN EXISTIR 2 RESPONSABLES (HOMBRE Y MUJER)",
  },
  {
    id: 5,
    name:
      "POR CADA 15 NIÑAS, NIÑOS Y/O ADOLESCENTES DEBEN TENER 1 RESPONSABLES",
  },
  {
    id: 6,
    name:
      "ORIGINAL Y FOTOCOPIA DE CEDULA DE IDENTIDAD DE LOS RESPONSABLES DE VIAJE",
  },
  {
    id: 7,
    name:
      "ORIGINAL Y FOTOCOPIA DE LAS CEDULAS DE IDENTIDAD O CERTIFICADO DE NACIMIENTO DE LAS NIÑAS, NIÑOS Y/O ADOLESCENTES (ESTE ULTIMO SOLO PARA MENORES DE 2 AÑOS)",
  },
  {
    id: 8,
    name:
      "FOTOCOPIAS DE LAS CEDULAS DE IDENTIDAD DE AMBOS PROGENITORES EN LA CUAL DEBE ESTAR ESCRITA LA AUTORIZACIÓN",
  },
];
const Autorizacion = () => {
  return (
    <div className="autorizacion">
      <div className="titulo">
        <img src={imageTitulo} width="100%" alt="Titulo" />
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={5}>
          <Box bgcolor="secondary.main" color="primary.contrastText" p={1} fontSize={16} fontWeight="fontWeightBold" width="80%">
            REQUISITOS PARA AUTORIZACIÓN DE VIAJE DE GRUPO O DELEGACIÓN
            </Box>
          <div className="requisitos">
            <Box p={1} fontSize={12} className="listRequi">
              <ul>
                {listRequisitos.map((tile) => (
                  <li key={tile.id}>
                    <Typography className="lista">{tile.name}</Typography>
                  </li>
                ))}
              </ul>
            </Box>
            <Box className="nota">NOTA.- {nota}</Box>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <Box bgcolor="primary.contrastText" p={1} fontSize={15} fontWeight="fontWeightBold" width="60%" className="infNacionales">
            NACIONALES PARA NIÑAS, NIÑOS Y ADOLESCENTES
            </Box>
          <Box bgcolor="secondary.main" color="primary.contrastText" p={1} fontSize={16} fontWeight="fontWeightBold" width="60%" className="contendorImg">
            LUNES A VIERNES: 08:00 A 16:00 HRS.
              <div className="imagenSuperpuesta">
              <img src={imageubicacion} alt="Ubicación" />
            </div>
          </Box>
          <Box p={1} fontSize={14}>
            <ul>
              {listDefensorias.map((info) => (
                <li key={info.id}>
                  <Typography>
                    <strong>{info.institution}</strong> {info.distrito} (
                      {info.lugar})
                    </Typography>
                </li>
              ))}
            </ul>
          </Box>
          <Box bgcolor="secondary.main" color="primary.contrastText" p={1} fontSize={16} fontWeight="fontWeightBold" width="30%" className="contendorImg">
            TERMINAL DE BUSES
              <div className="imagenSuperpuesta">
              <img src={imageflota} alt="Flota" />
            </div>
          </Box>
          <Box width="50%" fontWeight="fontWeightBold">
            <div className="infTerminalBuses">
              LUNES A VIERNES: 07:00 A 19:00 HRS.
              </div>
            <div className="infTerminalBuses">
              SÁBADOS Y DOMINGO: 08:00 A 20:00 HRS.
              </div>
          </Box>
          <div className="aeropuerto">
            <Box bgcolor="secondary.main" color="primary.contrastText" p={1} fontSize={16} fontWeight="fontWeightBold" width="30%" className="infAeropuerto">
              AEROPUERTO
                <div className="imagenSuperpuesta">
                <img src={imageavion} alt="Avion" />
              </div>
            </Box>
            <Box width="50%" fontWeight="fontWeightBold">
              <div className="textoAeropuerto">
                LUNES A VIERNES: 06:00 A 14:00 HRS.
                </div>
            </Box>
          </div>
        </Grid>
      </Grid>
      <Box bgcolor="secondary.main" color="primary.contrastText" p={2} fontSize={24} width="100%" textAlign="center" className="infFormulario">
        El formulario es <strong>gratuito</strong>
        <div className="imagenSuperpuesta">
          <img src={imagemaletas} alt="Maleta" />
        </div>
      </Box>
    </div>
  );
}
export default Autorizacion;
