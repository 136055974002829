import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ModalPdfViewerComponent from '../../../components/genericos/modal/ModalPdfViewerComponent';
import CardInformeComponent from '../../../components/genericos/cards/CardInformeComponent';

import {GLOBAL} from '../../../global';

const { useRef } = React;
const useStyles = makeStyles(theme => ({
    root: {
      padding:theme.spacing(3,1)
    },
    customTabStyles:{
        marginTop:theme.spacing(1)
    },
    customTabStyles2:{
        margin:theme.spacing(2,0)
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    customTitle:{
        textAlign:'center',
        color:theme.palette.primary.main
    },
    customSubTitle:{
        color:theme.palette.primary.main,
        //paddingBottom:theme.spacing(3),
        textAlign:'center',
    },
  }));

const InformeGestionComponent = (props) => {
    const classes = useStyles();
    const childRef = useRef();

    const { data={        
        title:"Rendición pública de cuentas",
        description:null,
        style:{
            container:null,
            title:null,
            subtitle:null
        },
        data:null
    }} = props;

    const onHandleClick = (dataItem) =>{        
        childRef.current.openModal({title:dataItem.title,archivo:`${GLOBAL.assetsBack}${dataItem.archivo}`});
    }

    useEffect(() => {
       window.scrollTo(0, 0);
    }, []);

    return(
        <Box className={classes.root} style={data.style.container}>
            <ModalPdfViewerComponent title="titulo"  btnclose={'Cerrar'} ref={childRef}/>
            <Grid container className={classes.customTabStyles} justify="center">
                <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                    <Box mb={3}>
                        {
                            data.title?
                            <Typography className={classes.customTitle} variant="h3" style={data.style.title}>{data.title?data.title:''}</Typography>:null
                        }                            
                        {
                            data.description?
                            <Typography className={classes.customSubTitle} variant="h6" style={data.style.subtitle}>{data.description?data.description:''}</Typography>:null
                        }
                        
                    </Box>
                </Grid>
            </Grid>
            <Grid container justify="center">
                <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                    <Grid container justify="center">
                        {
                            data.data?
                            data.data.map((item,index) => (
                                <Grid item xs={6} sm={6} md={4} lg={4} xl={3} key={index}>
                                    <CardInformeComponent data={item} onHandleClick={onHandleClick}/>
                                </Grid>
                            )):null
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box align="center" mt={3} onClick={() => window.open("/transparencia-GAMC-alcaldia", "_blank")}>
                        <Typography>Alcaldía transparente</Typography>
                    </Box>
                </Grid>
            </Grid>            
        </Box>
    );
}
export default InformeGestionComponent;
