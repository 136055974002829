import React,{ useRef,useState } from 'react';
import { Grid,Box,Button,Divider,Avatar } from '@material-ui/core';
import {Carousel} from 'react-responsive-carousel';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { GLOBAL } from '../../../global';
import {Parallax} from 'react-parallax';
import Convert from 'htmr';
import { Map, TileLayer, Marker, Popup,Icon } from 'react-leaflet';
import CustomButtonComponent from '../buttons/CustomButtonComponent';
import moment from 'moment';
import 'moment/locale/es';
import ModalComponentViewer from '../modal/ModalComponentViewer';
import TitlebarGridList from '../lista-cuadrada/TitlebarGridList';
import useAxios from 'axios-hooks';
import MapaEventosComponent from '../../../components/small-components/MapaEventosComponent';

moment().locale('es');

const useStyles = makeStyles((theme) => ({
    rootDesktop: {
        minHeight:'95vh',
        //backgroundImage: `url(${GLOBAL.assetsBack}/img/secciones/portada4.jpeg)`,
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        //padding:theme.spacing(10,1)
    },
    root: {
        //minHeight:'95vh',
        //backgroundImage: `url(${GLOBAL.assetsBack}/img/secciones/portada4.jpeg)`,
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        //padding:theme.spacing(10,1)
    },
    customDescriptionContainer:{
        //backgroundColor:theme.palette.primary.dark,
        backgroundColor:'rgba(0,0,0,0.3)',
        padding:theme.spacing(5,5),
        margin:theme.spacing(5,5),
        borderRadius:theme.spacing(2),
        color:'white',
    },
    customDescription:{
        fontSize:theme.typography.h6.fontSize
    },
    customTitle:{
        textAlign:'center'
    },
    customButtonContainer:{
        textAlign:'center',
        margin:theme.spacing(1)
    },
    customBodyContainer:{
        padding:theme.spacing(1)
    },
    customSummaryStyle:{
        backgroundColor:theme.palette.primary.main,
        color:"white"
    },
    customDivider:{
        backgroundColor:'white',
        margin:theme.spacing(2,0)
    },
    customIcon:{
        color:'white'
    },
    rounded: {
        color: '#fff',
        backgroundColor: theme.palette.quinary.main,
    },
    //PHONE
    phoneMainContainer:{
        backgroundImage:`url(${GLOBAL.assetsBack}/img/secciones/home/backgroundimage.jpg)`,
        backgroundSize:'200px'
    },
    customDescriptionContainerPhone:{
        backgroundColor:theme.palette.primary.dark,
        //backgroundColor:'rgba(72,39,120,0.7)',
        padding:theme.spacing(5,5),
        margin:theme.spacing(4,4),
        borderRadius:theme.spacing(2),
        color:'white',
    },
    customContanidoDestacadoPhone:{
        backgroundColor:theme.palette.primary.dark,
        //backgroundColor:'rgba(72,39,120,0.7)',
        padding:theme.spacing(5,1),
        margin:theme.spacing(4,4),
        borderRadius:theme.spacing(2),
        color:'white',
    },
    customExpansionIcon:{
        maxWidth:'20px'
    },
    customAvatar:{
        backgroundColor:theme.palette.secondary.main
    },
    leafletContainer:{    
        height: "480px",
    },
}));

/*
{
  "title": "GOBIERNO MUNICIPAL",
  "style": {
    "textAlign": "justify"
  },
  "content": null,
  "button": {
    "type": "interno",
    "url": "/gobierno-municipal",
    "text": "Conoce más"
  },
  "images": [
    {
      "img": "/img/secciones/home/img3.jpg",
      "hrini": "00:00:00",
      "hrfin": "06:00:00"
    },
    {
      "img": "/img/secciones/home/img1.jpg",
      "hrini": "06:00:00",
      "hrfin": "12:30:00"
    },
    {
      "img": "/img/secciones/home/img2.jpg",
      "hrini": "12:30:00",
      "hrfin": "18:30:00"
    },
    {
      "img": "/img/secciones/home/img3.jpg",
      "hrini": "18:30:00",
      "hrfin": "23:59:59"
    }
  ],
  "items": [
    {
      "title": "Restricción vehicular",
      "img": "/img/secciones/home/icon-no-estacionar.png",
      "component": "RestriccionVehicular",
      "data": {
        "button": {
          "color": "secondary",
          "label": "Ver área restringida"
        },
        "img": {
          "carousel": false,
          "data": "/img/secciones/home/mapa_restriccion.png"
        },
        "dates": [
          {
            "date": "Domingo",
            "pta": "Este día no hay restricción"
          },
          {
            "date": "Lunes",
            "pta": "Este día no ingregan las placas con terminación en 0 ó 1"
          },
          {
            "date": "Martes",
            "pta": "Este día no ingregan las placas con terminación en 2 ó 3"
          },
          {
            "date": "Miércoles",
            "pta": "Este día no ingregan las placas con terminación en 4 ó 5"
          },
          {
            "date": "Jueves",
            "pta": "Este día no ingregan las placas con terminación en 6 ó 7"
          },
          {
            "date": "Viernes",
            "pta": "Este día no ingregan las placas con terminación en 8 ó 9"
          },
          {
            "date": "Sábado",
            "pta": "Este día no hay restricción"
          }
        ]
      }
    },
    {
      "title": "Cierres de calles",
      "img": "/img/secciones/home/icon-road-block.png",
      "component": "CierreCalles",
      "data": {
        "button": {
          "color": "secondary",
          "label": "Por trabajos"
        },
        "button2": {
          "color": "secondary",
          "label": "Por eventos"
        },
        "img": null,
        "dates": [
          {
            "date": "Domingo",
            "description": "Hay cierre de calles",
            "data": [
              {
                "location": "-17.413366,-66.156577",
                "description": "<p><strong>Calle:</strong> Av. Ayacucho y 6 de Agosto</p><p><strong>Hora cierre:</strong> De 05:00 a 18:30 Hrs.</p>"
              }
            ]
          },
          {
            "date": "Lunes",
            "description": "Hay cierre de calles",
            "data": [
              {
                "location": "-17.413366,-66.156577",
                "description": "<p><strong>Calle:</strong> Av. Ayacucho y 6 de Agosto</p><p><strong>Hora cierre:</strong> De 07:00 a 12:30 Hrs.</p>"
              },
              {
                "location": "-17.413236,-66.155709",
                "description": "<p><strong>Calle:</strong> Av. Independencia y 6 de Agosto</p><p><strong>Hora cierre:</strong> De 09:00 a 14:30 Hrs.</p>"
              },
              {
                "location": "-17.412136,-66.153475",
                "description": "<p><strong>Calle:</strong> Av. Barrientos y 6 de Agosto</p><p><strong>Hora cierre:</strong> De 01:00 a 08:00 Hrs.</p>"
              }
            ]
          },
          {
            "date": "Martes",
            "description": "Hay cierre de calles",
            "data": [
              {
                "location": "-17.413236,-66.155709",
                "description": "<p><strong>Calle:</strong> Av. Independencia y 6 de Agosto</p><p><strong>Hora cierre:</strong> De 05:00 a 18:30 Hrs.</p>"
              },
              {
                "location": "-17.412136,-66.153475",
                "description": "<p><strong>Calle:</strong> Av. Barrientos y 6 de Agosto</p><p><strong>Hora cierre:</strong> De 05:00 a 18:30 Hrs.</p>"
              }
            ]
          },
          {
            "date": "Miércoles",
            "description": "Hay cierre de calles",
            "data": [
              {
                "location": "-17.413236,-66.155709",
                "description": "<p><strong>Calle:</strong> Av. Independencia y 6 de Agosto</p><p><strong>Hora cierre:</strong> De 14:00 a 18:30 Hrs.</p>"
              }
            ]
          },
          {
            "date": "Jueves",
            "description": "Hay cierre de calle",
            "data": [
              {
                "location": "-17.412136,-66.153475",
                "description": "<p><strong>Calle:</strong> Av. Barrientos y 6 de Agosto</p><p><strong>Hora cierre:</strong> De 20:00 a 00:30 Hrs.</p>"
              }
            ]
          },
          {
            "date": "Viernes",
            "description": "Hay cierre de calles",
            "data": [
              {
                "location": "-17.413366,-66.156577",
                "description": "<p><strong>Calle:</strong> Av. Ayacucho y 6 de Agosto</p><p><strong>Hora cierre:</strong> De 05:00 a 18:30 Hrs.</p>"
              },
              {
                "location": "-17.413236,-66.155709",
                "description": "<p><strong>Calle:</strong> Av. Independencia y 6 de Agosto</p><p><strong>Hora cierre:</strong> De 05:00 a 18:30 Hrs.</p>"
              },
              {
                "location": "-17.412136,-66.153475",
                "description": "<p><strong>Calle:</strong> Av. Barrientos y 6 de Agosto</p><p><strong>Hora cierre:</strong> De 05:00 a 18:30 Hrs.</p>"
              }
            ]
          },
          {
            "date": "Sábado",
            "description": "No hay cierre de calles",
            "data": null
          }
        ]
      }
    },
    {
      "title": "Restricciones Covid-19",
      "img": "/img/secciones/home/icon-cuarentena.png",
      "component": "RestriccionVehicular",
      "data": {
        "button": {
          "color": "secondary",
          "label": "Ver Restricciones Covid-19"
        },
        "img": {
          "carousel": true,
          "data": [
            "/img/secciones/home/slide_covid1.jpg",
            "/img/secciones/home/slide_covid2.jpg",
            "/img/secciones/home/slide_covid3.jpg",
            "/img/secciones/home/slide_covid4.jpg",
            "/img/secciones/home/slide_covid5.jpg",
            "/img/secciones/home/slide_covid6.jpg",
            "/img/secciones/home/slide_covid7.jpg"
          ]
        },
        "dates": [
          {
            "date": "Domingo",
            "pta": "Este día la circulación es de 05:00 a 00:00 hrs."
          },
          {
            "date": "Lunes",
            "pta": "Este día la circulación es de 05:00 a 00:00 hrs."
          },
          {
            "date": "Martes",
            "pta": "Este día la circulación es de 05:00 a 00:00 hrs."
          },
          {
            "date": "Miércoles",
            "pta": "Este día la circulación es de 05:00 a 00:00 hrs."
          },
          {
            "date": "Jueves",
            "pta": "Este día la circulación es de 05:00 a 00:00 hrs."
          },
          {
            "date": "Viernes",
            "pta": "Este día la circulación es de 05:00 a 00:00 hrs."
          },
          {
            "date": "Sábado",
            "pta": "Este día la circulación es de 05:00 a 00:00 hrs."
          }
        ]
      }
    }
  ],
  "destacados": {
    "smallText": "CONTENIDO",
    "bigText": "DESTACADO",
    "icon": null,
    "items": [
      {
        "img": "img/secciones/home/destacado-vamos-cochabamba.jpg",
        "title": "Vamos",
        "description": "Cochabamba",
        "action": {
          "type": "alert",
          "url": null,
          "data": "Contenido no disponible"
        }
      },
      {
        "img": "img/secciones/home/img-innova.jpg",
        "title": "Innova",
        "description": "Cochabamba",
        "action": {
          "type": "alert",
          "url": null,
          "data": "Contenido no disponible"
        }
      }
    ]
  }
}
*/

const RestriccionVehicular = (props) => {
    const classes = useStyles();
    const modalRef = useRef();
    const {
        title="", 
        data={
        button:{
            color:"secondary",
            label:"Ver área restringida"
        },
        img:"/img/secciones/home/mapa_restriccion.png"
    } } = props;
    //const dias = ['Domingo','Lunes','Martes','Miércoles','Jueves','Viernes','Sábado'];

    let mydate = new Date();
    //let weekDayName = moment(mydate).format('dddd');
    let day = moment(mydate).day();//return numer of a day 0(sunday)-6(saturday)
    //console.log("Hoy es ",dias[day]);
    //console.log("Numero del dia ",day);
    return(
        <div>
            <ModalComponentViewer ref={modalRef} title={title} body={<Component2PopUp data={data.img}/>}/>
            <div className={classes.customBodyContainer}>
                {
                    Convert(data.dates && Array.isArray(data.dates)?data.dates[day].pta:'<p></p>')
                }
            </div>            
            {
                data.button?
                <div style={{width:"100%"}}><Button variant="contained" color={data.button.color} onClick={()=>modalRef.current.openModal()}>{data.button.label}</Button></div>:null
            }
        </div>
    );
}

const CierreCalles = (props) => {
    const classes = useStyles();
    const modalRef = useRef();
    const [currentBtn,setCurrentBtn] = useState(null);
    const {
        title="", 
        data={
        button:{
            color:"secondary",
            label:"Ver área restringida"
        },
        img:"",
        dates:null
    } } = props;
    //const dias = ['Domingo','Lunes','Martes','Miércoles','Jueves','Viernes','Sábado'];

    let mydate = new Date();
    //let weekDayName = moment(mydate).format('dddd');
    let day = moment(mydate).day();//return numer of a day 0(sunday)-6(saturday)
    //let day = 6;//Para pruebas
    //console.log("Hoy es ",dias[day]);
    //console.log("Numero del dia ",day);    
    const loadComponent = (btn) => {        
        setCurrentBtn(btn);
        modalRef.current.openModal();
    }    
    return(
        <div style={{width:'100%'}}>
            <ModalComponentViewer ref={modalRef} title={title} body={currentBtn && currentBtn===1?<CierreCallesMap/>:<MapaEventosComponent/>}/>
            {
                data && data.description?
                <div className={classes.customBodyContainer}>
                    {
                        Convert(data.description)
                    }
                </div>:null
            }
            <Grid container align="center">
                {
                    data.button && data.button.enabled?
                    <Grid item xs={6}>                    
                            <Button variant="contained" color={data.button.color} onClick={()=>loadComponent(1)}>{data.button.label}</Button>                    
                    </Grid>:null
                }
                {
                    data.button2 && data.button2.enabled?
                    <Grid item xs={6}>                    
                        <Button variant="contained" color={data.button2.color} onClick={()=>loadComponent(2)}>{data.button2.label}</Button>                    
                    </Grid>:null
                }
            </Grid>                        
        </div>
    );
}

const Component2PopUp = (props) => {
    const {data=null} = props;
    return(
        <>
            {
                data.carousel?
                <Carousel centerMode centerSlidePercentage={100} emulateTouch infiniteLoop useKeyboardArrows autoPlay showThumbs={false} stopOnHover interval={5000}>
                    {
                        data.data && Array.isArray(data.data)?
                        data.data.map((item,index) => (
                            <div key={index}>
                                <img src={`${GLOBAL.assetsBack}${item}`} alt="" width="100%"/>
                            </div>
                        )):null
                    }
                </Carousel>:
                <img src={`${GLOBAL.assetsBack}${data.data}`} alt="" width="100%"/>
                
            }
        </>
    );
}

const CierreCallesMap = (props) => {
    const { data=null } = props;
    const classes = useStyles();

    const [{ data:calles_cerradas_data, error:calles_cerradas_error, loading:calles_cerradas_loading }] = useAxios({ url: 'http://192.168.220.59:8010/api/calles-cerradas', method: 'GET' });    
    const getPosition = (dato) => {
        let aux = String(dato).split(",");
        return Array.isArray(aux) && aux.length>1?aux:null;
    }
    const getBounds = (datos) => {
        //console.log("datos = ",datos);
        let myarray = [];
        datos.map(element => {
            let aux = getPosition(element.location);
            if(aux){
                myarray.push(aux);
            }
        });
        //console.log("getBounds = ",myarray);
        return myarray;
    }
    const getBounds2 = (datos) => {
        //console.log("datos = ",datos);
        let myarray = [];
        datos.map(element => {
            let aux = [element.latitud,element.longitud];
            if(aux){
                myarray.push(aux);
            }
        });
        //console.log("getBounds = ",myarray);
        return myarray;
    }
    //console.log("calles_cerradas_data=",calles_cerradas_data);    

    return <>
            {
                calles_cerradas_data && calles_cerradas_data.status && calles_cerradas_data.data && Array.isArray(calles_cerradas_data.data)?
                <Map 
                    bounds={getBounds2(calles_cerradas_data.data)} 
                    //center={[-17.406470,-66.152589]}
                    zoom={18} 
                    attributionControl={false} 
                    className={classes.leafletContainer}
                >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                    {                        
                        calles_cerradas_data.data.map((item,index)=>(                            
                            <Marker key={`marker${index}`} position={[item.latitud,item.longitud]}>
                                <Popup>
                                    {Convert(`
                                        <div>
                                            <p><strong>DETALLE</strong>: ${String(item.detalle).toUpperCase()}</p>
                                            <p><strong>UBICACIÓN</strong>: ${String(item.ubicacion).toUpperCase()}</p>
                                            <p><strong>UNIDAD</strong>: ${String(item.encargado).toUpperCase()}</p>
                                            <p><strong>TIPO CIERRE</strong>: ${String(item.descripcion).toUpperCase()}</p>
                                            <p><strong>FECHA INICIO</strong>: ${String(item.fecha_inicio).toUpperCase()}</p>
                                            <p><strong>HORA INICIO</strong>: ${String(item.hora_inicio).toUpperCase()}</p>
                                            <p><strong>FECHA FINAL</strong>: ${String(item.fecha_final).toUpperCase()}</p>
                                            <p><strong>HORA FINAL</strong>: ${String(item.hora_inicio).toUpperCase()}</p>
                                        </div>
                                    `)}
                                </Popup>
                            </Marker>
                        ))
                    }
                </Map>:
                <Box className={classes.leafletContainer}>
                    No hay cierre de calles
                </Box>
            }
        </>

    return(
        <>
            {
                calles_cerradas_data && Array.isArray(calles_cerradas_data.data)?
                <Map 
                    bounds={getBounds(data)} 
                    //center={[-17.406470,-66.152589]}
                    zoom={18} 
                    attributionControl={false} 
                    className={classes.leafletContainer}
                >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                    {                        
                        data.map((item,index)=>(
                            getPosition(item.location)?
                            <Marker key={`marker${index}`} position={String(item.location).split(",")}>
                                <Popup>
                                    {Convert(item.description?item.description:'<p></p>')}
                                </Popup>
                            </Marker>:null
                        ))
                    }
                </Map>:
                <Box className={classes.leafletContainer}>
                    No hay cierre de calles
                </Box>
            }
        </>
    );
}

const HeaderComponent = (props) => {
    const matches = useMediaQuery('(min-width:600px)');
    const data = useSelector(store => {
        let aux = store.datosMain.static && Array.isArray(store.datosMain.static)?store.datosMain.static.find(element => element.slug === 'HeaderComponent'):[];
        aux = JSON.parse(aux.data);
        return aux;
    });
    const classes = useStyles();
    
    const getImage = () => {        
        let result = null;
        let format = 'HH:mm:ss';
        let current = moment().format(format);
        if(data.images && Array.isArray(data.images) && data.images.length>0){
            result = data.images.find(element => element.hrini==current || element.hrfin==current);
            if(!result){
                result = data.images.find(element => {
                    let time = moment(current,format);
                    let beforeTime = moment(element.hrini,format);
                    let afterTime = moment(element.hrfin,format);
                    return time.isBetween(beforeTime, afterTime);
                });
            }
        }
        return result?result.img:'';
    }
    return (
        <>
            {
                matches?
                <Parallax
                    //blur={10}
                    bgImage = {`${GLOBAL.assetsBack}${getImage()}`}
                    bgImageAlt = "Pordata"
                    strength = {200}
                    bgImageStyle={{}}
                >
                    <Grid container className={classes.rootDesktop} justify="flex-start" alignContent="center">
                        <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
                            <Box className={classes.customDescriptionContainer}>
                                {
                                    false?
                                    <>
                                        <Typography variant="h4" className={classes.customTitle}>
                                            {data && data.title?data.title:null}
                                        </Typography>
                                        <Box className={classes.customDescription} style={data && data.style?data.style:null}>
                                            {
                                                Convert(data && data.content?data.content:'<p></p>')
                                            }
                                        </Box>
                                        <Box className={classes.customButtonContainer}>
                                            <CustomButtonComponent size="large" action={data.button}/>
                                        </Box>
                                        <Divider className={classes.customDivider}/>
                                    </>:null
                                }
                                <Box>
                                    <Box display="flex" flexDirection="row">
                                        <Box p={1}>
                                            <Avatar variant="rounded" className={classes.rounded}>
                                                <CalendarTodayIcon/> 
                                            </Avatar>
                                        </Box>
                                        <Box>
                                            <Box>
                                                <Typography variant="subtitle2" component="span">
                                                    {String(moment(new Date()).locale('es').format('dddd D [de] MMMM')).toUpperCase()}                                
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="h4" component="span">
                                                    {String(moment(new Date()).locale('es').format('YYYY')).toUpperCase()}                                
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                {
                                    data.items.map((item,index)=>(
                                        <ExpansionPanel key={`i${index}`}>
                                            <ExpansionPanelSummary
                                                expandIcon={<ExpandMoreIcon className={classes.customIcon}/>}
                                                aria-controls="panel1a-content"
                                                id={`panel-content${index}`}
                                                className={classes.customSummaryStyle}
                                            >
                                                {/* <Typography variant="h6" align="center">{item.title}</Typography> */}
                                                <Box display="flex" flexDirection="row" alignItems="center">
                                                    <Box px={1}>
                                                        <Avatar variant="rounded" className={classes.customAvatar}>
                                                            <img className={classes.customExpansionIcon} src={`${GLOBAL.assetsBack}${item.img}`} alt="Icono"/>
                                                        </Avatar>
                                                    </Box>
                                                    <Box>{item.title}</Box>
                                                </Box>
                                            </ExpansionPanelSummary>
                                            <Divider light={true}/>
                                            <ExpansionPanelDetails>
                                                {
                                                    item.component && item.component=="RestriccionVehicular"?
                                                    <RestriccionVehicular data={item.data} title={item.title}/>:null
                                                }
                                                {
                                                    item.component && item.component=="CierreCalles"?
                                                    <CierreCalles data={item.data} title={item.title}/>:null
                                                }                         
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    ))
                                }                        
                            </Box>
                            {
                                data && data.destacados?
                                <Box className={classes.customDescriptionContainer}>                                
                                    <TitlebarGridList data={data.destacados}/>
                                </Box>:null          
                            }
                        </Grid>
                    </Grid>
                </Parallax>:
                <Box className={classes.phoneMainContainer}>
                    <Grid container className={classes.root} justify="flex-end" alignContent="center">
                        <Grid item xs={12} sm={10} md={8} lg={4} xl={3}>
                            <Box className={classes.customDescriptionContainerPhone}>
                                {
                                    false?
                                    <>
                                        <Typography variant="h4" className={classes.customTitle}>
                                            {data && data.title?data.title:null}
                                        </Typography>
                                        <Box className={classes.customDescription} style={data && data.style?data.style:null}>
                                            {
                                                Convert(data && data.content?data.content:'<p></p>')
                                            }
                                        </Box>
                                        <Box className={classes.customButtonContainer}>
                                            <CustomButtonComponent size="large" action={data.button}/>
                                        </Box>
                                        <Divider className={classes.customDivider}/>
                                    </>:null
                                }
                                <Box>
                                    <Box display="flex" flexDirection="row" alignItems="center">
                                        <Box p={1}>
                                            <Avatar variant="rounded" className={classes.rounded}>
                                                <CalendarTodayIcon/> 
                                            </Avatar>
                                        </Box>
                                        <Box>
                                            <Box>
                                                <Typography variant="subtitle2" component="span">
                                                    {String(moment(new Date()).locale('es').format('dddd D [de] MMMM')).toUpperCase()}                                
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="h4" component="span">
                                                    {String(moment(new Date()).locale('es').format('YYYY')).toUpperCase()}                                
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                {
                                    data.items.map((item,index)=>(
                                        <ExpansionPanel key={`i${index}`}>
                                            <ExpansionPanelSummary                                                
                                                expandIcon={<ExpandMoreIcon className={classes.customIcon}/>}
                                                aria-controls="panel1a-content"
                                                id={`panel-content${index}`}
                                                className={classes.customSummaryStyle}
                                            >
                                                {/* <Typography variant="h6" align="center">{item.title}</Typography> */}
                                                <Box display="flex" flexDirection="row" alignItems="center">
                                                    <Box px={1}>
                                                        <Avatar variant="rounded" className={classes.customAvatar}>
                                                            <img className={classes.customExpansionIcon} src={`${GLOBAL.assetsBack}${item.img}`} alt="Icono"/>
                                                        </Avatar>
                                                    </Box>
                                                    <Box>{item.title}</Box>
                                                </Box>
                                            </ExpansionPanelSummary>
                                            <Divider light={true}/>
                                            <ExpansionPanelDetails>
                                                {
                                                    item.component && item.component=="RestriccionVehicular"?
                                                    <RestriccionVehicular data={item.data} title={item.title}/>:null
                                                }
                                                {
                                                    item.component && item.component=="CierreCalles"?
                                                    <CierreCalles data={item.data} title={item.title}/>:null
                                                }                         
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    ))
                                }                        
                            </Box>
                            {
                                data && data.destacados?
                                <Box className={classes.customContanidoDestacadoPhone}>
                                    <TitlebarGridList data={data.destacados}/>
                                </Box>:null
                            }
                        </Grid>
                    </Grid>
                </Box>
            }
        </>
    );
}
export default HeaderComponent;