import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Moment from 'react-moment';
//import CardMedia from '@material-ui/core/CardMedia';
//import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
//import GetAppIcon from '@material-ui/icons/GetApp';
import Toolbar from '@material-ui/core/Toolbar';
//import ImportContactsIcon from '@material-ui/icons/ImportContacts';
//import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import convert from 'htmr';
import Skeleton from '@material-ui/lab/Skeleton';

import {GLOBAL} from '../../../../global';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    cursor:'pointer',
    height:'100%',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width:'100%'
  },
  content: {
    flex: '1 0 auto',
    minWidth:"80%"
  },
  cover: {
    width: 250,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
 customToolBar:{
   paddingLeft:theme.spacing(0),
   paddingRight:theme.spacing(0)
 },
 customToolBar2:{
   padding:theme.spacing(0)
 },
 large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  CustomAlign:{
    textAlign:'center',
    margin:theme.spacing(1,1)
  }
}));

export default function CardGaceta(props) {
  const { loading = false } = props;

  const classes = useStyles();
  const theme = useTheme();

  const onHandleClick = () => {
    props.onHandleClick(props.data);
  }

  return (
    <Card className={classes.root} onClick={onHandleClick}>      
      <Toolbar variant="dense" className={classes.customToolBar}>
        <Box className={classes.CustomAlign}>
          {
            loading?
              <Skeleton variant="circle" width={40} height={40} />:              
              <img style={{maxWidth:'60px'}} src={GLOBAL.assetsBack+'/img/secciones/auditoria-interna/card-icon.png'}/>
          }
          <Typography  variant="caption" display="block" gutterBottom>{loading?<Skeleton/>:props.data.tipo}</Typography>
        </Box>
      </Toolbar>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography variant="button" display="block" gutterBottom>
            {loading?<Skeleton width={200}/>:props.data.name}
          </Typography>
          <Typography  variant="caption" display="block" gutterBottom>
            {loading?<Skeleton width={200}/>:convert(props.data.description)}
          </Typography>
        </CardContent>
      </div>
      <Toolbar variant="dense" className={classes.customToolBar2}>
        <Box className={classes.CustomAlign}>          
          {
            loading?
              <Skeleton variant="circle" width={40} height={40} />:
              <img style={{maxWidth:'60px'}} src={GLOBAL.assetsBack+'/img/secciones/auditoria-interna/card-download.png'}/>
          }
          <Typography  variant="caption" gutterBottom>{loading?<Skeleton />:<Moment format="YYYY/MM/DD">{props.data.fecha}</Moment>}</Typography>
        </Box>
      </Toolbar>
    </Card>
  );
}
CardGaceta.propTypes = {
  loading: PropTypes.bool,
};
