import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography,Grid } from '@material-ui/core';

import CardWithTitleBgSeeMore from '../../cards/CardWithTitleBgSeeMore';

const useStyles = makeStyles(theme => ({
    root:{
        flexGrow:1
    },
    customAlign:{
        float:'center'
    },
    customTitle:{
        margin:theme.spacing(5,1),        
    }
}));

function CardContainerWithTitle(props){
    const classes = useStyles();   
    return(
        <div className='CardContainerWithTitle'>            
            <Typography variant='h4' align='center' className={classes.customTitle}>
                {props.data.title}
            </Typography>            
            <Grid container>
                <Grid item xs={12} sm={1} md={1} lg={2} xl={3}></Grid>
                <Grid item xs={12} sm={10} md={10} lg={8} xl={6}>
                    <Grid container justify='center' spacing={2}>
                        {
                            props.data.items.map((c,index)=>(
                                <Grid key={index} item xs={12} sm={4} md={4} lg={4} xl={4}>
                                    <CardWithTitleBgSeeMore data={c} onHandleClick={props.onHandleClick}/>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={1} md={1} lg={2} xl={3}></Grid>
            </Grid>

        </div>
    );
}
export default CardContainerWithTitle;