import React,{ useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fab from '@material-ui/core/Fab';
import FondAwesome from 'react-fontawesome';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import convert from 'htmr';
import {GLOBAL} from '../../global';
import ReactPlayer from 'react-player'
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor:theme.palette.tertiary.main,
      flexGrow: 1,
      padding:theme.spacing(5,1),
      //backgroundImage:`url(${GLOBAL.assetsBack}/img/secciones/fondo-oscuro2.jpg)`,
      //backgroundRepeat: 'no-repeat',
      //backgroundSize:'cover'
      //backgroundAttachment: 'fixed',
      //backgroundPosition: 'center'
    },
    imgContainer:{
      padding:theme.spacing(0.5),
      textAlign:'center',
      borderRadius:'50%'
    },
    media:{
      width:'100%',
      height:'auto',
      maxWidth:'480px',
      maxHeight:'480px',
      borderRadius:'50%',
      border:'5px solid white'
    },
    mobilMedia:{
      maxWidth:'250px',
      borderRadius:'50%',
      border:'5px solid white'
    },
    customContaimer:{      
      fontSize:"16px",
      color:"white",
      //textShadow:"0.05em 0.05em #000"
    },
    customTitle:{
      textAlign:'center',
      color:theme.palette.primary.main,
      marginBottom:theme.spacing(3)
      //color:theme.palette.sextary.main

    },
    customSubTitle:{
      color:"white",
      //color:theme.palette.primary.main,
      padding:theme.spacing(0,1),
      textAlign:'center',            
    },
    imageBackdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0.4,
      transition: theme.transitions.create('opacity'),
    },
    contentStyle:{
      textAlign:'justify'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    customRRSSMargin:{
      margin:theme.spacing(0,0.5)
    },
    customBorder:{
      border:'2px solid white',
      backgroundColor: theme.palette.tertiary.main,
      margin:theme.spacing(1,1)
  },
  }));
  const socialIcons = {
    facebook:{
        color:'#ffffff',
        margin:'20px',
        fontSize:'30px'
    },
    twitter:{
        color:'#ffffff',
        margin:'20px',
        fontSize:'30px'
    },
    instagram:{
        color:'#ffffff',
        margin:'20px',
        fontSize:'30px'
    },
    redicons:{
        color:'#ffffff',
        fontSize:'20px'
    },
    textAlign:{
        textAlign:'center'
    }
  };

const NuestroAlcaldeComponent = (props) => {
    const matches = useMediaQuery('(min-width:600px)');
    const [ open, setOpen] = useState(false);
    const {data={
        title: "Nuestro alcalde",
        img: "/img/secciones/cap-manfred-reyes-villa.jpeg",
        name: "Cap. Manfred Reyes Villa Bacigalupi",
        button:"Descargar biografía",
        description: {
          titulo: "Biografía",
          video: "https://www.youtube.com/watch?v=klNT6SDjgQI",
          file: "/documents/pdf/Manfred-Reyes-Villa.pdf",
          contenido: null
        }
    }} = props;
    
    const classes = useStyles();

    const onHandleClick = () => {
      let atag = document.createElement('a');
      atag.setAttribute('href',`${GLOBAL.assetsBack}${data.description.file}`);
      atag.setAttribute('download','biografía.pdf');
      atag.click();
      atag = null;
    }

    return(
      <>
        <Grid container justify='center' className={classes.root}>
            <Grid item xs={12} sm={12} md={10} lg={8} xl={7}>
              <Typography className={classes.customTitle} variant="h3">{data.title}</Typography>
              <Grid container justify='center' spacing={4}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} align="center">
                  <Box>
                    <img className={matches?classes.media:classes.mobilMedia} src={GLOBAL.assetsBack+data.img} alt={data.name}/>
                    <Typography className={classes.customSubTitle} variant="h4">{data.name}</Typography>                    
                    <Box>
                      <Fab className={classes.customBorder} aria-label="Siguenos en Facebook" onClick={()=>window.open('https://www.facebook.com/ManfredReyesVillaOficial', '_blank')}>
                          <FondAwesome style={socialIcons.facebook} name="facebook-f"/>
                      </Fab> 
                      <Fab className={classes.customBorder} aria-label="Siguenos en Twitter" onClick={()=>window.open('https://twitter.com/ManfredBolivia', '_blank')}>
                          <FondAwesome style={socialIcons.twitter} name="twitter"/>
                      </Fab> 
                      <Fab className={classes.customBorder} aria-label="Siguenos en Instagram" onClick={()=>window.open('https://www.instagram.com/manfred_oficial', '_blank')}>
                          <FondAwesome style={socialIcons.instagram} name="instagram"/>
                      </Fab>
                    </Box>
                  </Box>
                  <br/>
                  {
                    data.description && data.description.file?
                    <Button variant="contained" color="secondary" startIcon={<PictureAsPdf/>} onClick={()=>onHandleClick()}>{data.button}</Button>:null                        
                  }
                </Grid>
                {
                  false?
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} align="center">
                    {
                      matches?
                      <Box className={classes.customContaimer}>
                        <Typography variant="h4" align="center">{data.description.titulo}</Typography>
                        {
                          data.description && data.description.contenido?
                          <Box className={classes.contentStyle}>
                            {convert(data.description.contenido?data.description.contenido:'<p></p>')}
                          </Box>:null
                        }
                        {
                          data.description && data.description.video?
                          <ReactPlayer url={data.description.video} controls width="100%"/>:null
                        }
                        <br/>
                        {
                          data.description && data.description.file?
                          <Button variant="contained" color="secondary" startIcon={<PictureAsPdf/>} onClick={()=>onHandleClick()}>{data.button}</Button>:null                        
                        }
                      </Box>:
                      <Box alignItems="center">
                        <ExpansionPanel expanded={open}>
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            onClick={()=> setOpen(!open)}
                          >
                            <Typography variant="h4" align="center" className={classes.heading}>{data.description.titulo}</Typography>
                          </ExpansionPanelSummary>
                          <Divider/>
                          <ExpansionPanelDetails>
                            <Grid container spacing={1}>
                              {
                                data.description && data.description.contenido?
                                <Grid item xs={12}>
                                  <Box className={classes.contentStyle} width="100%">
                                    {convert(data.description.contenido?data.description.contenido:'<p></p>')}
                                  </Box>
                                </Grid>:null
                              }
                              {
                                data.description && data.description.file?
                                <Grid item xs={12}>
                                  <Button variant="contained" color="secondary" startIcon={<PictureAsPdf/>}  onClick={()=>onHandleClick()}>{data.button}</Button>
                                </Grid>:null
                              }
                              {
                                data.description && data.description.video?
                                <Grid item xs={12}>
                                  <ReactPlayer url={data.description.video} controls width="100%"/>
                                </Grid>:null
                              }                            
                            </Grid>                          
                          </ExpansionPanelDetails>
                        </ExpansionPanel>                      
                      </Box>
                    }                    
                  </Grid>:null
                }
              </Grid>                
            </Grid>
        </Grid>
        
      </>
    );
}
export default withRouter(NuestroAlcaldeComponent);