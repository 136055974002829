import React from 'react';
import { Link,useParams } from 'react-router-dom';
import useAxios from 'axios-hooks';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import {Carousel} from 'react-responsive-carousel';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CustomButtonComponent from '../../components/genericos/buttons/CustomButtonComponent';

import ErrorRequestComponent from '../../components/genericos/error/ErrorRequestComponent';
import { GLOBAL } from '../../global';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      padding:theme.spacing(10,1),
      //backgroundColor:theme.palette.primary.main
    },
    imgContainer:{
      padding:theme.spacing(1),
      textAlign:'center'
    },
    media:{
      width:'100%',
      height:'auto'
    },
    customContaimer:{      
      fontSize:"16px"
    },
    customTitle:{
      textAlign:'center',
      color:theme.palette.primary.main

    },
    customSubTitle:{
        //color:'#ffffff',
        paddingBottom:theme.spacing(5),
        textAlign:'center',            
    },
    paper: {    
        //padding: theme.spacing(4,1),    
        //marginTop:theme.spacing(5),
        textAlign: 'center',
        height:'100%',
        //cursor:'pointer',
    },
    cutomsBox:{     
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      
    },
    customAvatar:{
      width:theme.spacing(8),
      height:theme.spacing(8),
      backgroundColor:theme.palette.tertiary.main
    },
    customNombre:{
      padding:theme.spacing(3,1)
    },
    customButton:{
      backgroundColor:theme.palette.quaternary
    },
    //mobile
    customImgMobile:{
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    customMobile:{
      textAlign:'center',
      color:'white'
    }
  }));
/*
const data = {
    "title": "Secretarías",
    "description": "Listado de unidades dependientes",
    "url": "/api/unidad/secretarias",
    "style": {
      "container": {
        "backgroundColor": "rgb(79,185,168)"
      },
      "title": {
        "color": "white"
      },
      "description": {
        "color": "white"
      }
    }
  }
*/
const MyCustomCard = (props) => {
    const { loading=false,data={} } = props;
    const classes = useStyles();
    const image = data && data.imagen?JSON.parse(data.imagen):'';
    return(
        <Card className={classes.paper}>
            <CardMedia
              component="img"
              alt={data.unidad}
              height="140"
              image={`${GLOBAL.assetsBack}${image.img}`}
              //image="https://material-ui.com/static/images/cards/contemplative-reptile.jpg"
              title={data.unidad}
            />
            <Typography variant="h6" gutterBottom className={classes.customNombre}>
                {loading?<Skeleton/>:data.unidad}
            </Typography>
            <Box className={classes.cutomsBox}>
              {
                loading?
                <Skeleton width="200px"/>:
                <Link to={`/unidad/${data.path}`} style={{textDecoration:'none'}}>                  
                  <CustomButtonComponent text="Visitar" size="small"/>
                </Link>
              }
            </Box>
            <br/>
        </Card>
    );
}
const SecretariasComponent = (props) => {
    const matches = useMediaQuery('(min-width:600px)');
    const {data={}} = props;
    const {main,child} = useParams();
    let path = data.url?`${GLOBAL.apiURL}${data.url}`:`${GLOBAL.apiURL}/api/getunidadesdependientes`;
    const [{ data:getData, loading }, refetch] = useAxios(
      {
        url:path,
        method:'POST',
        data:{
            slug:main?main:child
        }
      },{ manual: false });
    const classes = useStyles();
    
    const getImgData = (inf) => {
      let obj = JSON.parse(inf);
      console.log("obj=",obj);
      return obj.img;
    }

    return(
      <Grid container justify='center' className={classes.root} style={data.style && data.style.container?data.style.container:{}}>
          <Grid item xs={12} sm={12} md={10} lg={8} xl={7}>
              <Typography className={classes.customTitle} variant="h3" style={data.style && data.style.title?data.style.title:{}}>{data.title}</Typography>
              <Typography className={classes.customSubTitle} variant="h6" style={data.style && data.style.description?data.style.description:{}}>{data.description}</Typography>
              {
                matches?
                <Box>
                  <Grid container justify="center" spacing={2}>
                    {
                      loading?
                      Array.from(new Array(3)).map((item,index)=>(
                        <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={3}>
                          <MyCustomCard loading/>
                        </Grid>
                      )):null
                    }
                    {
                      getData?getData.data.map((item,index) => (
                        <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={3}>
                          <MyCustomCard data={item}/>
                        </Grid>
                      )):null
                    }
                    {
                      getData && getData.data.length==0?<ErrorRequestComponent severity="info" text="Sin resultados" handleClick={() => refetch}/>:null
                    }
                  </Grid>
                </Box>:
                // <Box>
                //   <Carousel centerMode centerSlidePercentage={100} emulateTouch infiniteLoop useKeyboardArrows autoPlay showThumbs={false} stopOnHover interval={5000}>
                //     {
                //       getData?getData.data.map((item,index) => (
                //         <div key={index}>
                //           <MyCustomCard data={item}/>
                //         </div>
                //       )):null
                //     }
                //   </Carousel>
                // </Box>
                <Box>
                  <Grid container spacing={1}>
                    {
                      getData?getData.data.map((item,index) => (                        
                        <Grid item xs={4} key={`i${index}`}>
                          <Card>
                            <CardMedia                              
                              alt={item.unidad}
                              image={`${GLOBAL.assetsBack}${getImgData(item.imagen)}`}
                              className={classes.customImgMobile}
                            />
                          </Card>
                          <Typography variant="caption" className={classes.customMobile}>
                              {loading?<Skeleton/>:item.unidad}
                          </Typography>                      
                        </Grid>
                        
                      )):null
                    }
                  </Grid>
                </Box>
              }
          </Grid>
      </Grid>
    );
}
export default SecretariasComponent;