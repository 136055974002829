import React from 'react';
import { useParams,withRouter } from 'react-router-dom';
import useAxios from 'axios-hooks';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import convert from 'htmr';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import ReactPlayer from 'react-player';
import {Carousel} from 'react-responsive-carousel';
import moment from 'moment';
import {GLOBAL} from '../../../global';

const useStyles = makeStyles(theme=>({
    root:{
        margin:theme.spacing(5,1)
    },
    card: {
        width: '100%',
    },
    media: {
        width:'100%'
    },
    media2: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    title: {
        fontSize: 14,
    },
    customSubtitle:{
        paddingBottom:theme.spacing(1)
    },
    customDivider:{
        margin:theme.spacing(2,0)
    },
    customContent:{
        fontFamily:  theme.typography.fontFamily,
        fontSize: '0.9rem',
    }
}));

function Detail(props) {
    const classes = useStyles();
    //var [data,setData] = React.useState({});    
    var { id } = useParams();
    const [{data,loading,error},refetch] = useAxios(`${GLOBAL.apiURL}/api/posts/${id}`);
    
    //if(loading) return <p>Cargando...</p>
    if(error) return <p>Error!</p>
    //if(data){console.log("data=",data);}
    
    const isImage = (ruta) => {
        let result = false;        
        if(ruta){
            let extension = ruta.substring(ruta.lastIndexOf('.') + 1).toLowerCase();
            if(extension == 'png' || extension == 'jpg' || extension == 'jpeg'){
                result = true;
            }
        }
        return result;
    }
    const isVideo = (ruta) => {
        let result = false;        
        if(ruta){
            let extension = ruta.substring(ruta.lastIndexOf('.') + 1).toLowerCase();
            if(extension == 'mp4'){
                result = true;
            }
        }
        return result;
    }    
    return (
        <Box className={classes.root} component="div">            
            <Card className={classes.card}>
                <Box>
                    {
                        !loading?
                        <>
                            {
                                data.archivo && isImage(data.archivo)?
                                <CardMedia
                                    className={classes.media}
                                    component="img"
                                    alt="Imagen principal"
                                    image={`${GLOBAL.assetsBack}/${data.archivo}`}
                                    title="Imagen de portada"
                                />:null
                            }
                            {
                                data.archivo && isVideo(data.archivo)?
                                <ReactPlayer
                                     align="center" 
                                     url={`${GLOBAL.assetsBack}/${data.archivo}`} 
                                     controls={true} 
                                     width="100%"
                                     height='auto'
                                />:null    
                            }
                        </>:
                        (
                            <Skeleton variant="rect" width="100%">
                                <div style={{ paddingTop: '56.25%' }} />
                            </Skeleton>
                        )

                    }
                    <CardContent>
                        <Box textAlign="center">
                            <img src={`${GLOBAL.assetsBack}/img/firma-GAMC.png`} alt="Logo GAMC" style={{maxWidth:'250px'}}/>
                        </Box>
                        <br/>
                        <Typography gutterBottom variant="h5" component="h2">
                            {!loading?(data.titulo?String(data.titulo).toUpperCase():''):<Skeleton/>}
                        </Typography>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    {                                        
                                        !loading?(data.category?data.category.nombre:''):<Skeleton/>
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    {
                                        !loading?(data.fecha?String(moment(data.fecha).format('LL')).toUpperCase():''):<Skeleton/>
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider className={classes.customDivider} />
                        <Box className={classes.customContent}>
                            {
                                !loading?
                                (
                                    convert(data.contenido?data.contenido:'<p></p>')):
                                (                                    
                                    Array.from(new Array(3)).map((item,index) => (                                        
                                        <Skeleton key={index}/>                                        
                                    ))
                                )
                            }
                        </Box>
                        {
                            !loading && data.imagenes?
                            <Box className={classes.customContent}>
                                <Divider className={classes.customDivider} />
                                <Typography className={classes.customSubtitle} gutterBottom variant="h5">Galería de imágenes</Typography>
                                <Carousel centerMode showIndicators={false} centerSlidePercentage={100} emulateTouch infiniteLoop useKeyboardArrows autoPlay showThumbs={false} stopOnHover interval={5000}>
                                    {                                    
                                        String(data.imagenes).split(',').map((item,index) => (
                                            <CardMedia key={index} className={classes.media2} image={`${GLOBAL.assetsBack}/${item}`} alt=""/>
                                        ))
                                        }
                                </Carousel>
                            </Box>:null
                        }
                        {
                            !loading && data.video?
                            <Box className={classes.customContent}>
                                <Divider className={classes.customDivider} />
                                <Typography className={classes.customSubtitle} gutterBottom variant="h5">Video</Typography>                            
                                <ReactPlayer
                                     align="center" 
                                     url={`${GLOBAL.assetsBack}/${data.video}`} 
                                     controls={true} 
                                     width="100%"
                                     height='auto'
                                />
                            </Box>:null
                        }
                    </CardContent>
                </Box>
                <CardActions>
                    
                </CardActions>
            </Card>
        </Box>
    );
}

export default withRouter(Detail);
