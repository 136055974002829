import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { GLOBAL } from '../../global';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight:'100vh',
        backgroundColor:theme.palette.primary.main
    },
    cutomContainer:{
        textAlign:'center'      
    },
    customImage:{
        marginBottom:theme.spacing(1),
        maxWidth:'250px',
        height:'auto'
    },
    customTextColor:{
        color:'white',
        textAlign:'center'
    }    
  }));

function LoadingView(props){
    const {loading=false} = props;
    const classes = useStyles();

    const data2 = useSelector(store => store.datosMain);
    //console.log("DATA2 desde LoadingView store.datosMain=",data2);

    return(
        <>
            <Grid container className={classes.root} spacing={0} direction="column" alignItems="center" justify="center">
                <Grid item xs={12} sm={1} md={1} lg={2} xl={3}></Grid>
                <Grid item xs={12} sm={10} md={10} lg={8} xl={6} style={{textAlign:'center'}}>
                    <img src={`${GLOBAL.assetsBack}/img/secciones/Cocha-somos-progreso.png`} className={classes.customImage}/>
                    {
                        loading?
                        <>
                            <div className={classes.cutomContainer}>
                                <CircularProgress color="secondary"/>
                            </div>                    
                            <Typography className={classes.customTextColor}>Cargando...</Typography>
                        </>:
                        <>                            
                            <Typography className={classes.customTextColor}>Se produjo un error en la obtención de datos, por favor vuelva a cargar la página más tarde.</Typography>
                        </>
                    }
                </Grid>
                <Grid item xs={12} sm={1} md={1} lg={2} xl={3}></Grid>
            </Grid>
        </>
    );
}

export default LoadingView;