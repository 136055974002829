import React,{useEffect} from 'react';
import { useParams } from 'react-router-dom';
import {makeStyles,Grid} from '@material-ui/core';
import Sidebar from '../../components/genericos/sidebar/Sidebar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CustomPortadaComponent from '../../components/genericos/portadas/portada-material-ui/CustomPortadaComponent';
import Posts from './components/Posts';
import Detail from './components/Detail';
import PostsByCategory from './components/PostsByCategory';

const useStyles = makeStyles(theme=>({
    root:{
        backgroundColor:theme.palette.background.default,
        marginBottom:theme.spacing(3),
        //backgroundImage:`url(${GLOBAL.assetsBack}/img/secciones/noticias/alcaldia-de-cochabamba.jpg)`,
        //backgroundSize:'300px'        
    },
    customGrid:{
        flexGrow:1
    }
}));

const sidebarData = {
    categorias :{title:'SECCIONES'},
    populares:{title:'LO MÁS LEÍDO'}
};

function Prensa(props){
    const matches = useMediaQuery('(min-width:600px)');        
    const classes = useStyles();    
    const { id,id_categoria } = useParams();   
    const {datos=null} = props;
    
    useEffect(() => {
       window.scrollTo(0, 0)
    }, [id,id_categoria]);
    
    return(
        <div className={classes.root}>            
            {
                !id?
                <CustomPortadaComponent data={datos}/>:null
            }
            <Grid container className={classes.customGrid} justify="center">
                <Grid item xs={12} sm={11} md={10} lg={9} xl={8}>
                    {
                        matches?
                        <Grid container spacing={2}>
                            <Grid xs={12} sm={3} md={4} lg={4} xl={4} item>
                                <Sidebar data={sidebarData}/>
                            </Grid>
                            <Grid xs={12} sm={9} md={8} lg={8} xl={8} item>
                                {
                                    id?<Detail/>:id_categoria?<PostsByCategory/>:<Posts/>
                                }
                            </Grid>
                        </Grid>:
                        <Grid container spacing={2}>
                            <Grid xs={12} sm={3} md={4} lg={4} xl={4} item>
                                {
                                    id?<Detail/>:id_categoria?<PostsByCategory/>:<Posts/>
                                }
                            </Grid>
                            <Grid xs={12} sm={9} md={8} lg={8} xl={8} item>
                                <Sidebar data={sidebarData}/>
                            </Grid>
                        </Grid>
                    }                    
                </Grid>                
            </Grid>
        </div>
    )
}

export default Prensa;