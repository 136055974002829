import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box,Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Divider from '@material-ui/core/Divider';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    cursor:'pointer',
    height:'100%',
    boxShadow:'none'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 250,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
 customToolBar:{
   paddingLeft:theme.spacing(0),
   paddingRight:theme.spacing(0)
 },
 customToolBar2:{
   padding:theme.spacing(0)
 },
 large: {
    minWidth: '100%',
    height:'auto'
    //minHeight: theme.spacing(25),
    //maxWidth: theme.spacing(15),
    //maxHeight: theme.spacing(15),
  },
  CustomAlign:{
    textAlign:'center',
    margin:theme.spacing(1,1)
  },
  customTitle:{
    color:'#681226',    
  },
  customDivider:{
    backgroundColor:'#681226',
    marginBottom:theme.spacing(3),    
  },
  customIconColor:{
    color:'#681226'
  },
  customTextContainer:{
    padding:theme.spacing(1,1)
  }
}));

function CardLibro(props) {
  const {portada='',titulo='',resena='',autor='',correo='',telefono='',loading=false,libro=null } = props;
  const classes = useStyles();

  const onDonwload = () => {
    console.log("Descargar libro = ",libro);    
    window.open(libro, "_blank");
  }

  return (
    <Card className={classes.root}>
      <Grid container
            alignItems="center"
            justify="center">
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box className={classes.CustomAlign}>
            {
              loading?
                <Skeleton width={100} height={180} />:
                <Avatar variant="square" src={portada} className={classes.large} />
            }
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box className={classes.customTextContainer}>
            <Typography variant="h6" className={classes.customTitle} gutterBottom>
                {loading?<Skeleton width={'100%'}/>:titulo}
            </Typography>
            <Divider className={classes.customDivider}/>
            <Typography>
              {loading?
                Array.from(new Array(4)).map((item,index) => (
                  <Skeleton key={index} />
                )):resena
              }
            </Typography>

            <List>
              <ListItem>
                <ListItemIcon>
                  <PersonIcon className={classes.customIconColor}/>
                </ListItemIcon>
                <ListItemText primary={loading?<Skeleton/>:autor}/>
              </ListItem>
              {
                false?
                <>
                <Divider className={classes.customDivider} style={{marginBottom:0}}/>
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon className={classes.customIconColor}/>
                  </ListItemIcon>
                  <ListItemText primary={loading?<Skeleton/>:correo}/>
                </ListItem>
                <Divider className={classes.customDivider} style={{marginBottom:0}}/>
                <ListItem>
                  <ListItemIcon>
                    <PhoneIcon className={classes.customIconColor}/>
                  </ListItemIcon>
                  <ListItemText primary={loading?<Skeleton/>:telefono}/>
                </ListItem>
                </>:null
              }
            </List>
            <Box mt={3} style={{textAlign:'center'}}>
              <Button size="small" onClick={()=>onDonwload()} variant="contained" color="secondary">Descargar Libro</Button>      
            </Box>
          </Box>
        </Grid>
      </Grid>      
    </Card>
  );
}

CardLibro.propTypes = {
    titulo:PropTypes.string,
    resena:PropTypes.string,
    autor:PropTypes.string,
    correo:PropTypes.string,
    telefono:PropTypes.string,
    loading:PropTypes.bool
};

export default CardLibro;