import React,{forwardRef,useRef,useImperativeHandle} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Button,Grid} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function ModalWithMethodToCall(props,ref) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useImperativeHandle(ref, () =>({
        showDialog(){            
            setOpen(true);
        }
    }));

    return (
        <Grid container>
            <Grid item xs={12}>
                {
                props.btn?
                    <Typography component="span" onClick={handleClickOpen}>
                        {props.btn}
                    </Typography>:''
                }                
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true} maxWidth='md'>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        {props.title}
                    </DialogTitle>
                    <DialogContent dividers>
                        {props.body}
                    </DialogContent>
                    {
                        props.btnclose?
                            <DialogActions>
                                <Typography component="span" onClick={handleClose}>
                                    {props.btnclose}
                                </Typography>  
                            </DialogActions>:null
                    }
                </Dialog>
            </Grid>
        </Grid>
    );
}
export default forwardRef(ModalWithMethodToCall);