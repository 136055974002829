import React from "react";
import Box from "@material-ui/core/Box";
import Areas from "./areas/areas";

const Servicios = () => {
  return (
    <div>
      <div>
        <Box p={1} bgcolor="secondary.main" color="primary.contrastText" textAlign="center" fontSize={24} fontWeight="fontWeightBold">
          SERVICIOS
          </Box>
      </div>
      <Areas />
    </div>
  );
}
export default Servicios;
