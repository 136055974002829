import React, { forwardRef, useImperativeHandle } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Paper } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import useAxios from 'axios-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorRequestComponent from '../genericos/error/ErrorRequestComponent';
import { useForm } from "react-hook-form";
import { GLOBAL } from '../../global';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    customInput: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginBottom:theme.spacing(1)
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    customLogo: {
        maxWidth: '300px'
    },
    customResults: {
        margin: theme.spacing(3, 1)
    },
    customResultsTitle: {
        color: theme.palette.sextary.main
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BuscadorComponent = (props, ref) => {
    const classes = useStyles();
    const { register, handleSubmit,formState,errors, reset, getValues } = useForm({mode:'onChange'});    
    const [open, setOpen] = React.useState(false);    
    const [{ data, loading, error }, refetch] = useAxios({},{manual:true});
    const onSubmit = datos => {        
        //console.log("onSubmit datos=",datos);        
        let config = {
            url:`${GLOBAL.apiURL}/api/getdatosbusqueda`,
            method:'POST',
            data:{
                cad:datos.palabra
            }
        };
        refetch(config);        
    };
    const openPage = () => {        
        setOpen(false);        
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    useImperativeHandle(ref, () => ({
        openModal() {
            setOpen(true);
        }
    }));
    return (
        <div>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Grid container justify="center">
                    <Grid item xs={12} sm={6} md={5} lg={5}>
                        <Box textAlign="center" mt={3}>
                            <img src={`${GLOBAL.assetsBack}/img/cocha-somos-innovacion.png`} alt="Cocha somos innovación" className={classes.customLogo} />
                        </Box>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Paper className={classes.customInput}>                            
                                <InputBase
                                    id="palabra"
                                    name="palabra"                                    
                                    className={classes.input}
                                    placeholder="Buscar"
                                    inputProps={{ 'aria-label': 'buscar' }}
                                    inputRef={
                                        register({
                                            required:true,
                                            minLength:2,
                                            maxLength:50,
                                            pattern:{
                                                value: /^[a-zA-Z0-9\n ñÑáéíóúÁÉÍÓÚ]+$/,
                                            }
                                        })}
                                    error={errors.palabra?true:false}                                    
                                />
                                <IconButton type="submit" className={classes.iconButton} aria-label="search" onClick={()=>handleSubmit(onSubmit)} disabled={!formState.isValid}>
                                    <SearchIcon />
                                </IconButton>                                
                            </Paper>
                            <Typography variant="subtitle2">Solo se permiten letras, números y espacios con longitud máximo de 50 caracteres</Typography>
                        </form>
                    </Grid>
                </Grid>
                <Grid container justify="center">
                    <Grid item xs={12} sm={11} md={8} lg={8}>
                        <Paper className={classes.customResults}>
                            <br /><br />
                            <Typography variant="h4" align="center" className={classes.customResultsTitle}>Resultados de la busqueda</Typography>
                            {
                                !error && data && data.data && data.status?
                                <List>
                                    {
                                        data.data.map((item,index)=>(                                            
                                            <ListItem key={index} button onClick={()=>handleClose()}>
                                                <Link to={item.main==null?`/${item.slug}`:`/unidad/${item.slug}`}>
                                                    <ListItemText primary={item.titulo} secondary={item.descripcion} />
                                                </Link>
                                            </ListItem>
                                        ))
                                    }                                
                                    <Divider />                                
                                </List>:null
                            }
                            {
                                loading?<CircularProgress color="primary" />:null
                            }
                            {
                                !error && data && data.status && data.data.length==0?<ErrorRequestComponent severity="info" text="Sin resultados"/>:null
                            }
                            {
                                !error && data && !data.status?<ErrorRequestComponent severity="error" text={data.message}/>:null
                            }
                        </Paper>
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );
}
export default forwardRef(BuscadorComponent);