import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ReactPlayer from 'react-player';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {                
        backgroundColor: theme.palette.background.paper,
        cursor:'pointer'
    },
    inline: {
        display: 'inline',
    },
    customVideoContainer:{
        backgroundColor:'black'
    },
    customVideoTitle:{
        fontWeight:'bold',
        color:'white',
        padding:theme.spacing(2,1)
    },
    customTitle:{
        fontWeight:'bold',
        padding:theme.spacing(2,1)
    },
    videoSkelenton:{
        width:"100%",
        height:0,
        paddingTop:'54.24%'
    },
    customVideoNotDisponible:{
        color:'white',
        padding:theme.spacing(1)
    },
    customList:{
        maxHeight:'320px',
        overflow:'auto'
    }
}));

const YoutubePlayerComponent = (props) => {
    const { data=null } = props;
    const [video,setVideo] = React.useState(null);
    const classes = useStyles();

    return (
        <Box width="100%">
            <Box className={classes.customVideoContainer}>
                {
                    !video?
                    <>
                        <Box className={classes.videoSkelenton}></Box>
                        <Typography className={classes.customVideoNotDisponible}>Seleccione un video de la lista</Typography>
                    </>:
                    <>
                        <ReactPlayer url={video?video.url:null} controls width="100%"/>
                        <Typography className={classes.customVideoTitle}>{video?video.title:''}</Typography>
                    </>
                }                
            </Box>
            <Divider/>
            <Box>
                <Typography className={classes.customTitle}>LISTA DE VIDEOS</Typography>
                <Divider/>
                <Box className={classes.customList}>
                    <List className={classes.root}>
                        {
                            data && Array.isArray(data)?
                            data.map((item,index)=>(
                                <div key={index}>
                                    <ListItem alignItems="flex-start" onClick={()=>setVideo(item)}>
                                        <ListItemText
                                            primary={item.title}
                                            //secondary={item.description}
                                        />
                                    </ListItem>
                                    <Divider component="li" />
                                </div>
                            )):null
                        }            
                    </List>
                </Box>
            </Box>
        </Box>        
    );
}

export default YoutubePlayerComponent;