import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, makeStyles,Box } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import useAxios from 'axios-hooks';

import ListaComponent from './componentes/ListaComponent';
import DetalleComponent from './componentes/DetalleComponent';
import DestacadosComponent from './componentes/DestacadosComponent';

import ErrorRequestComponent from '../../../components/genericos/error/ErrorRequestComponent';
import { GLOBAL } from '../../../global';

const useStyles = makeStyles(theme => ({
    
}));

const InfoTramitesView = (props) => {
    const classes = useStyles();
    const [{ data, loading, error }, refetch] = useAxios(
        //`${GLOBAL.apiURL}/api/listatramites`
        //'http://localhost:8100/api/listatramites'
        'https://innova.cochabamba.bo/api/listatramites'
      )
    const [itemSelected,setItemSelected] = React.useState({});
    const onHandleItem = (item) => {
        //console.log("itemSelected selected=",item);
        setItemSelected(item);
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Box my={5}>            
            <br/>
            <Grid container justify="center">
                <Grid item xs={12} sm={12} md={11} lg={11} xl={10}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={3}>                            
                            {
                                error?
                                <ErrorRequestComponent 
                                    variante="standard" 
                                    handleClick={() => refetch}
                                />:null
                            }
                            {
                                loading?
                                <ListaComponent
                                    header = {{title:'LISTADO DE TRÁMITES',subtitle:'Seleccione un trámite para ver la información a detalle',searchplaceholder:'Buscar trámite'}}
                                    icono = {<Menu/>}
                                    loading
                                    data = {[]}
                                    //itemSelected = {itemSelected}
                                    //onHandleItem = {onHandleItem}
                                />:null
                            }
                            {
                                !error && !loading && data && data.data?
                                <ListaComponent
                                    header = {{title:'LISTADO DE TRÁMITES',subtitle:'Seleccione un trámite para ver la información a detalle',searchplaceholder:'Buscar trámite'}}
                                    icono = {<Menu/>}
                                    data = {data.data}
                                    itemSelected = {itemSelected}
                                    onHandleItem = {onHandleItem}
                                />:null
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={9}>
                            {
                                itemSelected && itemSelected.nro?
                                <DetalleComponent nombre={itemSelected.nombre} nro={itemSelected.nro}/>:null
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default withRouter(InfoTramitesView);