import React,{useEffect,useState} from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { blue,lightGreen,purple,amber } from '@material-ui/core/colors';
import {GetApp,Info, CodeSharp} from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';
import { Box,Grid,Button,Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import useAxios from 'axios-hooks';
import {Request} from 'react-axios';

//import withWidth from '@material-ui/core/withWidth';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import convert from 'htmr';

//import SimplePortada from '../../../components/genericos/portadas/portada-material-ui/SimplePortada';
import ErrorRequestComponent from '../../../components/genericos/error/ErrorRequestComponent';
import ModalComponentViewer from '../../../components/genericos/modal/ModalComponentViewer';
import CampoBuscar from '../../../components/genericos/CampoBuscar';
import {GLOBAL} from '../../../global';

const { useRef } = React;

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.quaternary.main,
    color: theme.palette.common.white,
  },
  //body: {
    //fontSize: 14,
  //},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ColorButton = withStyles((theme) => ({
  root: {
    //color: theme.palette.getContrastText(blue[500]),
    //color: theme.palette.primary.main,
    //backgroundColor: blue[500],
    // '&:hover': {
    //   backgroundColor: blue[700],
    // },
  },
}))(Button);

const ColorpurpleButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  },
}))(Button);

const ColorgreenButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
    '&:hover': {
      backgroundColor: lightGreen[700],
    },
  },
}))(Button);

const ColororangeButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(amber[500]),
    backgroundColor: amber[500],
    '&:hover': {
      backgroundColor: amber[700],
    },
  },
}))(Button);


const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    customTitleMargin:{
        margin:theme.spacing(5,1),
    },
    customButtonMargin:{
        margin:theme.spacing(2),
    },
    table: {
        width: '100%',
    },
    customMargin:{
      marginBottom:theme.spacing(1.5),
    },
    container: {
      maxHeight: 640,      
    },
  }));

const dataPortada = {
    background:GLOBAL.assetsURL+'/images/proveedores/proveedores_portada.jpg',
    title:{line1:'CONTRATACIÓN DE BIENES Y SERVICIOS REQUERIDOS POR EL GAMC',line2:''},
    description:{
        config:{
            html:false,
        },
        style:{
            textAlign:'justify',
            color:'white',
            backgroundColor:'rgba(0, 0, 0, 0)',
        },
        content:[            
        ],            
    },
    phone:{//******AQUI CAMBIO
        enabled:false,
        config:{
            color:'white'
        },
        data:{
            address:'',
            phone:'',
            schedule:'Mañana: 08:00 - 12:00 Tarde: 14:30 - 18:30',
        }
    },
    button:{
        enabled:false,
        label:'Continuar'
    }
}

function htmlDecode(input){  
  var e = document.createElement('div');
  e.innerHTML = input;  
  return e.childNodes[0].nodeValue;
}

const InfoModalBody = (props) => {
  const classes = useStyles();  
  const onHandleClick2Download = (path) => {
        let extension = get_url_extension(path);
        //console.log("path=",path);
        //console.log("extension=",extension);
        
        let atag = document.createElement('a');
        atag.setAttribute('href',path);
        atag.setAttribute('download','ARCHIVO.'+extension);
        atag.click();
        atag = null;
        
    }
  return(
    <>    
      <Box>
        {convert(`
          <table style="width:100%;border-collapse: collapse;">
          <tr style="background-color:rgb(79,185,168);">
            <td colspan="3" style="border: 1px solid #dddddd;text-align: center;padding: 16px 8px;color:#ffffff;">DETALLE</td>
          </tr>
          <tr>
            <td style="border: 1px solid #dddddd;text-align: center;padding: 8px;"><strong>CÓDIGO:</strong><br/>${props.data.codigo}</td>
            <td style="border: 1px solid #dddddd;text-align: center;padding: 8px;"><strong>CANTIDAD:</strong><br/>${props.data.cantidad}</td>
            <td style="border: 1px solid #dddddd;text-align: center;padding: 8px;"><strong>UNIDAD MEDIDA:</strong><br/>${props.data.unidad_medida}</td>
          </tr>
          <tr>
            <td style="border: 1px solid #dddddd;text-align: center;padding: 8px;"><strong>PROCEDENCIA:</strong><br/>${props.data.procedencia}</td>
            <td style="border: 1px solid #dddddd;text-align: center;padding: 8px;"><strong>TIEMPO DE ENTREGA:</strong><br/>${props.data.tiempo_entrega}</td>
            <td style="border: 1px solid #dddddd;text-align: center;padding: 8px;"><strong>FECHA LÍMITE DE PRESENTACIÓN:</strong><br/>${props.data.fecha_limite}</td>
          </tr>  
        </table>
        `)}
      </Box>
      
      <Request url="https://proveedoresgamc.cochabamba.bo/servicio-caracteristicas" method="get" params={{ id: props.data.id_item }}>
        {(error, response, isLoading, makeRequest, axios) => {
          if (error) {
            return (<ErrorRequestComponent variante="standard" handleClick={() => makeRequest({ params: { reload: true } })}/>)
          }
          else if (isLoading) {
            return (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="cutomized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">CARACTERÍSTICAS REQUERIDAS</StyledTableCell>
                      <StyledTableCell align="center">DETALLE CARACTERÍSTICA</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      Array.from(new Array(4)).map((row, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="center"><Skeleton /></StyledTableCell>
                          <StyledTableCell align="center"><Skeleton /></StyledTableCell>
                        </StyledTableRow>))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            )
          }
          else if (response !== null) {
            return (
              <>
                {
                  response.data && response.data.length>0?
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="cutomized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">CARACTERÍSTICAS REQUERIDAS</StyledTableCell>
                          <StyledTableCell align="center">DETALLE CARACTERÍSTICA</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          response.data.map((row) => (
                            <StyledTableRow key={row.id_caracteristica}>
                              <StyledTableCell>{row.descripcion}</StyledTableCell>
                              <StyledTableCell>{row.caracteristica_detalle && row.caracteristica_detalle.toString().length>1?convert(htmlDecode(row.caracteristica_detalle)):null}</StyledTableCell>
                            </StyledTableRow>))
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>:<Alert severity="info">No hay características</Alert>
                }
              </>
            )
          }
          return (<div>Default message before request is made.</div>)
        }}
      </Request>
      <br/>
      {
        props.data.file_documento?
        <Box align="center">
          <Button variant="contained" color="secondary" onClick={() => onHandleClick2Download(UrlGlobal.activo+props.data.file_documento)}>DESCARGAR ARCHIVO</Button>
        </Box>:''
      }
    </>
    )
}


const InfoModal2table = (props) => {
  const classes = useStyles();

  const onHandleClick2Download = (path) => {
        let extension = get_url_extension(path);
        let atag = document.createElement('a');        
        atag.setAttribute('href',path);
        atag.setAttribute('download','ARCHIVO.'+extension);
        atag.click();
        atag = null;
        
    } 
    return (
      <>
        <Box>
          {convert(`
            <table style="width:100%;border-collapse: collapse;">
            <tr style="background-color:rgb(79,185,168);">
              <td colspan="3" style="border: 1px solid #dddddd;text-align: center;padding: 16px 8px;color:#ffffff;">DETALLE</td>
            </tr>
            <tr>
              <td style="border: 1px solid #dddddd;text-align: center;padding: 8px;"><strong>CÓDIGO:</strong><br/>${props.data.codigo}</td>
              <td style="border: 1px solid #dddddd;text-align: center;padding: 8px;"><strong>CANTIDAD:</strong><br/>${props.data.cantidad}</td>
              <td style="border: 1px solid #dddddd;text-align: center;padding: 8px;"><strong>UNIDAD MEDIDA:</strong><br/>${props.data.unidad_medida}</td>
            </tr>
            <tr>
              <td style="border: 1px solid #dddddd;text-align: center;padding: 8px;"><strong>PROCEDENCIA:</strong><br/>${props.data.procedencia}</td>
              <td style="border: 1px solid #dddddd;text-align: center;padding: 8px;"><strong>TIEMPO DE ENTREGA:</strong><br/>${props.data.tiempo_entrega}</td>
              <td style="border: 1px solid #dddddd;text-align: center;padding: 8px;"><strong>FECHA LÍMITE DE PRESENTACIÓN:</strong><br/>${props.data.fecha_limite}</td>
            </tr>  
          </table>
          `)}
        </Box>
        <Request url="https://proveedoresgamc.cochabamba.bo/servicio-caracteristicas" method="get" params={{ id: props.data.id_item }}>
          {(error, response, isLoading, makeRequest, axios) => {
            if (error) {
              return (<ErrorRequestComponent variante="standard" handleClick={() => makeRequest({ params: { reload: true } })}/>)
            }
            else if (isLoading) {
              return (
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="cutomized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">CARACTERÍSTICAS REQUERIDAS</StyledTableCell>
                        <StyledTableCell align="center">DETALLE CARACTERÍSTICA</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        Array.from(new Array(4)).map((row, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell align="center"><Skeleton /></StyledTableCell>
                            <StyledTableCell align="center"><Skeleton /></StyledTableCell>
                          </StyledTableRow>))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              )
            }
            else if (response !== null) {
              return (
                <>
                  {
                  response.data && response.data.length>0?  
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="cutomized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">CARACTERÍSTICAS REQUERIDAS</StyledTableCell>
                          <StyledTableCell align="center">DETALLE CARACTERÍSTICA</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          response.data.map((row) => (
                            <StyledTableRow key={row.id_caracteristica}>
                              <StyledTableCell>{row.descripcion}</StyledTableCell>
                              <StyledTableCell>{row.caracteristica_detalle && row.caracteristica_detalle.toString().length>1?convert(htmlDecode(row.caracteristica_detalle)):null}</StyledTableCell>
                            </StyledTableRow>))
                        }                      
                      </TableBody>
                    </Table>
                  </TableContainer>:<Alert severity="info">No hay caracterÍsticas</Alert>
                  }
                </>
              )
            }
            return (<div>Default message before request is made.</div>)
          }}
        </Request>
        <br/>
        {
        props.data.archivo?
            <Box align="center">
              <Button variant="contained" color="secondary" onClick={() => onHandleClick2Download(UrlGlobal.activo+props.data.archivo)}>DESCARGAR ARCHIVO</Button>
            </Box> : ''
        }
      </>
    );    
}

var ban = '';
var UrlGlobal = {activo:'',inactivo:''};
const get_url_extension = ( url ) => {
  return url.split(/[#?]/)[0].split('.').pop().trim();
}

function ProveedoresComponent(props){  
    const [data2component,setData2component] = React.useState(null);    
    const [{ data, loading, error }, refetch] = useAxios('https://proveedoresgamc.cochabamba.bo/servicio-requerimientos');
    //const [{ data, loading, error }, refetch] = useAxios('http://sandbox.cochabamba.bo/proveedor/servicio-requerimientos');
    const classes = useStyles();
    const childRef = useRef();
    const [txtSearch,setTxtSearch] = React.useState('');
    const [txtSearch2,setTxtSearch2] = React.useState('');
    const handleChange = (event) => {
        setTxtSearch(event.target.value);
    }
    const handleClickToClear = () => {
        setTxtSearch('');
    };
    const computedSearchField = (items) => {
        try {
            return items.filter(element => String(element.nombre_item).toUpperCase().indexOf(String(txtSearch).toUpperCase()) != -1 || String(element.codigo).toUpperCase().indexOf(String(txtSearch).toUpperCase()) != -1 || String(element.unidad_medida).toUpperCase().indexOf(String(txtSearch).toUpperCase()) != -1 || String(element.procedencia).toUpperCase().indexOf(String(txtSearch).toUpperCase()) != -1 || String(element.fecha_limite).toUpperCase().indexOf(String(txtSearch).toUpperCase()) != -1);
        } catch (error) {
            return [];            
        }        
    }
  const handleChange2 = (event) => {
      setTxtSearch2(event.target.value);
  }
  const handleClickToClear2 = () => {
      setTxtSearch2('');
  };
  const computedSearchField2 = (items) => {
      try {
          return items.filter(element => String(element.nombre_item).toUpperCase().indexOf(String(txtSearch2).toUpperCase()) != -1 || String(element.codigo).toUpperCase().indexOf(String(txtSearch2).toUpperCase()) != -1 || String(element.unidad_medida).toUpperCase().indexOf(String(txtSearch2).toUpperCase()) != -1 || String(element.procedencia).toUpperCase().indexOf(String(txtSearch2).toUpperCase()) != -1 || String(element.fecha_limite).toUpperCase().indexOf(String(txtSearch2).toUpperCase()) != -1);
      } catch (error) {
          return [];            
      }        
  }

    const onHandleClick = () => {      
        let atag = document.createElement('a');
        let url = `${GLOBAL.assetsBack}/front/documents/proveedores/MANUAL_SISTEMA_DE_PROVEEDORES.pdf`;        
        atag.setAttribute('href',url);
        atag.setAttribute('download','MANUAL SISTEMA DE PROVEEDORES.pdf');
        atag.click();
        atag = null;
    }
    const handleShow = (datos) => {
      ban = 'table';
      childRef.current.openModal();
      setData2component(datos);
    }
    const handleShow2 = (datos) => {
      ban = 'regular';
      childRef.current.openModal();
      setData2component(datos);
    }
    const onHandleClickDownloadfile = (path,nombre=null) => {
      let extension = get_url_extension(path);      
      let atag = document.createElement('a');
      atag.setAttribute('href',path);
      atag.setAttribute('download',nombre?nombre+'.'+extension:'archivo.'+extension);
      atag.click();
      atag = null;
      
    }

    useEffect(() => {
       window.scrollTo(0, 0);
       
    }, []);
    const setGlobalDatos = () => {
      UrlGlobal.activo = data?data.url_activo:'';
      UrlGlobal.inactivo = data?data.url_inactivo:'';
      //console.log(UrlGlobal);
    }
    return(
        <div>
            {setGlobalDatos()}            
            <ModalComponentViewer ref={childRef} title={data2component?data2component.nombre_item:''} body={ban=='table'?<InfoModal2table data={data2component}/>:<InfoModalBody data={data2component}/>}/>
            <Grid container justify="center">                
                <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                    <Typography>{props.width}</Typography>
                    <Typography className={classes.customTitleMargin} align="center" variant="h4">LISTADO DE ÍTEMS REQUERIDOS</Typography>                    
                    {
                      data?
                      <Grid container>
                        <Grid item xs={12} sm={6} md={8} lg={8} xl={8}></Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                          <CampoBuscar
                            placeholder = 'Buscar'
                            txtSearch = {txtSearch}
                            handleChange = {handleChange}
                            handleClickToClear = {handleClickToClear}/>
                        </Grid>                      
                      </Grid>:''
                    }
                    <TableContainer component={Paper} className={classes.container}>
                      <Table className={classes.table} aria-label="cutomized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>ÍTEM</StyledTableCell>
                            <StyledTableCell align="center">CÓDIGO</StyledTableCell>
                            <StyledTableCell align="center">UNIDAD MEDIDA</StyledTableCell>
                            <StyledTableCell align="center">CANTIDAD</StyledTableCell>
                            <StyledTableCell align="center">PROCEDENCIA</StyledTableCell>
                            <StyledTableCell align="center">FECHA LÍMITE DE PRESENTACIÓN</StyledTableCell>
                            <StyledTableCell align="center">OPCIONES</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {                            
                            data?computedSearchField(data.activo).map((row,index) => (
                              <StyledTableRow key={row.codigo+'-'+index}>
                                <StyledTableCell component="th" scope="row">{row.nombre_item}</StyledTableCell>
                                <StyledTableCell align="center">{row.codigo}</StyledTableCell>
                                <StyledTableCell align="center">{row.unidad_medida}</StyledTableCell>
                                <StyledTableCell align="center">{row.cantidad}</StyledTableCell>
                                <StyledTableCell align="center">{row.procedencia}</StyledTableCell>
                                <StyledTableCell align="center">{row.fecha_limite}</StyledTableCell>
                                <StyledTableCell align="center">                                 
                                    <ColorButton variant="contained" color='primary' size="small" onClick={() => handleShow(row)}>VER DETALLE</ColorButton>
                                  </StyledTableCell>
                              </StyledTableRow>)):                            
                            loading?Array.from(new Array(4)).map((row,index) => (
                              <StyledTableRow key={index}>
                                <StyledTableCell component="th" scope="row"><Skeleton/></StyledTableCell>
                                <StyledTableCell align="center"><Skeleton/></StyledTableCell>
                                <StyledTableCell align="center"><Skeleton/></StyledTableCell>
                                <StyledTableCell align="center"><Skeleton/></StyledTableCell>
                                <StyledTableCell align="center"><Skeleton/></StyledTableCell>
                                <StyledTableCell align="center"><Skeleton/></StyledTableCell>
                                <StyledTableCell align="center">
                                  <Skeleton variant="circle" width={40} height={40} />
                                </StyledTableCell>
                              </StyledTableRow>)):''
                          }
                        </TableBody>
                      </Table>
                      {
                        error?
                        <ErrorRequestComponent variante="standard" handleClick={() => refetch}/>:
                        data && data.length==0?<Alert severity="info">No hay ítems activos</Alert>:''
                      }
                    </TableContainer>
                    <br/>
                    <Typography className={classes.customTitleMargin} align="center" variant="h4">CONVOCATORIAS CERRADAS</Typography>                    
                    {
                      data && data.inactivo?
                      <Grid container>
                        <Grid item xs={12} sm={6} md={8} lg={8} xl={8}></Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                          <CampoBuscar
                            placeholder = 'Buscar'
                            txtSearch = {txtSearch2}
                            handleChange = {handleChange2}
                            handleClickToClear = {handleClickToClear2}/>
                        </Grid>
                      </Grid>:''
                    }
                    <TableContainer component={Paper} className={classes.container}>
                      <Table className={classes.table} aria-label="cutomized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>ÍTEM</StyledTableCell>
                            <StyledTableCell align="center">CÓDIGO</StyledTableCell>
                            <StyledTableCell align="center">UNIDAD MEDIDA</StyledTableCell>
                            <StyledTableCell align="center">CANTIDAD</StyledTableCell>
                            <StyledTableCell align="center">PROCEDENCIA</StyledTableCell>
                            <StyledTableCell align="center">FECHA LÍMITE DE PRESENTACIÓN</StyledTableCell>
                            <StyledTableCell align="center">OPCIONES</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            data?computedSearchField2(data.inactivo).map((row,index) => (
                              <StyledTableRow key={row.codigo+'-'+index}>
                                <StyledTableCell component="th" scope="row">{row.nombre_item}</StyledTableCell>
                                <StyledTableCell align="center">{row.codigo}</StyledTableCell>
                                <StyledTableCell align="center">{row.unidad_medida}</StyledTableCell>
                                <StyledTableCell align="center">{row.cantidad}</StyledTableCell>
                                <StyledTableCell align="center">{row.procedencia}</StyledTableCell>
                                <StyledTableCell align="center">{row.fecha_limite}</StyledTableCell>
                                <StyledTableCell align="center">                                 
                                    <Tooltip title="Ver detalle"><ColorButton variant="contained" color="primary" size="small" onClick={() => handleShow2(row)}><Info/></ColorButton></Tooltip>
                                    {row.calificacion && row.calificacion.toString().length>4?<Tooltip title="Informe de Calificación"><ColorpurpleButton variant="contained" size="small" onClick={() => onHandleClickDownloadfile(`${data.url_inactivo}adjudicaciones/informe/${row.calificacion}`,'Informe de Calificación')}><GetApp/></ColorpurpleButton></Tooltip>:null}
                                    {row.adjudicacion && row.adjudicacion.toString().length>4?<Tooltip title="Nota o Resolución de Adjudicación"><ColororangeButton variant="contained" size="small" onClick={() => onHandleClickDownloadfile(`${data.url_inactivo}adjudicaciones/resolucion/${row.adjudicacion}`,'Nota o Resolución de Adjudicación')}><GetApp/></ColororangeButton></Tooltip>:null}
                                    {row.orden && row.orden.toString().length>4?<Tooltip title="Contrato, u Orden de Compra u Orden de Servicio"><ColorgreenButton variant="contained" size='small' onClick={() => onHandleClickDownloadfile(`${data.url_inactivo}adjudicaciones/contrato/${row.orden}`,'Contrato')}><GetApp/></ColorgreenButton></Tooltip>:null}
                                  </StyledTableCell>
                              </StyledTableRow>)):
                            loading?Array.from(new Array(4)).map((row,index) => (
                              <StyledTableRow key={index+'b'}>
                                <StyledTableCell component="th" scope="row"><Skeleton/></StyledTableCell>
                                <StyledTableCell align="center"><Skeleton/></StyledTableCell>
                                <StyledTableCell align="center"><Skeleton/></StyledTableCell>
                                <StyledTableCell align="center"><Skeleton/></StyledTableCell>
                                <StyledTableCell align="center"><Skeleton/></StyledTableCell>
                                <StyledTableCell align="center"><Skeleton/></StyledTableCell>
                                <StyledTableCell align="center">
                                  <Skeleton variant="circle" width={40} height={40} />
                                </StyledTableCell>
                              </StyledTableRow>)):''
                          }
                        </TableBody>
                      </Table>
                      {                        
                        !error && data && data.inactivos && data.inactivos.length==0?<Alert severity="info">No hay ítems activos</Alert>:''
                      }
                    </TableContainer>
                    



                    <Box align="center">
                        <Button className={classes.customButtonMargin} variant="contained" color="secondary" size="large" onClick={ () =>  window.open('https://proveedoresgamc.cochabamba.bo/', "_blank") }>REALIZAR PROPUESTA</Button>
                        <Button className={classes.customButtonMargin} variant="contained" color="secondary" size="large" onClick={ () => onHandleClick()}> DESCARGAR MANUAL </Button>
                    </Box>
                </Grid>                
            </Grid>
        </div>
    );
}
export default withRouter(ProveedoresComponent);