import React,{useEffect} from 'react';
import { useParams,useRouteMatch } from 'react-router-dom';

import {makeStyles} from '@material-ui/core';
import useAxios from 'axios-hooks';
//import getComponent from '../../components/getComponent';
import { useDispatch ,useSelector} from 'react-redux';
import { obtenerDatosMenuAction } from '../../redux/dataDucks';

import {GLOBAL} from '../../global';

//LOADING
import LoadingView from '../loading/LoadingView';
//MENU
import NavBarComponent from '../../components/genericos/navbar/NavbarComponent';
import ChildNavBarComponent from '../../components/genericos/navbar/ChildNavBarComponent';

//SLIDER
import CarouselTextWithImg from '../../components/genericos/carousel/CarouselTextWithImg';
import MyCarousel from '../../components/genericos/carousel/Carousel';
import UltimasNoticiasComponent from '../../views/home/secciones/UltimasNoticiasComponent';
import TitleWithCards from '../../components/text-conteiner/TitleWithCards2';
import CiudadInteligente from '../../components/text-conteiner/background-image-with-text/BackgroundImgAndText';
import ListaImagenComponent from '../../components/genericos/carousel/ListaImagenComponent';
import TitleWithCardsAndImgs from '../../components/text-conteiner/TitleWithCardsAndImgs';
import CarouselImgWithTitleAndDescription from '../../components/genericos/carousel/CarouselImgWithTitleAndDescription';

//BUTTONS
import ButtonWithBackgroundAndTextComponent from '../../components/small-components/ButtonWithBackgroundAndTextComponent';

//FOOTER
import Footer from '../../components/genericos/footer/Footer';
import ChildFooter from '../../components/genericos/footer/ChildFooter';

//PORTADAS
import HeaderComponent from '../../components/genericos/header/HeaderComponent';
import CustomPortadaComponent from '../../components/genericos/portadas/portada-material-ui/CustomPortadaComponent';
//import FullImgComponent from '../../components/genericos/portadas/portada-material-ui/FullImgComponent';
//END PORTADA

import ContactosComponent from '../contactos/ContactosComponent';
import Prensa from '../prensa/Prensa';
import InformeGestionComponent from '../otras-secciones/informe-gestion/InformeGestionComponent';
import NuestroAlcaldeComponent from '../gobierno-municipal/NuestroAlcaldeComponent';
import InfoMisionVisionComponent from '../gobierno-municipal/InfoMisionVisionComponent';
import SecretariasComponent from '../gobierno-municipal/SecretariasComponent';
import OrganigramaComponent from '../gobierno-municipal/OrganigramaComponent';

//CHILD BODY
import ChildMisionVision from '../../components/small-components/ChildMisionVisionComponent';
import ChildBiografiaComponent from '../../components/small-components/ChildBiografiaComponent';
import ChildContactoComponent from '../../components/small-components/ChildContactoComponent';
import CarouselAnuncios from '../../components/genericos/carousel/CarouselAnuncios';
import SugerenciaComponent from '../../components/small-components/SugereneciaComponent';
import DesplegableComponent from '../../components/small-components/DesplegableComponent';
import HtmlComponent from '../../components/small-components/HtmlComponent';
import IframeComponent from '../../components/small-components/IframeComponent';
import IframeWidthTitleAndDescription from '../../components/small-components/IframeWidthTitleAndDescription';
import SeguimientoTramitesCatastroComponent from '../../components/small-components/SeguimientoTramitesCatastroComponent';

//FUNCIONALIDAD ESPECIFICA
import ProveedoresComponent from '../otras-secciones/proveedores/ProveedoresComponent';
import AuditoriaInternaComponent from '../otras-secciones/auditoria-interna/AuditoriaInternaComponent';
import GacetaComponent from '../otras-secciones/gaceta/GacetaComponent';
import ConsultasEnLineaView from '../otras-secciones/consultas-en-linea/ConsultasEnLineaView';
import DefensoriaNinesAdolescenciaComponent from '../otras-secciones/DefensoriaNinesAdolescencia/DefensoriaNinesAdolescencia';
import SlimComponent from '../otras-secciones/Slim/SlimView';
import PoliticaPrivacidadView from '../../components/small-components/PoliticaPrivacidadView';
import SaludComponent from '../salud/SaludComponent';
import Cultura from '../cultura/Cultura';
import LiteraturaComponent from '../cultura/views/LiteraturaComponent';
import ConcursosMunicipalesComponent from '../cultura/views/ConcursosMunicipalesComponent';
import EmprendedurismoComponent2 from '../otras-secciones/emprendedurismo/EmprendedurismoComponent2';
import InfoTramitesView from '../otras-secciones/info-tramites/infotramitesView';
import ZoonosisComponent from '../../components/especificos/zoonosis/ZoonosisComponent';
import EmprendedurismoDetalleComponent from '../../components/especificos/EmprendedurismoDetalleComponent';

//CONSULTAS EN LÍNEA
import ConsultaInfraccionView from '../consulta-linea/ConsultaInfraccionView';
import ConsultaPatentesMunicipalesView from '../consulta-linea/ConsultaPatentesMunicipalesView';
import ConsultaTramitesMunicipalesView from '../consulta-linea/ConsultaTramitesMunicipalesView';

//PÁGINA COMPLETA
import MiPlanoComponent from '../otras-secciones/mi-plano/MiPlanoComponent';

//MODAL
import ModalComunicadoComponent from '../../components/genericos/modal/ModalComunicadoComponent';
import AsistenteComponent from '../../components/small-components/AsistenteComponent';

const useStyles = makeStyles(theme=>({
    root:{
        backgroundColor:theme.palette.background.default
    },
    customGrid:{
        flexGrow:1
    },
    customFloatingButton:{
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    }
}));

const MainView = () => {    
    const classes = useStyles();
    const dispatch = useDispatch();
    const { main,child,encrypted_id } = useParams();        

    let match = useRouteMatch();    
    
    const config = {
        url:null,
        method:'POST',
        data:{
            slug:null
        }
    };
    if(match.url == '/'){config.data.slug = 'inicio';config.url = GLOBAL.apiURL+'/api/getdata'}
    if(!child && main && !encrypted_id){config.data.slug = main;config.url = GLOBAL.apiURL+'/api/getdata'}
    if(child && !main && !encrypted_id){config.data.slug = child;config.url = GLOBAL.apiURL+'/api/getdatachild'}
    if(child && !main && encrypted_id){config.data.slug = child;config.url = GLOBAL.apiURL+'/api/getdatachild'}
    if(match.url != '/' && child && main && !encrypted_id){config.data.slug = null;}
    if(match.url != '/' && !child && !main && !encrypted_id){config.data.slug = null;}

    const [{ data, loading, error }, refetch] = useAxios(config,{ manual: false });
    //const currentSite = useSelector(store => store.datosMain.currentSite);
    //const onrequest = useSelector(store => store.datosMain.onrequest);        
    //if(child && !main && currentSite!=child && !onrequest){
    //dispatch(obtenerDatosMenuAction(child));

    const dataToJson = (data) => {
        let auxdata = [];
        if(data && String(data).length>2){
            auxdata = JSON.parse(data);
        }
        return auxdata;
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(obtenerDatosMenuAction({currentSite:data?data.sitio:data,slug:child}));
    }, [data]);
    
    if(error) return<h3>Error</h3>
    if(data && !data.status) return<h3>Not found</h3>
    //if(loading) return <LoadingView/>
    //return <ZoonosisComponent/>
    return(
        <>             
            {
               data && data.data && data.status?
               data.data.map((item,index)=>(
                   <React.Fragment key={`component${index}`}>
                        {
                            item.componente=='NavbarComponent'?<NavBarComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='MyCarousel'?<MyCarousel/>:null
                        }
                        {
                            item.componente=='UltimasNoticiasComponent'?<UltimasNoticiasComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='TitleWithCards'?<TitleWithCards data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='CiudadInteligente'?<CiudadInteligente/>:null
                        }
                        {
                            item.componente=='Footer'?<Footer/>:null
                        }
                        {
                            item.componente=='HeaderComponent'?<HeaderComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='ContactosComponent'?<ContactosComponent/>:null
                        }
                        {
                            item.componente=='Prensa'?<Prensa datos={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='InformeGestionComponent'?<InformeGestionComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='NuestroAlcaldeComponent'?<NuestroAlcaldeComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='InfoMisionVisionComponent'?<InfoMisionVisionComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='SecretariasComponent'?<SecretariasComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='OrganigramaComponent'?<OrganigramaComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='CustomPortadaComponent'?<CustomPortadaComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='ChildNavBarComponent'?<ChildNavBarComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='ChildFooter'?<ChildFooter data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='ChildMisionVision'?<ChildMisionVision data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='ChildBiografiaComponent'?<ChildBiografiaComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='ChildContactoComponent'?<ChildContactoComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='ProveedoresComponent'?<ProveedoresComponent/>:null
                        }
                        {
                            item.componente=='ModalComunicadoComponent'?<ModalComunicadoComponent show data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='MiPlanoComponent'?<MiPlanoComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='AuditoriaInternaComponent'?<AuditoriaInternaComponent datos={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='GacetaComponent'?<GacetaComponent datos={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='ConsultasEnLineaView'?<ConsultasEnLineaView data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='DefensoriaNinesAdolescenciaComponent'?<DefensoriaNinesAdolescenciaComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='SlimComponent'?<SlimComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='CarouselAnuncios'?<CarouselAnuncios data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='ConsultaInfraccionView'?<ConsultaInfraccionView data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='SugerenciaComponent'?<SugerenciaComponent datos={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='ButtonWithBackgroundAndTextComponent'?<ButtonWithBackgroundAndTextComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='ListaImagenComponent'?<ListaImagenComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='ConsultaPatentesMunicipalesView'?<ConsultaPatentesMunicipalesView data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='ConsultaTramitesMunicipalesView'?<ConsultaTramitesMunicipalesView data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='PoliticaPrivacidadView'?<PoliticaPrivacidadView data={item.data}/>:null
                        }
                        {
                            item.componente=='SaludComponent'?<SaludComponent data={item.data}/>:null
                        }
                        {
                            item.componente=='Cultura'?<Cultura data={item.data}/>:null
                        }
                        {
                            item.componente=='LiteraturaComponent'?<LiteraturaComponent data={item.data}/>:null
                        }
                        {
                            item.componente=='ConcursosMunicipalesComponent'?<ConcursosMunicipalesComponent data={item.data}/>:null
                        }
                        {
                            item.componente=='DesplegableComponent'?<DesplegableComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='EmprendedurismoComponent2'?<EmprendedurismoComponent2 datos={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='InfoTramitesView'?<InfoTramitesView datos={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='HtmlComponent'?<HtmlComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='ZoonosisComponent'?<ZoonosisComponent datos={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='IframeComponent'?<IframeComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='TitleWithCardsAndImgs'?<TitleWithCardsAndImgs data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='CarouselTextWithImg'?<CarouselTextWithImg data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='IframeWidthTitleAndDescription'?<IframeWidthTitleAndDescription data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='CarouselImgWithTitleAndDescription'?<CarouselImgWithTitleAndDescription data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='SeguimientoTramitesCatastroComponent'?<SeguimientoTramitesCatastroComponent data={dataToJson(item.data)}/>:null
                        }
                        {
                            item.componente=='EmprendedurismoDetalleComponent'?<EmprendedurismoDetalleComponent data={dataToJson(item.data)}/>:null
                        }
                    </React.Fragment>
               )):null
            }
            <AsistenteComponent/>
        </>
    )
}
export default MainView;