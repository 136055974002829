import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { GLOBAL } from '../../../global';
import Helper from '../../../Helper';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
});

const CardComponent = (props) => {
  const { data={title:'',imgBackground:null,btnLabel:null,disabled:false,link:null}} = props;
  const classes = useStyles();
  const handleClick = () => {
    //console.log("handleChange");
    if(data.link){
        Helper.checkLink(props,data.link);
    }
    //props.onHandleClick(props.data.id);
  }
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={`${GLOBAL.assetsURL}${data.imgBackground}`}          
        />
        <CardContent>
          {
            false?
            <Typography gutterBottom variant="h5" component="h2">
              {data.title}
            </Typography>:null
          }
          {
            true?
            <Typography variant="body2" color="textSecondary" component="p">
              {data.title}
            </Typography>:null
          }
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" variant="contained" onClick={()=>handleClick()}>
          {data.btnLabel}
        </Button>        
      </CardActions>
    </Card>
  );
}
export default withRouter(CardComponent);