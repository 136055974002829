import React from 'react';
import { withRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { GLOBAL } from '../../global';
import { Typography, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CustomButtonComponent from '../genericos/buttons/CustomButtonComponent';
import Helper from '../../Helper';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        //padding:theme.spacing(10,1),
        position: 'relative',
        padding: theme.spacing(0, 0),
        backgroundColor: theme.palette.primary.main
    },
    customTextContainer: {
        width: '100%',
        color: 'white',
        padding:theme.spacing(2,1),
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'Translate(-50%,-50%)'
    },
    customTextContainer2: {
        padding:theme.spacing(2,2)
    },
}));

const ButtonWithBackgroundAndTextComponent = (props) => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:600px)');
    const { data={
        title:{
            data:"Gaceta Municipal",
            style:null
        },
        description:{
            data:"En esta sección usted podrá encontrar Decretos Municipales, Decretos Ediles y Decretos Ejecutivos.",
            style:null
        },
        img:"/img/secciones/home/gaceta-municipal.jpg",
        onlyimg:false,
        action:{
            type:"interno",
            url:"/gaceta-municipal"
        }
    }} = props;

    return (
        <Box className={classes.root}>            
            {
                data.onlyimg?
                <>
                    <img src={`${GLOBAL.assetsBack}${data.img}`} alt={data.title?data.title.data:''} style={{ width: '100%',cursor:'pointer' }} onClick={()=>Helper.checkLink(props,data.action)}/>
                </>:
                <>
                    <img src={`${GLOBAL.assetsBack}${data.img}`} alt={data.title?data.title.data:''} style={{ width: '100%' }} />
                    <Box className={classes.customTextContainer}>
                        <Grid container justify="center">
                            <Grid item xs={12} sm={10} md={8}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} sm={12} md={4}>
                                        
                                            <Typography variant={matches?"h3":"h4"}>{data.title?data.title.data:''}</Typography>                                
                                            {
                                                matches?
                                                <Typography variant={matches?"subtitle1":"caption"}>{data.description?data.description.data:''}</Typography>:null
                                            }
                                        
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8}>
                                        <CustomButtonComponent text='Ver más' action={data.action}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            }
        </Box>
    );
}
export default withRouter(ButtonWithBackgroundAndTextComponent);