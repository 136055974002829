import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
import Toolbar from '@material-ui/core/Toolbar';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Divider from '@material-ui/core/Divider';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import BusinessIcon from '@material-ui/icons/Business';
import CategoryIcon from '@material-ui/icons/Category';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    cursor:'pointer',
    height:'100%',
    //boxShadow:'none'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 250,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
 customToolBar:{
   paddingLeft:theme.spacing(0),
   paddingRight:theme.spacing(0)
 },
 customToolBar2:{
   padding:theme.spacing(0)
 },
 large: {
    minWidth: theme.spacing(25),
    height:'auto'
    //minHeight: theme.spacing(25),
    //maxWidth: theme.spacing(15),
    //maxHeight: theme.spacing(15),
  },
  CustomAlign:{
    textAlign:'center',
    margin:theme.spacing(1,1)
  },
  customTitle:{
    color:'#681226',    
  },
  customDivider:{
    backgroundColor:'#681226',
    marginBottom:theme.spacing(3),    
  },
  customIconColor:{
    color:'#681226'
  }
}));

function CardEmprendedurismo(props) {
  const {loading=false} = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Toolbar variant="dense" className={classes.customToolBar}>
        <Box className={classes.CustomAlign}>
          {
            loading?
              <Skeleton width={100} height={180} />:
              <Avatar variant="square" src={props.data.imagen} className={classes.large} />
          }          
        </Box>
      </Toolbar>
          <div className={classes.details}>
              <CardContent className={classes.content}>
                  <Typography variant="h6" className={classes.customTitle} gutterBottom>
                      {loading ? <Skeleton width={'100%'} /> : props.data.nombre_productor}
                  </Typography>
                  <Divider className={classes.customDivider} />
                  <List>
                      <ListItem>
                          <ListItemIcon>
                              <PersonIcon className={classes.customIconColor} />
                          </ListItemIcon>
                          <ListItemText primary={loading ? <Skeleton /> : props.data.representante} />
                      </ListItem>
                      <ListItem>
                          <ListItemIcon>
                              <CategoryIcon className={classes.customIconColor} />
                          </ListItemIcon>
                          <ListItemText primary={loading ? <Skeleton /> : props.data.descripcion} />
                      </ListItem>
                      <Divider className={classes.customDivider} style={{ marginBottom: 0 }} />
                      <ListItem>
                          <ListItemIcon>
                              <BusinessIcon className={classes.customIconColor} />
                          </ListItemIcon>
                          <ListItemText primary={loading ? <Skeleton /> : props.data.direccion} />
                      </ListItem>
                      <Divider className={classes.customDivider} style={{ marginBottom: 0 }} />
                      <ListItem>
                          <ListItemIcon>
                              <PhoneIcon className={classes.customIconColor} />
                          </ListItemIcon>
                          <ListItemText primary={loading ? <Skeleton /> : props.data.contacto} />
                      </ListItem>
                      <ListItem>
                          <ListItemIcon>
                            <EmailIcon className={classes.customIconColor} />
                          </ListItemIcon>
                          <ListItemText primary={loading ? <Skeleton /> : props.data.correo_electronico} />
                      </ListItem>                      
                  </List>
              </CardContent>
          </div>      
    </Card>
  );
}
/*
CardEmprendedurismo.propTypes = {
    titulo:PropTypes.string,
    resena:PropTypes.string,
    autor:PropTypes.string,
    correo:PropTypes.string,
    telefono:PropTypes.string,
    loading:PropTypes.bool
};
*/
export default CardEmprendedurismo;