import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Button,Grid} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Iframe from 'react-iframe';
import { GLOBAL } from '../../../global';

const { forwardRef, useImperativeHandle } = React;

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const ModalPdfViewerComponent = (props, ref) => {    
    const [open, setOpen] = React.useState(false);
    const [data,setData] = React.useState({});

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    useImperativeHandle(ref, () => ({
        openModal(data) {            
            setData(data);
            setOpen(true);
        }    
      }));
    return (        
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title" 
            open={open}
            fullWidth={true}
            maxWidth={'lg'}
            >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {data.title}
            </DialogTitle>
            <DialogContent dividers>
                <Iframe url={data.archivo}
                    width="100%"
                    height="648"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"/>
            </DialogContent>
            {
                props.btnclose?
                    <DialogActions>                        
                        <Button color="secondary" onClick={handleClose}>{props.btnclose}</Button>
                    </DialogActions>:null
            }
        </Dialog>           
    );
}
export default forwardRef(ModalPdfViewerComponent);