import React from 'react';
import { Grid } from '@material-ui/core';
import Iframe from 'react-iframe';
import { makeStyles,Box,Typography } from '@material-ui/core';
import { Parallax } from 'react-parallax';
import { GLOBAL } from '../../global';

const useStyles = props => makeStyles( theme => ({    
    customMargin:{
        paddingTop:theme.spacing(props.containerStyle && (props.containerStyle.paddingTop || props.containerStyle.paddingTop===0)?props.containerStyle.paddingTop:5),
        paddingBottom:theme.spacing(props.containerStyle && (props.containerStyle.paddingBottom || props.containerStyle.paddingBottom===0)?props.containerStyle.paddingBottom:5),
        paddingLeft:theme.spacing(1),
        paddingRight:theme.spacing(1)
    },
    customIframeStyle:{
        border:props.iframeStyle && props.iframeStyle.border?props.iframeStyle.border:'0px'
    },
    customTitle:{
        textAlign:'center',
        color:theme.palette.primary.main
    },
    customSubTitle:{
        color:theme.palette.primary.main,
        //paddingBottom:theme.spacing(3),
        textAlign:'center',
    }
}));

const MyComponent = (props) => {    
    const {data={}} = props;
    const classes = useStyles(data)();    
    return(
        <Grid container className={classes.customMargin} justify="center">
            <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                <Box mb={3}>
                    <Typography className={classes.customTitle} variant="h3" style={data.textStyle.title}>{data.textData.title?data.textData.title:''}</Typography>
                    <Typography className={classes.customSubTitle} variant="h6" style={data.textStyle.subtitle}>{data.textData.subtitle?data.textData.subtitle:''}</Typography>
                </Box>
                <Iframe url={data.url?data.url:''}
                        width={data.iframeprop.width}
                        height={data.iframeprop.height}
                        id="myId"
                        className={classes.customIframeStyle}
                        display="initial"
                        position="relative">                        
                </Iframe>
            </Grid>
        </Grid>
    );
}

const IframeComponent = (props) => {
    const {data={}} = props;    
    return data.parallax.enabled?
    <Parallax blur={data.parallax.blur?data.parallax.blur:0} bgImage={`${GLOBAL.assetsBack}${data.parallax.bgimg}`} bgImageAlt="Background image" strength={data.parallax.strength?data.parallax.strength:0}>
        <MyComponent data={data}/>
    </Parallax>:<div style={data.mainContainerStyle}><MyComponent data={data}/></div>
}

export default IframeComponent;