import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import FondAwesome from 'react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CopyrightIcon from '@material-ui/icons/Copyright';
import Divider from '@material-ui/core/Divider';
import {GLOBAL} from '../../../global';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      backgroundColor:'#482778',
      padding:theme.spacing(5,1),
      //marginTop:theme.spacing(5)
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    customPadding:{                 
        //backgroundImage:'linear-gradient(to right, rgb(88,190,208) , rgb(244,69,86))',
        backgroundColor:'#482778',
        color:'#FFFFFF'        
    },
    customBgColor:{        
        padding:theme.spacing(3,1),        
        color:'#ffffff',        
    },
    customFooterColor:{    
        //backgroundImage:'linear-gradient(to right, rgb(88,190,208) , rgb(244,69,86))'
        backgroundColor:'#482778'
    },
    customInfoPadding:{
        padding:theme.spacing(1,1),       
    },
    customBorder:{
        border:'2px solid white',
        backgroundColor:'rgba(0,0,0,0)',
        margin:theme.spacing(1,1)
    },
    customDivider:{
        border:'0.1px solid white',
        marginBottom:theme.spacing(3)
    },
    customLogoContainer:{
        textAlign:'center',
        paddingBottom:theme.spacing(3)
    },
    customLogo:{
        width:'100%',
        maxWidth:'350px'
    },
    customIcon:{
        maxWidth:'25px',
        height:'auto'
    },
    customQrImg:{
        maxWidth:'100px'
    }
  }));

  const socialIcons = {
    facebook:{
        color:'#ffffff',
        margin:'20px',
        fontSize:'30px'
    },
    twitter:{
        color:'#ffffff',
        margin:'20px',
        fontSize:'30px'
    },
    instagram:{
        color:'#ffffff',
        margin:'20px',
        fontSize:'30px'
    },
    redicons:{
        color:'#ffffff',
        fontSize:'20px'
    },
    textAlign:{
        textAlign:'center'
    }
  };

function Footer(){
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <Grid container justify='center'>
                <Grid item xs={12} sm={12} md={11} lg={10} xl={9}>
                    <Grid container justify='center'>
                        <Grid item xs={8} sm={7} md={6} lg={5} className={classes.customLogoContainer}>
                            <img src={`${GLOBAL.assetsBack}/img/cocha-somos-innovacion-blanco.png`} className={classes.customLogo}/>
                        </Grid>
                    </Grid>
                    <Divider variant='middle' className={classes.customDivider}/>
                    <Grid container className={classes.customBgColor}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.customInfoPadding}>
                            <Typography align={'left'}><FondAwesome style={socialIcons.redicons} name="home"/> Gobierno Autónomo Municipal de Cochabamba</Typography>
                            <Typography align={'left'}><FondAwesome style={socialIcons.redicons} name="map-marker"/> Oficina central: Plaza de Armas 14 de Septiembre acera oeste</Typography>
                            <Typography align={'left'}><FondAwesome style={socialIcons.redicons} name="phone"/> Telf.: 4258030 - Central piloto: 151</Typography>
                            <Typography align={'left'}><FondAwesome style={socialIcons.redicons} name="whatsapp"/> Whatsapp:</Typography>
                            <Box>
                                <img src={`${GLOBAL.assetsBack}/img/secciones/qr-whatsapp.jpeg`} className={classes.customQrImg}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography align='center'>Síguenos en:</Typography>
                            <Typography style={socialIcons.textAlign}>
                                <Fab className={classes.customBorder} aria-label="Siguenos en Facebook" href={'https://www.facebook.com/gamcochabamba'}>
                                    <FondAwesome style={socialIcons.facebook} name="facebook-f"/>
                                </Fab> 
                                <Fab className={classes.customBorder} aria-label="Siguenos en Twitter" href={'https://twitter.com/gam_cochabamba'}>
                                    <FondAwesome style={socialIcons.twitter} name="twitter"/>
                                </Fab> 
                                <Fab className={classes.customBorder} aria-label="Siguenos en Instagram" href={'https://www.instagram.com/cochabambaciudad'}>
                                    <FondAwesome style={socialIcons.instagram} name="instagram"/>
                                </Fab>
                                <Fab className={classes.customBorder} aria-label="Siguenos en Youtube" href={'https://www.youtube.com/channel/UC6mQC2naSRZuNfuYvqKX2ow'}>
                                    <FondAwesome style={socialIcons.instagram} name="youtube"/>
                                </Fab>
                                <Fab className={classes.customBorder} aria-label="Siguenos en TikTok" href={'https://vm.tiktok.com/ZMd7uhHLb/'}>
                                    <img src={`${GLOBAL.assetsBack}/img/icons/tiktok.png`} alt="TikTok" className={classes.customIcon}/>
                                </Fab>
                                <Fab className={classes.customBorder} aria-label="Siguenos en LinkedIn" href={'https://www.linkedin.com/company/gamc'}>
                                    <img src={`${GLOBAL.assetsBack}/img/icons/linkedin.png`} alt="LinkedIn" className={classes.customIcon}/>
                                </Fab>
                            </Typography>
                        </Grid>                        
                    </Grid>
                    <Grid container className={classes.customBgColor}>
                        <Grid item xs={12} className={classes.customPadding}>
                            <Divider variant='middle' className={classes.customDivider}/>
                            <Typography display="block" component='div' style={{textAlign:'center',display:'flex',alignItems:'center',flexWrap:'wrap',justifyContent:'center'}}><CopyrightIcon/> Derechos Reservados - Gobierno Autónomo Municipal de Cochabamba {new Date().getFullYear()}</Typography>
                        </Grid>
                    </Grid>        
                </Grid>
            </Grid>
        </div>
    );
}
export default Footer;