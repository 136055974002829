import React,{useRef,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Typography,Grid,InputLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Skeleton from '@material-ui/lab/Skeleton';
import Select from '@material-ui/core/Select';
import useAxios from 'axios-hooks';
import Alert from '@material-ui/lab/Alert';

import CardLibro from '../components/CardLibro';
import ErrorRequestComponent from '../../../components/genericos/error/ErrorRequestComponent';
import CustomPortadaComponent from '../../../components/genericos/portadas/portada-material-ui/CustomPortadaComponent';
import CampoBuscar from '../../../components/genericos/CampoBuscar';
import {GLOBAL} from '../../../global';

const useStyles = makeStyles(theme => ({
    root:{
        flexGrow:1
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    title:{
        textAlign:'center',
        margin:theme.spacing(3,1),
    },
    customPadding:{
        paddingleft:theme.spacing(2),
        paddingRight:theme.spacing(2),
    },    
    customMargin:{
      marginBottom:theme.spacing(1.5),
    },
    container: {
      maxHeight: 640,      
    },
    customHeigth:{
        minHeight: '90vh',
    }
}));

const dataPortada = {
    title:'LITERATURA',
    subtitle:'SECRETARÍA DE CULTURA Y TURISMO',
    description:[
        'Las palabras transitan por el tiempo. A través de ellas experimentamos nuestro mundo, soñamos, sentimos, vivimos. Por ellos queremos crear un nexo entre lectores y escritores. En esta sección encontraremos un compendio de títulos y autores de obras locales.'
    ],
    background:GLOBAL.assetsURL+'/images/cultura/libros-portada2.jpg',
    logo:{
        title:'',
        img:null,
    }    
}

const LiteraturaComponent = () => {
    const [{ data, loading, error }, refetch] = useAxios('https://cultura.cochabamba.bo/utils/request/getLibros');
    const [categoria, setCategoria] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [txtSearch,setTxtSearch] = React.useState('');    
    
    const classes = useStyles();

    const handleChangeCategory = (event) => {
        setCategoria(event.target.value);
        console.log(event.target.value);
    };    
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleChange = (event) => {
        setTxtSearch(event.target.value);
    }
    const handleClickToClear = () => {
        setTxtSearch('');
    };

    const computedSearchField = (items) => {
        try {
            if(categoria && categoria.toString().trim().length>0){
                return items.filter(element => String(element.titulo).toUpperCase().indexOf(String(txtSearch).toUpperCase()) != -1 && element.id_categoria==categoria);
            }else{
                return items.filter(element => String(element.titulo).toUpperCase().indexOf(String(txtSearch).toUpperCase()) != -1);
            }
        } catch (error) {
            return [];            
        }        
    }    
    useEffect(() => {
       window.scrollTo(0, 0);
    }, []);
    return(
        <React.Fragment>            
            <Typography variant="h4" component="h2" gutterBottom className={classes.title}>BIBLIOTECA VIRTUAL</Typography>
            <Grid container className={classes.customPadding} style={{paddingBottom:'20px',minHeight:'768px'}}>
                <Grid item xs={12} sm={1} md={1} lg={2} xl={2}></Grid>
                <Grid item xs={12} sm={10} md={10} lg={8} xl={8}>
                    {
                    data && data.libros ? 
                        <Grid container className={classes.customPadding}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.customPadding}>
                                {
                                    data?
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-controlled-open-select-label">Filtrar por género</InputLabel>
                                            <Select
                                                labelId="demo-controlled-open-select-label"
                                                id="demo-controlled-open-select"
                                                open={open}
                                                onClose={handleClose}
                                                onOpen={handleOpen}
                                                value={categoria}
                                                onChange={handleChangeCategory}
                                            >
                                                <MenuItem value="">
                                                    <em>Ninguno</em>
                                                </MenuItem>
                                                {
                                                    data && data.categorias?data.categorias.map((item,index)=>(
                                                        <MenuItem key={item.id_categoria} value={item.id_categoria}>{item.categoria_libro}</MenuItem>
                                                    )):null
                                                }
                                            </Select>
                                        </FormControl>:null
                                }
                                {
                                    loading?
                                    <Skeleton />:null
                                }
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.customPadding}>
                                <CampoBuscar
                                    placeholder = 'Buscar'
                                    txtSearch = {txtSearch}
                                    handleChange = {handleChange}
                                    handleClickToClear = {handleClickToClear}/>
                            </Grid>
                        </Grid>:null
                    }
                    <Grid container className={classes.customPadding} spacing={2}>
                        
                            {
                                data && data.libros ? computedSearchField(data.libros).map((row, index) => (
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6} key={index}>
                                        <CardLibro
                                            portada={row.portada}
                                            titulo={row.titulo}
                                            resena={row.resena}
                                            autor={row.autor}
                                            correo={row.correo}
                                            telefono={row.telefono}
                                            libro={row.enlace_libro}
                                            />
                                    </Grid>)):null
                            }
                            {
                                data && !data.libros ?
                                <Grid item xs={12}>
                                    <Alert severity="info">No hay libros disponibles en este momento</Alert>
                                </Grid>:null                                
                            }
                            {
                                loading ?
                                Array.from(new Array(4)).map((item,index) => (
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6} key={index}>
                                        <CardLibro loading/>
                                    </Grid>
                                )):null
                                
                            }
                            {
                                error ?
                                    <ErrorRequestComponent variante="standard" handleClick={() => refetch} /> :
                                    data && data.length == 0 ? <Alert severity="info">No hay libros registrados</Alert> : ''
                            }
                        
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={1} md={1} lg={2} xl={2}></Grid>
            </Grid>
        </React.Fragment>               
    );
}
export default LiteraturaComponent;
