import React from 'react';
import { Grid,Typography,Divider,Box,IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RoomIcon from '@material-ui/icons/Room';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import PhoneIcon from '@material-ui/icons/Phone';
import convert from 'htmr';


const useStyles = makeStyles(theme => ({
    root:{
        flexGrow:1,
    },
    /*
    customBg:{
        margin:"0px",
        backgroundImage:`URL("./images/portada-ci.jpg")`,
        width:'100%',
        //height:`calc(100vh - ${variable}px)`,
        height:'90vh',
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    },
    customCaption:{
        position: 'absolute',
        left: 0,
        top: '50%',
        width: '100%',
        textAlign: 'center',
        color: '#000',
    },
    */
    mainContainer:{
        display: 'flex',
        //backgroundImage:`URL("./images/portada-ci.jpg")`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: '100%',
        minHeight: '90vh',
        margin: '0px',
        alignItems:'center',
        justifyContent:'center',
    },    
    customTitle:{
        textAlign:'center',
        color:'white',
        backgroundColor:'rgba(212,32,35,0.5)',
        padding:theme.spacing(2,2),        
    },
    customDescription:{
        textAlign:'justify',
        color:'white',
        fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
        padding:theme.spacing(2,2),
    },
    customTextToHtml:{
        //color:'white',
        fontSize: '0.875rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
        fontWeight: 400,
        lineHeight: 1.43,
        letterSpacing: '0.01071em',
        padding:theme.spacing(1,1),
    },
    animationItem:{
        animation:`$myEffect 0.8s infinite linear`
    },
    "@keyframes myEffect": {
        "0%": {
          top: 0,
          
        },
        "50%": {
          top: 10,          
        },
        "100%": {
          top: 0,          
        }
    },
}));

function SimplePortada(props){

    const classes = useStyles();

    return(
        <Box className={classes.mainContainer} style={{backgroundImage:`URL(${props.data.background})`}}>
            <Box>
                <Grid container>
                    <Grid item xs={12} sm={1} md={2} lg={3} xl={3}></Grid>
                    <Grid item xs={12} sm={10} md={8} lg={6} xl={6}>
                        <Typography variant="h3" className={classes.customTitle}>{props.data.title.line1}<br/>{props.data.title.line2}</Typography>
                        <br/>
                        {
                            props.data.description.config.html?
                            <Box className={classes.customTextToHtml} style={props.data.description.style}>
                                {convert(props.data.description.content)}
                            </Box>:
                            <Box style={props.data.description.style}>
                                {
                                    <Typography variant="h5" className={classes.customDescription}>
                                        {
                                            props.data.description.content.map((item,index) => (
                                                <Typography key={index}>{item}<br/></Typography>
                                            ))
                                        }
                                    </Typography>
                                }                                
                                {
                                    props.data.phone.enabled?
                                    <List>
                                        {
                                            props.data.phone.enabled && props.data.phone.data.address?                                            
                                            <ListItem>
                                                <ListItemIcon>
                                                    <RoomIcon style={props.data.phone.config}/>
                                                </ListItemIcon>
                                                <ListItemText primary={props.data.phone.data.address}/>
                                            </ListItem>:''
                                        }
                                        {
                                            props.data.phone.enabled && props.data.phone.data.schedule?                                            
                                            <ListItem>
                                            <ListItemIcon>
                                                <QueryBuilderIcon style={props.data.phone.config}/>
                                            </ListItemIcon>
                                            <ListItemText primary={props.data.phone.data.schedule}/>
                                            </ListItem>:''                                            
                                        }
                                        {
                                            props.data.phone.enabled && props.data.phone.data.phone?                                            
                                            <ListItem>
                                            <ListItemIcon>
                                                <PhoneIcon style={props.data.phone.config}/>
                                            </ListItemIcon>
                                            <ListItemText primary={props.data.phone.data.phone}/>
                                            </ListItem>:''                                            
                                        }
                                    </List>:''
                                }
                            </Box>                            
                        }
                        {    
                            props.data.button.enabled?
                            <Box style={{textAlign:'center'}}>                                
                                <IconButton color="primary" size='medium' className={classes.animationItem}>
                                   <ExpandMoreIcon fontSize="default" style={{color:"white"}}/>
                                </IconButton>
                            </Box>:''
                        }
                    </Grid>
                    <Grid item xs={12} sm={1} md={2} lg={3} xl={3}></Grid>
                </Grid>                    
            </Box>
        </Box>
    );
}

export default SimplePortada;