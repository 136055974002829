import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { GLOBAL } from '../../global';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight:'100vh',
        backgroundColor:'#5db5ce'
    },
    customHeight:{
        minHeight:'768px',         
    },
    customImage:{        
        marginBottom:theme.spacing(5)
    },
    customTextColor:{
        color:'white',
    }    
  }));

function Notfound(){
    const classes = useStyles();
    return(
        <>
            <Grid container className={classes.root} spacing={0} direction="column" alignItems="center" justify="center">
                <Grid item xs={12} sm={1} md={1} lg={2} xl={3}></Grid>
                <Grid item xs={12} sm={10} md={10} lg={8} xl={6} style={{textAlign:'center'}}>
                    <img src={`${GLOBAL.assetsURL}/logo-cocha.png`} className={classes.customImage}/>
                    <Alert severity="error">
                        <Typography variant="h3" align="center">OOPS!</Typography>
                        <Typography variant="h4" align="center">PAGINA NO ENCONTRADA</Typography>
                    </Alert>
                </Grid>
                <Grid item xs={12} sm={1} md={1} lg={2} xl={3}></Grid>
            </Grid>
        </>
    );
}

export default Notfound;