import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {GLOBAL} from '../../../global';

const useStyles = makeStyles({
  root: {
    //maxWidth: 345,
    height:'100%'
  },
  media: {
    height: 0,
    paddingTop: '133%', // 16:9    
    width:"100%"
  },
  customAlign:{
    justifyContent:'center',

  }
});

const CardInformeComponent = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={() => {props.onHandleClick(props.data)}}>
        <CardMedia
          className={classes.media}
          image={`${GLOBAL.assetsBack}${props.data.url}`}
          title={String(props.data.subtitulo1).toUpperCase()}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {String(props.data.titulo).toUpperCase()}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {String(props.data.descripcion).toUpperCase()}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.customAlign}>
        <Button variant="outlined" color="secondary" size="small" onClick={() => {props.onHandleClick(props.data)}}>
          Abrir
        </Button>        
      </CardActions>
    </Card>
  );
}

export default CardInformeComponent;