import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import "./style.css";
const lineaGratuita = "800140206";
const listDefensorias = [
  {
    id: 1,
    institution: "JEFATURA D.N.A. ",
    address: "PLAZA COLÓN, ACERA ESTE",
    phone: "479-3050"
  },
  {
    id: 2,
    institution: "D.N.A. SUB ALCALDÍA ADELA ZAMUDIO",
    address: "CALLE BAPTISTA ENTRE ECUADOR Y COLOMBIA",
  },
  {
    id: 3,
    institution: "D.N.A. SUB ALCAL",
    address: "AV. MELCHOR PÉREZ 2 CUADRAS AL SUD DE LA AV. CIRCUNVALACIÓN",
    phone: "447-6773"
  },
  {
    id: 4,
    institution: "D.N.A. VALLE HERMOSO",
    distrito: "D-7",
    address: "AV. HUANCHACA LADO CENTRO DE SALUD V. VENEZUELA",
    phone: "479-3759"
  },
  {
    id: 5,
    institution: "D.N.A. VALLE HERMOSO",
    distrito: "D-14",
    address: "AV. HUMBERTO ASÍN FRENTE AL HOSPITAL S. PAGADOR",
    phone: "401-0881"
  },
  {
    id: 6,
    institution: "D.N.A. MOLLE",
    address: "AV. BEIJING CASI AV. BLANCO GALINDO",
    phone: "440-8372"
  },
  {
    id: 7,
    institution: "D.N.A. ITOCTA",
    address: "AV. PETROLERA KM 41/2 FRENTE AL POLITÉCNICO MILITAR",
    phone: "423-4395"
  },
  {
    id: 8,
    institution: "D.N.A. ALEJO CALATAYUD",
    address: "AV. PETROLERA KM2 1/2",
    phone: "422- 5890"
  },
  {
    id: 9,
    institution: "D.N.A. VILLA MÉXICO",
    address: "LADO MERCADO VILLA MÉXICO",
    phone: "423-5243"
  },
];
const Encuentranos = () => {
  return (
    <div className="encuentranos">
      <Box color="secondary.main" textAlign="center" fontWeight="fontWeightBold">
        <h2>¿DÓNDE NOS ENCUENTRAS?</h2>
      </Box>
      <Box p={1} fontSize={14}>
        <ul>
          {listDefensorias.map((info) => (
            <li key={info.id}>
              <Typography>
                <strong>{info.institution}</strong> {info.distrito} (
                  {info.address}) {info.phone ? <b>TELF. {info.phone}</b> : ''}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
      <Box textAlign="center">
        <h4>LÍNEA GRATUITA : {lineaGratuita}</h4>
      </Box>
    </div>
  );
}
export default Encuentranos;
