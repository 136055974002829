import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import Alert from '@material-ui/lab/Alert';
import { useParams } from 'react-router-dom';
import useAxios from 'axios-hooks';
import {GLOBAL} from '../../../global';
import Post from './Post';

var auxglobal = null;

const PostsByCategory = (props,ref) => {    
    var {id_categoria} = useParams();
    const [page, setPage] = React.useState(1);
    const [url,setUrl] = React.useState(GLOBAL.apiURL+"/api/posts/por-categoria/"+id_categoria);
    const [{ data, loading, error }, refetch] = useAxios(url,{useCache:false});        
    
    if(auxglobal == null){        
        auxglobal = id_categoria;
    }else{
        if(auxglobal != id_categoria){
            auxglobal = id_categoria;
            setUrl(GLOBAL.apiURL+"/api/posts/por-categoria/"+id_categoria);            
        }
    }

    //if (loading) return <p>Loading...</p>
    const handleChangePage = (event, value) => {
        setPage(value);
        setUrl(GLOBAL.apiURL+"/api/posts/por-categoria/"+id_categoria+"?page="+value);
    };   

    if (error) return <p>Error en la obtención de noticias!</p>
    
    return (
        <>
            {/* <button onClick={refetch}>refetch</button> */}
            {
                !loading?
                (                    
                    data.data.map((item,index) => (
                        <Post key={item.slug} data={item}/>
                    ))
                ):
                (
                    Array.from(new Array(4)).map((item,index) => (
                        <Post key={index} data={{}} loading/>
                    ))
                )
            }            
            {
                !loading && data.data.length>0?
                <Pagination count={data?data.last_page:1} page={page} onChange={handleChangePage} color="secondary"/>:<Alert severity="info" style={{marginTop:"50px"}}>Sin noticias</Alert>
            }
        </>
    )
}

export default PostsByCategory;