import React,{ useImperativeHandle,forwardRef,useState } from 'react';
import useAxios from 'axios-hooks';
import Pagination from '@material-ui/lab/Pagination';
import Alert from '@material-ui/lab/Alert';
import {GLOBAL} from '../../../global';
import Post from './Post';

function Posts(props,ref){
    const [url,setUrl] = useState(GLOBAL.apiURL+'/api/posts');
    const [page, setPage] = React.useState(1);
    const [{ data, loading, error }, refetch] = useAxios(url,{useCache:false});

    const handleChangePage = (event, value) => {
        setPage(value);
        setUrl(GLOBAL.apiURL+'/api/posts?page='+value);
    };

    useImperativeHandle(ref,() => ({
        getPostsByCategory(data){
            //console.log(data);
            if(data){
                //console.log("1");
                setUrl('/api/noticia-post/porcategoria/'+data.slug);
            }else{
                //console.log("2");
                setUrl('/api/posts');
            }
            
        }
    }));
    
    //if (loading) return <p>Loading...</p>
    if (error) return <p>Error en la obtención de noticias.</p>
    
    return (
        <>
            {/* <button onClick={refetch}>refetch</button> */}
            {
                !loading?
                (
                    
                    data.data.map((item,index) => (
                        <Post key={item.slug} data={item}/>
                    ))                                   
                ):
                (
                    Array.from(new Array(4)).map((item,index) => (
                        <Post key={index} data={{}} loading/>
                    ))
                )
            }
            {
                !loading && data.data.length>0?
                <Pagination count={data?data.last_page:1} page={page} onChange={handleChangePage} color="secondary"/>:<Alert severity="info" style={{marginTop:"50px"}}>Sin noticias</Alert>
            }
                    
        </>
    )
}

export default forwardRef(Posts);