const Helper = {
    checkLink:function(props,obj){
        //console.log("goToSomeThing ind = ",obj);
        switch(obj.type){
            case 'interno':
                props.history.push(obj.url);
                break;
            case 'externo':
                window.open(obj.url, "_blank");
                break;
            case 'scroll':                
                let objEl = document.getElementById("myiframecontainer");
                if(objEl){objEl.scrollIntoView({behavior:'smooth'});}
                break;
            case 'dropdown':
                //props.history.push(obj.url);
                break;
            case 'descarga':
                let atag = document.createElement('a');
                if(obj.data){
                    atag.setAttribute('href',obj.url+obj.data);
                    atag.setAttribute('download',obj.data);
                }else{
                    atag.setAttribute('href',obj.url);
                    atag.setAttribute('download','archivo');
                }
                //atag.setAttribute('href',obj.url+obj.data);
                //atag.setAttribute('download',obj.data);
                atag.click();
                atag = null;
                break;
            case 'test':
                console.log("El boton esta funcionando bien");
                break;
            case 'alert':
                if(obj.data){
                    alert(obj.data);
                }else{
                    console.log("Falta data");
                }
                break;
        }
    },
    getDomainUrl:() => {
        const { protocol, hostname, port } = window.location;
        return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    }
}

export default Helper;