import React,{useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid,Button,TextField,Box,Typography } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReCAPTCHA from "react-google-recaptcha";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import { useForm } from "react-hook-form";
import Axios from 'axios';
import {GLOBAL} from '../../global';

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding:theme.spacing(5,1),
        backgroundColor:theme.palette.tertiary.main
    },
    customMainContainer:{
        margin:theme.spacing(10,1)
    },
    customDataStyle: {
        width: '100%',    
        backgroundColor: theme.palette.background.paper,
    },
    customWith:{
        width:'100%',
        margin:theme.spacing(2,0),
    },
    customPadding:{
        padding:theme.spacing(2,2)
    },
    customPaper:{
        backgroundColor:theme.palette.common.white,
        padding:theme.spacing(5,1)
    },
    whatsapp:{
        color:'#f50057',
        //margin:'10px'
    },
    customTitle:{
        textAlign:'center',
        //color:theme.palette.primary.main
        color:'white'

    },
    customSubTitle:{
        color:'#ffffff',
        paddingBottom:theme.spacing(5),
        textAlign:'center',            
    },
    customAcordionTitle:{
        color:theme.palette.sextary.main
    },
    customInfo:{
        color:theme.palette.tertiary.main
    }
  }));

const SugerenciaComponent = (props) => {    
    const [loading,setLoading] = React.useState(false);
    const [data,setData] = React.useState(null);
    const [error,setError] = React.useState(false);
    const [sexo, setSexo] = React.useState('null');    
    const [human,setHuman] = React.useState(null);
    const { register, handleSubmit, formState, errors, reset, getValues } = useForm({mode:'onChange'});
    const captcha = useRef(null);
    
    const { datos={
        title:"Participación ciudadana",
        description:"¿Tienes una idea o proyecto para mejorar nuestra ciudad? - ¡Compartela con nosotros!",
        alertSuccessMsj:"Los datos se enviaron correctamente.",
        alertErrorMsj:"Se produjo un error en el envio de datos, vuelva a intentarlo más tarde",
        alertMsjSended:"Mensaje enviado correctamente"
    } } = props;

    const [open, setOpen] = React.useState(false);
    const [archivo, setArchivo] = React.useState(null);
    const [expand, setExpand] = React.useState(false);
    const classes = useStyles();    

    const onChange = (value) => {        
        if(value){            
            setHuman(value);
        }else{            
            setHuman(null);
        }
    }
    const onSubmit = data => {
        //let data2Send = {sexo:sexo,...data};
        //data2Send.archivo = data.archivo[0];
        //console.log("INFO A ENVIAR data2Send = ",data2Send);
        let formData = new FormData();
        formData.append('nombre',data.nombre);
        formData.append('sexo',sexo);
        formData.append('fec_nacimiento',data.fec_nacimiento);
        formData.append('telefono',data.telefono);
        formData.append('email',data.email);
        formData.append('token',human);
        if(data.archivo.length>0){
            formData.append('archivo',data.archivo[0]);
        }
        formData.append('descripcion',data.descripcion);
        setLoading(true);        
        // refetch({
        //     data:formData
        // });

        Axios.request({
            method: "post",
            url: `${GLOBAL.apiURL}/api/enviarideaproyecto`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then(function (response) {
            //console.log(response);
            setError(null);
            setData(response.data);
        })
        .catch(function (error) {            
            setData(null);
            setError(true);
        })
        .then(function () {
            setLoading(false);
            setSexo('null');
            setOpen(true);
            reset();
            setHuman(null);
            captcha.current.reset();
        });
    };
    const handleChange = (event) => {
        setSexo(event.target.value);
    };
    const handleClose = (event, reason) => {
        //console.log("reason=",reason);
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const handleInputFileChange = event => {        
        let obj = getValues('archivo');
        //console.log("OBJ=",obj);
        if(obj && obj.length>0){
            if(obj[0].size > 5242880){//muy pesado
                setArchivo(1);
            }else if(obj[0].type != "application/pdf"){//otro archivo
                setArchivo(2);
            }else{
                setArchivo(0);
            }
        }else{
            setArchivo(0);
        }
    }
    //if(data || error){alert("Se produjo un error en el envio de los datos")}
    //if(data && data.data && data.data.status){alert(datos.alertMsjSended)}
    return(
        <Grid container justify='center' className={classes.root}>
            <Grid item xs={12} sm={11} md={10} lg={8} xl={8}>
                <Typography className={classes.customTitle} variant="h3">{datos.title}</Typography>
                <Typography className={classes.customSubTitle} variant="h6">{datos.description}</Typography>
                <ExpansionPanel expanded={expand}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={()=> setExpand(!expand)}
                    >
                        <Typography className={classes.customAcordionTitle} align="center">{expand?"Ocultar formulario":"Mostrar formulario"}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container>
                            <Grid item xs={12} className={classes.customPadding}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <TextField                                        
                                        id="nombre"
                                        name="nombre"
                                        label="Nombre (*)"
                                        //helperText="Requerido"
                                        className={classes.customWith}
                                        //value={input.nombre}
                                        //onChange={handleInputChange}
                                        inputRef={register({
                                            required:true,
                                            minLength:3,
                                            maxLength:50
                                        })}
                                        error={errors.nombre?true:false}
                                        />
                                    <TextField
                                        id="sexo"
                                        name="sexo"
                                        select
                                        label="Sexo (*)"
                                        className={classes.customWith}
                                        value={sexo}
                                        onChange={handleChange}
                                        //helperText="Seleccinar sexo"
                                        variant="outlined"
                                        error={errors.sexo?true:false}
                                        >
                                            <MenuItem value="null" disabled>
                                                <i>Seleccionar</i>
                                            </MenuItem>
                                            <MenuItem value="hombre">
                                                Hombre
                                            </MenuItem>
                                            <MenuItem value="mujer">
                                                Mujer
                                            </MenuItem>                       
                                    </TextField>
                                    <TextField                                        
                                        id="fec_nacimiento"
                                        name="fec_nacimiento"
                                        label="Fecha nacimiento (*)"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type="date"
                                        //helperText="Requerido"
                                        className={classes.customWith}
                                        //value={input.nombre}
                                        //onChange={handleInputChange}
                                        inputRef={register({
                                            required:true                                            
                                        })}
                                        error={errors.fec_nacimiento?true:false}
                                    />
                                    <TextField                                        
                                        id="telefono"
                                        name="telefono"
                                        label="Teléfono (*)"
                                        //helperText="Requerido"
                                        className={classes.customWith}
                                        //value={input.nombre}
                                        //onChange={handleInputChange}
                                        inputRef={register({                                            
                                            required:true,                                            
                                            minLength:7,
                                            maxLength:8,
                                            pattern: {
                                                value: /^([0-9])*$/,
                                            }
                                        })}
                                        error={errors.telefono?true:false}
                                    />
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="Email"
                                        //helperText="Requerido"
                                        className={classes.customWith}
                                        //value={input.email}
                                        //onChange={handleInputChange}
                                        inputRef={register({
                                            required:false,
                                            pattern:{
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            }
                                        })}
                                        error={errors.email?true:false}                                        
                                    />
                                    <TextField
                                        id="archivo"
                                        name="archivo"
                                        label="Adjuntar archivo PDF (5MB tamaño máximo)"
                                        type="file"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                                accept:"application/pdf"
                                        }}
                                        //helperText="Requerido"
                                        className={classes.customWith}
                                        //value={input.email}
                                        onChange={handleInputFileChange}
                                        inputRef={register({
                                            required:false                                            
                                        })}
                                        error={errors.archivo || archivo===1 || archivo===2?true:false}
                                        //helperText={archivo===1 || archivo===2?'Se requiere un archivo PDF de 5MB máximo':null}
                                        />
                                    <TextField                                        
                                        id="descripcion"
                                        name="descripcion"
                                        //value={input.mensaje}
                                        label="Breve descripción del proyecto (*)"
                                        className={classes.customWith}
                                        multiline
                                        rows={4}
                                        //defaultValue=""
                                        variant="outlined"
                                        inputRef={register({
                                            required:true,
                                            minLength:10,
                                            maxLength:1500
                                        })}
                                        error={errors.mensaje?true:false}
                                        helperText="1500 caracteres máximo"
                                        />
                                    <Box>
                                        <Typography variant="caption" className={classes.customInfo}>Los campos con (*) son obligatorios</Typography>
                                    </Box>
                                    <Box my={2}>
                                        <ReCAPTCHA
                                            ref={captcha}
                                            sitekey={RECAPTCHA_KEY}
                                            onChange={onChange}
                                        />
                                    </Box>
                                    {
                                        !loading?
                                        <Button disabled={!formState.isValid || sexo==='null' || archivo===1 || archivo===2 || !human} variant="contained" color="secondary" onClick={() => handleSubmit(onSubmit)()}>Enviar sugerencia</Button>:
                                        <CircularProgress color="secondary" />
                                    }                                    
                                </form>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>                
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }} 
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}>
                    <Alert onClose={handleClose} severity={data && data.status?"success":"error"}>
                        {
                            !error && data && !data.status?                            
                            <Typography>{data.message}</Typography>:null
                        } 
                        {
                            !error && data && data.data && !data.status?                            
                            <Typography>Los datos no fueron enviados, vuelva a intentarlo más tarde</Typography>:null
                        }                        
                        {
                            !error && data && data.status?
                            <Typography>Los datos fueron enviados correctamente</Typography>:null
                        }
                        {
                            error && !data?
                            <Typography>Se produjo un error, vuelva a intentarlo más tarde</Typography>:null
                        }
                    </Alert>
            </Snackbar>
        </Grid>
    );
}

export default SugerenciaComponent;