import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {Carousel} from 'react-responsive-carousel';
import {GLOBAL} from '../../../global';

const useStyles = makeStyles(theme => ({
        customBackgroundColor:{
            backgroundColor:theme.palette.secondary.main,            
            //padding:theme.spacing(10,1)
        },
        customTitle:{
            textAlign:'center',
            color:theme.palette.quinary.main

        },
        customSubTitle:{
            color:theme.palette.quinary.main,
            //paddingBottom:theme.spacing(10),
            textAlign:'center',            
        }
}));
/*
const data = {
    style:null,
    title:{
        style:null,
        data:'Estamos para servirte'
    },
    description:{
        style:null,
        data:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita incidunt culpa earum ea doloremque, optio accusamus, ipsa saepe numquam distinctio adipisci consequatur ipsum nisi quae possimus cum laboriosam. Accusamus, dolorum!'
    },
    items:[
        {
            name:'Rebajón',
            img:'/img/secciones/home/rebajon.jpg'
        }
    ]
};
*/
const CarouselAnuncios = (props) => {
    const { data=null } = props;
    const classes = useStyles();
    return(
        <Box className={classes.customBackgroundColor} style={data && data.style?data.style:null} py={10}>
            <Grid container justify='center'>
                <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                    <Box mb={3}>
                        <Typography className={classes.customTitle} variant="h3">{data && data.title && data.title.data?data.title.data:''}</Typography>
                        <Typography className={classes.customSubTitle} variant="h6">{data && data.description && data.description.data?data.description.data:''}</Typography>
                    </Box>
                    <Carousel centerMode centerSlidePercentage={100} emulateTouch infiniteLoop useKeyboardArrows autoPlay showThumbs={false} stopOnHover interval={5000}>
                        {
                            data && data.items?
                            data.items.map((item,index)=>(
                                <div key={index}>
                                    <img src={`${GLOBAL.assetsBack}${item.img}`} alt={item.name}/>
                                </div>
                            )):null
                        }                            
                    </Carousel>
                </Grid>
            </Grid>
        </Box>
    );    
}
export default CarouselAnuncios;