import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography,Grid, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import {GLOBAL} from '../../../global';

const useStyles = makeStyles(theme => ({
    root:{
        flexGrow:1
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    title:{
        color:'white',
        textAlign:'center',
        margin:theme.spacing(2,0),
        padding:theme.spacing(2,1),
        backgroundColor:'#EB0047',
        //fontWeight:'bold'
    },    
    subtitle:{
        textAlign:'center',
        //fontWeight:'bold'
    },
    textJustified:{
        textAlign:'justify',
        margin:theme.spacing(2,1)
    },
    customPurpleBg:{
        backgroundColor:'#2D0840',
        color:'white'
    },    
    purple: {
        color: '#2D0840',
        backgroundColor: '#ffffff',
        fontWeight:'bold'
    },
    boxContainer:{
        display:'flex',
        flexDirection: 'column',
        textAlign:'center',
        height:"100%"
    },
    boxContent:{
        textAlign:'center',
        alignSelf: 'flex-start'
    },
    boxAction:{        
        justifyContent: "flex-end",
        margin:theme.spacing(3,1)
    },
    customImage:{
        width:"100px"
    },
    customSeparator:{
        backgroundColor:'#EB0047',
        color:'white',
        padding:theme.spacing(3,1),
        textAlign:'center',
        //fontWeight:'500'
    },
    customRutaStyle:{
        backgroundColor:'#EFF0EF',        
    },
    customContenidoDescargableContainer:{
        //backgroundColor:'white'
    },
    customTitleContenidoDescargable:{
        color:'#2D0840',
        margin:theme.spacing(6,1),
        textAlign:'center'
    },
    customInfo:{
        backgroundColor:'#2D0840',
        color:'white',
        padding:theme.spacing(1,1)
    },
    customInfoContainer:{
        textAlign:'center',
        margin:theme.spacing(3,1)
    },
    customSeparator2:{
        textAlign:'center',
        color:'#2D0840',
        //fontWeight:'500'
    },
    customSeparator3:{
        padding:theme.spacing(2,1),
        textAlign:'center',
        backgroundColor:'#2D0840',
        color:'#FFFFFF',
        //fontWeight:'500'
    }
    
}));

const descriptionstyle = {
    color:'initial',    
}

const SlimComponent = () => {    
    const classes = useStyles(); 
    useEffect(() => {
       window.scrollTo(0, 0);
    }, []);
    return(
        <React.Fragment>           
            <Typography variant="h3" gutterBottom className={classes.title}>
                <span variant="span" className={classes.title}>
                    JUNTOS POR
                </span><br/>
                <span variant="span" className={classes.title}>
                    <strong>UNA VIDA</strong>
                </span><br/>
                <span variant="span" className={classes.title}>
                    SIN VIOLENCIA
                </span>
            </Typography>
            <Grid container justify="center">
                <Grid item xs={12} sm={11} md={10} lg={8} xl={8}>
                    <Typography variant="h4" className={classes.subtitle}>
                        Trabajamos las 24 horas del día, los 365 días del año.
                    </Typography>
                    <Typography className={classes.textJustified}>
                        Trabajamos en 4 áreas: Atención Jurídica, Atención Psicosocial, Protección y Prevención.
                        Nuestro apoyo es mediante abogadas/os, con psicólogas y trabajadoras/es Sociales. También ofrecemos terapias psicológicas para personas y grupos que lo necesiten. Asimismo, el SLIM brinda terapias a parejas, familias o personas que tienen dificultad para controlar su ira.
                    </Typography>
                </Grid>
            </Grid>
            <br/>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom style={{textAlign:'center',marginBottom:'0px',fontWeight:'bold'}}>SERVICIOS DEL SLIM</Typography>
                    <Typography style={{textAlign:'center'}}>Todos estos servicios son <strong>GRATUITOS</strong></Typography>
                </Grid>
            </Grid>
            <br/>
            <Grid container>
                <Grid item xs={12} sm={12} md={5}>
                    <img src={`${GLOBAL.assetsURL}/images/slim/img1.jpg`} alt="" width="100%"/>
                </Grid>
                <Grid item xs={12} sm={12} md={7} className={classes.customPurpleBg}>
                    <List className={classes.root}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.purple}>
                                    1
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="JURÍDICO" secondary="Orientación y apoyo legal gratuito desde la denuncia hasta la sentencia" secondaryTypographyProps={descriptionstyle}/>
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.purple}>
                                    2
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="PROTECCIÓN" secondary={<>Derivación al Ministerio Público casos que se constituyan delitos.<br/>Ingreso de la víctima a la Casa de Acogida para su protección.</>} secondaryTypographyProps={descriptionstyle}/>
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.purple}>
                                    3
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="PREVENCIÓN" secondary="Servicios de apoyo psicológico y social; Terapia psicológica especializada individual y grupal con enfoque de género." secondaryTypographyProps={descriptionstyle}/>
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.purple}>
                                    4
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="ATENCIÓN" secondary="Difusión, promoción y formación para la prevención de la violencia a la mujer. Coordinación interinstitucional." secondaryTypographyProps={descriptionstyle}/>
                        </ListItem>
                    </List>
                </Grid>                
            </Grid>
            <Box className={classes.customSeparator}>
                <Typography variant="h3" gutterBottom>RUTA DE LA DENUNCIA</Typography>
            </Box>
            <Box className={classes.customRutaStyle}>
            <Grid container justify="center">
                <Grid item item xs={12} sm={11} md={10} lg={8} xl={8}>
                    <img src={`${GLOBAL.assetsURL}/images/slim/ruta-denuncia.png`} alt="RUTA DE LA DENUNCIA" width="100%"/>
                </Grid>
            </Grid>                
            </Box>
            <Box className={classes.customContenidoDescargableContainer}>
                <Typography variant="h3" className={classes.customTitleContenidoDescargable}>CONTENIDO DESCARGABLE</Typography>
                <Grid container justify="center">
                    <Grid item item xs={12} sm={11} md={10} lg={8} xl={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={3} ls={3}>
                                <Box className={classes.boxContainer}>
                                    <Box className={classes.boxContent}>
                                        <img src={`${GLOBAL.assetsURL}/images/slim/icono.png`} alt="icono" className={classes.customImage}/>                                
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Contenido descargable 1
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            Ea ut excepteur dolore aute eu officia culpa quis consectetur velit do culpa. Eu fugiat nulla fugiat ex consequat nulla labore ut duis magna qui. Ullamco anim pariatur ad sunt sit consequat aliqua elit.
                                        </Typography>
                                    </Box>
                                    <Box className={classes.boxAction}>
                                        <Button color="secondary" variant="contained">
                                            Descargar
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} ls={3}>
                                <Box className={classes.boxContainer}>
                                    <Box className={classes.boxContent}>
                                        <img src={`${GLOBAL.assetsURL}/images/slim/icono.png`} alt="icono" className={classes.customImage}/>                                
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Contenido descargable 2
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            Ea ut excepteur dolore aute eu officia culpa quis consectetur velit do culpa. Eu fugiat nulla fugiat ex consequat nulla labore ut duis magna qui. Ullamco anim pariatur ad sunt sit consequat aliqua elit.
                                        </Typography>                                
                                    </Box>
                                    <Box className={classes.boxAction}>
                                        <Button color="secondary" variant="contained">
                                            Descargar
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} ls={3}>
                                <Box className={classes.boxContainer}>
                                    <Box className={classes.boxContent}>
                                        <img src={`${GLOBAL.assetsURL}/images/slim/icono.png`} alt="icono" className={classes.customImage}/>                                
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Contenido descargable 3
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            Ea ut excepteur dolore aute eu officia culpa quis consectetur velit do culpa. Eu fugiat nulla fugiat ex consequat nulla labore ut duis magna qui. Ullamco anim pariatur ad sunt sit consequat aliqua elit.
                                        </Typography>
                                    </Box>
                                    <Box className={classes.boxAction}>
                                        <Button color="secondary" variant="contained">
                                            Descargar
                                        </Button>                           
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} ls={3}>
                                <Box className={classes.boxContainer}>
                                    <Box className={classes.boxContent}>
                                        <img src={`${GLOBAL.assetsURL}/images/slim/icono.png`} alt="icono" className={classes.customImage}/>                                
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Contenido descargable 4
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            Ea ut excepteur dolore aute eu officia culpa quis consectetur velit do culpa. Eu fugiat nulla fugiat ex consequat nulla labore ut duis magna qui. Ullamco anim pariatur ad sunt sit consequat aliqua elit.
                                        </Typography>                                
                                    </Box>
                                    <Box className={classes.boxAction}>
                                        <Box justify="center">
                                            <Button color="secondary" variant="contained">
                                                Descargar
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>                
            </Box>
            <br/>
            <Box className={classes.customSeparator2}>
                <Typography variant="h3">CONTACTOS</Typography>                
            </Box>            
            <Box>                
                <Typography variant="h4" className={classes.customInfoContainer}><span className={classes.customInfo}>Llama gratis al <span style={{color:'#F0E732'}}>800 14 0205</span>, a cualquier hora del día.</span></Typography>                
                <Typography variant="h4" className={classes.customInfoContainer}><span className={classes.customInfo}>Atendemos el teléfono fijo  <span style={{color:'#F0E732'}}>4586818</span> en horarios de oficina, de lunes a viernes.</span></Typography>                
            </Box>
            <br/>
            <Box>                
                <Typography variant="h4" className={classes.customSeparator3}>DONDE UBICARNOS</Typography>
                <Grid container justify="center">
                    <Grid item xs={12} sm={11} md={10} lg={8} xl={8}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6}>
                                <ul>
                                    <li>Jefatura de SLIM: Plaza Colón acera este. Telf. 4586818.</li>
                                    <li>Punto SLIM EPI Norte: Av. Melchor Pérez y F. Roosevelt. Telf. 4476773.</li>
                                    <li>Punto SLIM Zona Central: Baptista entre Colombia y Ecuador. Telf.: 4010884.</li>
                                    <li>Punto Slim Villa Pagador: Calle Humberto Asín y Tomás Barrón, frente al Centro de Salud. Telf. 4010881.</li>
                                    <li>Punto Slim Distrito 7 Mercado Campesino: Calle Huanchaca y Pablo Meneces, lado del Centro de Salud de Villa Venezuela.</li>
                                </ul>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <ul>
                                    <li>Punto SLIM Villa México: Lado del Mercadito de Villa México. Telf.: 4235243.</li>
                                    <li>Punto SLIM Itocta: Av. Petrolera km. 4 y 1⁄2, entrando al Politécnico hasta su puerta principal. Telf.: 4234395.</li>
                                    <li>Punto Slim Molle: Av. Beijing y Acre.</li>
                                    <li>Punto SLIM Alejo Calatayud: Av. Petrolera Km. 2 y 1⁄2 (Subalcaldía)</li>
                                </ul>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
}
export default SlimComponent;
