import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import useAxios from 'axios-hooks';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ReactPlayer from 'react-player';
import {Carousel} from 'react-responsive-carousel';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CodeIcon from '@material-ui/icons/Code';
import ListOutlined from '@material-ui/icons/ListOutlined';
import moment from 'moment';
import 'moment/locale/es';
import Button from '@material-ui/core/Button';
import CustomButtonComponent from '../../../components/genericos/buttons/CustomButtonComponent';
import ErrorRequestComponent from '../../../components/genericos/error/ErrorRequestComponent';
import {GLOBAL} from '../../../global';

moment().locale('es');

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      padding:theme.spacing(5,1),
      backgroundColor:theme.palette.tertiary.main
    },
    customCard:{        
        height:'100%'
    },
    customCardAction:{
        display:"flex",
        justifyContent:"center"
    },
    customMargin:{
        marginTop:theme.spacing(5),
        marginBottom:theme.spacing(5),
        color:"#ffffff"
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    customTitle:{
        textAlign:'center',
        //color:theme.palette.primary.main
        color:'white'
    },
    customSubTitle:{
        color:'#ffffff',
        //paddingBottom:theme.spacing(10),
        textAlign:'center',            
    },
    customButtonContainer:{
        paddingTop:theme.spacing(3),
        textAlign:'center'
    },
    customButton:{
        backgroundColor:theme.palette.tertiary.dark,
        '&:hover': {
            backgroundColor: theme.palette.tertiary.main,
        },
        color:'white'
    },
    customCardTitle:{
        color:theme.palette.sextary.main,
        minHeight:'80px'
    },
    customDescription:{
        color:theme.palette.sextary.light,
        display:'flex',
        flexWrap:'wrap',
        justifyContent:'left'

    },
    customCardPadding:{
        marginBottom:theme.spacing(1)
    },
    customTitleMobile:{
        color:theme.palette.sextary.main,        
    }
  }));

const MyCustomCard = (props) => {
    const {loading=false,data={}} = props;
    const classes = useStyles();

    const isImage = (ruta) => {
        let result = false;
        if(ruta){
            let extension = ruta.substring(ruta.lastIndexOf('.') + 1).toLowerCase();
            if(extension == 'png' || extension == 'jpg' || extension == 'jpeg'){
                result = true;
            }
        }
        return result;
    }
    const isVideo = (ruta) => {
        let result = false;
        if(ruta){
            let extension = ruta.substring(ruta.lastIndexOf('.') + 1).toLowerCase();
            if(extension == 'mp4'){
                result = true;
            }
        }
        return result;
    }
    return (        
        <Card className={classes.customCard}>
            {
                !loading?
                    <>
                        {
                            data && isImage(data.archivo)?
                                <CardMedia
                                    className={classes.media}                                    
                                    alt="Imagen principal"
                                    image={`${GLOBAL.assetsBack}/${data.archivo}`}
                                />:null
                        }
                        {                                    
                            data && isVideo(data.archivo)?
                                <ReactPlayer
                                        align="center"
                                        url={`${GLOBAL.assetsBack}/${data.archivo}`}
                                        controls={true} 
                                        width="100%"
                                        height="auto"
                                />:null
                        }
                    </>:
                    (
                        <Skeleton variant="rect" width="100%">
                            <div style={{ paddingTop: '56.25%' }} />
                        </Skeleton>
                    )
            }
            <CardContent>
                <Typography gutterBottom variant="h5" className={classes.customCardTitle}>
                    {!loading?data.titulo:<Skeleton/>}
                </Typography>                
                <Box display="flex" alignItems="center" className={classes.customDescription}>
                    <Box>
                        <AccessTimeIcon style={{display:'block'}}/>
                    </Box>
                    <Box>
                        {
                            !loading?
                            (data && data.fecha?moment(data.fecha).locale('es').format('LL').toUpperCase():''):
                            (<Skeleton/>)
                        }
                    </Box>                    
                </Box>
            </CardContent>
            <CardActions className={classes.customCardAction}>
                {
                    !loading?
                    (
                        <Link to={"/noticias/"+data.slug} style={{textDecoration:'none'}}>
                            <Button variant="contained" color="primary">
                                LEER MÁS
                            </Button>
                        </Link>                        
                    ):
                    (
                        <Button variant="contained" color="primary">
                            <Skeleton width='100px'/>
                        </Button>
                    )
                }
            </CardActions>
        </Card>        
    )
}

const UltimasNoticiasComponent = (props) => {    
    const matches = useMediaQuery('(min-width:600px)');    
    const classes = useStyles();
    const [{ data:getData, loading, error }, refetch] = useAxios(
        `${GLOBAL.apiURL}/api/noticias/ultimas`
    )    
    const { data={
        containerStyle:null,
        title:{
            style:null,
            data:""
        },
        description:{
            style:null,
            data:""
        },
        options:{
            lista:false,
            showOptions:false
        }
    } } = props;
    const [lista, setLista] = React.useState(data && data.options?data.options.lista:false);

    const isImage = (ruta) => {
        let result = false;
        if(ruta){
            let extension = ruta.substring(ruta.lastIndexOf('.') + 1).toLowerCase();
            if(extension == 'png' || extension == 'jpg' || extension == 'jpeg'){
                result = true;
            }
        }
        return result;
    }
    const isVideo = (ruta) => {
        let result = false;
        if(ruta){
            let extension = ruta.substring(ruta.lastIndexOf('.') + 1).toLowerCase();
            if(extension == 'mp4'){
                result = true;
            }
        }
        return result;
    }

    return(        
        <Grid container justify='center' className={classes.root} style={data.containerStyle?data.containerStyle:null}>
            <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>                
                <Box mb={3}>
                    <Typography className={classes.customTitle} variant="h3">{data.title && data.title.data?data.title.data:""}</Typography>
                    <Typography className={classes.customSubTitle} variant="h6">{data.description && data.description.data?data.description.data:""}</Typography>
                </Box>
                    {
                        matches?
                        <Grid container spacing={2}>
                            {
                                loading?
                                Array.from(new Array(4)).map((item,index) => (
                                    <Grid key={index} item xs={12} sm={12} md={6} lg={3} xl={3}>                               
                                        <MyCustomCard loading/>
                                    </Grid>
                                )):null
                            }                    
                            {
                                !loading && !error && getData?
                                getData.map((item,index) => (
                                    <Grid key={`card${index}`} item xs={6} sm={6} md={4} lg={3} xl={3}>                                
                                        <MyCustomCard data={item}/>
                                    </Grid>
                                )):null 
                            }
                            {
                                error?
                                <ErrorRequestComponent handleClick={refetch}/>:null
                            }
                        </Grid>:
                        <>
                            {
                                data.options && data.options.showOptions?
                                <Box alignContent="center" pb={3}>
                                    <Button size="small" variant="contained" color="secondary" onClick={()=>setLista(!lista)} startIcon={lista?<CodeIcon/>:<ListOutlined/>}>{lista?"Modo carrusel":"Modo lista"}</Button>
                                </Box>:null
                            }
                            {
                                !lista?
                                <Carousel centerMode centerSlidePercentage={100} emulateTouch infiniteLoop useKeyboardArrows autoPlay showThumbs={false} stopOnHover interval={5000} showIndicators={false}>
                                    {
                                        !loading && !error && getData?
                                        getData.map((item,index) => (
                                            <div key={index}>
                                                <MyCustomCard data={item}/>
                                            </div>
                                        )):null
                                    }
                                </Carousel>:
                                <>
                                    {
                                        !loading && !error && getData?
                                        getData.map((item,index) => (
                                            <Card key={`k${index}`} className={classes.customCardPadding}>
                                                <Link to={"/noticias/"+item.slug} style={{textDecoration:'none'}}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={6}>
                                                            {
                                                                data && isImage(item.archivo)?
                                                                <CardMedia
                                                                    className={classes.media}
                                                                    alt="Imagen de portada"
                                                                    image={`${GLOBAL.assetsBack}/${item.archivo}`}
                                                                />:null
                                                            }
                                                            {
                                                                data && isVideo(item.archivo)?
                                                                <ReactPlayer
                                                                    align="center"
                                                                    url={`${GLOBAL.assetsBack}/${item.archivo}`}
                                                                    controls={true} 
                                                                    width="100%"
                                                                    height="auto"
                                                                />:null
                                                            }
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Box p={1} display="flex" alignItems="center" height="100%">
                                                                <Box>
                                                                    <Typography color="primary" variant="caption">{item.fecha?moment(item.fecha).locale('es').format('LL').toUpperCase():''}</Typography>
                                                                    <Typography variant="subtitle2" className={classes.customTitleMobile}>{item.titulo}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Link>
                                            </Card>
                                        )):null
                                    }
                                </>
                            }
                        </>
                    }
                <Box className={classes.customButtonContainer}>
                    <Link to="noticias" style={{textDecoration:'none'}}>
                        <CustomButtonComponent color="secondary" text="Más noticias"/>
                    </Link>
                </Box>
            </Grid>
        </Grid>
    );
}
export default UltimasNoticiasComponent;