import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { deepPurple } from '@material-ui/core/colors';
import convert from 'htmr';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
      },
  }));

const Pasos = (props) => {
    const classes = useStyles();
    return (
        <VerticalTimeline>
            {
                Array.isArray(props.data)?
                props.data.map((item,index)=>(
                    <VerticalTimelineElement
                        key={index}
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#16CEDE', color: '#000' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}                        
                        date={item.titulo?item.titulo:''}
                        //iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<Avatar className={classes.large+" "+classes.purple}>{item.orden}</Avatar>}
                    >
                        {convert(item.descripcion?item.descripcion:'<p></p>')}
                    </VerticalTimelineElement>
                )):null
            }            
        </VerticalTimeline>
    )
}
export default Pasos;