import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Carousel} from 'react-responsive-carousel';
import Grid from '@material-ui/core/Grid';
import { Box,CardMedia,Container,Typography } from '@material-ui/core';
import CustomButtonComponent from '../buttons/CustomButtonComponent';
import { GLOBAL } from '../../../global';

const useStyles = makeStyles((theme) => ({
  root:{
    padding:theme.spacing(5,1)
  },  
  customTitle:{
    textAlign:'center',
    color:theme.palette.primary.main
    //color:'white'
  },
  customSubTitle:{
    color:'#ffffff',
    paddingBottom:theme.spacing(10),
    textAlign:'center',            
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  customTextContainer:{
    backgroundColor:theme.palette.tertiary.main,
    padding:theme.spacing(2,0),
    color:'white'
  },
  customTitle2:{
    margin:'0px',
    //padding:theme.spacing(1)
  },
  customDescription:{
    margin:'0px',
    //padding:theme.spacing(1)
  }
}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const itemData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
export default function ListaImagenComponent(props) {
  const classes = useStyles();
  const { data = {
    style:null,
    title:{
      data:"Trabajos y gestión municipal",
      style:null
    },
    description:{
      data:null,
      style:null
    },
    button:{
      color:"secondary",
      text:"Conoce más",
      type:"interno",
      url:"gobierno-municipal"
    },
    items:null
  } } = props;
  return (
    <Box className={classes.root}>
      <Grid container justify="center">
        <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
          <Box>
            <Typography className={classes.customTitle} variant="h3">{data.title?data.title.data:''}</Typography>
            {
              data.description && data.description.data?
              <Typography className={classes.customSubTitle} variant="h6">{data.description?data.description.data:''}</Typography>:null
            }
          </Box>
          <Box my={3}>
            {
              <Carousel centerMode showIndicators={false} centerSlidePercentage={100} emulateTouch infiniteLoop useKeyboardArrows autoPlay showThumbs={false} stopOnHover interval={5000}>
                {
                  data.items && Array.isArray(data.items)?
                  data.items.map((item,index) => (
                      <Box key={index}>
                          <CardMedia className={classes.media} image={`${GLOBAL.assetsBack}${item.img}`} alt={item.title}/>
                          {
                            item.title || item.description?
                            <Box className={classes.customTextContainer}>
                              <Typography className={classes.customTitle2} variant="h5">{item.title}</Typography>
                              <Typography className={classes.customDescription}>{item.description}</Typography>
                          </Box>:null
                          }                        
                      </Box>
                  )):null
                }
              </Carousel>
            }
          </Box>
          <Box className={classes.customButtonContainer} textAlign="center">
            <CustomButtonComponent action={data.button} color={data.button.color} text={data.button.text}/>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
