import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import DirectionsIcon from '@material-ui/icons/Directions';

const useStyles = makeStyles((theme) => ({
  root: {
    margin:theme.spacing(0,0),
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },  
}));

export default function SearchComponent(props) {
  const classes = useStyles();
  const [text, setText] = React.useState('');
  const [buscando, setBuscando] = React.useState(false);
  const handleChange = (event) => {
    setText(event.target.value);
  };
  const onHandleClick = () => {    
    if(!buscando && text.length>0){
      console.log(text);
      setBuscando(true);
      props.onHandleSearch(text);
    }else{
      setBuscando(false);
      setText('');
      props.onHandleCancelSearch();
    }
  }
  return (
    <Paper variant="outlined" square className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Buscar decreto"
        inputProps={{ 'aria-label': 'Buscar decreto' }}
        value={text}
        onChange={handleChange}
        disabled={buscando}
      />
      <IconButton color={'primary'} disabled={text.length==0?true:false} className={classes.iconButton} onClick={onHandleClick}>
        {buscando?<CloseIcon/>:<SearchIcon/>}
      </IconButton>
    </Paper>
  );
}