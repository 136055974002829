import React from "react";
import { withRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { GLOBAL } from '../../../../global';
import Helper from "../../../../Helper";

const useStyles =  makeStyles(theme => ({
  root: {
    height:'100%',
    cursor:'pointer'    
  },
  customTitle:{
    //fontWeight:'bold',
    textAlign:'center',
    color:theme.palette.sextary.main
  },
  customDescription:{
    color:theme.palette.sextary.light
  },
  customImgContainer:{
    //height: 0,
    //paddingTop: '56.25%',
    textAlign:'center',
    backgroundColor:theme.palette.tertiary.main,
    //borderTopLeftRadius:'180px',
    //borderTopRightRadius:'180px',
    borderBottomLeftRadius:'180px',
    borderBottomRightRadius:'180px',
  },
  customImgContent:{
    width:'100%',
    height:'100%'
  },
  customImg:{
    maxWidth:'80px',    
    top:'-100px'
  },
  customContent:{
    padding:theme.spacing(2)
  }
}));

const CustomCardButtonComponent = (props) => {  
  const { info = {icon:'',title:'',description:''} } = props;
  const classes = useStyles();

  const onHandleClick = (data) => {
    Helper.checkLink(props,data);
  }

  return (
    <Card className={classes.root} onClick={()=>onHandleClick(props.info)}>
      <Box className={classes.customImgContainer}>
        <img className={classes.customImg} src={`${GLOBAL.assetsBack}${info.icon}`} alt="logo" />
      </Box>
      <CardContent className={classes.customContent}>
        <Typography variant='h5' className={classes.customTitle}>{info.title}</Typography>
        <Typography className={classes.customDescription}>{info.description}</Typography>
      </CardContent>
    </Card>
  );
};
export default withRouter(CustomCardButtonComponent);
