import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from '@material-ui/core/Box';
import CodeIcon from '@material-ui/icons/Code';
import GridOnIcon from '@material-ui/icons/GridOn';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Parallax} from 'react-parallax';
import {Carousel} from 'react-responsive-carousel';
import { withRouter } from 'react-router-dom';
import Helper from '../../Helper';
import {GLOBAL} from '../../global';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,        
        padding:theme.spacing(5,1),
    },
    paper: {    
        padding: theme.spacing(4,1),    
        marginTop:theme.spacing(5),
        textAlign: 'center',
        height:'70%',   
        cursor:'pointer',
    },
    bigAvatar: {    
        marginTop:theme.spacing(-6),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: 60,
        height: 60,
    },
    customTitle:{
        textAlign:'center',
        color:theme.palette.primary.main
    },
    customSubTitle:{
        color:theme.palette.primary.main,
        //paddingBottom:theme.spacing(3),
        textAlign:'center',
    },
    rounded: {
        color: '#fff',
        backgroundColor: theme.palette.tertiary.main,
        width:theme.spacing(7),
        height:theme.spacing(7)
    },
    customMobile:{
        textAlign:'center',
        color:theme.palette.sextary.main
    },
    customMedia:{
        width:'100%',
        height: 'auto'
    },
    rootcard: {
        flexGrow: 1,
        height:'100%',
        cursor:'pointer'
        //padding:theme.spacing(5,0),
    },
    customContent:{
        padding:theme.spacing(2)
    },
    customTitle:{
        //fontWeight:'bold',
        textAlign:'center',
        color:theme.palette.sextary.main
      },
      customDescription:{
        color:theme.palette.sextary.light
      },
}));

const MyCustomCard = (props) => {
    const classes = useStyles();
    const { data = {} } = props;

    return (
        <Card className={classes.rootcard} onClick={()=>props.onCustomClick({url:data.url,type:data.type})}>
            <Box>
                <img src={`${GLOBAL.assetsBack}${data.icon}`} className={classes.customMedia}/>
            </Box>
            <CardContent className={classes.customContent}>
                <Typography variant='h5' className={classes.customTitle}>{data.title}</Typography>
                <Typography className={classes.customDescription}>{data.description}</Typography>
            </CardContent>
        </Card>
    );
}

const MyComponent = (props) => {
    const { data = {} } = props;
    const matches = useMediaQuery('(min-width:600px)');
    const classes = useStyles();
    const [grid, setGrid] = useState(data && data.options?data.options.grid:false);    

    return(
        <Grid container className={classes.root} justify='center' style={data.style.container}>
            <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                <Box mb={3}>
                    <Typography className={classes.customTitle} variant="h3" style={data.style.title}>{data.title?data.title:''}</Typography>
                    <Typography className={classes.customSubTitle} variant="h6" style={data.style.subtitle}>{data.description?data.description:''}</Typography>
                </Box>
                {
                    matches?
                    <Grid container spacing={data.style && data.style.gridSpacing?data.style.gridSpacing:2} justify="center">
                        {
                            data.buttons?data.buttons.map((item,index)=>(
                                <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={3} align="center">
                                    <MyCustomCard data={item} onCustomClick={(chilParam)=>props.onHandleClick(chilParam)}/>
                                </Grid>
                            )):null
                        }
                    </Grid>:
                    <>                        
                        {
                            data.options && data.options.showOptions?
                            <Box alignContent="center" pb={3}>
                                <Button size="small" variant="contained" color="secondary" onClick={()=>setGrid(!grid)} startIcon={grid?<CodeIcon/>:<GridOnIcon/>}>{grid?"Modo carrusel":"Modo grid"}</Button>
                            </Box>:null
                        }
                        {
                            !grid?
                            <Carousel centerMode centerSlidePercentage={100} emulateTouch infiniteLoop useKeyboardArrows autoPlay showThumbs={false} stopOnHover interval={5000} showIndicators={false}>
                                {
                                    data && data.buttons?
                                    data.buttons.map((item,index)=>(
                                        <div key={`j${index}`}>
                                            <MyCustomCard data={item} onCustomClick={(chilParam)=>props.onHandleClick(chilParam)}/>
                                        </div>
                                    )):null
                                }
                            </Carousel>:                                                                                                
                            <Grid container spacing={data.style && data.style.gridSpacing?data.style.gridSpacing:2} justify="center">
                                {
                                    data.buttons?data.buttons.map((item,index)=>(
                                        <Grid key={index} item xs={6} sm={6} md={4} lg={3} xl={3} align="center">
                                            <MyCustomCard data={item} onCustomClick={(chilParam)=>props.onHandleClick(chilParam)}/>
                                        </Grid>
                                    )):null
                                }
                            </Grid>                            
                        }                        
                    </>                    
                }
            </Grid>
        </Grid>
    );
}

const TitleWithCardsAndImgs = (props) =>{    
    const { data={} } = props;
    const doSomething = (dato) => {
        Helper.checkLink(props,dato);
    }
    return data.parallax.enabled?
    <Parallax blur={data.parallax.blur?data.parallax.blur:0} bgImage={`${GLOBAL.assetsBack}${data.parallax.bgimg}`} bgImageAlt="Background image" strength={data.parallax.strength?data.parallax.strength:0}>
        <MyComponent data={data} onHandleClick={(paramFromChild)=>doSomething(paramFromChild)}/>
    </Parallax>:<MyComponent data={data} onHandleClick={(paramFromChild)=>doSomething(paramFromChild)}/>
    
}

export default withRouter(TitleWithCardsAndImgs);