import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import cyan from '@material-ui/core/colors/cyan';
const cian = cyan['600'];
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
const Area = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.root}>
        <Grid container spacing={3} >
          <Grid item xs>
            <Box p={2} bgcolor={cian} color="primary.contrastText" fontSize={18} fontWeight="fontWeightBold">
              ÁREA LEGAL
            </Box>
          </Grid>
          <Grid item xs>
            <Box p={2} bgcolor="secondary.main" color="primary.contrastText" fontSize={18} fontWeight="fontWeightBold">
              ÁREA SOCIAL
            </Box>
          </Grid>
          <Grid item xs>
            <Box p={2} bgcolor={cian} color="primary.contrastText" fontSize={18} fontWeight="fontWeightBold">
              ÁREA PSICOLÓGICA
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default Area;
