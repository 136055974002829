import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link,withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import Helper from '../../../Helper';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function Dropdown(props) {   
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };    
    const linkAction = (obj) => {
        //console.log("the param1 = ", param1);
        //props.history.push(param1);
        Helper.checkLink(props,obj);
        setAnchorEl(null);
    }    
    return (
      <div className="MuiIconButton-label">
          <Button aria-controls="customized-menu" color="inherit" aria-haspopup="true" onClick={handleClick}>{props.label}</Button>
          {/* <Typography variant='body2' component="span" onClick={handleClick}>{props.label}</Typography> */}
          <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
          >
              {
                props.data.map((item, index) => (
                  <StyledMenuItem key={index}>
                      <ListItemText primary={item.label} onClick={() => linkAction(item)} />
                  </StyledMenuItem>
                ))
              }
          </StyledMenu>
      </div>
    );
}
export default withRouter(Dropdown);