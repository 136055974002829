import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {GLOBAL} from '../../../global';
import { Typography } from '@material-ui/core';
import Helper from '../../../Helper';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    //backgroundColor: 'rgba(0,0,0,0.5)',
    //padding: theme.spacing(1,5)
  },
  gridList: {
    width: 500,
    height: 'auto',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  rounded: {
    color: '#fff',
    backgroundColor: theme.palette.tertiary.main,
  },
  customHeaderContainer:{
    color:'#fff',
    lineHeight:'0px'
  },
  customCard:{
    cursor:'pointer'
  }
}));

const data = {
  "smallText":"CONTENIDO",
  "bigText":"DESTACADO",
  "icon":null,
  "items":[
    {
      "img":"img/secciones/home/destacado-vamos-cochabamba.jpg",
      "title":"Vamos",
      "description":"Cochabamba",
      "action":{
        "type":"alert",
        "url":null,
        "data":"Contenido no disponible"
      }
    },
    {
      "img":"img/secciones/home/img-innova.jpg",
      "title":"Innova",
      "description":"Cochabamba",
      "action":{
        "type":"alert",
        "url":null,
        "data":"Contenido no disponible"
      }
    }
  ]
}

const TitlebarGridList = (props) => {
  const classes = useStyles();
  const { data={} } = props;
  return (
    <div className={classes.root}>
      <GridList cellHeight={180} className={classes.gridList}>
        <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
          <ListSubheader component="div" style={{padding:'0px'}}>
            <Box className={classes.customHeaderContainer}>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Box p={1}>
                        <Avatar variant="rounded" className={classes.rounded}>
                          {
                            data && data.icon?
                            <img src={data.icon} alt=""/>:
                            <CalendarTodayIcon/>
                          }                          
                        </Avatar>
                    </Box>
                    <Box>
                        <Box>
                            <Typography variant="subtitle2" component="span">
                              {data && data.smallText?data.smallText:null}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h4" component="span">
                              {data && data.bigText?data.bigText:null}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
          </ListSubheader>
        </GridListTile>
        {
          data && Array.isArray(data.items)?
          data.items.map((tile,index) => (
            <GridListTile key={`tile${index}`} className={classes.customCard} onClick={()=>Helper.checkLink(props,tile.action)}>
              <img src={`${GLOBAL.assetsBack}/${tile.img}`} alt={tile.title} />
              <GridListTileBar
                title={tile.title}
                subtitle={<span>{tile.description}</span>}
                actionIcon={
                  <IconButton aria-label={`btn${tile.title}`} className={classes.icon}>
                    <LinkIcon />
                  </IconButton>
                }
              />
            </GridListTile>
          )):null
        }
      </GridList>
    </div>
  );
}
export default withRouter(TitlebarGridList);