import React from 'react';
import {Link} from 'react-router-dom';
import useAxios from 'axios-hooks';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from "react-hook-form";
import { Grid, Button, TextField, Typography,Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
//import { GLOBAL } from '../../global';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(10, 1),
    },
    customCardContainer: {
        padding: theme.spacing(5, 1),
    },
    customWith: {
        width: '100%'
    },
    customFooter: {
        justifyContent: 'center',
        padding: theme.spacing(3, 1)
    },
    customButton: {
        backgroundColor: "#4caf50",
        color: 'white',
        '&:hover': {
            backgroundColor: "#28792b",
        }
    },
    customTableTitle: {
        textAlign: 'center',
        color:theme.palette.sextary.dark,
        //backgroundColor: theme.palette.tertiary.dark,
    },
    customSectionTitle: {
        backgroundColor: theme.palette.tertiary.main,
        textAlign: 'left',
        color:theme.palette.sextary.dark,
        color: 'white',
        padding: theme.spacing(2, 1)
    },
    customTable: {
        width: '100%',
        textAlign: 'center'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width:"100%"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    dataStyle:{
        color:theme.palette.sextary.main
    },
    dataStyle2:{
        textAlign:'left',
        color:theme.palette.sextary.light,
        padding:theme.spacing(1)
    },
    menu:{
        flexGrow: 1,
    },    
    title: {
        flexGrow: 1,
    }
}));

export default function ConsultaPatentesMunicipalesView() {
    const classes = useStyles();
    const [onrequest, setOnrequest] = React.useState(false);
    const { register, handleSubmit, formState, errors, reset } = useForm({ mode: 'onChange' });
    const [{ data, loading, error }, refetch] = useAxios({ url: `https://www.cochabamba.bo/api/municipio/sitiosjson`, method: 'POST' }, { manual: true });
    const [actividad, setActividad] = React.useState(0);
    var actualDatos = null;

    const handleChange = (event) => {
        setActividad(event.target.value);
    };
    const onSubmit = datos => {
        console.log("datos=",datos);
        let formData = new FormData();
        formData.append('codigo_licencia', datos.cod_licencia);
        formData.append('tipo', actividad);
        refetch({
            data: formData
        });
        setOnrequest(true);
    };
    const NewRequest = () => {
        setOnrequest(false);
        reset();
    }
    const setDatos = (dat) => {
        actualDatos = null;
        console.log("##### actualDatos ####");
        if(dat){
            if(Array.isArray(dat)){
                actualDatos = dat;
            }else{
                actualDatos = [dat];
            }
        }        
    }
    if(data){setDatos(data);}
    return (
        <Grid container justify="center" className={classes.root}>
            <Grid item xs={12} sm={11} md={8} lg={8} xl={8}>
                <div className={classes.menu}>
                    <AppBar position="static">
                        <Toolbar>                            
                            <Typography variant="h6" className={classes.title}>
                                Formulario de consultas
                            </Typography>
                            <Tooltip title="Ir al menú de Consultas en línea">
                                <Link to="consultas-en-linea" style={{textDecoration:'none'}}>
                                    <Button color="secondary" variant="contained" startIcon={<MenuIcon/>}>Ir al menú</Button>
                                </Link>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                </div>
                <Card className={classes.customCardContainer}>                    
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <CardContent>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Tipo de actividad</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={actividad}
                                onChange={handleChange}
                                label="Tipo de actividad"
                                autoWidth
                                disabled={onrequest}
                            >
                                <MenuItem value={0}>Sitios</MenuItem>
                                <MenuItem value={1}>Mercados</MenuItem>
                                <MenuItem value={2}>Mercados zonales</MenuItem>
                                <MenuItem value={3}>Publicidad</MenuItem>
                            </Select>
                            <FormHelperText>Dato requerido</FormHelperText>
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                disabled={onrequest}
                                color="primary"
                                id="cod_licencia"
                                name="cod_licencia"
                                label="Código de licencia"
                                placeholder="Ingrese el código de licencia"
                                variant="outlined"
                                helperText="Requerido"
                                className={classes.customWith}
                                //value={input.nombre}
                                //onChange={handleInputChange}
                                inputRef={register({
                                    required: true,
                                    minLength: 3,
                                    maxLength: 18
                                })}
                                error={errors.cod_licencia ? true : false}
                            />
                        </FormControl>
                            {
                                loading ?
                                    <CircularProgress /> : null
                            }
                            {
                                onrequest && error && !loading?
                                    <Alert variant="filled" severity="error">
                                        {error.messaje?error.messaje:"Se produjo un error, vuelva a intentarlo nuevamente"}
                                    </Alert> : null
                            }
                            {
                                onrequest && data && Object.keys(data).length===1 && !error && !loading?
                                    <Alert variant="filled" severity="error">
                                        {data.deuda?data.deuda:"Se produjo un error, vuelva a intentarlo nuevamente"}
                                    </Alert> : null
                            }
                            {
                                onrequest && data && !error && !loading && Object.keys(data).length>1?
                                    <>
                                        <Alert variant="filled" severity="success">
                                            <Typography variant="body2">Consulta exitosa</Typography>
                                        </Alert>
                                        <br/>
                                        {
                                            actualDatos?
                                                <>
                                                    <Typography className={classes.customTableTitle}>RESULTADO DE LA CONSULTA</Typography>
                                                    <Grid container className={classes.customTable} justify="center">                                                    
                                                        <Grid item xs={12}>
                                                            <Typography className={classes.customSectionTitle}>DATOS ACTIVIDAD</Typography>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={6} md={4}>
                                                                    <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>RAZÓN SOCIAL:</span><br/>{actualDatos.length>0?actualDatos[0].razon_social:''}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={4}>
                                                                    <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>ACTIVIDAD:</span><br/>{actualDatos.length>0?actualDatos[0].actividad:''}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={4}>
                                                                    <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>DIRECCIÓN:</span><br/>{actualDatos.length>0?actualDatos[0].direccion:''}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={4}>
                                                                    <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>SUPERFICIE:</span><br/>{actualDatos.length>0?actualDatos[0].superficie:''}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={4}>
                                                                    <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>SINDICATO:</span><br/>{actualDatos.length>0?actualDatos[0].sindicato:''}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={4}>
                                                                    <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>TIPO:</span><br/>{actualDatos.length>0?actualDatos[0].tipo:''}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Card>
                                                                <Typography className={classes.customSectionTitle}>DATOS DEUDA</Typography>
                                                                <CardContent>
                                                                    {
                                                                        actualDatos?
                                                                        actualDatos.map((item,index)=>(
                                                                            <Box key={`i${index}`} py={2} px={2} style={{backgroundColor:(index%2)==0?'white':'#ebebeb'}}>
                                                                                <Grid container justify="center">
                                                                                    <Grid item xs={4} sm={4} md={4}>
                                                                                        <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>GESTIÓN:</span><br/>{item.gestion}</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={4} sm={4} md={4}>
                                                                                        <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>DEUDA:</span><br/>{item.deuda}</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={4} sm={4} md={4}>
                                                                                        <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>DESCUENTO:</span><br/>{item.descuento?item.descuento:null}</Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                        )):null
                                                                    }
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                                </> : null
                                        }
                                    </> : null
                            }
                        </CardContent>
                        <CardActions className={classes.customFooter}>                            
                            {
                                onrequest ?
                                    <Button variant="contained" color="secondary" onClick={() => NewRequest()}>Nueva consulta</Button> :
                                    <Button disabled={!formState.isValid || String('0123').indexOf(actividad.toString())===-1} variant="contained" className={classes.customButton} onClick={() => handleSubmit(onSubmit)()}>Consultar</Button>
                            }                            
                        </CardActions>
                    </form>
                </Card>
            </Grid>
        </Grid>
    );
}
