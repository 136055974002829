import React,{useRef,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CardContainerWithTitle from '../../components/genericos/secciones/card-container/CardContainerWithTitle';
import ModalWithMethodToCall from '../../components/genericos/modal/ModalWithMethodToCall';
import CardWithTitleImgDesc from '../../components/genericos/cards/CardWithTitleImgDesc';
import CardContainerComponent from './components/CardContainerComponent';

import {GLOBAL} from '../../global';

const useStyles = makeStyles(theme => ({
    root:{
        flexGrow:1
    },
    customTitle:{
        textAlign:'justify',
        marginTop:theme.spacing(5),
        marginBottom:theme.spacing(1),
        //color:'#0C1D39',
        //color:'#3A4961',
    },
    customDescription:{
        textAlign:'center',
        color:'#3A4961',
        //color:'#73839C',
        marginBottom:theme.spacing(5),
    },
    
}));

const data = {
    portada:{
        logo:{
            img:false,
            src:GLOBAL.assetsURL+'/images/cultura/logo_cultura.png',
            title:'SECRETARIA DE CULTURA'
        },
        background:GLOBAL.assetsURL+'/images/cultura/bg_portada_cultura.jpg',
        title:'LOREM IPSUM DOLOR SIT AMET',
        description:[
            'La Secretaria de Cultura y Turismo tiene como objetivo Planificar, organizar, dirigir y evaluar la ejecución de políticas, estratégicas, planes, programas, proyectos y actividades de promoción, fomento, control, regulación, desarrollo, fortalecimiento y difusión de la Cultura y el Turismo; relacionado con el desarrollo integral y sustentable de las Artes, el fortalecimiento de las relaciones intra e interculturales y la Gestión del Patrimonio Cultural y con el mejoramiento del Patrimonio, infraestructura, equipamiento, atractivos, Servicios y Destinos Turísticos del Gobierno Autónomo Municipal de Cochabamba',
            'Ad officia minim velit ullamco. Fugiat aliquip ad non sit sunt commodo incididunt in laborum officia deserunt non nostrud. Nulla ut Lorem consequat in consequat et commodo esse. Occaecat anim excepteur magna sint tempor incididunt cupidatat amet ea occaecat quis do esse. Reprehenderit duis fugiat anim aliquip anim incididunt occaecat sunt. Ipsum adipisicing ullamco nisi adipisicing ex laboris. Ullamco magna sint fugiat labore veniam irure reprehenderit nisi.'
        ]
    },
    espaciosculturales:{
        title:'ESPACIOS CULTURALES',
        items:[
            {
                id:1,
                title:'CASA MUSEO MARTÍN CÁRDENAS',
                background:GLOBAL.assetsURL+'/images/cultura/museo_martin_cardenas/martin_cardenas1.jpg',
                btnlabel:'CONOCER MÁS',
                description:`
                    <p>La casa museo fue la vivienda del investigador botánico y entomólogo más importante del país. Dr. Martín Cárdenas Hermosa.</p>
                    <p>Este notable científico boliviano nació en 1899, fue científico reconocido nacional e internacionalmente, graduado como profesor en Ciencias Naturales. Falleció en 1973.</p>
                    <p>En este magnífico espacio, encontramos más de mil libros sobre botánica, de permanente consulta; existen documentos que reflejan sus expediciones botánicas por todo el territorio nacional, donde recolectó especímenes de la flora, de la cual llegó a reunir 6.500 ejemplares.</p>
                    <p>
                        <strong>Dirección:</strong>Av. Heroínas O-266 entre c. Junín y c. Hamiraya
                    </p>
                    <p>
                        <strong>Horarios de atención:</strong> Lunes a viernes: 08:00 - 12:00 14:30 - 14:30
                    </p>                    
                `,
                images:[
                    GLOBAL.assetsURL+'/images/cultura/museo_martin_cardenas/martin_cardenas1.jpg',
                    GLOBAL.assetsURL+'/images/cultura/museo_martin_cardenas/martin_cardenas2.jpg',
                    GLOBAL.assetsURL+'/images/cultura/museo_martin_cardenas/martin_cardenas3.jpg',
                    GLOBAL.assetsURL+'/images/cultura/museo_martin_cardenas/martin_cardenas4.jpg',
                ]
            },
            {
                id:2,
                title:'CASONA SANTIVAÑEZ',
                background:GLOBAL.assetsURL+'/images/cultura/casona_santivanez/santivanez.jpg',
                btnlabel:'CONOCER MÁS',
                description:`
                    <p>Su construcción se remonta a los siglos XV – XVI (1770 – 1845). La edificación es originalmente de estilo colonial y posteriormente de estilo republicano. Es uno de los referentes más importantes de Cochabamba, como expresión de su identidad cultural mestiza y valluna.</p>
                    <p>El edificio se restauró entre 1997 y 1999 y hoy es una de las muestras arquitectónicas más importantes de la época colonial. Entre sus salas se encuentra: La Pinacoteca, el Salón de Banquetes, el Salón de Recepciones, la Sala VIP de estilo republicano, la Sala Museo de Escritores “Augusto Guzmán”, el Salón de Mapas y Escudos de las batallas de la Independencia de Cochabamba y el Salón del Artista Gildardo Antezana.</p>                    
                    <p>
                        <strong>Dirección:</strong> La Casona Santivañez, está ubicada en la calle Santivañez, entre Junín y Av. Ayacucho, Nº E-156.
                    </p>                    
                `,
                images:[
                    GLOBAL.assetsURL+'/images/cultura/casona_santivanez/santivanez.jpg',
                    GLOBAL.assetsURL+'/images/cultura/casona_santivanez/santivanez1.jpg',                    
                ]
            },
            {
                id:3,
                title:'CASA DE LA CULTURA-SALÓN DE EXPOSICIONES MARIO UNZUETA',
                background:GLOBAL.assetsURL+'/images/cultura/salon_exposicions_mario_unzueta/img1.jpg',
                btnlabel:'CONOCER MÁS',
                description:`
                    <p>Lleva el nombre del reconocido novelista, poeta, pintor y periodista, Mario Unzueta Urquidi, notable cochabambino, que fue  director de la Escuela de Bellas Artes. El Salón recibe exposiciones de forma permanente, además es espacio de  Conversatorios artísticos. Está ubicado en la Casa de la Cultura, planta baja, sobre la calle 25 de Mayo, casi esquina Av. Heroínas.</p>                   
                    <p>
                        <strong>Dirección:</strong> c. 25 de Mayo esq. Av. Heroínas
                    </p>                                  
                `,
                images:[
                    GLOBAL.assetsURL+'/images/cultura/salon_exposicions_mario_unzueta/img1.jpg',
                    GLOBAL.assetsURL+'/images/cultura/salon_exposicions_mario_unzueta/img2.jpg',
                    GLOBAL.assetsURL+'/images/cultura/salon_exposicions_mario_unzueta/img3.jpg',
                    GLOBAL.assetsURL+'/images/cultura/salon_exposicions_mario_unzueta/img4.jpg',
                ]
            },
            {
                id:4,
                title:'SALÓN DE EXPOSICIONES GILDARO ANTEZANA',
                background:GLOBAL.assetsURL+'/images/cultura/salon_exposiciones_gildaro_antezana/img1.jpg',
                btnlabel:'CONOCER MÁS',
                description:`
                    <p>Es el más importante espacio municipal de exposiciones transitorias para las artes plásticas, presentadas por artistas nacionales o extranjeros. Su nombre se debe al artista plástico cochabambino, más brillante del S. XX, Gildaro Antezana (1938 – 1976). Se encuentra ubicado en la Plaza Principal 14 de Septiembre, esq. España. Telf. (591-4) 4258045.</p>                    
                    <p>
                        <strong>Dirección:</strong> Plaza Principal, esq. C. España
                    </p>                    
                `,
                images:[
                    GLOBAL.assetsURL+'/images/cultura/salon_exposicions_mario_unzueta/img1.jpg',
                    GLOBAL.assetsURL+'/images/cultura/salon_exposicions_mario_unzueta/img2.jpg',
                    GLOBAL.assetsURL+'/images/cultura/salon_exposicions_mario_unzueta/img3.jpg',
                    GLOBAL.assetsURL+'/images/cultura/salon_exposicions_mario_unzueta/img4.jpg',
                    GLOBAL.assetsURL+'/images/cultura/salon_exposicions_mario_unzueta/img5.jpg',
                    GLOBAL.assetsURL+'/images/cultura/salon_exposicions_mario_unzueta/img6.jpg',
                    GLOBAL.assetsURL+'/images/cultura/salon_exposicions_mario_unzueta/img7.jpg',
                ]
            },
            {
                id:5,
                title:'TEATRO ACHÁ',
                background:GLOBAL.assetsURL+'/images/cultura/teatro_acha/teatro_acha.jpg',
                btnlabel:'CONOCER MÁS',
                description:`
                    <p>Considerado como el escenario más representativo de Cochabamba. Fue la bóveda principal del antiguo convento de la Orden sé San Agustín, hasta 1826. Fue adecuado para convertirse en Teatro, siendo inaugurado en 1864, bajo el nombre del Presidente boliviano de entonces, José María de Achá, quien destino la edificación para actividades del arte y la cultura, misión que continúa cumpliendo hasta hoy. El Teatro Achá, es el lugar emblemático de Cochabamba, plagado de historia, enigmas, voces y pasos cansados que permanecen latentes en el tiempo.</p>
                    <p>
                        <strong>Dirección:</strong> Está ubicado en la calle España, entre Av. Heroínas y calle Bolívar. Telf. (591-4) 4258054
                    </p>                    
                `,
                images:[
                    GLOBAL.assetsURL+'/images/cultura/teatro_acha/teatro_acha.jpg'
                ]
            },
            {
                id:6,
                title:'CASONA MAYORASGO',
                background:GLOBAL.assetsURL+'/images/cultura/casona_mayorasgo/img1.jpg',
                btnlabel:'CONOCER MÁS',
                description:`
                    <p>La Casona Mayorazgo fue edificada durante la época del Virrey Toledo en 1571. Su diseño arquitectónico de estilo colonial – republicano data de los siglos XVI y XVII. Fue declarada Patrimonio Local del Departamento de Cochabamba, por el Concejo Municipal, bajo la Ordenanza Municipal Nº 3484, el año 2005.</p>                    
                    <p>
                        <strong>Dirección:</strong> Esta hermosa infraestructura, está ubicada en la Av. Simón López, esquina Av. Melchor Pérez de Olguín.
                    </p>                    
                `,
                images:[
                    GLOBAL.assetsURL+'/images/cultura/casona_mayorasgo/img1.jpg',
                    GLOBAL.assetsURL+'/images/cultura/casona_mayorasgo/img2.jpg',
                    GLOBAL.assetsURL+'/images/cultura/casona_mayorasgo/img3.jpg',
                    GLOBAL.assetsURL+'/images/cultura/casona_mayorasgo/img4.jpg',
                    GLOBAL.assetsURL+'/images/cultura/casona_mayorasgo/img5.jpg',
                    GLOBAL.assetsURL+'/images/cultura/casona_mayorasgo/img6.jpg',
                    GLOBAL.assetsURL+'/images/cultura/casona_mayorasgo/img7.jpg',
                    GLOBAL.assetsURL+'/images/cultura/casona_mayorasgo/img8.jpg',
                    GLOBAL.assetsURL+'/images/cultura/casona_mayorasgo/img9.jpg',
                ]
            },
            {
                id:7,
                title:'TEATRO ADELA ZAMUDIO',
                background:GLOBAL.assetsURL+'/images/cultura/santa_teresa.jpg',
                btnlabel:'CONOCER MÁS',
                description:`
                    <p>Dividido en dos niveles. Es escenario para actividades culturales como festivales nacionales e internacionales de música, presentaciones de baile, ciclos cinematográficos, obras de teatro, actuaciones estelares, conciertos folklóricos, eventos históricos y otros.</p>
                `,
                images:[
                    GLOBAL.assetsURL+'/images/cultura/santa_teresa.jpg',
                ]
            },
            {
                id:8,
                title:'MUSEO ARQUEOLÓGICO UMSS',
                background:GLOBAL.assetsURL+'/images/cultura/museo_arqueologico_umss/umss1.jpg',
                btnlabel:'CONOCER MÁS',
                description:`
                    <p>Fundado en 1951 por el Prof. Dick Edgar Ibarra Grasso, originalmente como Museo Arqueológico y Etnográfico de la UMSS. En este sitio se creó la primera Escuela de Antropología y Arqueología de Bolivia, y el tercero en Latinoamérica. Cuenta con 3 salas en el que exposiciones temporales de diversas temáticas, programas y eventos culturales y académicos.se exhiben material paleontológico, arqueológico y etnográfico; presentan además</p>                    
                    <p>
                        <strong>Dirección:</strong> c. Jordán E-199 esq. C. Nataniel Aguirre
                    </p>
                    <p>
                        <strong>Horarios de atención:</strong> Lunes a Viernes: 08:00 - 18:00
                    </p>                    
                `,
                images:[
                    GLOBAL.assetsURL+'/images/cultura/museo_arqueologico_umss/umss1.jpg',
                    GLOBAL.assetsURL+'/images/cultura/museo_arqueologico_umss/umss2.jpg',
                    GLOBAL.assetsURL+'/images/cultura/museo_arqueologico_umss/umss3.jpg',
                ]
            },
            {
                id:9,
                title:'MUSEO DE HISTORIA NATURAL “ALCIDES DORBIGNY”',
                background:GLOBAL.assetsURL+'/images/cultura/museo_natural_alcides_dorbigny/alcides_dorbigni1.jpg',
                btnlabel:'CONOCER MÁS',
                description:`
                    <p>Fue inaugurado el 27 de enero del 2003, luego de 16 años de gestiones para su creación por la Fundación para las Ciencias, se ha convertido en un referente científico para la investigación, conservación y difusión de las ciencias naturales de Bolivia. Cuenta con más de 34 mil piezas distribuidas en áreas de vertebrados, invertebrados y restos vegetales. Una colección de esqueletos fósiles y vegetales que datan aproximadamente de entre 63 a 470 millones de años.</p>                    
                    <p>
                        <strong>Dirección:</strong> Av. Potosí #1458
                    </p>
                `,
                images:[
                    GLOBAL.assetsURL+'/images/cultura/museo_natural_alcides_dorbigny/alcides_dorbigni1.jpg',
                    GLOBAL.assetsURL+'/images/cultura/museo_natural_alcides_dorbigny/alcides_dorbigni2.jpg',
                ]
            },
            {
                id:10,
                title:'PALACIO PORTALES SIMÓN I. PATIÑO',
                background:GLOBAL.assetsURL+'/images/cultura/museo_simon_patino/portales.jpg',
                btnlabel:'CONOCER MÁS',
                description:`
                    <p>El palacio Portales fue construido entre 1915 y 1927, desde 1968 está bajo la custodia del Centro Pedagógico y Cultural Simón I. Patiño. De estilo arquitectónico ecléctico y estética de inicios del siglo XX, el Palacio Portales tiene una variedad de estilos en su construcción, destaca el renacentista en la fachada norte, con un hall neoclásico. Sobresalen los jardines realizados por expertos japoneses, y en su interior presenta una serie de muebles antiguos, lámparas y arañas, así como imponentes estufas de mármol de Carrara.</p>                    
                    <p>
                        <strong>Dirección:</strong> Av. Potosí #1450 Teléfono: 4489666
                    </p>                    
                `,
                images:[
                    GLOBAL.assetsURL+'/images/cultura/museo_simon_patino/portales.jpg',
                ]
            },
            {
                id:11,
                title:'SALÓN DE EXPOSICIONES WALTER TERRAZAS',
                background:GLOBAL.assetsURL+'/images/cultura/convento_santa_teresa/santa_teresa.jpg',
                btnlabel:'CONOCER MÁS',
                description:`
                    <p>El salón “Walter Terrazas” es un recinto de arte contemporáneo que abre sus puertas a artistas plásticos, escultores, y pintores que trabajan con distintos materiales, siendo un espacio para exposiciones, concursos y charlas involucradas al arte y la cultura. La sede y salón de exposiciones de Arte lleva el nombre del reconocido artista plástico Walter Terrazas Pardo (1928 -1989), por el diseño en reconocimiento a la trayectoria y aporte de este pintor y escultor prestigioso, conocido por la población de la escultura del Cristo de la Concordia. La responsable de este salón es el ABAP Cochabamba.</p>                    
                    <p>
                        <strong>Dirección:</strong> Av. Heroínas #284 esq. C. España
                    </p>                    
                `,
                images:[
                    GLOBAL.assetsURL+'/images/cultura/convento_santa_teresa/santa_teresa.jpg',
                    GLOBAL.assetsURL+'/images/cultura/convento_santa_teresa/santa_teresa1.jpg',
                ]
            },
        ]
    },    
    patrimoniocultural2:{
        title:'PATRIMONIO CULTURAL',
        items:[
            {
                id:1,
                title:'ARCHIVO HISTÓRICO MUNICIPAL',
                background:GLOBAL.assetsURL+'/images/cultura/museo_martin_cardenas/martin_cardenas1.jpg',
                btnlabel:'CONOCER MÁS',
                description:`
                    <p>Todos tenemos derecho a heredar el patrimonio cultural de nuestros predecesores y al mismo tiempo tenemos la obligación de conservarla, de asegurar su protección para las gestiones futuras. Los diferentes documentos que resguardan en el Archivo Histórico y Hemeroteca Municipales son parte de nuestro patrimonio cultural histórico y documental.</p>
                    <p>Actualmente, el Archivo Histórico y Hemeroteca Municipal, aplica métodos de inventario y catalogación de los documentos, es responsable de la preservación de la base documental, y ofrece sus servicios  de atención y asesoramiento a la población en general, y especialmente a estudiantes, tesistas, profesionales, e investigadores.</p>                    
                    <p>
                        <strong>Dirección:</strong> Av. Heroínas esquina 25 de Mayo, tercer piso
                    </p>
                    <p>
                        <strong>Horarios de atención:</strong> 08:00 – 12:00 y 14:30 – 18:30
                    </p>                    
                `,
                images:null
            },
            {
                id:2,
                title:'BIBLIOTECA MUNICIPAL “JESÚS LARA”',
                background:GLOBAL.assetsURL+'/images/cultura/museo_martin_cardenas/martin_cardenas1.jpg',
                btnlabel:'CONOCER MÁS',
                description:`
                    <p>Constituye uno de los más importantes centros de orientación y difusión cultural en nuestra ciudad, donde se encuentran fondos bibliográficos de valor histórico y educativo.</p>
                    <p>La biblioteca fue fundada el año 1844 por Don Melchor Urquidi (1807-1864), conteniendo 2.000 ejemplares bibliográficos. Estaba ubicada en la parte derecha del templo San Francisco. En la actualidad, la Biblioteca Municipal Jesús Lara cuenta con un Sistema de Bibliotecas Zonales ubicadas en los diferentes distritos municipales de Cochabamba.</p>
                `,
                images:null
            },
            {
                id:3,
                title:'CATEDRAL METROPOLITANA',
                background:GLOBAL.assetsURL+'/images/cultura/museo_martin_cardenas/martin_cardenas1.jpg',
                btnlabel:'CONOCER MÁS',
                description:`
                    <p>El año 1542, se erigió una pequeña iglesia construida de adobe y piedra de un solo cañón hasta 1616. Las investigaciones realizadas dan a conocer que antiguamente era un cementerio. Sobre la base de la capilla, se edificó la actual iglesia en 1701 y se concluye en 1735. Posteriormente pasa a rango de catedral Metropolitana en 1975.</p>
                    <p>La Catedral está ubicada en la Plaza 14 de Septiembre.</p>                    
                    <p>
                        <strong>Dirección:</strong> Plaza 14 de Septiembre.
                    </p>                    
                `,
                images:null
            },
            {
                id:4,
                title:'HOSPICIO',
                background:GLOBAL.assetsURL+'/images/cultura/museo_martin_cardenas/martin_cardenas1.jpg',
                btnlabel:'CONOCER MÁS',
                description:`
                    <p>La construcción del templo del Hospicio tardó 16 años, se inició en 1859 y se concluyó en 1875, obra del arquitecto José Roserri, construida enteramente de adobe, en los arcos se utilizó piedra y ladrillo de estilo republicano, se construyó con aportes de la población cochabambina y de los centros mineros donde acudían los sacerdotes franciscanos a pedir contribuciones.</p>                    
                    <p>
                        <strong>Dirección:</strong> calle Mayor Rocha y Plaza Colón.
                    </p>                    
                `,
                images:null
            },
            {
                id:5,
                title:'TEMPLO Y CONVENTO SANTA TERESA',
                background:GLOBAL.assetsURL+'/images/cultura/museo_martin_cardenas/martin_cardenas1.jpg',
                btnlabel:'CONOCER MÁS',
                description:`
                    <p>Es probablemente la mejor construcción religiosa de Cochabamba, es una conjunción de dos iglesias, una dentro la otra; la primera en fundó en 1753, la segunda en 1792. En los claustros se custodian obras de gran valor, las características de su estado inconcluso, dan un aspecto de fortaleza medieval.</p>
                    <p>
                        <strong>Dirección:</strong> calle Baptista # 0344 casi Ecuador.
                    </p>
                `,
                images:null
            },
        ]
    }    
}

const expresionesArtisticasCulturalesData = {
    title:'Visite alguna de nuestras secciones',
    items:[
        {
            title:'BIBLIOTECA VIRTUAL',
            imgBackground:`/images/cultura/biblioteca-virtual.jpg`,
            btnLabel:"Visitar",
            disabled:false,
            link:{
                text:'',
                type:'interno',
                url:'/unidad/secretaria-desarrollo-productivo-turismo-cultura-biblioteca-virtual'
            }
            
        },
        {
            title:'CONCURSOS MUNICIPALES',
            imgBackground:`/images/cultura/consursos-municipales.jpg`,
            btnLabel:"Visitar",
            disabled:false,
            link:{
                text:'',
                type:'interno',
                url:'/unidad/secretaria-desarrollo-productivo-turismo-cultura-concursos-municipales'
            }            
        }
    ]
};
const concursosMunicipalesData = {
    title:'CONCURSOS MUNICIPALES',
    items:[
        {
            title:'CONCURSOS MUNICIPALES',
            imgBackground:`${GLOBAL.assetsURL}/images/cultura/consursos-municipales.jpg`,
            btnLabel:null,
            disabled:false,
            link:{
                text:'',
                type:'interno',
                url:'/unidad/secretaria-desarrollo-productivo-turismo-cultura-concursos-municipales'
            }            
        },        
    ]
};

function Cultura(){
    const childRef = useRef();
    const [currentData,setCurrentData] = React.useState(null);
    const classes = useStyles();

    const onHandleClick = (id) => {
        const auxItem = data.espaciosculturales.items.find(element => element.id==id);
        setCurrentData(auxItem);
        childRef.current.showDialog();
    }
    const onHandleClick2 = (id) => {
        const auxItem = data.patrimoniocultural2.items.find(element => element.id==id);
        setCurrentData(auxItem);
        childRef.current.showDialog();
    }
    useEffect(() => {
       window.scrollTo(0, 0);
    }, []);
    return(
        <React.Fragment>                  
            <CardContainerComponent title={expresionesArtisticasCulturalesData.title} items={expresionesArtisticasCulturalesData.items}/>                        
            <br/>
            <CardContainerWithTitle data={data.espaciosculturales} onHandleClick={onHandleClick}/>
            {/* <TitlebarGridList/> */}
            
            <ModalWithMethodToCall ref={childRef} title="ESPACIOS CULTURALES" body={<CardWithTitleImgDesc img={currentData?currentData.background:null} images={currentData?currentData.images:null} title={currentData?currentData.title:null} body={currentData?currentData.description:null}/>}/>
            {/* <FotoInformation2 data={data.patrimoniocultural}/> */}            
            <CardContainerWithTitle data={data.patrimoniocultural2} onHandleClick={onHandleClick2}/>
        </React.Fragment>
    );
}
export default Cultura;
