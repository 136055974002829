import React from 'react';
import { withRouter } from 'react-router-dom';
import {Request} from 'react-axios';
import { Typography,Grid,Box, Card,Button } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {Carousel} from 'react-responsive-carousel';
import ErrorRequestComponent from '../genericos/error/ErrorRequestComponent';
import Alert from '@material-ui/lab/Alert';

import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        //paddingRight: theme.spacing(1),
        //paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(10),
    },
    customPaddingCard:{
        padding:theme.spacing(3)
    }
}));

const EmprendedurismoDetalleComponent = (props) => {    
    const { data=null } = props;
    const classes = useStyles();
    const { encrypted_id } = useParams();
    
    let id_productor;
    
    id_productor = encrypted_id?atob(encrypted_id):0;
    
    return (
        <>
            <Grid container justify="center" className={classes.root}>
                <Grid item xs={12} sm={11} md={8} lg={8} xl={8}>
                    <Card className={classes.customPaddingCard}>
                        <Request url="https://produce.cochabamba.bo/utils/request/getProductos" method="get" params={{ id: id_productor }}>
                            {(error, response, isLoading, makeRequest, axios) => {
                            if (error) {
                                return (<ErrorRequestComponent variante="standard" handleClick={() => makeRequest({ params: { reload: true } })}/>)
                            }
                            else if (isLoading) {
                                return (
                                    <Box>
                                        <Grid container justify="center" className={classes.root}>
                                            <Grid item xs={12} sm={11} md={6} lg={6} xl={6}>
                                                <Typography variant="h6">
                                                    <span style={{fontWeight:'bold',backgroundColor:'#D7CBC5',padding:'5px 3px'}}>DATOS GENERALES</span><br/>                                    
                                                </Typography>
                                                <br/>
                                                <Typography>
                                                    <span style={{fontWeight:'bold'}}>RUBRO:</span> <Skeleton width="100%"/>
                                                </Typography>
                                                <br/>                                
                                                <Typography>
                                                    <span style={{fontWeight:'bold'}}>PROPIETARIO / REPRESENTANTE:</span> <Skeleton width="100%"/>
                                                </Typography>
                                                <Typography>
                                                    <span style={{fontWeight:'bold'}}>DIRECCIÓN:</span> <Skeleton width="100%"/>
                                                </Typography>
                                                <Typography>
                                                    <span style={{fontWeight:'bold'}}>TELÉFONO:</span> <Skeleton width="100%"/>
                                                </Typography>
                                                <Typography>
                                                    <span style={{fontWeight:'bold'}}>CORREO ELECTRÓNICO:</span> <Skeleton width="100%"/>
                                                </Typography>
                                                <Typography>
                                                    <span style={{fontWeight:'bold'}}>PRODUCTOS:</span><br/>
                                                    <Skeleton width="150px"/>
                                                    <Skeleton width="150px"/>
                                                    <Skeleton width="150px"/>
                                                </Typography>
                                                <br/>                
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                <Skeleton width="100%" height="450px"/>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Skeleton width="100%" height="450px"/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )
                            }
                            else if (response !== null) {
                                return (
                                <>
                                    {
                                    response.data && response.data.productor?
                                    <Box>
                                        <Grid container>
                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                <Typography variant="h6">
                                                    <span style={{fontWeight:'bold',backgroundColor:'#D7CBC5',padding:'5px 3px'}}>DATOS GENERALES: {response.data.productor.nombre_productor}</span><br/>
                                                </Typography>
                                                <br/>                                   
                                                <Typography>
                                                    <span style={{fontWeight:'bold'}}>RUBRO:</span> <span>{response.data.productor.descripcion}</span>
                                                </Typography>
                                                <br/>                                
                                                <Typography>
                                                    <span style={{fontWeight:'bold'}}>PROPIETARIO / REPRESENTANTE:</span> <span>{response.data.productor.representante}</span>
                                                </Typography>
                                                <br/>
                                                <Typography>
                                                    <span style={{fontWeight:'bold'}}>DIRECCIÓN:</span> <span>{response.data.productor.direccion}</span>
                                                </Typography>
                                                <br/>
                                                <Typography>
                                                    <span style={{fontWeight:'bold'}}>TELÉFONO:</span> <span>{response.data.productor.contacto}</span>
                                                </Typography>
                                                <br/>
                                                <Typography>
                                                    <span style={{fontWeight:'bold'}}>CORREO ELECTRÓNICO:</span> <span>{response.data.productor.correo_electronico}</span>
                                                </Typography>
                                                <br/>
                                                <Typography>
                                                    <span style={{fontWeight:'bold'}}>PRODUCTOS:</span><br/>
                                                </Typography>
                                                {
                                                    response.data.productos?
                                                    <ul>
                                                        {
                                                        response.data.productos?.map((item,index)=>(
                                                            <li key={'cat'+index}>{item.detalle}</li>
                                                        ))
                                                        }
                                                    </ul>:null
                                                }                                
                                                <br/>                
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                {
                                                    response.data.fotos && response.data.fotos.length>0?
                                                    <Carousel centerMode centerSlidePercentage={100} emulateTouch infiniteLoop useKeyboardArrows autoPlay showThumbs={true} stopOnHover interval={5000}>
                                                        {
                                                            response.data.fotos.map((item,index)=>(
                                                                <div key={'img'+index}>
                                                                    <img src={item.imagen} alt={item.descripcion}/>
                                                                    <p className="legend">{item.descripcion}</p>
                                                                </div>
                                                            ))
                                                        }
                                                    </Carousel>:null
                                                }
                                            </Grid>                      
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                {response.data.productor && response.data.productor.latitud && response.data.productor.longitud?
                                                    <Map center={[response.data.productor.latitud,response.data.productor.longitud]} zoom={18}>
                                                        <TileLayer
                                                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                        />
                                                        <Marker position={[response.data.productor.latitud,response.data.productor.longitud]}>
                                                            <Popup>
                                                                {response.data.productor.nombre_productor}
                                                            </Popup>
                                                        </Marker>
                                                    </Map>:<Alert severity="error">Sin ubicación</Alert>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Box>:
                                    <Alert severity="info">Sin información</Alert>
                                    }
                                </>
                                )
                            }
                            return (<div>Default message before request is made.</div>)
                            }}
                        </Request>                        
                        <Box px={1} pt={1}>
                            <Button variant='contained' size="large" color="secondary" onClick={() => props.history.push(`/unidad/${data.fatherSlug}`)}>
                                VOLVER A LA LISTA
                            </Button>
                        </Box>                
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

export default withRouter(EmprendedurismoDetalleComponent);