import React from 'react';
import { Parallax } from 'react-parallax';
import { makeStyles } from '@material-ui/core/styles';
import { Typography,Grid, Box } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { GLOBAL } from '../../../../global';

const useStyles = makeStyles(theme => ({
    root:{
        flexGrow:1,        
    },
    customPadding:{
        padding:theme.spacing(8,5),        
        minHeight:'95vh',
    },
    customImgSize:{
        maxWidth:150
    },
    subtitle:{
        color:'white',
        textAlign:'right',
        marginBottom:theme.spacing(3),
        opacity:1
    },
    customColorText:{
        color:'white',
        //maxWidth:'400px',
        //textAlign:'right',
        //padding:theme.spacing(3,3)
    },
    customContainerPadding:{
        color:'white',
        //padding:theme.spacing(0,2)
    },
    customTextContainer:{
        backgroundColor:'rgba(79,185,168,.5)',
        padding:theme.spacing(5,5),
        borderRadius:theme.spacing(2),        
    },
    customTextContainer2:{
        position:'absolute',        
        top:'50%',
        left:'50%',
        transform:'translate(-50%,-50%)',
        backgroundColor:'rgba(79,185,168,.5)',
        padding:theme.spacing(2,1),
        borderRadius:theme.spacing(1),
        color:'white',
        textAlign:'center',
        width:'90%'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    customPhoneContainer:{
        position:'relative',
        textAlign:'center'        
    }
}));

/*
const data = {
    "title": "NOTICIAS",
    "subtitle": "Alcaldía de Cochabamba",
    "description": null,
    "background": "/img/secciones/noticias/portada.jpeg",
    "logo": {
        "title": "",
        "img": null
    }
}
*/

function CustomPortadaComponent(props){
    const matches = useMediaQuery('(min-width:1024px)');
    const {descriptionstyle={}} = props;
    const bg = props.data.background;
    const imguc = props.data.logo && props.data.logo.img?props.data.logo.src:null;

    const classes = useStyles();

    return(
        <div className={classes.root}>
            {
                matches?
                <Parallax
                    //blur={1}
                    bgImage={`${GLOBAL.assetsBack}${bg}`}
                    bgImageAlt="Fondo Portada"
                    strength={200}
                    >
                    <Grid container className={classes.customPadding} justify="flex-end">
                        <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
                            {
                                props.data.title || props.data.subtitle?
                                <Box style={{height:'100%'}} display='flex' alignItems='center'>
                                    <Box className={classes.customTextContainer}>
                                        <Box align="right">
                                            {
                                                props.data.logo && props.data.logo.img?<img src={`${GLOBAL.assetsBack}${imguc}`} className={classes.customImgSize}/>:null
                                            }
                                            <Typography variant="h3" component="h3" className={classes.customContainerPadding}>{props.data.title}</Typography>
                                        </Box>
                                        <Typography variant='h4' className={classes.subtitle}>
                                            {props.data.subtitle}
                                        </Typography>
                                        {
                                            props.data.description?.map((d,index)=>(
                                                <Typography variant="h6" key={index} className={classes.customColorText} align="justify" style={descriptionstyle}>
                                                    {d}
                                                </Typography>
                                            ))
                                        }
                                    </Box>
                                </Box>:null
                            }
                        </Grid>
                    </Grid>
                </Parallax>:
                <Box className={classes.customPhoneContainer}>
                    <CardMedia
                        className={classes.media}
                        image={`${GLOBAL.assetsBack}${bg}`}
                        title="Portada"
                    />
                    {
                        props.data.title || props.data.subtitle?
                        <Box className={classes.customTextContainer2}>
                            <Typography variant="h4" component="h4" align="center">{props.data.title}</Typography>                        
                            <Typography variant='h5' align="center">
                                {props.data.subtitle}
                            </Typography>
                        </Box>:null
                    }
                </Box>
            }
        </div>
    );
}

export default CustomPortadaComponent;