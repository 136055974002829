import React from 'react';
import {Typography,Grid,makeStyles} from '@material-ui/core';
import {GLOBAL} from '../../../global';

const useStyles = makeStyles(theme => ({
    customAppBgColor:{
        padding:theme.spacing(5,0),
        backgroundColor:'#F2F2F2'
    },
    iframeStyle:{
      border:'0px'
    }
}));

function ConsultasEnLineaView(){
    const classes = useStyles();    
    return(
        <div>            
            <Grid container className={classes.customAppBgColor}>
                <Grid item xs={12} sm={12} md={1} lg={2} xl={3}></Grid>
                  <Grid item xs={12} sm={12} md={10} lg={8} xl={6}>
                    <Typography variant="h4" align="center">Realiza consultas acerca de deudas de actividades económicas, infracciones de Movilidad Urbana e otros</Typography>
                    <br/>                    
                  </Grid>
                  <Grid item xs={12} sm={12} md={1} lg={2} xl={3}></Grid>
            </Grid>
        </div>
    );
}

export default ConsultasEnLineaView;