import React, { useRef } from 'react';
import QRCode from "react-qr-code";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import { withRouter } from 'react-router-dom';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Divider from '@material-ui/core/Divider';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import CategoryIcon from '@material-ui/icons/Category';
import { useScreenshot } from 'use-react-screenshot';

import Helper from '../../../../Helper';


const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "100%",
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  customAction:{
      marginBottom:theme.spacing(3)
  }
}));

const CardEmprendedurismo2 = (props) => {
    const { data,loading=false } = props;
    const canvasRef = useRef();
    const classes = useStyles();
    
    const [image, takeScreenshot] = useScreenshot();
        
    const handleCanvas = () => {
        let s = new XMLSerializer().serializeToString(canvasRef.current);
        let encodedData = btoa(s);
        props.onHandleChange(props.data,encodedData);
    };

    return (
        <Card className={classes.root}>
                {
                    loading?
                    <Skeleton height="200px"/>:
                    <CardMedia
                        className={classes.media}
                        image={data && data.fotito?data.fotito:'http://sandbox.cochabamba.bo/productores/uploads/FuIW8706SEzKUvZD.jpg'}
                        title="Imagen principal"
                    />
                }
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {loading?<Skeleton/>:data.nombre_productor}
                    </Typography>

                    <Divider className={classes.customDivider} />
                    <List>
                        <ListItem aria-label="delete">
                            <ListItemIcon>
                                <PersonIcon className={classes.customIconColor} />
                            </ListItemIcon>
                            <ListItemText primary="Representante" secondary={loading ? <Skeleton /> : data.representante}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CategoryIcon className={classes.customIconColor} />
                            </ListItemIcon>
                            <ListItemText primary="Rubro" secondary={loading ? <Skeleton /> : data.descripcion}/>
                        </ListItem>
                        
                        <ListItem>
                            <ListItemIcon>
                                <PhoneIcon className={classes.customIconColor} />
                            </ListItemIcon>
                            <ListItemText primary="Contactos" secondary={loading ? <Skeleton /> : data.contacto}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <EmailIcon className={classes.customIconColor} />
                            </ListItemIcon>
                            <ListItemText primary="Email" secondary={loading ? <Skeleton /> : data.correo_electronico}/>
                        </ListItem>
                    </List>
                </CardContent>
            
            
            <Box alignContent="center" textAlign="center" className={classes.customAction}>
                <Divider/>                
                <Box>
                    {
                        loading?
                        <Skeleton/>:
                        <QRCode ref={canvasRef} value={`${Helper.getDomainUrl()}/unidad/desarrollo-productivo-detalle/${btoa(props.data.id_productor)}/show`} style={{maxWidth:'200px'}}/>
                        
                    }
                </Box>
                <Divider/>
                <br />
                <Box px={1}>
                    {
                        loading?
                        <Skeleton/>:
                        <Button variant='contained' size="large" color="secondary" onClick={() => props.history.push(`/unidad/desarrollo-productivo-detalle/${btoa(props.data.id_productor)}/show`)} fullWidth>MÁS INFORMACIÓN</Button>
                    }
                </Box>
                <br/>
                <Box px={1}>
                    {
                        loading?
                        <Skeleton/>:
                        <Button variant='contained' size="large" color="primary" onClick={() => handleCanvas()} fullWidth>Generar tarjeta</Button>
                    }
                </Box>                
            </Box>
        </Card>
    );
}

export default withRouter(CardEmprendedurismo2);