import React,{useRef,useEffect} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Typography,Grid,InputLabel,Button,Box } from '@material-ui/core';
import useAxios from 'axios-hooks';
import Alert from '@material-ui/lab/Alert';

import ModalPdfViewerComponent from '../../../components/genericos/modal/ModalPdfViewerComponent';
import ErrorRequestComponent from '../../../components/genericos/error/ErrorRequestComponent';
import CampoBuscar from '../../../components/genericos/CampoBuscar';
import CardAuditoria from './components/CardAuditoria';
import {GLOBAL} from '../../../global';

const useStyles = makeStyles(theme => ({
    root: {
        padding:theme.spacing(3,1)
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    title:{
        textAlign:'center',
        margin:theme.spacing(3,1),
    },
    customPadding:{
        paddingleft:theme.spacing(2),
        paddingRight:theme.spacing(2),
    },
    table: {
        width: '100%',
    },
    customMargin:{
      marginBottom:theme.spacing(1.5),
    },
    container: {
      maxHeight: 640,      
    },
    customHeigth:{
        minHeight: '90vh',
    },
    customCategoria:{
        fontWeight:'bold',
        backgroundColor:'#F2EAEB',
        padding:theme.spacing(1,1)
    },
    customMarginButton:{
        margin:theme.spacing(3,2),
        minWidth:"200px"
    },
    customTitle:{
        textAlign:'center',
        color:theme.palette.primary.main
    },
    customSubTitle:{
        color:theme.palette.primary.main,
        //paddingBottom:theme.spacing(3),
        textAlign:'justify',
    },
}));

const dataPortada = {
    title:'AUDITORÍA INTERNA',
    subtitle:'',
    description:[
        'La Auditoría Interna es una actividad independiente y objetiva de supervisión, una consultoría diseñada para agregar valor y mejorar las operaciones de una organización. Ayuda a una organización a cumplir sus objetivos aportando un enfoque sistemático y disciplinado para evaluar y mejorar la eficacia de los procesos de gestión de riesgo, control y gobierno.'
    ],
    background:GLOBAL.assetsURL+'/images/auditoria-interna/portada.jpg',
    logo:{
        title:'',
        img:null,
    }    
}




const AuditoriaInternaComponent = (props) => {
    const [{ data, loading, error }, refetch] = useAxios(GLOBAL.apiURL+'/api/auditoria/publicaciones');
    //const [categoria, setCategoria] = React.useState('');
    //const [open, setOpen] = React.useState(false);
    const [txtSearch,setTxtSearch] = React.useState('');
    //const [data2component,setData2component] = React.useState(null);
    const { datos={        
        title:null,
        description:null,
        style:{
            container:null,
            title:null,
            subtitle:null
        },
        data:null
    }} = props;
    
    const childRef = useRef();
    
    const classes = useStyles();   

   

   

    const handleChange = (event) => {
        setTxtSearch(event.target.value);
    }
    const handleClickToClear = () => {
        setTxtSearch('');
    };

    const computedSearchField = (items) => {
        try {            
            return items.filter(element => String(element.name).toUpperCase().indexOf(String(txtSearch).toUpperCase()) != -1 || String(element.description).toUpperCase().indexOf(String(txtSearch).toUpperCase()) != -1);            
        } catch (error) {
            return [];            
        }        
    }
    const onHandleClick = (data) => {
        //console.log("onHandleClick");
        childRef.current.openModal({title:data.name,archivo:GLOBAL.apiURL+'/'+data.file});
    }
    
    useEffect(() => {
       window.scrollTo(0, 0);
    }, []);
    return(
        <Box className={classes.root} style={datos && datos.style && datos.style.container?datos.style.container:null}>
            <ModalPdfViewerComponent btnclose={'Cerrar'} ref={childRef}/>
            <Grid container className={classes.customTabStyles} justify="center">
                <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                    <Box mb={3} px={1}>
                        {
                            datos.title?
                            <Typography className={classes.customTitle} variant="h3" style={datos.style.title}>{datos.title?datos.title:''}</Typography>:null
                        }                            
                        {
                            datos.description?
                            <Typography className={classes.customSubTitle} variant="h6" style={datos.style.subtitle}>{datos.description?datos.description:''}</Typography>:null
                        }
                        
                    </Box>
                </Grid>
            </Grid>            
            <Grid container className={classes.customPadding} style={{paddingBottom:'20px',minHeight:'768px'}}>
                <Grid item xs={12} sm={1} md={2} lg={3} xl={3}></Grid>
                <Grid item xs={12} sm={10} md={8} lg={6} xl={6}>
                    <Grid container className={classes.customPadding}>                        
                        <Grid item xs={12} className={classes.customPadding}>
                            <CampoBuscar
                                placeholder = 'Buscar por nombre'
                                txtSearch = {txtSearch}
                                handleChange = {handleChange}
                                handleClickToClear = {handleClickToClear}/>
                        </Grid>
                    </Grid>
                    <br/>
                    <Grid container className={classes.customPadding} spacing={2}>                        
                            {
                                data ? computedSearchField(data).map((row, index) => (
                                <Grid item key={index} xs={12} sm={12} md={6} lg={6}>
                                    <CardAuditoria data={row} onHandleClick={onHandleClick}/>
                                </Grid>)):null
                            }
                            {
                                loading ? Array.from(new Array(4)).map((row, index) => (
                                <Grid item key={index} xs={12} sm={12} md={6} lg={6}>
                                    <CardAuditoria loading/>
                                </Grid>)) : null
                            }
                            {
                                error ?
                                <ErrorRequestComponent variante="standard" handleClick={() => refetch} /> :
                                data && data.length == 0 ? <Alert severity="info">No hay publicaciones</Alert> : ''
                            }                        
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={1} md={2} lg={3} xl={3}></Grid>
            </Grid>
        </Box>
    );
}
export default AuditoriaInternaComponent;
