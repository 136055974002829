import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {Carousel} from 'react-responsive-carousel';
import { withRouter } from 'react-router-dom';
import {makeStyles,useTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import convert from 'htmr';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {GLOBAL} from '../../../global';
import Helper from '../../../Helper';

const useStyles = makeStyles(theme => ({    
    customTitle:{
        color:'#ffffff',
        //textAlign:'left'
    },
    customDescription:{
        color:'#ffffff',
        //textAlign:'left'
    },
    cutomButtonsContainer:{
        //textAlign:'left'
    },
    customCard:{
        width:'100%',
        padding:theme.spacing(4,6)
    }
}));

const CarouselImgWithTitleAndDescription = (props) => {
    const { data=null } = props;
    const classes = useStyles();
    const theme = useTheme();
    //const matches = useMediaQuery('(min-width:600px)');
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const {primary, secondary} = theme.palette.text;

    const fusionObj = (obj1,obj2) => {
        if(obj1 && obj2){return {...obj1,...obj2};}
        if(obj1 && !obj2){return obj1;}
        if(!obj1 && obj2){return obj2;}
        return {};
    }

    return (
        <Box pt={matches?0:5} style={data && data.mainStyle?{...data.mainStyle}:{}} id='myiframecontainer'>
            <Grid container justify='center'>
                <Grid item xs={12} sm={12} md={10} lg={8} xl={8} className='carousel-outer'>
                    <Carousel centerMode centerSlidePercentage={100} emulateTouch infiniteLoop useKeyboardArrows autoPlay={true} showThumbs={false} stopOnHover interval={5000} dynamicHeight={false} showIndicators={false} showStatus={false}>
                        {
                            data && data.items && Array.isArray(data.items)?data.items.map((item,index) => (
                                <div key={`item${index}`} style={item.containerStyle?{...item.containerStyle}:{}}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <img src={`${GLOBAL.assetsBack}${item.img}`} alt="" />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Card className={classes.customCard} style={matches?{marginLeft:'-3rem'}:null}>
                                                <Box>
                                                    <Typography className={classes.customTitle} style={fusionObj({textAlign:matches?"left":"center"},item.title && item.title.style?item.title.style:null)}>{item.title && item.title.text?item.title.text:null}</Typography>                                                    
                                                    {
                                                        item.description && item.description.text?
                                                        <Box style={fusionObj({textAlign:matches?"left":"center"},item.description && item.description.style?item.description.style:null)}>
                                                            {
                                                                convert(item.description.text)
                                                            }
                                                        </Box>:null
                                                    }
                                                    <Box my={2} className={classes.cutomButtonsContainer} style={{textAlign:matches?"left":"center"}}>
                                                    {
                                                        item.links && Array.isArray(item.links)?item.links.map((link,ind)=>(
                                                            <Button key={`link${ind}`} onClick={()=>Helper.checkLink(props,link)} variant="contained" color={link && link.color?link.color:null}>{link.label}</Button>
                                                        )):null
                                                    }
                                                    </Box>
                                                </Box>
                                            </Card>                                    
                                        </Grid>                                
                                    </Grid>
                                </div>
                            )):null
                        }
                    </Carousel>
                </Grid>
            </Grid>            
        </Box>
    );    
}

export default withRouter(CarouselImgWithTitleAndDescription);