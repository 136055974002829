import React from 'react';
import { Grid } from '@material-ui/core';
import Iframe from 'react-iframe';
import convert from 'htmr';
import { makeStyles,Box,Typography } from '@material-ui/core';
import { Parallax } from 'react-parallax';
import { GLOBAL } from '../../global';

const useStyles = props => makeStyles( theme => ({    
    customIframeStyle:{
        border:props.iframeStyle && props.iframeStyle.border?props.iframeStyle.border:'0px'
    },
    customTitle:{
        textAlign:"left",
        fontWeight:"bold"
    },
    customDescription:{
        textAlign:"justify",
    }
}));

const MyComponent = (props) => {    
    const {data={}} = props;
    const classes = useStyles(data)();    
    return(        
        <Grid container justify="center">
            <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                <Grid container spacing={data.gridSpacing?Number(data.gridSpacing):2}>
                    <Grid item xs={12}>                        
                        {
                            data.textData && data.textData.title && data.textData.title.text?
                            <Typography className={classes.customTitle} style={data.textData.title.style?data.textData.title.style:null}>{data.textData.title.text}</Typography>:null
                        }
                        {
                            data.textData && data.textData.description && data.textData.description.text?
                            <Box className={classes.customDescription} style={data.textData.description.style?data.textData.description.style:null}>
                                {convert(data.textData.description.text)}
                            </Box>:null
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {
                            data.iframe && data.iframe.url?
                            <Iframe url={data.iframe.url}
                                width={data.iframe.iframeprop.width}
                                height={data.iframe.iframeprop.height}
                                id="myId"
                                className={classes.customIframeStyle}
                                display="initial"
                                position="relative">                        
                            </Iframe>:null
                        }                        
                    </Grid>
                </Grid>                    
            </Grid>
        </Grid>        
    );
}

const IframeWidthTitleAndDescription = (props) => {
    const {data={}} = props;    
    return data.parallax.enabled?
    <Parallax blur={data.parallax.blur?data.parallax.blur:0} bgImage={`${GLOBAL.assetsBack}${data.parallax.bgimg}`} bgImageAlt="Background image" strength={data.parallax.strength?data.parallax.strength:0}>
        <MyComponent data={data}/>
    </Parallax>:<div style={data.mainContainerStyle}><MyComponent data={data}/></div>
}

export default IframeWidthTitleAndDescription;