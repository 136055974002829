import React from "react";
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CustomButtonComponent from "../../genericos/buttons/CustomButtonComponent";
import { GLOBAL } from "../../../global";
import "./style.css";

const useStyles = makeStyles(theme => ({
    root:{
        position:'relative',
        textAlign:'left'
    }
}));

const BackgroundImgAndText = () => {
    return (        
        <Box className="container">
            <img src={`${GLOBAL.assetsBack}/img/secciones/home/tramites-gamc.jpg`} width="100%"/>                                     
            <Box className="centered-left">
                <Grid container justify="center">
                    <Grid item xs={12} sm={10} md={8} lg={8}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h3" justify="left">Trámites GAMC</Typography>
                                <Typography align="justify">Trabajamos para convertirnos en un municipio eficiente, transparente y accesible. Nuestro propósito es brindar servicios más efectivos, soluciones eficaces y rápidas.</Typography>
                                <br/>
                                <CustomButtonComponent text="Ingresar"/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>            
        </Box>
    );
};
export default BackgroundImgAndText;