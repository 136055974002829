import React from 'react';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import {makeStyles} from '@material-ui/core';
import { GLOBAL } from '../../global';

const useStyles = makeStyles(theme=>({    
    customFloatingButton:{
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    }
}));

/*
const data = {
    "message": "Tienes alguna duda, consulta o sugerencia? ¡Hablemos!",
    "img": "/img/secciones/operatorIcon.png",
    "color": null,
    "style": null
}
*/

const AsistenteComponent = (props) => {    
    const classes = useStyles();
    const { data={
        message:"Tienes alguna duda, consulta o sugerencia? ¡Hablemos!",
        color:"secondary",
        img:"/img/secciones/operatorIcon.png",
        style:null
    } } = props;

    return(
        <>
            {/* <Tooltip title={<p>{data.message}</p>} arrow open> */}
                <Fab className={classes.customFloatingButton} color={data.color} aria-label="Asistente" style={data.style} onClick={()=>window.open("https://api.whatsapp.com/send?phone=59161561515&text=informaci%C3%B3n%20", "_blank")}>
                    <img src={`${GLOBAL.assetsBack}${data.img}`} style={{maxWidth:'35px'}}/>                    
                </Fab>
            {/* </Tooltip> */}
        </>
    );
}
export default AsistenteComponent;