import React from 'react';
import useAxios from 'axios-hooks';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from "react-hook-form";
import { Grid,Button,TextField,Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Alert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

import {GLOBAL} from '../../global';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding:theme.spacing(10,1),
    },
    customCardContainer:{
        padding:theme.spacing(5,1),
    },
    customWith:{
        width:'100%'
    },
    customFooter:{
        justifyContent: 'center',
        padding:theme.spacing(3,1)
    },    
    customButton:{
        backgroundColor:"#4caf50",
        color:'white',
        '&:hover':{
            backgroundColor:"#28792b",            
        }
    },
    customTableTitle:{
        textAlign:'center',        
        //fontWeight:'bold',
        color:theme.palette.secondary.dark,
        //backgroundColor:theme.palette.tertiary.dark,
    },
    customSectionTitle:{
        backgroundColor:theme.palette.tertiary.main,
        textAlign:'left',
        //fontWeight:'bold',
        color:'white',
        padding:theme.spacing(2,1)
    },
    customTable:{
        width:'100%',
        textAlign:'center'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width:"100%"
    },
    dataStyle:{
        color:theme.palette.sextary.main
    },
    dataStyleb:{
        color:theme.palette.secondary.dark
    },
    dataStylec:{
        color:theme.palette.secondary.dark,
        fontWeight:'bold'
    },
    dataStyle2:{
        padding:theme.spacing(1),
        textAlign:'left',
        color:theme.palette.sextary.light
    },
    dataStyle3:{
        padding:theme.spacing(1),
        textAlign:'left',
        color:theme.palette.secondary.main
    },
    menu:{
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    }
}));
const Mycard = (props) => {
    const {data,ind} = props;
    const classes = useStyles();
    
    const fechaValido = (fec) => {        
        let fechaLimite = moment('2022-06-07');
        let fechaInfraccion = moment(fec,'YYYY-MM-DD');
        let duration = moment.duration(fechaInfraccion.diff(fechaLimite));
        let days = duration.asDays();        
        return days;
    }

    return(
        <Box py={2} px={2} style={{backgroundColor:(ind%2)==0?'white':'#ebebeb'}}>            
            <Grid container justify="center">
                <Grid item xs={12} sm={6} md={4}>
                    <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>FECHA:</span><br/>{data.fur?moment(data.fecha_infraccion,'YYYY-MM-DD').format('DD-MM-YYYY'):moment(data.fecha,'DD/MM/YYYY').format('DD-MM-YYYY')}</Typography>                    
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>HORA:</span><br/>{data.hora}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>ORDEN MUNICIPAL:</span><br/>{data.om?data.om:''}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>NRO. DE BOLETA:</span><br/>{data.num_boleta?data.num_boleta:''}</Typography>
                </Grid>                
                <Grid item xs={12} sm={6} md={4}>
                    <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>MONTO (BS.):</span><br/>{data.monto?data.monto:''}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Typography className={classes.dataStyle}><span className={classes.dataStyle}>REPOSICIÓN DE COMPROBANTE (BS.):</span><br/>4</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Typography className={classes.dataStyle3}><span className={classes.dataStyleb}>MONTO TOTAL A PAGAR (BS.):</span><br/>{data.monto?(Number(data.monto)+4):''}</Typography>
                </Grid>
                {
                    data.fur?
                    <Grid item xs={12} sm={4} md={4}>
                        <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>FUR:</span><br/>{data.fur}</Typography>
                    </Grid>:null
                }                
                <Grid item xs={12} sm={6} md={4}>
                    <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>LÍNEA DE SERVICIO:</span><br/>{Array.isArray(data.linea_servicio)?'':data.linea_servicio}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>DIRECCIÓN:</span><br/>{data.direccion?data.direccion:''}</Typography>
                </Grid>                
                <Grid item xs={12}>
                    <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>DETALLE:</span><br/>{data.detalle?data.detalle:''}</Typography>
                </Grid>                                                            
            </Grid>                                                                    
        </Box>);
}
export default function ConsultaInfraccionView() {    
    const classes = useStyles();    
    const [onrequest, setOnrequest] = React.useState(false);    
    const { register, handleSubmit,formState,errors, reset, getValues } = useForm({mode:'onChange'});    
    const [{ data, loading, error }, refetch] = useAxios({url:`https://busquedasgamc.cochabamba.bo/web/index.php?r=services/get-infringement`,method:'POST'},{manual:true});
    
    const onSubmit = datos => {        
        let formData = new FormData();
        formData.append('num_placa',datos.placa);
        refetch({
            data:formData
        });
        setOnrequest(true);
    };
    const NewRequest = () => {
        setOnrequest(false);
        reset();
    }
    return (
        <Grid container justify="center" className={classes.root}>
            <Grid item xs={12} sm={11} md={8} lg={8} xl={8}>
                    <div className={classes.menu}>
                        <AppBar position="static">
                            <Toolbar>
                                <Typography variant="h6" className={classes.title}>
                                    Formulario de consultas
                                </Typography>
                                <Tooltip title="Ir al menú de Consultas en línea">
                                    <Link to="consultas-en-linea" style={{textDecoration:'none'}}>
                                        <Button color="secondary" variant="contained" startIcon={<MenuIcon/>}>Ir al menú</Button>
                                    </Link>
                                </Tooltip>
                            </Toolbar>
                        </AppBar>
                    </div>
                    <Card className={classes.customCardContainer}>                        
                        <form onSubmit={handleSubmit(onSubmit)}>                            
                            <CardContent>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <TextField
                                        disabled={onrequest}
                                        color="primary"
                                        id="placa"
                                        name="placa"
                                        label="Placa del vehículo"
                                        placeholder="Ej: 123ABC"
                                        variant="outlined"
                                        //helperText="Requerido"
                                        className={classes.customWith}
                                        //value={input.nombre}
                                        //onChange={handleInputChange}
                                        inputRef={register({
                                            required:true,
                                            minLength:6,
                                            maxLength:7
                                        })}
                                        error={errors.nombre?true:false}
                                    />
                                </FormControl>
                                {
                                    loading?
                                    <CircularProgress/>:null
                                }
                                {
                                    onrequest && error?
                                    <Alert variant="filled" severity="error">
                                        {error.messaje?error.messaje:"No hay resultados para la cosulta"}
                                    </Alert>:null
                                }
                                {
                                    onrequest && data && !data.status?
                                    <Alert variant="filled" severity="error">
                                        <Typography variant="body2">{String(data.message).toUpperCase()}</Typography>                                        
                                    </Alert>:null
                                }
                                {
                                    onrequest && data && data.status && !error?                                    
                                    <>
                                        <Alert variant="filled" severity="success">                                            
                                            <Typography variant="body2">Consulta exitosa</Typography>
                                        </Alert>
                                        <br/>
                                        {
                                            data && data.status?                                            
                                            <>
                                                <Typography variant="h6" className={classes.customTableTitle}>RESULTADO DE LA CONSULTA</Typography>
                                                <Grid container className={classes.customTable} justify="center">                                                    
                                                    <Grid item xs={12}>
                                                        <Typography className={classes.customSectionTitle}>DATOS IDENTIFICACIÓN VEHÍCULO</Typography>
                                                        <Grid container justify="center">
                                                            <Grid item xs={12} sm={6} md={4}>
                                                                <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>PLACA:</span><br/>{data.response && Array.isArray(data.response) && data.response.length>0?data.response[0].fur?data.response[0].identificador:data.response[0].placa:''}</Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={4}>
                                                                <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>MARCA:</span><br/>{data.response && Array.isArray(data.response) && data.response.length>0?data.response[0].marca:''}</Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={4}>
                                                                <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>COLOR:</span><br/>{data.response && Array.isArray(data.response) && data.response.length>0?data.response[0].color:''}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Card>
                                                            <Typography className={classes.customSectionTitle}>DATOS INFRACCIÓNES</Typography>
                                                            <CardContent>                                                                
                                                                {
                                                                    data.response && Array.isArray(data.response)?
                                                                    data.response.map((item,index)=>(
                                                                        <div key={index}>
                                                                        {
                                                                            !item.hasOwnProperty('fotomulta') && !item.hasOwnProperty('fecha_notificacion')? 
                                                                            <Mycard ind={index} data={item}/>:null
                                                                        }
                                                                        {
                                                                            item.hasOwnProperty('fotomulta') && item.hasOwnProperty('fecha_notificacion') && ((item.fotomulta && item.fecha_notificacion) || (item.fotomulta===null && item.fecha_notificacion===null))?
                                                                            <Mycard ind={index} data={item}/>:null
                                                                        }
                                                                        </div>
                                                                    )):null
                                                                }
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </>:null
                                        }
                                    </>:null
                                }
                            </CardContent>
                            <CardActions className={classes.customFooter}>                                
                                {
                                    onrequest?
                                    <Button variant="contained" color="primary" onClick={() => NewRequest()}>Nueva consulta</Button>:
                                    <Button disabled={!formState.isValid} variant="contained" className={classes.customButton} onClick={()=>handleSubmit(onSubmit)()}>Consultar</Button>
                                }                                
                            </CardActions>
                        </form>
                    </Card>                                    
            </Grid>
        </Grid>
    );
}
