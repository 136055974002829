import React,{useRef,useEffect} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Typography,Grid,InputLabel,Button,Box } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Skeleton from '@material-ui/lab/Skeleton';
import Select from '@material-ui/core/Select';
import {Request} from 'react-axios';
import useAxios from 'axios-hooks';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';

import ModalComponentViewer from '../../../components/genericos/modal/ModalComponentViewer';
import ErrorRequestComponent from '../../../components/genericos/error/ErrorRequestComponent';
import CustomPortadaComponent from '../../../components/genericos/portadas/portada-material-ui/CustomPortadaComponent';
import CampoBuscar from '../../../components/genericos/CampoBuscar';
import {GLOBAL} from '../../../global';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#DCB5BF',
      color: theme.palette.common.white,
    },
    //body: {
      //fontSize: 14,
    //},
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
        backgroundColor:'#F6EBEC',
      '&:nth-of-type(odd)': {
        //backgroundColor: theme.palette.action.hover,
        backgroundColor: '#EFE4E3',
      },
    },
  }))(TableRow);
  const ColorButton = withStyles((theme) => ({
    root: {
      color: 'white',
      backgroundColor: '#790921',
      '&:hover': {
        backgroundColor: '#DCB5BF',
      },
    },
  }))(Button);

const useStyles = makeStyles(theme => ({
    root:{
        flexGrow:1
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    title:{
        textAlign:'center',
        margin:theme.spacing(3,1),
    },
    customPadding:{
        paddingleft:theme.spacing(2),
        paddingRight:theme.spacing(2),
    },
    table: {
        width: '100%',
    },
    customMargin:{
      marginBottom:theme.spacing(1.5),
    },
    container: {
      maxHeight: 640,      
    },
    customHeigth:{
        minHeight: '90vh',
    },
    customCategoria:{
        fontWeight:'bold',
        backgroundColor:'#F2EAEB',
        padding:theme.spacing(1,1)
    },
    customMarginButton:{
        margin:theme.spacing(3,2),
        minWidth:"200px"
    },
}));

const dataPortada = {
    title:'CONCURSOS MUNICIPALES',
    subtitle:'SECRETARÍA DE CULTURA Y TURISMO',
    description:[
        'En esta sección presentamos los diferentes concursos municipales, vigentes en la gestión 2020. Estos mismos se realizan cada año para fomentar las diferentes expresiones artístico culturales. Las inscripciones se efectúan a través de esta sección de nuestro portal web, siguiendo las bases de cada concurso.'
    ],
    background:GLOBAL.assetsURL+'/images/cultura/portada-concursos-municipales2.jpg',
    logo:{
        title:'',
        img:null,
    }    
}


const InfoModalBody = (props) => {
    const classes = useStyles();
    const onHandleClick2Download = (path) => {
      let anchor = document.createElement("a");
      //anchor.download = 'Convocatoria.pdf';
      anchor.target = "_blank";
      anchor.href = path;
      anchor.click();
      anchor = null;
    } 
    return(
      <React.Fragment>
        <Request url="https://cultura.cochabamba.bo/utils/request/getEvento" method="get" params={{ id: props.data.id_evento }}>
            {(error, response, isLoading, makeRequest, axios) => {
            if (error) {
                return (<ErrorRequestComponent variante="standard" handleClick={() => makeRequest({ params: { reload: true } })}/>)
            }
            else if (isLoading) {
                return (
                    <Box>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography>
                                    <span style={{fontWeight:'bold'}}>CATEGORÍA:</span><br/>
                                    <Skeleton width="200px"/>
                                </Typography>
                                <br/>                                
                                <Typography>
                                    <span style={{fontWeight:'bold'}}>NOMBRE DEL CONCURSO:</span> <Skeleton width="220px"/>
                                </Typography>
                                <br/>
                                <Typography>
                                    <span style={{fontWeight:'bold'}}>FECHA INICIO:</span> <Skeleton width="150px"/>
                                </Typography>
                                <br/>
                                <Typography>
                                    <span style={{fontWeight:'bold'}}>FECHA FIN:</span> <Skeleton width="100px"/>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Skeleton width="100%" height="400px"/>
                            </Grid>                      
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography style={{marginTop:'10px'}}><span style={{fontWeight:'bold'}}>DESCRIPCIÓN:</span><br/><Skeleton width="100%"/><br/><Skeleton width="100%"/></Typography>
                                <br/>
                                <Box align="center">
                                    <Skeleton width="150px"/>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                )
            }
            else if (response !== null) {
                return (
                <>
                    {
                    response.data?
                    <Box>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography>
                                    <span className={classes.customCategoria}>CATEGORÍA:</span>                                    
                                </Typography>
                                <ul>
                                    {
                                        response.data.categorias?.map((item,index)=>(
                                        <li key={'cat'+index}>{item.descripcion_categoria}</li>
                                        ))
                                    }
                                </ul>
                                <br/>                                
                                <Typography>
                                    <span className={classes.customCategoria}>NOMBRE DEL CONCURSO:</span> <span>{response.data.evento.nombre_evento}</span>                                    
                                </Typography>
                                <br/>
                                <Typography>
                                    <span className={classes.customCategoria}>FECHA INICIO:</span> <span>{response.data.evento.inicio}</span>
                                </Typography>
                                <br/>
                                <Typography>
                                    <span className={classes.customCategoria}>FECHA FIN:</span> <span>{response.data.evento.fin}</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <img src={response.data.evento.banner} width="100%"/>
                            </Grid>                      
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography style={{marginTop:'20px'}}><span className={classes.customCategoria}>DESCRIPCIÓN:</span> {response.data.evento.descripcion_evento}</Typography>
                                <br/>
                                {
                                    response.data.evento.convocatoria?
                                    <Box align="center">
                                        <Button variant="contained" color="secondary" onClick={() => onHandleClick2Download(String(response.data.evento.convocatoria).trim())}>DESCARGAR ARCHIVO</Button>
                                    </Box> : null
                                }
                            </Grid>
                        </Grid>
                    </Box>:
                    <Alert severity="info">Sin información</Alert>
                    }
                </>
                )
            }
            return (<div>Default message before request is made.</div>)
            }}
        </Request>        
      </React.Fragment>
    )  
}


const ConcursosMunicipalesComponent = () => {
    const [{ data, loading, error }, refetch] = useAxios('https://cultura.cochabamba.bo/utils/request/getEventos');
    const [categoria, setCategoria] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [txtSearch,setTxtSearch] = React.useState('');
    const [data2component,setData2component] = React.useState(null);
    
    const childRef = useRef();
    
    const classes = useStyles();   

    const handleChangeCategory = (event) => {
        setCategoria(event.target.value);
        //console.log(event.target.value);        
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleChange = (event) => {
        setTxtSearch(event.target.value);
    }
    const handleClickToClear = () => {
        setTxtSearch('');
    };

    const computedSearchField = (items) => {
        try {
            if(categoria && categoria.toString().trim().length>0){
                return items.filter(element => String(element.nombre_evento).toUpperCase().indexOf(String(txtSearch).toUpperCase()) != -1 && element.id_tipo_evento==categoria);
            }else{
                return items.filter(element => String(element.nombre_evento).toUpperCase().indexOf(String(txtSearch).toUpperCase()) != -1);
            }
        } catch (error) {
            return [];            
        }        
    }
    const handleShow = (datos) => {        
        //console.log("handleShow datos=",datos);
        childRef.current.openModal();
        setData2component(datos);
    }
    const getTipoEvento = (id_tipo_evento) => {
        //console.log("getTipoEvento");
        let result = null;
        if(data){
            result = data.tipos.find(element => element.id_tipo_evento==id_tipo_evento);
        }
        //console.log("result=",result);
        return result?result.nombre_tipo_evento:'';
    }
    const onHandleClick = () => {
        let atag = document.createElement('a');
        atag.setAttribute('href',GLOBAL.assetsURL+'/documents/manuales/concursos-municipales.pdf');
        atag.setAttribute('download','MANUAL CONCURSOS MUNICIPALES.pdf');
        atag.click();
        atag = null;
    }
    useEffect(() => {
       window.scrollTo(0, 0);
    }, []);
    return(
        <React.Fragment>            
            <ModalComponentViewer ref={childRef} title='BASES DEL CONCURSO' body={<InfoModalBody data={data2component}/>}/>
            <br/>
            <Typography variant="h4" component="h2" gutterBottom className={classes.title}>CONCURSOS MUNICIPALES</Typography>
            <Grid container className={classes.customPadding} style={{paddingBottom:'20px',minHeight:'768px'}}>
                <Grid item xs={12} sm={1} md={2} lg={3} xl={3}></Grid>
                <Grid item xs={12} sm={10} md={8} lg={6} xl={6}>
                    <Grid container className={classes.customPadding}>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.customPadding}>
                            {
                                data?
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="demo-controlled-open-select-label">Filtrar por tipo de evento</InputLabel>
                                        <Select
                                            labelId="demo-controlled-open-select-label"
                                            id="demo-controlled-open-select"
                                            open={open}
                                            onClose={handleClose}
                                            onOpen={handleOpen}
                                            value={categoria}
                                            onChange={handleChangeCategory}
                                        >
                                            <MenuItem value="">
                                                <em>Ninguno</em>
                                            </MenuItem>
                                            {
                                                data && data.tipos?data.tipos.map((item,index)=>(                                                    
                                                    <MenuItem key={item.id_tipo_evento} value={item.id_tipo_evento}>{item.nombre_tipo_evento}</MenuItem>
                                                )):null
                                            }
                                        </Select>
                                    </FormControl>:null
                            }
                            {
                                loading?
                                <Skeleton />:null
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.customPadding}>
                            <CampoBuscar
                                placeholder = 'Buscar por nombre de evento'
                                txtSearch = {txtSearch}
                                handleChange = {handleChange}
                                handleClickToClear = {handleClickToClear}/>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.customPadding}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper} className={classes.container}>
                                {
                                    !error?
                                    <Table className={classes.table} aria-label="cutomized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>NOMBRE</StyledTableCell>
                                                <StyledTableCell align="center">TIPO DE EVENTO</StyledTableCell>
                                                <StyledTableCell align="center">FECHA INICIO</StyledTableCell>
                                                <StyledTableCell align="center">FECHA FIN</StyledTableCell>
                                                <StyledTableCell align="center">OPCIONES</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                data ? computedSearchField(data.eventos).map((row, index) => (
                                                    <StyledTableRow key={row.id_evento + '-' + index}>
                                                        <StyledTableCell component="th" scope="row">{row.nombre_evento}</StyledTableCell>
                                                        <StyledTableCell align="center">{getTipoEvento(row.id_tipo_evento)}</StyledTableCell>
                                                        <StyledTableCell align="center">{row.inicio}</StyledTableCell>
                                                        <StyledTableCell align="center">{row.fin}</StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            <ColorButton variant="contained" size="small" onClick={() => handleShow(row)}>MÁS INFORMACIÓN</ColorButton>
                                                        </StyledTableCell>
                                                    </StyledTableRow>)) : null
                                            }
                                            {
                                                loading ? Array.from(new Array(4)).map((row, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell component="th" scope="row"><Skeleton /></StyledTableCell>
                                                        <StyledTableCell align="center"><Skeleton /></StyledTableCell>
                                                        <StyledTableCell align="center"><Skeleton /></StyledTableCell>
                                                        <StyledTableCell align="center"><Skeleton /></StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            <Skeleton variant="circle" width={40} height={40} />
                                                        </StyledTableCell>
                                                    </StyledTableRow>)) : null
                                            }
                                        </TableBody>
                                    </Table>:null
                                }
                                {
                                    error ?
                                        <ErrorRequestComponent variante="standard" handleClick={() => refetch} /> :
                                        data && data.length == 0 ? <Alert severity="info">No hay ítems activos</Alert> : ''
                                }
                            </TableContainer>
                            <br/>
                            <Box align="center">
                                <Button className={classes.customMarginButton} variant="contained" color="secondary" size="large" onClick={ () =>  window.open('https://cultura.cochabamba.bo/', "_blank") }>IR AL SISTEMA</Button>
                                <Button className={classes.customMarginButton} variant="contained" color="secondary" size="large" onClick={ () => onHandleClick()}> DESCARGAR MANUAL </Button>
                            </Box>
                        </Grid>                        
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={1} md={2} lg={3} xl={3}></Grid>
            </Grid>
        </React.Fragment>
    );
}
export default ConcursosMunicipalesComponent;
