import React from 'react';
import Button  from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import LineWeightIcon from '@material-ui/icons/LineWeight';
import Helper from '../../../Helper';

const useStyles = makeStyles((theme) => ({
    button: {
        padding: theme.spacing(0),
    },
    root:{

    },
    iconContainer:{        
        verticalAlign:'middle',
        //backgroundColor:theme.palette.primary.main,
        borderTopRightRadius:theme.spacing(2),
        borderBottomRightRadius:theme.spacing(2),
        padding:theme.spacing(1,1)
    },
    iconContainerInverse:{
        verticalAlign:'middle',
        //backgroundColor:theme.palette.primary.main,
        borderTopLeftRadius:theme.spacing(2),
        borderBottomLeftRadius:theme.spacing(2),
        padding:theme.spacing(1,1)
    },
    textContainer:{
        padding:theme.spacing(1,2),
        textTransform:'none'
    }
}));

const CustomButtonComponent = (props) => {
    const theme = useTheme();
    const {text='Ver más',icon=null,color='secondary',secondColor=theme.palette.primary.main,size='medium',inverse=false,action=null} = props;    
    const classes = useStyles();    
    return (
        <Button
            size={size}
            variant="contained"
            color={color}
            className={classes.button}
            onClick={()=>action?Helper.checkLink(props,action):null}
        >
            {
                !inverse?
                <table style={{borderSpacing:'0px'}}>
                    <tbody style={{height:'100%'}}>
                        <tr>
                            <td className={classes.iconContainer} style={{backgroundColor:secondColor}}>
                                {icon?icon:<LineWeightIcon style={{display:'block'}}/>}
                            </td>
                            <td className={classes.textContainer}>{action && action.text?action.text:text}</td>
                        </tr>
                    </tbody>                
                </table>:
                <table style={{borderSpacing:'0px'}}>
                    <tbody style={{height:'100%'}}>
                        <tr>
                            <td className={classes.textContainer}>{text}</td>
                            <td className={classes.iconContainerInverse} style={{backgroundColor:secondColor}}>
                                {icon?icon:<LineWeightIcon style={{display:'block'}}/>}
                            </td>
                        </tr>
                    </tbody>                
                </table>
            }
        </Button>        
    );
}
CustomButtonComponent.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.node,
    color: PropTypes.string,
    secondColor: PropTypes.any,
    size: PropTypes.string,
    inverse: PropTypes.bool,
    onHandleClick: PropTypes.any,
    action: PropTypes.object
}
export default withRouter(CustomButtonComponent);