import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {Card,Box} from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import convert from 'htmr';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Skeleton from '@material-ui/lab/Skeleton';
import ReactPlayer from 'react-player';
import moment from 'moment';
import 'moment/locale/es';
import {GLOBAL} from '../../../global';

moment().locale('es');

const useStyles = makeStyles(theme=>({
    root:{
        margin:theme.spacing(5,1)
    },
    card: {
        width: '100%',
    },
    media: {
        width:'100%'
    },
    title: {
        fontSize: theme.typography.subtitle1.fontSize
    },
    customDivider:{
        margin:theme.spacing(2,0)
    },
    customContent:{
        fontFamily:  theme.typography.fontFamily,
        fontSize: theme.typography.subtitle1.fontSize,
    },
    customCardPadding:{
        marginBottom:theme.spacing(1)
    },
    mediaMobile:{
        height: 0,
        paddingTop: '56.25%', // 16:9
    }
}));

function Post(props) {
    const matches = useMediaQuery('(min-width:600px)');
    const { loading = false } = props;
    const classes = useStyles();
    const handleDetail = () => {
        props.history.push('/noticias/'+props.data.slug);
    };
    const isImage = (ruta) => {
        let result = false;        
        if(ruta){
            let extension = ruta.substring(ruta.lastIndexOf('.') + 1).toLowerCase();
            if(extension == 'png' || extension == 'jpg' || extension == 'jpeg'){
                result = true;
            }
        }
        return result;
    }
    const isVideo = (ruta) => {
        let result = false;        
        if(ruta){
            let extension = ruta.substring(ruta.lastIndexOf('.') + 1).toLowerCase();
            if(extension == 'mp4'){
                result = true;
            }
        }
        return result;
    }
    const onHandleChange = () => {
        props.history.push(`/noticias/${props.data.slug}`);
    }
    return (
        <div className={classes.root}>
            {
                matches?
                <Card className={classes.card}>
                    <Box>
                        {
                            !loading?
                                <>
                                    {                            
                                        props.data.archivo && isImage(props.data.archivo)?                                    
                                            <CardMedia
                                                className={classes.media}
                                                component="img"
                                                alt="Imagen principal"
                                                image={`${GLOBAL.assetsBack}/${props.data.archivo}`}
                                                title="Imagen de portada"
                                            />:''
                                    }
                                    {                                    
                                        props.data.archivo && isVideo(props.data.archivo)?
                                            <ReactPlayer
                                                align="center"
                                                url={`${GLOBAL.assetsBack}/${props.data.archivo}`}
                                                controls={true}
                                                width="100%"
                                                height="auto"
                                            />:''                                
                                    }
                                </>:
                                (
                                    <Skeleton variant="rect" width="100%">
                                        <div style={{ paddingTop: '56.25%' }} />
                                    </Skeleton>
                                )
                        }
                        <CardContent>
                            <Box textAlign="center">
                                <img src={`${GLOBAL.assetsBack}/img/firma-GAMC.png`} alt="Logo GAMC" style={{maxWidth:'250px'}}/>
                            </Box>
                            <br/>
                            <Typography gutterBottom variant="h5" component="h2">
                                {!loading?String(props.data.titulo).toUpperCase():<Skeleton/>}
                            </Typography>
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        {
                                            !loading?
                                            (props.data.category?props.data.category.nombre:''):
                                            (<Skeleton/>)
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        {
                                            !loading?
                                            (props.data && props.data.fecha?String(moment(props.data.fecha).format('LL')).toUpperCase():''):
                                            (<Skeleton/>)
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider className={classes.customDivider} />
                            <Box className={classes.customContent}>
                                {
                                    !loading?
                                    (
                                        convert(props.data && props.data.resumen?props.data.resumen:'<p></p>')
                                    ):
                                    (
                                        Array.from(new Array(3)).map((item,index) => (
                                            <Skeleton key={index}/>
                                        ))
                                    )
                                }
                            </Box>
                        </CardContent>
                    </Box>
                    <CardActions>
                        {
                            !loading?
                            (
                                <Button size="small" variant="contained" color="primary" onClick={handleDetail}>
                                    LEER MÁS
                                </Button>
                            ):
                            (
                                <Button size="small" color="primary">
                                    <Skeleton/>
                                </Button>
                            )
                        }
                    </CardActions>
                </Card>:
                <Card className={classes.customCardPadding} onClick={()=>onHandleChange()}>                    
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            {
                                props.data && isImage(props.data.archivo)?
                                <CardMedia
                                    className={classes.mediaMobile}                                    
                                    alt="Imagen de portada"
                                    image={isVideo(props.data.archivo)?`${GLOBAL.assetsBack}/img/noticias/default-video.jpeg`:`${GLOBAL.assetsBack}/${props.data.archivo}`}
                                />:null
                            }
                            {
                                props.data && isVideo(props.data.archivo)?
                                <ReactPlayer
                                    align="center"
                                    url={`${GLOBAL.assetsBack}/${props.data.archivo}`}
                                    controls={true}
                                    width="100%"
                                    height="auto"
                                />:null
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <Box p={1} display="flex" alignItems="center" height="100%">
                                <Box>
                                    <Typography color="primary" variant="caption">{props.data.fecha?moment(props.data.fecha).locale('es').format('LL'):''}</Typography>
                                    <Typography variant="subtitle2">{props.data.titulo}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>                    
                </Card>
            }
        </div>
    );
}

Post.propTypes = {
    loading: PropTypes.bool,
}

export default withRouter(Post);
