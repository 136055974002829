import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid,Box,Button,Paper} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';

import { Map, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import Skeleton from '@material-ui/lab/Skeleton';
import useAxios from 'axios-hooks';

//import clsx from 'clsx';
/*
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';


let DefaultIcon = L.icon({
    iconUrl: 'http://192.168.220.106:8000/img/icons/cono-marker.png',
    shadowUrl: 'http://192.168.220.106:8000/img/icons/cono-marker-shadow.png',
    iconAnchor: [17, 25],
    shadowAnchor: [24, 24]
});

L.Marker.prototype.options.icon = DefaultIcon;
*/

const apiUrl = "http://192.168.220.59:8010";

const useStyles = makeStyles((theme) => ({
    rootlist: {
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 650,
        backgroundColor: theme.palette.background.paper
    },    
    customTitle:{
        color:theme.palette.secondary.dark,
        padding:theme.spacing(3),
        fontWeight: 'bold'
    },
    customMapHeight:{
        height:'450px'
    },
    customErrorMessage:{
        color:theme.palette.error.main
    },
    borderedStyle: {
        border:'1px solid gray'
    },
    media: {        
        //height:'auto'
        height: 0,
        paddingTop: '56.25%', // 16:9        
    },
    customDataStyle:{
        fontWeight:'bold',
        padding:theme.spacing(1,2)
    }
  }));

const MapaEventosComponent = () => {
    const classes = useStyles();
    const [{ data, loading, error }, refetch] = useAxios(`${apiUrl}/api/evento-map`);
    const [evento,setEvento] = useState(null);
    const [ubicacion, setUbicacion] = useState([[-17.393557,-66.157715]]);
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    //var ubicacion = [-17.393557,-66.157715];

    const convertToArray = (e) => {
        let aux = [];
        e.puntos.map(element => {
            aux.push([element.latitud,element.longitud]);
        });        
        return aux;
    }
    const onChangeEvento = (e,index) => {        
        if(Array.isArray(e.puntos) && e.puntos.length>0){
            setUbicacion(convertToArray(e));            
        }
        setSelectedIndex(index);
        setEvento(e);
    }

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={2} md={3} lg={3} xl={3}>
                <Paper>
                    <Typography variant="h5" className={classes.customTitle}>Lista de eventos</Typography>
                    <List className={classes.rootlist}>
                    {
                        loading?
                        Array.from(new Array(4)).map((item,index)=>(
                            <ListItem key={`list${index}`}>
                                <Skeleton/>
                            </ListItem>
                        )):null
                    }
                    {
                        !error && data && data.status && data.data && Array.isArray(data.data)?
                        data.data.map((item,index)  =>  (
                            <div key={`list${index}`}>
                                <Divider variant="inset" component="li" />
                                <ListItem button onClick={()=>onChangeEvento(item,index)} selected={selectedIndex==index}>
                                    <ListItemIcon>
                                        <EventAvailableIcon color='secondary'/>
                                    </ListItemIcon>                                
                                    <ListItemText primary={item.detalle} secondary={item.ubicacion} />
                                </ListItem>
                            </div>
                        )):null
                    }
                    {
                        !loading && error?
                        <Typography variant="h5" className={classes.customErrorMessage}>Error en la obtención de datos</Typography>:null
                    }
                    </List>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={10} md={9} lg={9} xl={9}>
                <Paper px={2}>
                    <Typography variant="h5" className={classes.customTitle}>Detalle del evento</Typography>
                        {
                            evento?
                            <Box px={2}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container className={classes.borderedStyle}>
                                            <Grid item xs={4}>
                                                <Typography className={classes.customDataStyle}>Detalle:</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {evento?evento.detalle:null}
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.borderedStyle}>
                                            <Grid item xs={4}>
                                                <Typography className={classes.customDataStyle}>Ubicación:</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {evento?evento.ubicacion:null}
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.borderedStyle}>
                                            <Grid item xs={4}>
                                                <Typography className={classes.customDataStyle}>Entidad:</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {evento?evento.entidad:null}
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.borderedStyle}>
                                            <Grid item xs={4}>
                                                <Typography className={classes.customDataStyle}>Tipo evento:</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {evento?evento.descripcion:null}
                                            </Grid>
                                        </Grid>                        
                                    </Grid>                                    
                                </Grid>
                                <Box>
                                    <Typography className={classes.customDataStyle}>Imagen del evento:</Typography>
                                    {
                                        evento && evento.imagen?
                                        <CardMedia
                                            className={classes.media}
                                            image={`${apiUrl}/imagen/${evento.imagen}`}
                                            title="Imagen evento"
                                        />:null
                                    }
                                    <Typography className={classes.customDataStyle}>Ubicación en mapa:</Typography>
                                    <Map bounds={ubicacion} zoom={18} attributionControl={false} className={classes.customMapHeight}>
                                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                                        {
                                            evento && Array.isArray(evento.puntos)?
                                            evento.puntos.map((ele,index)=>(
                                                <Marker key={`marker${index}`} position={[ele.latitud,ele.longitud]}>
                                                    <Popup>Punto cerrado</Popup>
                                                </Marker>
                                            )):null
                                        }
                                        {
                                            evento && Array.isArray(evento.puntos)?<Polyline color='#931344' weight={10} positions={convertToArray(evento)}/>:null
                                        }
                                    </Map>                            
                                </Box>
                            </Box>:<Box p={2}><Typography className={classes.customDataStyle}>Debe seleccionar un evento de la lista.</Typography></Box>
                        }
                </Paper>
            </Grid>
        </Grid>
    </>
}

export default MapaEventosComponent;