import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import convert from 'htmr';
import { Carousel } from 'react-responsive-carousel';

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
  },
  media: {
    height: 140,
  },
});

export default function MediaCard(props) {
    const classes = useStyles();    
    return (
        <Card className={classes.root}>
            {                   
                   props.images?
                    <Carousel>
                        {
                            props.images.map((element,index)=>(
                                <div key={index}>
                                    <img src={element}/>
                                    <p className="legend">Legend</p>
                                </div>
                            ))
                        }
                    </Carousel>:<p></p>
            }
            <CardActionArea>                
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {props.title ? props.title : ''}
                    </Typography>
                    <Box>
                        {convert(props.body ? props.body : '<p></p>')}
                    </Box>
                </CardContent>
            </CardActionArea>
            {/* <CardActions>
                <Button size="small" color="primary">
                    Share
                </Button>
                <Button size="small" color="primary">
                    Learn More
                </Button>
            </CardActions> */}
        </Card>
    );
}
