//IMPORTS
import axios from 'axios';
import { GLOBAL } from '../global';

//STATIC
var dataInicial = {
    palette:{},
    static:[],
    buscador:[],
    childMenu:null,
    currentSite:null,
    onrequest:false
}
//TYPES
const UPDATE_CURRENT_MENU = 'UPDATE_CURRENT_MENU';
const SET_DATOS_ESTATICOS = 'SET_DATOS_ESTATICOS';
const SET_ONREQUEST = 'SET_ONREQUEST';
const SET_CURRENTSITE = 'SET_CURRENTSITE';

//REDUCER
export default function dataReducer(state = dataInicial,action){    
    //console.log("REDUCER EN ACCION action.payload=",action.payload);
    switch(action.type){
        case UPDATE_CURRENT_MENU:
            //console.log("ENTRE A HACER UPDATE_CURRENT_MENU action.payload=",action.payload);
            return {
                ...state,
                childMenu:action.payload.data?JSON.parse(action.payload.data.datos):null,
                currentSite:action.payload.data.currentSite,
                onrequest:action.payload.onrequest
            };
        case SET_DATOS_ESTATICOS:
            return {...state,static:action.payload};
        case SET_ONREQUEST:
            return {
                ...state,
                onrequest:action.payload
            };
        case SET_CURRENTSITE:
            return {
                ...state,
                currentSite:action.payload
            };
        default:
            return state;
    }
}
//ACTIONS
//dispatch.- sirve para activar el reducer
//getState.- sirve para obtener la data inicial
export const obtenerDatosMenuAction = (dato) => (dispatch,getState) => {
    //console.log("********* DATO=",dato);
    //console.log("FROM ACTIONS datosMain.static=",getState().datosMain);
    let currentSite = getState().datosMain.currentSite;
    let onrequest = getState().datosMain.onrequest;
    if(dato.currentSite && currentSite==null){
        dispatch({
            type:SET_CURRENTSITE,
            payload:dato.currentSite
        });
    }
    if(dato.currentSite && dato.slug && currentSite != dato.currentSite && !onrequest){
        //console.log("???????????? ARE LA PETICION DE DATOS PARA EL MENU");
        dispatch({
            type : SET_ONREQUEST,
            payload : true
        });
        axios({
            method: 'post',
            url: `${GLOBAL.apiURL}/api/getdatasubmenu`,
            data: {
                slug: dato.slug,
            }})
        .then(function (response) {
            //console.log("obtenerDatosMenuAction=",response);
            dispatch({
                type : UPDATE_CURRENT_MENU,
                payload : {data:response.data.data,onrequest:false}
            });
        })
        .catch(function(error){
            dispatch({
                type : SET_ONREQUEST,
                payload : false
            });
        });        
    }    
}

export const setDatosEstaticosAction = (datos) => async (dispatch,getState) => {    
    dispatch({
        type : SET_DATOS_ESTATICOS,
        payload : datos
    });
}