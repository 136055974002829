import React from 'react';
import { Link } from 'react-router-dom';
import useAxios from 'axios-hooks';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from "react-hook-form";
import { Grid, Button, TextField, Typography,Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import FormHelperText from '@material-ui/core/FormHelperText';

import { GLOBAL } from '../../global';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(10, 1),
    },
    customCardContainer: {
        padding: theme.spacing(5, 1),
    },
    customWith: {
        width: '100%'
    },
    customFooter: {
        justifyContent: 'center',
        padding: theme.spacing(3, 1)
    },
    customButton: {
        backgroundColor: "#4caf50",
        color: 'white',
        '&:hover': {
            backgroundColor: "#28792b",
        }
    },
    customTableTitle: {
        textAlign: 'center',
        color:theme.palette.sextary.dark,
        //fontWeight: 'bold',
        //backgroundColor: theme.palette.quaternary.dark,
    },
    customSectionTitle: {
        backgroundColor: theme.palette.tertiary.main,
        textAlign: 'left',
        //fontWeight: 'bold',
        color:theme.palette.sextary.dark,
        color: 'white',
        padding: theme.spacing(2, 1)
    },
    customTable: {
        width: '100%',
        textAlign: 'center'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width:"100%"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    dataStyle:{
        color:theme.palette.sextary.main
    },
    dataStyle2:{
        textAlign:'left',
        color:theme.palette.sextary.light
    },
    menu:{
        flexGrow: 1,
    },
    // menuButton: {
    //     marginRight: theme.spacing(2),
    // },
    title: {
        flexGrow: 1,
    }    
}));

export default function ConsultaTramitesMunicipalesView(props) {    
    const classes = useStyles();
    const [onrequest, setOnrequest] = React.useState(false);
    const { register, handleSubmit, formState, errors, reset, getValues } = useForm({ mode: 'onChange' });
    const [{ data, loading, error }, refetch] = useAxios({ url: `https://appgamc.cochabamba.bo/correspondencia/consultatramite.php`, method: 'POST' }, { manual: true });
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const onSubmit = datos => {
        let formData = new FormData();
        formData.append('gestion', age);
        formData.append('numero', datos.num_tramite);
        formData.append('ci', datos.carnet);
        refetch({
            data: formData
        });
        setOnrequest(true);
    };
    const NewRequest = () => {
        setOnrequest(false);
        reset();
    }
    return (
        <Grid container justify="center" className={classes.root}>
            <Grid item xs={12} sm={11} md={8} lg={8} xl={8}>
                <div className={classes.menu}>
                    <AppBar position="static">
                        <Toolbar>                            
                            <Typography variant="h6" className={classes.title}>
                                Formulario de consultas
                            </Typography>
                            <Tooltip title="Ir al menú de Consultas en línea">
                                <Link to="consultas-en-linea" style={{textDecoration:'none'}}>
                                    <Button color="secondary" variant="contained" startIcon={<MenuIcon/>}>Ir al menú</Button>
                                </Link>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                </div>
                <Card className={classes.customCardContainer}>                    
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <CardContent>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="gestion-simple-select-outlined-label">Año</InputLabel>
                            <Select
                                labelId="gestion-simple-select-outlined-label"
                                id="gestion-simple-select-outlined"
                                value={age}
                                onChange={handleChange}
                                label="Gestión"
                                autoWidth
                                disabled={onrequest}
                            >
                            <MenuItem value="">
                                <em>Seleccionar</em>
                            </MenuItem>
                            <MenuItem value={2021}>2021</MenuItem>
                            <MenuItem value={2020}>2020</MenuItem>
                            <MenuItem value={2019}>2019</MenuItem>
                            <MenuItem value={2018}>2018</MenuItem>
                            <MenuItem value={2017}>2017</MenuItem>
                            <MenuItem value={2016}>2016</MenuItem>
                            </Select>
                            <FormHelperText>Dato requerido</FormHelperText>
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                disabled={onrequest}
                                color="primary"
                                id="num_tramite"
                                name="num_tramite"
                                label="Nro. Trámite"
                                placeholder="Ingrese el número de trámite"
                                variant="outlined"
                                helperText="Dato requerido"
                                className={classes.customWith}
                                //value={input.nombre}
                                //onChange={handleInputChange}
                                inputRef={register({
                                    required: true,
                                    minLength: 1,
                                    maxLength: 6
                                })}
                                error={errors.nombre ? true : false}
                            />
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                disabled={onrequest}
                                color="primary"
                                id="carnet"
                                name="carnet"
                                label="Carnet de identidad"
                                placeholder="Ingrese el CI"
                                variant="outlined"
                                //helperText="Requerido"
                                className={classes.customWith}
                                //value={input.nombre}
                                //onChange={handleInputChange}
                                inputRef={register({
                                    required: false,
                                    minLength: 3,
                                    maxLength: 10
                                })}
                                error={errors.nombre ? true : false}
                            />
                        </FormControl>
                            {
                                loading ?
                                    <CircularProgress /> : null
                            }
                            {
                                onrequest && error ?
                                    <Alert variant="filled" severity="error">
                                        {error.messaje?error.message:'Se produjo un error en la consulta, vuelva a intentarlo más tarde'}
                                    </Alert> : null
                            }
                            {
                                onrequest && data && !data.status ?
                                    <Alert variant="filled" severity="error">
                                        <Typography variant="body2">{String(data.data?'No se encontraron resultados para la consulta':'Se produjo un error en la consulta, vuelva a intentarlo más tarde').toUpperCase()}</Typography>
                                    </Alert> : null
                            }
                            {
                                onrequest && data && data.status ?                                    
                                    <>
                                        <Alert variant="filled" severity="success">
                                            <Typography variant="body2">Consulta exitosa</Typography>
                                        </Alert>
                                        <br />
                                        {
                                            data && data.data ?
                                                //true?
                                                <>
                                                    <Typography className={classes.customTableTitle}>RESULTADO DE LA CONSULTA</Typography>
                                                    <Grid container className={classes.customTable} justify="center">                                                        
                                                        <Grid item xs={12}>
                                                            <Typography className={classes.customSectionTitle}>DATOS TRÁMITE</Typography>
                                                            <Card>
                                                                <CardContent>
                                                                {
                                                                    Array.isArray(data.data)?
                                                                    data.data.map((item,index)=>(
                                                                        <Grid container justify="center" style={{backgroundColor:(index%2)==0?'white':'#ebebeb'}}>
                                                                            <Grid item xs={6} sm={6}>
                                                                                <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>NOMBRE:</span><br/>{item.nombre}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={6} sm={6}>
                                                                                <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>FECHA TRÁMITE:</span><br/>{item.fecha_tramite}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={6} sm={6}>
                                                                                <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>INSTITUCIÓN:</span><br/>{item.institucion}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={6} sm={6}>
                                                                                <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>ASUNTO DEL TRÁMITE:</span><br/>{item.asunto}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={6} sm={6}>
                                                                                <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>OFICINA EMISOR:</span><br/>{item.oficina_emisor}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={6} sm={6}>
                                                                                <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>OFICINA RECEPTOR:</span><br/>{item.oficina_receptor}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={6} sm={6}>
                                                                                <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>INSTRUCCIÓN:</span><br/>{item.instruccion}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={6} sm={6}>
                                                                                <Typography className={classes.dataStyle2}><span className={classes.dataStyle}>GESTIÓN:</span><br/>{item.gestion}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    )):null
                                                                }
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                                </> : null
                                        }
                                    </> : null
                            }
                        </CardContent>
                        <CardActions className={classes.customFooter}>
                            {
                                onrequest ?
                                    <Button variant="contained" color="primary" onClick={() => NewRequest()}>Nueva consulta</Button> :
                                    <Button disabled={!formState.isValid || !age} variant="contained" className={classes.customButton} onClick={() => handleSubmit(onSubmit)()}>Consultar</Button>
                            }
                        </CardActions>
                    </form>
                </Card>
            </Grid>
        </Grid>
    );
}
