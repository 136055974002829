import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography,Grid,Box } from '@material-ui/core';

import CardComponent from './CardComponent';

const useStyles = makeStyles(theme => ({
    root:{
        flexGrow:1
    },
    customAlign:{
        float:'center'
    },
    customTitle:{
        color:theme.palette.primary.main
    }
}));

function CardContainerComponent(props){
    const { title='',items=[] } = props;
    const classes = useStyles();
    return(
        <div>
            <Box px={1} mb={3}>
                <Typography variant='h4' align='center' className={classes.customTitle}>
                    {title}
                </Typography>
            </Box>
            <Grid container justify="center">
                <Grid item xs={12} sm={10} md={10} lg={8} xl={6}>
                    <Grid container justify='center' spacing={2}>
                        {
                            items.map((c,index)=>(
                                <Grid key={index} item xs={12} sm={4} md={4} lg={4} xl={4}>
                                    <CardComponent data={c}/>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>                
            </Grid>
        </div>
    );
}

CardContainerComponent.propTypes = {
    title: PropTypes.string,
    items: PropTypes.array,
};

export default CardContainerComponent;