import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Parallax } from 'react-parallax';
import Typography from '@material-ui/core/Typography';
import convert from 'htmr';
import {GLOBAL} from '../../global';

const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor:theme.palette.tertiary.main,
      flexGrow: 1,
      padding:theme.spacing(10,1),
      //backgroundImage:`url(${GLOBAL.assetsBack}/img/secciones/fondo-oscuro2.jpg)`,
      //backgroundRepeat: 'no-repeat',
      //backgroundSize:'cover'
      //backgroundAttachment: 'fixed',
      //backgroundPosition: 'center'
    },
    imgContainer:{
      padding:theme.spacing(1),
      textAlign:'center'
    },
    media:{
      maxWidth:'480px',
      maxHeight:'480px',
      borderRadius:'50%',
      border:'5px solid white'
    },
    customContaimer:{      
      fontSize:"16px",
      color:"white",
      //textShadow:"0.05em 0.05em #000"
    },
    customTitle:{
      textAlign:'center',
      //color:"white",
      marginBottom:theme.spacing(3),
      color:theme.palette.primary.main

    },
    customSubTitle:{
      color:"white",
      //color:theme.palette.sextary.light,
      padding:theme.spacing(3,1),
      textAlign:'center',            
    },
    imageBackdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0.4,
      transition: theme.transitions.create('opacity'),
    },
    contentStyle:{
      textAlign:'justify'
    },
    customDivider:{
      border:'0.1px solid white',
      marginBottom:theme.spacing(5)
    }
  }));


const ChildBiografiaComponent = (props) => {
    const {data={}} = props;
    // const data = {
    //   title:'NUESTRO ALCALDE',
    //   img:'/img/secciones/nuestro-alcalde-mandred.jpg',
    //   name:'Cap. Manfred Reyes Villa',
    //   description:'<p>Ullamco enim non cillum exercitation dolore fugiat. Deserunt sint laborum Lorem laboris ad tempor laboris non do enim labore mollit. Consequat quis qui Lorem magna nostrud est esse sunt cupidatat dolor. Laborum ipsum laboris eiusmod cillum nisi labore exercitation nulla non qui. Et nisi reprehenderit in ad commodo. Qui reprehenderit est ipsum duis id minim non non aliquip fugiat cillum in. Deserunt sit sint sunt commodo ut in labore nulla commodo incididunt esse amet nostrud ea.</p><p>Ipsum consectetur officia dolore cupidatat et amet sint proident occaecat enim nulla ea. Excepteur qui ullamco et proident quis enim. Quis excepteur reprehenderit ea exercitation id esse tempor. Cupidatat fugiat eu deserunt enim quis laborum deserunt elit nisi. Velit ea ea reprehenderit eu do Lorem.<p><p>Nulla dolor enim sunt culpa esse officia mollit nisi non fugiat ipsum. Commodo labore velit aute officia culpa ea. Enim sit est magna consectetur cillum dolor pariatur sunt est qui. Irure dolor aliqua sint deserunt. Labore ex fugiat occaecat velit. Qui laboris excepteur proident commodo et minim qui. Ex officia irure ad et non commodo nostrud aliquip labore.</p>'
    // };

    const classes = useStyles();
    return(
      <>
        {/* <Parallax
          //blur={3}
          bgImage={`${GLOBAL.assetsBack}/img/secciones/bggroundbiografia.jpg`}
          bgImageAlt="Birgrafía"          
          > */}
          
          <Grid container justify='center' className={classes.root}>
              <Grid item xs={12} sm={12} md={10} lg={8} xl={7}>
                <Typography className={classes.customTitle} variant="h3">{data.title}</Typography>
                <br/><br/>
                <Grid container justify='center' spacing={4}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} align="center">
                    <Box>
                      <img className={classes.media} src={GLOBAL.assetsBack+data.img} alt={data.name}/>
                      <Typography className={classes.customSubTitle} variant="h4">{data.name}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} align="center">
                    <Box className={classes.customContaimer}>
                      <Typography variant="h4" align="center">{data.description.titulo}</Typography>
                      <Box className={classes.contentStyle}>
                        {convert(data.description.contenido)}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
          </Grid>
        {/* </Parallax> */}
      </>
    );
}
export default ChildBiografiaComponent;