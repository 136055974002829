import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import convert from 'htmr';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      backgroundColor:theme.palette.primary.main,
      padding:theme.spacing(5,1)
    },
    customMargin:{
        padding:theme.spacing(5,1),
        color:"#ffffff",                       
    },
    customTitle:{
        textAlign:'center',
        margin:theme.spacing(5,1)
    },
    subTitle:{
        textAlign:"left",
    },
    contentStyle:{
        textAlign:"jusfify"
    },
    customTextContainer:{
        //fontSize:theme.typography.body2.fontSize
    }
  }));

const PoliticaPrivacidadView = (props) => {
    const { data="<p></p>" } = props;
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <Grid container className={classes.customMargin} justify="center">
                <Grid item xs={12} sm={12} md={8} lg={8} xl={6}>
                    <Box className={classes.customTextContainer}>
                        {
                            convert(data)
                        }
                    </Box>
                </Grid>
            </Grid>            
        </div>
    );
}
export default PoliticaPrivacidadView;