import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import {Room} from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import convert from 'htmr';
import '../../../../App.css';
//import ModalComponentViewer from '../../components/genericos/modal/ModalComponentViewer';
import ModalComponentViewer from '../../../../components/genericos/modal/ModalComponentViewer';
import {GLOBAL} from '../../../../global';

const { useRef } = React;

const useStyles = makeStyles({
    root: {
      height: "100%",
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
  });

 const MediaCard = (props) => {
    const classes = useStyles();
    const posicion = props.data.ubicacion?String(props.data.ubicacion).split(","):null;
    return (
      <Card className={classes.root}>        
            <CardMedia
                className={classes.media}
                image={props.data.portada?`${GLOBAL.assetsURL}${props.data.portada}`:'https://images.freeimages.com/images/large-previews/c1d/buildings-buildings-buildings-1560481.jpg'}
                title="Portada ubicación"
            />
            <CardContent>
                {convert(props.data.contenido?props.data.contenido:'<p></p>')}                
            </CardContent>
            <CardActions disableSpacing>
                {
                    props.data.ubicacion?
                    <Button
                        onClick={()=>props.onHandleClick(props.data)}
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        startIcon={<Room />}>
                        Ver en mapa
                    </Button>:null
                }
            </CardActions>
      </Card>
    );
  }
const Mapa = (props) => {
    const posicion = props.data.ubicacion?String(props.data.ubicacion).split(","):null;    
    return(
        <>
            <Map center={posicion} zoom={18} attributionControl={false}>
                <TileLayer          
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={posicion}>
                    <Popup>
                        Aquí se encuentra la oficina
                    </Popup>
                </Marker>
            </Map>
        </>
    )
}
const Temporizador = (props) => {
    const [data2component,setData2component] = React.useState(null);
    const childRef = useRef();
    const classes = useStyles();

    const onHandleClick = (dataItem) =>{
        console.log("dataItem=",dataItem);
        childRef.current.openModal();
        setData2component(dataItem);
    }
    return (
        <>
            <ModalComponentViewer ref={childRef} title='UBICACIÓN' body={<Mapa data={data2component}/>}/>
            <Grid container spacing={1}>
                {
                    props.data.map((item,index)=>(
                        <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={3}>
                            <MediaCard data={item} onHandleClick={(coors)=>onHandleClick(coors)}/>
                        </Grid>
                    ))
                }
            </Grid>
        </>
    );
}

export default Temporizador;