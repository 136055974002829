import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { blue } from '@material-ui/core/colors';
import { withRouter } from 'react-router-dom';

import { GLOBAL } from '../../../../global';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,      
    },
    margin: {
       margin: theme.spacing(0),
    },
    customModalTitleStyle:{
       //backgroundColor:'#e8f4fd',
       padding:theme.spacing(1,1),
       textAlign:'right',
       color:"rgb(13, 60, 97)"       
    },
    customColorText:{
        color:"rgb(13, 60, 97)"
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    customImg:{
        width:'100%'
    },
    customTitle:{
        color:"rgb(13, 60, 97)",
        textDecoration:'underline'
    },
    customPadding:{
        //height:theme.spacing(2),
        backgroundImage:'linear-gradient(to right, rgb(88,190,208) , rgb(244,69,86))',
        color:'#FFFFFF',
    },
    customModalStyle:{
        padding:theme.spacing(0,0)
    },
    customStyleToButton:{
        marginTop:theme.spacing(-6),
        marginBottom:theme.spacing(1)
    },
    customMarginButton:{
        margin:theme.spacing(0,1)
    }
  }));

function ModalComunicadoComponent(props){
    const [open, setOpen] = React.useState(props.show?true:false);
    const classes = useStyles();

    const onHandleDownload = (patharchivo,archivo) => {        
        let atag = document.createElement('a');
        atag.setAttribute('href',GLOBAL.assetsURL+patharchivo);
        atag.setAttribute('download',archivo?archivo:'archivo');
        atag.click();
        atag = null;
      };

    const handleClose = () => {
        setOpen(false);
    };

    return(        
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={handleClose}
            >            
            <DialogTitle className={classes.customModalTitleStyle}>
                <IconButton aria-label="delete" className={classes.margin} onClick={handleClose} size="small">
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.customModalStyle}>
                <Box align="center">
                    <img src={GLOBAL.assetsBack+'/front/images/mi-plano/destacado-sis-inscripcion2.jpg'} className={classes.customImg}/>
                </Box>                
                <Box align="center" className={classes.customStyleToButton}>
                    <Button className={classes.customMarginButton} variant="contained" color="secondary" onClick={() => window.open("https://miplano.cochabamba.bo/Login/SegundaEtapaWeblogin", "_blank")}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;INSCRÍBASE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>
                    <Button className={classes.customMarginButton} variant="contained" color="secondary" onClick={(e) => onHandleDownload("/documents/mi-plano/INGRESO_DE_REQUISITOS_2DA_ETAPA-MANUAL_DE_USUARIO.pdf","INGRESO DE REQUISITOS 2DA ETAPA - MANUAL DE USUARIO.pdf")}>DESCARGAR MANUAL</Button>
                </Box>
            </DialogContent>
            <DialogActions className={classes.customPadding}>
                <Typography variant="caption">Portal Web GAMC</Typography>
            </DialogActions>                
        </Dialog>        
    );
}
export default withRouter(ModalComunicadoComponent);