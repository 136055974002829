import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid,Paper,Typography } from '@material-ui/core';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RoomIcon from '@material-ui/icons/Room';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';
import Divider from '@material-ui/core/Divider';
import FondAwesome from 'react-fontawesome';
//import '../../assets/css/childContactoComponent.css';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex"        
    },
    customMainContainer:{
        margin:theme.spacing(10,1)
    },
    customDataStyle: {
        width: '100%',    
        backgroundColor: theme.palette.background.paper,
    },
    customWith:{
        width:'100%',
        margin:theme.spacing(2,0),
    },
    customPadding:{
        padding:theme.spacing(2,2)
    },
    customPaper:{
        padding:theme.spacing(5,1)
        //backgroundColor:theme.palette.common.white,
    },
    whatsapp:{
        color:'#f50057',
        //margin:'10px'
    },
    customTitle:{
        color:theme.palette.sextary.main
    },
    customsubTitle:{
        color:theme.palette.sextary.main,
        marginBottom:theme.spacing(3)
    },
    customMapHeight:{
        height:'468px'
    }
  })); 

const ChildContactoComponent = (props) => {
    const { data={} } = props;
    const classes = useStyles();
    const ubicacion = data.location?String(data.location).split(','):[-17.393557,-66.157715];    
    return(
        <>            
            <Grid container className={classes.customMainContainer} justify="center">
                <Grid item xs={12} sm={10} md={10} lg={8} xl={6}>
                    <Paper className={classes.customPaper}>
                        <Typography variant="h4" align="center" className={classes.customTitle}>{data.title?data.title:''}</Typography>
                        <Grid container>
                            <Grid item xs={12} className={classes.customPadding}>                                
                                <List className={classes.customDataStyle}>
                                    {
                                        data.address?
                                        <ListItem>
                                            <ListItemIcon>
                                            <RoomIcon color="secondary"/>
                                            </ListItemIcon>
                                            <ListItemText primary={data.address}/>
                                        </ListItem>:null
                                    }
                                    {data.address?<Divider />:null}
                                    {
                                        data.email?
                                        <ListItem>
                                            <ListItemIcon>
                                                <MailOutlineIcon color="secondary"/>
                                            </ListItemIcon>
                                            <ListItemText primary={data.email}/>
                                        </ListItem>:null
                                    }
                                    {data.email?<Divider />:null}
                                    {
                                        data.phone?
                                        <ListItem>
                                            <ListItemIcon>
                                                <PhoneIcon color="secondary"/>
                                            </ListItemIcon>
                                            <ListItemText primary={data.phone}/>
                                        </ListItem>:null
                                    }
                                    {data.phone?<Divider />:null}
                                    {
                                        data.whatsapp?
                                        <ListItem>
                                            <ListItemIcon>
                                                <FondAwesome className={classes.whatsapp} size={'2x'} name="whatsapp"/>
                                            </ListItemIcon>
                                            <ListItemText primary={data.whatsapp}/>
                                        </ListItem>:null
                                    }
                                    {data.whatsapp?<Divider />:null}
                                    {
                                        data.website?
                                        <ListItem>
                                            <ListItemIcon>
                                                <LanguageIcon color="secondary"/>
                                            </ListItemIcon>
                                            <ListItemText primary={data.website}/>
                                        </ListItem>:null
                                    }
                                </List>
                            </Grid>
                            <Grid item xs={12} className={classes.customPadding}>
                                <Typography variant="h4" align="center" className={classes.customsubTitle}>{data.mapatitle?data.mapatitle:'Ubicación en mapa'}</Typography>
                                <Map center={ubicacion} zoom={18} attributionControl={false} className={classes.customMapHeight}>
                                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                                    <Marker position={ubicacion}>
                                        <Popup>
                                            Oficina de la unidad
                                        </Popup>
                                    </Marker>
                                </Map>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>                
            </Grid>            
        </>
    );
}

export default ChildContactoComponent;