import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import DropDown from '../dropdown/Dropdown';
import { withRouter } from 'react-router-dom';

import { useSelector} from 'react-redux';

import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import SearchIcon from '@material-ui/icons/Search';

import MoreIcon from '@material-ui/icons/MoreVert';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import FondAwesome from 'react-fontawesome';
import Link from '@material-ui/core/Link';

import { GLOBAL } from '../../../global';
import Helper from '../../../Helper';

const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar,
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logo: {
    maxWidth: '200px'
  },
  social: {
    color: 'white'
  },
  social2: {
    color: '#66cccc'
  },
  customNavBar: {
    // backgroundImage:'linear-gradient(to right, rgb(88,190,208) , rgb(244,69,86))'
    backgroundColor: theme.palette.primary.main
  },
  divider: {
    height: 28,
    backgroundColor: '#ffffff',
    marginTop: theme.spacing(2),
    //border:`1px solid ${theme.palette.common.white}`
  },
  customMenuButton: {
    color: '#ffffff',
  }
}));

function ChildNavBarComponent(props) {  
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const data = useSelector(store => store.datosMain.childMenu);   
  
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl); 

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }  
  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }
  function getComponent(obj) {
    let componentToReturn = null;
    switch (obj.type) {
      case 'interno':
        componentToReturn = <Typography variant='body2' component="span">{obj.label}</Typography>;
        break;
      case 'externo':
        componentToReturn = <Typography variant='body2' component="span">{obj.label}</Typography>;
        break;
      case 'scroll':
        componentToReturn = <Typography variant='body2' component="span">{obj.label}</Typography>;
        break;
      case 'dropdown':
        componentToReturn = <DropDown label={obj.label} data={obj.data} />;
        break;
      case 'descarga':
        componentToReturn = <Typography variant='body2' component="span">{obj.label}</Typography>;
        break;
      case 'modal':
        componentToReturn = <Typography variant='body2' component="span">{obj.label}</Typography>;
        break;
      default:
        break;
    }
    return componentToReturn;
  }
  function goToSomeThing(obj) {
    //console.log("goToSomeThing ind = ",obj);    
      Helper.checkLink(props, obj);    
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {
        data?data.buttons.map((item, index) => (
          <MenuItem onClick={() => goToSomeThing(item)} key={`btn2${index}`}>
            {getComponent(item)}
          </MenuItem>
        )):null
      }
    </Menu>
  );  
  return (
    <div>     
      <AppBar position='fixed' className={classes.customNavBar}>
        <Toolbar>
          <Button
            edge="start"
            className={classes.menuButton}
            color="inherit"
          >
            <img src={`${GLOBAL.assetsBack}${data?data.logo:''}`} alt="Logo Cocha somos todos" className={classes.logo} />
          </Button>
          <Typography className={classes.title} variant="h6" noWrap>
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {
              data?data.buttons.map((item, index) => (
                <Button color="inherit" onClick={() => goToSomeThing(item)} key={`btn${index}`} className={classes.customMenuButton}>
                  {getComponent(item)}
                </Button>
              )):null
            }                      
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.offset}></div>
      {renderMobileMenu}
      {renderMenu}     
    </div>
  );
}
export default withRouter(ChildNavBarComponent);