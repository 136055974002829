import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Box,Typography } from '@material-ui/core';
import YoutubePlayerComponent from '../youtube-player/YoutubePlayerComponent';
//import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Get } from 'react-axios';
import moment from 'moment';

import {GLOBAL} from '../../../global';

const useStyles = makeStyles(theme => ({
    root: {
        //padding: theme.spacing(3, 2),
        margin: theme.spacing(5,0),
        //width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    customVideoArea:{        
        //backgroundColor:theme.palette.tertiary.main
    }
}));

const multimedia = [
    {
        code:1,
        title:'Tutorial para realizar el llenado de Licencia de Funcionamiento - Alcaldía de Cochabamba',
        description:'En este video podrá ver paso a paso  el llenado de una licencia de funcionamiento para actividades económicas para el Municipio de Cochabamba.',
        url:'https://www.youtube.com/watch?v=i0moEU0GGrM&t=8s'
    },
    {
        code:2,
        title:'¡Somos Turismo!',
        description:'Cochabamba es la capital gastronómica de Bolivia.',
        url:'https://www.youtube.com/watch?v=C0z80Wow7iY'
    },
    {
        code:3,
        title:'¡Si te proteges tú, proteges a todos!',
        description:'Evitemos una catástrofe sanitaria cumpliendo todas las medidas de bioseguridad.',
        url:'https://www.youtube.com/watch?v=qDDMqFlhBTY'
    },
    {
        code:4,
        title:'EMSA LIMPIEZA NOCHE',
        description:'Velando por la salud de la población cochabambia, la Empresa Municipal de Servicios de Aseo (EMSA) realiza trabajos de limpieza y desfinfección nocturna en diferentes zonas de nuestra ciudad.',
        url:'https://www.youtube.com/watch?v=AQv-wI-C964'
    }
];

function Sidebar(props) {
    //const matches = useMediaQuery('(min-width:600px)');
    //const [expanded1, setExpanded1] = React.useState(matches);
    //const [expanded2, setExpanded2] = React.useState(matches);
    moment.locale('es');
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = React.useState(null);   

    const handleListItemClick = (event, index,item) => {
        //props.handleCategorySelected(item);
        //console.log(item);
        if(selectedIndex == index){
            setSelectedIndex(null);
            //props.handleCategorySelected(null);
            props.history.push('/noticias');
        }else{
            setSelectedIndex(index);
            //props.handleCategorySelected(item);
            props.history.push('/noticias/categoria/'+item.slug);
            //setSelectedIndex(null);
        }        
    };
    const handleChange = (idobj) => {
        props.history.push('/noticias/'+idobj.slug);
    };

    return (
        <Box className={classes.root}>            
            {
                props.data.categorias?
                <Get url={GLOBAL.apiURL+'/api/categories'}>
                    {(error, response, isLoading, makeRequest, axios) => {
                        if (error) {
                            return (<div>Se presento el siguiente error: {error.message} <button onClick={() => makeRequest({ params: { reload: true } })}>Recargar</button></div>)
                        }
                        else if (isLoading) {
                            return (<div>Cargando...</div>)
                        }
                        else if (response !== null) {
                            return (
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header1">
                                        <Typography variant="h6" gutterBottom>{props.data.categorias.title?props.data.categorias.title:''}</Typography>
                                    </ExpansionPanelSummary>
                                    <Divider />
                                    <ExpansionPanelDetails>
                                        <List component="nav">
                                            {
                                                response.data.map((element,index)=>(
                                                    <React.Fragment key={element.slug}>
                                                        <ListItem                                                            
                                                            button
                                                            selected={selectedIndex === index}
                                                            onClick={event => handleListItemClick(event, index, element)}
                                                        >
                                                            <ListItemText primary={element.nombre} />
                                                        </ListItem>
                                                    </React.Fragment>
                                                ))
                                            }
                                        </List>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            )
                        }
                        return (<div>En breve se hara la solicitud de los datos</div>)
                    }}
                </Get>:null
            }
            {
                props.data.populares?
                <Get url={GLOBAL.apiURL+'/api/posts/mas-leidos/posts'}>
                    {(error, response, isLoading, makeRequest, axios) => {
                        if (error) {
                            return (<div>Se presento el siguiente error: {error.message} <button onClick={() => makeRequest({ params: { reload: true } })}>Recargar</button></div>)
                        }
                        else if (isLoading) {
                            return (<div>Cargando...</div>)
                        }
                        else if (response !== null) {
                            return (
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header2">
                                            <Typography variant="h6" gutterBottom>{props.data.populares.title?props.data.populares.title:''}</Typography>
                                    </ExpansionPanelSummary>
                                    <Divider />
                                    <ExpansionPanelDetails>
                                        <List component="nav">
                                        {
                                            response.data.map((element,index)=>(
                                                <React.Fragment key={element.slug}>
                                                    <ListItem                                                        
                                                        button
                                                        //selected={selectedIndex === index}
                                                        onClick={event=>handleChange(element)}
                                                    >
                                                        <ListItemText primary={String(element.titulo).toUpperCase()} secondary={String(moment(element.fecha).format('LL')).toUpperCase()} />
                                                    </ListItem>
                                                    <Divider />
                                                </React.Fragment>
                                            ))
                                        }
                                        </List>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            )
                        }
                        return (<div>En breve se hara la solicitud de los datos</div>)
                    }}
                </Get>:null
            }
            {
                false?
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header3">
                            <Typography variant="h6" gutterBottom>CONTENIDO MULTIMEDIA</Typography>
                    </ExpansionPanelSummary>
                    <Divider />
                    <ExpansionPanelDetails className={classes.customVideoArea}>
                        <YoutubePlayerComponent data={multimedia}/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>:null
            }
        </Box>
    );
}

export default withRouter(Sidebar);