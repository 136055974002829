import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import Helper from '../../../Helper';

import { withRouter } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0,0),
    textAlign:'center'
  },
  customTitle:{
    padding:'5px 5px',
    minHeight:60,
    backgroundColor:'#80082b',
    color:'white'
  },
  customImg:{
    width:'100%'
  },
  customButton:{
    marginTop:theme.spacing(-15)
  },
  media: {
    height: 200,
  },
}));

function CardWithTitleBgSeeMore(props) {
  const classes = useStyles();
  const handleChange = event => {
    console.log("handleChange");
    //Helper.checkLink(props,{type:'test'});
    props.onHandleClick(props.data.id);
  }

  return (
      <Box className={classes.root}>
        <Typography variant="h5" component="h5" className={classes.customTitle}>
          {props.data.title}
        </Typography>
        {/* <img src={props.data.background} alt='imagen' className={classes.customImg}/> */}
        <CardMedia
            className={classes.media}
            image={props.data.background}
            title={props.title}
        />
        <Button variant="contained" color="secondary" className={classes.customButton} onClick={handleChange}>{props.data.btnlabel}</Button>
      </Box>    
  );
}

export default withRouter(CardWithTitleBgSeeMore);