import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Typography, Button} from '@material-ui/core';
import TreeItem from '@material-ui/lab/TreeItem';
import useAxios from 'axios-hooks';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import { GLOBAL } from '../../global';

const useStyles = makeStyles(theme => ({
    root: {
        //height: 264,
        flexGrow: 1,
        //maxWidth: 400,
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0.5),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
    },
    customTreeItem:{
        backgroundColor:theme.palette.primary.main
    },
    customButton:{
        minWidth:'35px'
    }
}));
var currentNode = null;
var treeBuilt = null;

export default function ArbolComponent(props) {
    const classes = useStyles();
    const [ selected,setSelected ] = React.useState(null);
    const [{ data, error, loading }] = useAxios({ url: `${GLOBAL.assetsBack}/api/getorganigrama2mobile`, method: 'POST' });

    const treeBuilder = (obj) => {
        //console.log("############## treeBuilder called ##############");
        let treeNodes = obj.find(element => element.area_id === null);
        obj.map(element => {
            currentNode = element;
            throughRecursive(treeNodes);
        });
        treeBuilt = treeNodes;
        //console.log("******** treeNodes ********* = ", treeNodes);
    }
    const throughRecursive = (obj) => {
        let aux = false;
        if (obj.id === currentNode.id) {
            aux = true;
            let childs = data.data.filter(element => element.area_id === currentNode.id);
            if (Array.isArray(childs)) {
                currentNode.children = childs;
            }
        }
        if (!aux) {
            if (Array.isArray(obj.children)) {
                obj.children.map(element => throughRecursive(element));
            }
            //Array.isArray(obj.children) ? obj.children.map(element => throughRecursive(element)) : null
        }
    }
    if (data && data.status && data.data && Array.isArray(data.data)) {
        treeBuilder(data.data);
    }
    const getColor = (item) => {
        //console.log("getColor item = ", item);
        let dato = {};
        try {
            dato = item;
        } catch (error) {
            dato = {};
        }
        return { color: dato.backgroundColor };
    }
    const renderTree = (nodes) => (
        <TreeItem 
            key = {nodes.id} 
            nodeId = {String(nodes.id)} 
            label = {
                <div className={classes.labelRoot} style={String(selected)===String(nodes.id)?{backgroundColor:'rgb(109,86,160)'}:null}>
                    <StopRoundedIcon color="inherit" className={classes.labelIcon} style={getColor(nodes.style)} />
                    <Typography variant="body2" className={classes.labelText}>
                        {nodes.data.label}
                    </Typography>
                    {/* <Typography variant="caption" color="inherit">
                        {Array.isArray(nodes.children) && nodes.children.length>0?nodes.children.length:null}
                    </Typography> */}
                    <Button variant="contained" className={classes.customButton} color="secondary" onClick={()=>props.onHandleClick(nodes)} size="small">
                        <OpenInNewIcon fontSize="inherit"/>
                    </Button>
                </div>
            }            
        >
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </TreeItem>
    );
    //throughRecursive(data);
    const onNodeSelected = (event,value) => {
        //console.log("onNodeSelected value=",value);
        setSelected(value);
    }
    return (
        <>
            <TreeView
                className={classes.root}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={['1']}
                defaultExpandIcon={<ChevronRightIcon />}
                defaultEndIcon={<div style={{ width: 24 }} />}
                onNodeSelect={onNodeSelected}
            >
                {treeBuilt ? renderTree(treeBuilt) : null}
            </TreeView>
        </>
    );
}
