import React,{useEffect, useState} from 'react';
import { createMuiTheme,ThemeProvider } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import CssBaseline from '@material-ui/core/CssBaseline';
import axios from 'axios';
import { 
  useDispatch,
  //useSelector 
} from 'react-redux';
import { setDatosEstaticosAction} from './redux/dataDucks';

import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import LoadingView from './views/loading/LoadingView';
import {GLOBAL} from './global';
import { pdfjs } from 'react-pdf';
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';
/*
const futura = {
    fontFamily: 'Futurastd',
    fontStyle: 'medium',    
    src: `
      local('Futurastd'),
      local('Futurastd-Medium'),
      url(${GLOBAL.assetsURL}/fonts/futurastd/Futura_Std_Medium.woff2) format('woff2')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  };
  const theme = createMuiTheme({
    typography: {
      fontFamily: 'Futurastd',
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [futura],
        },
      },
    },
  });
*/
const poppins = {
  fontFamily: 'Poppins',
  fontStyle: 'regular',    
  src: `
    local('Poppins'),
    local('Poppins-Regular'),
    url(${GLOBAL.assetsBack}/front/fonts/poppins/Poppins-Regular.woff2) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
  const breakpoints = createBreakpoints({});
  const theme = createMuiTheme({
    palette:{
      primary:{
        main:'rgb(109,86,160)',
        dark:'rgb(72,39,120)'
      },
      secondary:{
        main:'rgb(234,84,124)',
        dark:'rgb(174,24,87)'
      },
      tertiary:{
        main:'rgb(74,193,224)',
        dark:'rgb(0,172,216)'
      },
      quaternary:{
        main:'rgb(79,185,168)',
        dark:'rgb(0,152,119)'
      },
      quinary:{
        main:'rgb(249,177,84)',
        dark:'rgb(241,135,33)'
      },
      sextary:{
        main:'rgb(60,60,59)',
        light:'rgb(112,111,111)'
      }
    },
    typography: {
      fontFamily: 'Poppins',
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [poppins],
        },        
      },
      MuiTypography: {
        h1: {
          // fontSize: "3rem",
          // [breakpoints.down("sm")]: {
          //   fontSize: "2.5rem"
          // }
        },
        h2: {
          // fontSize: "3rem",
          // [breakpoints.down("sm")]: {
          //   fontSize: "2.5rem"
          // }
        },   
        h3: {
          //fontSize: "3rem",
          [breakpoints.up("xs")]: {
            fontSize: "2.2rem"
          },
          [breakpoints.up("sm")]: {
            fontSize: "2.5rem"
          },
          [breakpoints.up("md")]: {
            fontSize: "2.6rem"
          }
        },
        h4: {
          fontSize: "2.125rem",
          [breakpoints.up("sm")]: {
            fontSize: "1.625rem"
          },
          [breakpoints.up("md")]: {
            fontSize: "1.725rem"
          }
        },
        h5: {
          //fontSize: "1.5rem",
          [breakpoints.up("sm")]: {
            fontSize: "1rem"
          },
          [breakpoints.up("md")]: {
            fontSize: "1.1rem"
          }
        },
        h6: {
          //fontSize: "2.125rem",
          [breakpoints.up("xs")]: {
            fontSize: "1rem"
          },
          [breakpoints.up("sm")]: {
            fontSize: "1.1rem"
          },
          [breakpoints.up("md")]: {
            fontSize: "1.2rem"
          }
        },
        body1:{
          //fontSize: "2.125rem",
          [breakpoints.up("sm")]: {
            fontSize: "0.75rem"
          },
          [breakpoints.up("md")]: {
            fontSize: "0.85rem"
          }
        },
        body2:{
          //fontSize: "2.125rem",
          [breakpoints.up("sm")]: {
            fontSize: "0.75rem"
          },
          [breakpoints.up("md")]: {
            fontSize: "0.85rem"
          }
        },
        subtitle1:{
          //fontSize: "2.125rem",
          [breakpoints.up("sm")]: {
            fontSize: "0.8rem"
          },
          [breakpoints.up("md")]: {
            fontSize: "0.9rem"
          }
        },
        subtitle2:{
          //fontSize: "2.125rem",
          [breakpoints.up("sm")]: {
            fontSize: "0.7rem"
          },
          [breakpoints.up("md")]: {
            fontSize: "0.8rem"
          }
        }
      }
    },
  });

function App() {    
  const [loading,setLoading] = useState(true);
  const [error,setError] = useState(null);   
  const dispatch = useDispatch();
  //const data2 = useSelector(store => store.datosMain);  
  //console.log("DATA2 desde NavbarComponent",data2);

  const getDatos = () => {    
    axios({
      method: 'post',
      url: `${GLOBAL.apiURL}/api/getdataInicial`,      
    })
    .then(function (response) {
      // handle success      
      dispatch(setDatosEstaticosAction(response.data.data));
    })
    .catch(function (err) {
      // handle error
      setError(err);
    })
    .then(function () {
      // always executed
      setLoading(false);
    });
  }
  useEffect(() => {
    //console.log("¡¡¡APP CARGADO AMIGOS!!!");
    getDatos();    
  }, []);  
  return (    
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          {
            loading?
            <LoadingView loading/>:null
          }
          {
            !loading && !error?<Router/>:null
          }
          {
            error?<LoadingView/>:null
          }          
        </BrowserRouter>
      </ThemeProvider>    
  );
}

export default App;
