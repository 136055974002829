import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import imagen1 from "../images/prevencion/img1.png";
import imagen2 from "../images/prevencion/img2.png";
import imagen3 from "../images/prevencion/img3.png";
import imagen4 from "../images/prevencion/img4.png";
import imagen5 from "../images/prevencion/img5.png";
import imagen6 from "../images/prevencion/img6.png";
import imagen7 from "../images/prevencion/img7.png";
import imagen8 from "../images/prevencion/img8.png";
import "./style.css";

const tileData = [
  {
    id: 1,
    img: imagen1,
    title: 'Image',
  },
  {
    id: 2,
    img: imagen2,
    title: 'Image',
  },
  {
    id: 3,
    img: imagen3,
    title: 'Image',
  },
  {
    id: 4,
    img: imagen4,
    title: 'Image',
  },
  {
    id: 5,
    img: imagen5,
    title: 'Image',
  },
  {
    id: 6,
    img: imagen6,
    title: 'Image',
  },
  {
    id: 7,
    img: imagen7,
    title: 'Image',
  },
  {
    id: 8,
    img: imagen8,
    title: 'Image',
  }
]

const Prevencion = () => {
  return (
    <div>
      <Box p={1} bgcolor="secondary.main" color="primary.contrastText" textAlign="center" fontSize={20} fontWeight="fontWeightBold">
        PREVENCIÓN
      </Box>
      <Box m={2} textAlign="center">
        <Grid container spacing={3}> 
        {tileData.map((tile) => (
          <Grid item xs={12} sm={6} md={3} key={tile.id}>
            <Box className="imageList">              
                <img src={tile.img} alt={tile.title} width="100%"/>        
            </Box>
          </Grid>            
        ))}
        </Grid>
      </Box>
    </div>
  );
}
export default Prevencion;
