import React,{useState,useEffect} from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button  from '@material-ui/core/Button';
import GavelIcon from '@material-ui/icons/Gavel';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useAxios from 'axios-hooks';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';

import Pagination from '@material-ui/lab/Pagination';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SearchComponent from '../../../components/genericos/search/SearchComponent';
import CardGaceta from '../../../components/genericos/cards/CardGaceta';
import ErrorRequestComponent from '../../../components/genericos/error/ErrorRequestComponent';
//import PdfViewer from '../../components/genericos/pdf-viewer/PdfViewer';
//import Modal from '../../components/genericos/modal/Modal';
import ModalPdfViewerComponent from '../../../components/genericos/modal/ModalPdfViewerComponent';

import {GLOBAL} from '../../../global';

const { useRef } = React;

const useStyles = makeStyles(theme => ({
    root: {
        padding:theme.spacing(3,1)
    },
    customTabStyles:{
        marginTop:theme.spacing(1)
    },
    customTabStyles2:{
        margin:theme.spacing(2,0)
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    customTabStyles3:{
        margin:theme.spacing(3,0)
    },
    customButton:{
        width:'100%'
    },
    formControl: {
        margin: theme.spacing(0,2),
        minWidth: 120,
        width:'100%'
      },
    selectEmpty: {
        marginTop: theme.spacing(2),
      },
    customSwitch:{
        margin:theme.spacing(2,4)
    },
    customTitle:{
        textAlign:'center',
        color:theme.palette.primary.main
    },
    customSubTitle:{
        color:theme.palette.primary.main,
        //paddingBottom:theme.spacing(3),
        textAlign:'center',
    },
  }));


const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#52d869',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });


let bansearch = '';

const GacetaComponent = (props) => {
    const [gestion,setGestion] = React.useState("");
    const [orden,setOrden] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [tab, setTab] = React.useState(null);
    const [search, setSearch] = React.useState('');
    const { datos={        
        title:null,
        description:null,
        style:{
            container:null,
            title:null,
            subtitle:null
        },
        data:null
    }} = props;
    const [{ data, loading, error }, refetch] = useAxios(
        {
            url:GLOBAL.apiURL+'/api/getgacetadata',
            method:'POST',
            data:{
                orden:orden,
                search:search,
                category:tab,
                gestion:gestion,
                page:page
            }
        },{useCache:false});
    const handleChangeGestion = (event) => {
        setGestion(event.target.value);
        setPage(1);
        /*
        refetch({
            data:{
                orden:orden?1:0,
                search:bansearch,
                category:tab,
                gestion:event.target.value,
                page:1
            }
        });
        */
    }
    const handleChangeOrden = (event) => {
        setOrden(event.target.checked);
        /*
        refetch({
            data:{
                orden:event.target.checked?1:0,
                search:bansearch,
                category:tab,
                gestion:gestion,
                page:page
            }
        });
        */
    }

    const handleChangeTab = (newValue) => {
        //console.log("newValue=",newValue);       
        setPage(1);
        if(tab === newValue){
            setTab(null);
            /*
            refetch({
                data:{
                    orden:orden?1:0,
                    search:bansearch,
                    category:null,
                    gestion:gestion,
                    page:1
                }
            });
            */
        }else{
            setTab(newValue);
            /*
            refetch({
                data:{
                    orden:orden?1:0,
                    search:bansearch,
                    category:newValue,
                    gestion:gestion,
                    page:1
                }
            });
            */                 
        }
        
                
    };
    const handleChangePage = (event, value) => {
        //console.log("CAMBIAR A LA PAGINA VALUE=",value);
        setPage(value);
        /*
        refetch({
            data:{
                orden:orden?1:0,
                search:bansearch,
                category:tab,
                gestion:gestion,
                page:value
            }
        });
        */
    };
    const onHandleSearch = (value) => {
        setPage(1);
        setSearch(value);
        bansearch = value;
        /*
        refetch({
            data:{
                orden:orden?1:0,
                search:bansearch,
                category:tab,
                gestion:gestion,
                page:1
            }
        });
        */        
    }
    const onHandleCancelSearch = () => {        
        setPage(1);
        setSearch('');
        bansearch = '';
        /*
        refetch({
            data:{
                orden:orden?1:0,
                search:bansearch,
                category:tab,
                gestion:gestion,
                page:1
            }
        });
        */    
    }
    //console.log("GacetaComponent bansearch=",bansearch);
    const classes = useStyles();
    const childRef = useRef();
    
    const onHandleClick = (data) => {        
        childRef.current.openModal({title:data.nombre,archivo:GLOBAL.assetsBack+'/'+data.archivo});
    }

    //if (loading) return <p>Loading...</p>
    //if (error) return <p>Error en la obtención de datos.</p>

    useEffect(() => {
       window.scrollTo(0, 0);
    }, []);
    
    return(
        <Box className={classes.root} style={datos && datos.style && datos.style.container?datos.style.container:null}>
            <ModalPdfViewerComponent btnclose={'Cerrar'} ref={childRef}/>
            <Grid container className={classes.customTabStyles} justify="center">
                <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                    <Box mb={3}>
                        {
                            datos.title?
                            <Typography className={classes.customTitle} variant="h3" style={datos.style.title}>{datos.title?datos.title:''}</Typography>:null
                        }                            
                        {
                            datos.description?
                            <Typography className={classes.customSubTitle} variant="h6" style={datos.style.subtitle}>{datos.description?datos.description:''}</Typography>:null
                        }
                        
                    </Box>
                </Grid>
            </Grid>
            {
                !error?
                (
                    <>
                        <Grid container className={classes.customTabStyles}>
                            <Grid item xs={12} sm={12} md={1} lg={2} xl={3}></Grid>
                            <Grid item xs={12} sm={12} md={10} lg={8} xl={6}>                    
                                <Paper elevation={0} square>
                                    {
                                        1==0?
                                        <Tabs
                                            value={tab}
                                            indicatorColor="secondary"
                                            textColor="secondary"
                                            variant="fullWidth"
                                            centered
                                            onChange={handleChangeTab}
                                        >
                                            <Tab icon={<GavelIcon />} label="MUNICIPAL" />
                                            <Tab icon={<GavelIcon />} label="EDIL" />
                                            <Tab icon={<GavelIcon />} label="EJECUTIVO" />
                                        </Tabs>:
                                        <Grid container className={classes.customTabStyles3}>
                                            <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                                                <Button className={classes.customButton} variant="contained" size="large" color={tab===0?"secondary":"default"} onClick={() => handleChangeTab(0)}>DECRETO MUNICIPAL</Button>
                                            </Grid>
                                            <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                                                <Button className={classes.customButton} variant="contained" size="large" color={tab===1?"secondary":"default"} onClick={() => handleChangeTab(1)}>DECRETO EDIL</Button>
                                            </Grid>
                                            <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                                                <Button className={classes.customButton} variant="contained" size="large" color={tab===2?"secondary":"default"} onClick={() => handleChangeTab(2)}>DECRETO EJECUTIVO</Button>
                                            </Grid>
                                        </Grid>
                                        
                                    }
                                </Paper>                    
                            </Grid>
                            <Grid item xs={12} sm={6} md={1} lg={2} xl={3}></Grid>
                        </Grid>
                        <Grid container justify="center">
                            <Grid item xs={12} sm={12} md={1} lg={2} xl={3}></Grid>
                            <Grid item xs={12} sm={12} md={10} lg={8} xl={6}>
                                <Grid container>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <SearchComponent onHandleSearch={onHandleSearch} onHandleCancelSearch={onHandleCancelSearch}/>
                                    </Grid>                            
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Grid container justify="center">
                                            <Grid item xs={6}>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    <InputLabel id="gestion">GESTIÓN</InputLabel>
                                                    <Select
                                                        labelId="gestion"
                                                        id="gestion"
                                                        value={gestion}
                                                        onChange={handleChangeGestion}
                                                        label="FILTRAR POR GESTIÓN"
                                                        >
                                                        <MenuItem value="">
                                                            <em>FILTRAR POR GESTIÓN</em>
                                                        </MenuItem>
                                                        {
                                                            data && data.gestiones?data.gestiones.map((item,index)=>(
                                                                <MenuItem key={`ges${index}`} value={item.gestion}>{item.gestion}</MenuItem>
                                                            )):null
                                                        }                                                        
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormGroup className={classes.customSwitch}>
                                                    <FormControlLabel
                                                        control={<IOSSwitch checked={orden} onChange={handleChangeOrden} name="checked" />}
                                                        label={orden?"ASCENDENTE":"DESCENDENTE"}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={1} lg={2} xl={3}></Grid>                            
                        </Grid>
                        <Grid container className={classes.customTabStyles2}>
                            <Grid item xs={12} sm={12} md={1} lg={2} xl={3}></Grid>
                            <Grid item xs={12} sm={12} md={10} lg={8} xl={6}>                    
                                <Grid container spacing={3}>
                                    {
                                        loading?
                                        Array.from(new Array(4)).map((item,index) => (
                                            <Grid item xs={6} key={index}>
                                                <CardGaceta loading/>
                                            </Grid>
                                        )):
                                        data && data.data && data.data.data?data.data.data.map((item,index) => (
                                            <Grid item xs={6} key={index}>
                                                <CardGaceta data={item} onHandleClick={onHandleClick}/>
                                            </Grid>
                                        )):null
                                    }
                                </Grid>
                                <br/>
                                <Pagination count={data && data.data?data.data.last_page:1} page={page} onChange={handleChangePage} color="secondary"/>
                            {/*<CardGacetaComponent/>*/}
                            </Grid>
                            <Grid item xs={12} sm={12} md={1} lg={2} xl={3}></Grid>
                        </Grid>
                    </>
                ):
                (
                    <Grid item xs={12}>
                        <ErrorRequestComponent handleClick={refetch}/>
                    </Grid>
                )
            }
        </Box>
    );
}
export default GacetaComponent;
