import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',    
  },
  margin: {
    margin: theme.spacing(1),    
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    flexBasis: 200    
  },
}));

export default function CampoBuscar(props) {
  const classes = useStyles();

  return (
    
    <FormControl fullWidth={true} className={clsx(classes.margin, classes.textField)}>
        <InputLabel htmlFor="adornment-search">{props.placeholder}</InputLabel>
        <Input
          id="adornment-search"
          value={props.txtSearch}
          onChange={props.handleChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton                
                onClick={props.handleClickToClear}
              >
                {props.txtSearch.length>0 ? <Clear /> : <Search />}
              </IconButton>
            </InputAdornment>
          }
        />
    </FormControl>
    
  );
}