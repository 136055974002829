//Desde la store siempre se deben llamar a los reducer
import { createStore,combineReducers,compose,applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import dataReducer from './dataDucks';

//solo esto se modifica
const rootReducer = combineReducers({
    datosMain : dataReducer    
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore(){
    const store = createStore( rootReducer,composeEnhancers( applyMiddleware(thunk)));
    return store;
}