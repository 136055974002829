import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useAxios from 'axios-hooks';
import { useForm } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box,Button, Grid,Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
    customContainer:{
        margin:theme.spacing(10,1)
    },
    customTitle:{
        textAlign:'center',
        color:theme.palette.primary.main
    },
    customSubtitle:{
        textAlign:'center',
        margin:theme.spacing(3,0),
        color:theme.palette.primary.main
    },
    customButton:{
        height:theme.spacing(7)
    },
    customTextContainer:{
        textAlign:'center'
    }
}));

export default function SeguimientoTramitesCatastroComponent() {
    const classes = useStyles();
    const [{ data, loading, error }, refetch] = useAxios({url:`https://servicio1.catastrocochabamba.com/STCService.svc/BUSQUEDATRAMITENRO/`,method:'GET'},{manual:true});
    const { register, handleSubmit,formState,errors, reset, getValues } = useForm({mode:'onChange'});    
    const [onrequest, setOnrequest] = React.useState(false); 

    const onSubmit = async (datos) => {        
        setOnrequest(true);

        try {
            //const response = await executeRequest();
            const response = await refetch({url:`https://servicio1.catastrocochabamba.com/STCService.svc/BUSQUEDATRAMITENRO/${datos.nro_tramite}`});        
            //console.log('Datos obtenidos:', response);
        } catch (error) {
            console.error('Data:', error);
        }

        
    };
    const NewRequest = () => {        
        setOnrequest(false);        
        reset();        
    }

    return (
        <Box className={classes.customContainer}>
            <Grid container justify="center">
                <Grid item xs={12} md={8}>
                    <Box>
                        <Typography variant='h3' className={classes.customTitle}>Seguimiento de trámites</Typography>
                        <Typography variant='h6' className={classes.customSubtitle}>Realice el seguimiento de los tramites catastrales</Typography>
                    </Box>
                    <Box className={classes.customTextContainer}>
                        <form onSubmit={handleSubmit(onSubmit)}> 
                            <TextField
                                disabled={onrequest}
                                color="primary"
                                id="nro_tramite"
                                name="nro_tramite"
                                label="Número de trámite"
                                variant="outlined"
                                //helperText="Requerido"
                                //className={classes.customWith}
                                //value={input.nombre}
                                //onChange={handleInputChange}
                                inputRef={register({
                                    required:true,
                                    minLength:5,
                                    maxLength:20,
                                    pattern: {
                                        value: /^[0-9]*$/,
                                        message: 'Ingresa solo números',
                                    },
                                })}
                                error={errors.nro_tramite?true:false}
                            />
                            {
                                onrequest?
                                <Button variant="contained" color="secondary" className={classes.customButton} onClick={() => NewRequest()}>Nueva consulta</Button>:                                
                                <Button disabled={!formState.isValid} color="primary" variant="contained" className={classes.customButton} onClick={()=>handleSubmit(onSubmit)()}>Consultar</Button>
                            }
                            
                        </form>
                    </Box>
                    <Box mt={5}>
                        {
                            loading?
                            <CircularProgress/>:null
                        }
                        {
                            onrequest && error?
                            <Alert variant="filled" severity="error">
                                Trámite no encontrado!
                            </Alert>:null
                        }
                        {
                            onrequest && !error && data?
                            <>
                                <Alert variant="filled" severity="success">
                                    <Typography variant="body2">CONSULTA EXITOSA</Typography>
                                </Alert>
                                <br/>
                                {
                                    data?
                                    <List subheader={<Typography variant="button">RESULTADOS DE LA CONSULTA</Typography>}>
                                        <ListItem>
                                            <ListItemText primary="NRO. TRAMITE" secondary={String(data.NROTRAMITE?data.NROTRAMITE:'').toUpperCase()} />
                                        </ListItem>
                                        <ListItem>                                                
                                            <ListItemText primary="COMUNA" secondary={String(data.COMUNA?data.COMUNA:'').toUpperCase()} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="FECHA INGRESO" secondary={String(data.FECHAINGRESO?data.FECHAINGRESO:'').toUpperCase()} />
                                        </ListItem>
                                        <ListItem>                                                
                                            <ListItemText primary="FECHA SALIDA" secondary={String(data.FECHASALIDA?data.FECHASALIDA:'').toUpperCase()} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="GESTION TRAMITE" secondary={String(data.GESTIONTRAMITE?data.GESTIONTRAMITE:'').toUpperCase()} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="MOTIVO" secondary={String(data.MOTIVO?data.MOTIVO:'').toUpperCase()} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="UNIDAD ORIGEN" secondary={String(data.UNIDADORIGEN?data.UNIDADORIGEN:'').toUpperCase()} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="UNIDAD DESTINO" secondary={String(data.UNIDADDESTINO?data.UNIDADDESTINO:'').toUpperCase()} />
                                        </ListItem>
                                    </List>:null
                                }
                            </>:null
                        }
                    </Box>
                </Grid>            
            </Grid>
        </Box>
    );
}
