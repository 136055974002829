import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import convert from 'htmr';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      padding:theme.spacing(10,1),
      //backgroundColor:theme.palette.secondary.main
    },    
    customContaimer:{      
      color:theme.palette.sextary.ligth,
      fontSize:"16px"
    },
    customSubTitle:{
      color:theme.palette.primary.main,
      //color:"white",
      paddingTop:theme.spacing(3),
      paddingBottom:theme.spacing(0),
      textAlign:'center',
  },
  }));

const ChildMisionVisionComponent = (props) => {    
    const { data=[] } = props;
    const classes = useStyles();
    return(
        <Grid container justify='center' className={classes.root}>
            <Grid item xs={12} sm={12} md={10} lg={8} xl={7}>
                {
                    data.map((item,index) => (
                        <Box key={index} className={classes.customContaimer}>
                            <Typography className={classes.customSubTitle} variant="h4">{item.title}</Typography>
                            {convert(item.description)}              
                        </Box>
                    ))
                }                
            </Grid>
        </Grid>
    );
}
export default ChildMisionVisionComponent;