import React from 'react';
import '../../../../App.css';

const GraficaComponent = (props) => {
    const handleClick = (data) => {
        //console.log("data=",data);
        props.onHandleOptions(data);
    }
    return(
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 344 280" style={{enableBackground:"new 0 0 344 280"}}>
            <g id="Capa_3">            
                    <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-504.9744" y1="383.3165" x2="-504.9744" y2="169.3471" gradientTransform="matrix(-1.2702 -1.408374e-02 6.672510e-03 -1 -475.221 377.7032)">
                    <stop  offset="0.8" style={{stopColor:"#FFFFFF"}}/>
                    <stop  offset="0.8" style={{stopColor:"#FFFFFF",stopOpacity:"0"}}/>
                </linearGradient>
                <path className="st0" d="M38.8,137.2c-3.4-9.6-5.2-19.6-5.1-30.1C34.3,48.5,94.9,1.4,169.1,2s133.8,48.9,133.3,107.7
                    c-0.3,33.5-20,63.1-50.6,82.4C228.5,206.6,199,215.2,167,215c-31-0.3-59.4-8.8-82-23C63.1,178.1,46.7,159.1,38.8,137.2"/>
                
                    <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="262.5174" y1="111.2947" x2="262.5174" y2="90.3338" gradientTransform="matrix(1 0 0 -1 0 282)">
                    <stop  offset="0.8" style={{stopColor:"#FFFFFF"}}/>
                    <stop  offset="0.8" style={{stopColor:"#FFFFFF",stopOpacity:"0"}}/>
                </linearGradient>
                <path className="st1" d="M249.9,192.4c9-5.4,18-11.7,25.2-19.3"/>
                
                    <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="72.554" y1="111.289" x2="72.554" y2="90.3312" gradientTransform="matrix(1 0 0 -1 0 282)">
                    <stop  offset="0.8" style={{stopColor:"#FFFFFF"}}/>
                    <stop  offset="0.8" style={{stopColor:"#FFFFFF",stopOpacity:"0"}}/>
                </linearGradient>
                <path className="st2" d="M59.7,171.1c7.1,7.5,16.4,14,25.8,20.1"/>
            </g>
            <g className="st3">
                <g>
                    <line className="st4" x1="165.7" y1="110.4" x2="98.8" y2="179.9"/>
                    <g>
                        <polygon className="st5" points="98.6,178.3 99.1,179.6 100.4,180.1 97.4,181.4 			"/>
                    </g>
                </g>
            </g>
            <g>
                <circle className="st6" cx="38.7" cy="138.8" r="11.3"/>
                <circle id="cReglamento" className="st7 over" cx="38.8" cy="139" r="15.7" onClick={() => handleClick({show:true,anchor:'left',opcion:"leyes_decretos"})}/>
            </g>
            <g>
                <circle className="st8" cx="85.1" cy="192.7" r="11.3"/>
                <circle className="st9 over" cx="85.6" cy="192.5" r="15.7" onClick={() => handleClick({show:true,anchor:'left',opcion:'codigo_catastral'})}/>
            </g>
            <g>
                <circle className="st10" cx="251.7" cy="193.8" r="11.3"/>
                <circle className="st11 over" cx="251.5" cy="193.6" r="15.7" onClick={() => handleClick({show:true,anchor:'right',opcion:'actualizacion_datos_tecnicos'})}/>
            </g>
            <g>
                <circle className="st12" cx="297" cy="138.8" r="11.3"/>
                <circle className="st13 over" cx="297.2" cy="139" r="15.7" onClick={() => handleClick({show:true,anchor:'right',opcion:"consulta_deuda_inmueble"})}/>
            </g>
            <g className="st3">
                <g>
                    <line className="st4" x1="165" y1="108.6" x2="57.3" y2="135.6"/>
                    <g>
                        <polygon className="st5" points="54.5,136.1 57.9,133.7 57.4,135.5 58.7,136.8 			"/>
                    </g>
                </g>
            </g>
            <circle className="st14" cx="167.5" cy="55.9" r="19.8"/>
            <circle className="st15 over" cx="167.1" cy="55.9" r="25" onClick={() => handleClick({show:true,anchor:"top",opcion:"sectoriales"})}/>
            <g id="document_x5F_text_x5F_accept_2_" className="st16">
                <path className="st17" d="M174.4,55.6V50l-3.6-3.6h-12v20.9h15.6l0,0c3-0.4,5.2-2.8,5.2-5.8C179.6,58.5,177.4,56,174.4,55.6z M170.5,48
                    l2.3,2.3h-2.3V48z M160.1,66V47.8h9.1v3.9h3.9v4c-1.1,0.1-2.2,0.6-3.1,1.3h-8.7v1.3h7.5c-0.3,0.4-0.5,0.8-0.6,1.3h-6.9v1.2h6.6
                    c0,0.2,0,0.5,0,0.6c0,1.9,0.8,3.5,2.2,4.5C170.1,66,160.1,66,160.1,66z M173.8,66c-2.5,0-4.5-2-4.5-4.5c0-2.5,2-4.5,4.5-4.5
                    c2.5,0,4.5,2,4.5,4.5C178.2,64,176.3,66,173.8,66z M171.8,54.3h-10.5v1.3h10.5V54.3z"/>
                <polygon className="st17" points="170.5,61.5 171.8,60.2 173.1,61.5 175.7,58.9 177,60.2 173.1,64.1 	"/>
            </g>
            <text transform="matrix(1 0 0 1 29.7456 160.2878)" className="st5 st18 st19">LEYES</text>
            <text transform="matrix(1 0 0 1 24.1802 166.2243)" className="st5 st18 st20">MUNICIPALES</text>
            <text transform="matrix(1 0 0 1 26.9634 175.1276)" className="st5 st18 st19">DECRETO</text>
            <text transform="matrix(1 0 0 1 28.8179 181.0642)" className="st5 st18 st20">MUNICIPAL</text>
            <text transform="matrix(1 0 0 1 45.2398 214.9597)" className="st5 st18 st19">OBTÉN TU CÓDIGO CATASTRAL</text>
            <text transform="matrix(1 0 0 1 69.2632 220.8962)" className="st5 st18 st20">MAPA DIGITAL</text>
            <text transform="matrix(1 0 0 1 232.1353 216.0163)" className="st5 st18 st19">ACTUALIZACIÓN</text>
            <text transform="matrix(1 0 0 1 232.5064 221.9519)" className="st5 st18 st20">DE DATOS TÉCNICOS</text>
            <text transform="matrix(1 0 0 1 292.7349 160.2868)" className="st5 st18 st19">CONSULTA</text>
            <text transform="matrix(1 0 0 1 288.4683 166.2233)" className="st5 st18 st20">DEUDA INMUEBLE</text>
            <text transform="matrix(1 0 0 1 138.2671 95.1267)" className="st5 st18 st21">SECTORIALES</text>
            <text transform="matrix(1 0 0 1 136.4126 101.6188)" className="st5 st18 st20">Y PLANOS ZONAS HOMOGÉNEAS</text>
            {/* <text transform="matrix(1 0 0 1 122.4995 275.6091)" className="st5 st18 st22">1</text>
            <text transform="matrix(1 0 0 1 127.1372 272.5202)" className="st5 st18 st23">ra </text>
            <text transform="matrix(1 0 0 1 134.5571 275.6091)" className="st5 st18 st22"> ETAPA</text>
            <text transform="matrix(1 0 0 1 175.9868 276.2272)" className="st5 st18 st22">2</text>
            <text transform="matrix(1 0 0 1 180.7456 273.1384)" className="st5 st18 st23">da </text>
            <text transform="matrix(1 0 0 1 189.2818 276.2272)" className="st5 st18 st22"> ETAPA</text> */}
            {/* <text transform="matrix(1 0 0 1 141.9771 259.8102)" className="st5 st18 st21">REQUISITOS</text> */}
            <g>
                <circle className="st5" cx="168" cy="215" r="11.3"/>
                <circle className="st24 over" cx="168" cy="215" r="15.7" onClick={() => handleClick({show:true,anchor:"bottom",opcion:"transparentando"})}/>
            </g>
            <g className="st3">
                <g>
                    <line className="st4" x1="238.9" y1="180.7" x2="171.1" y2="110.4"/>
                    <g>
                        <polygon className="st5" points="240.4,182.2 237.3,180.9 238.6,180.5 239.2,179.2 			"/>
                    </g>
                </g>
            </g>
            <g className="st3">
                <g>
                    <line className="st4" x1="279.3" y1="134.6" x2="173.1" y2="108.7"/>
                    <g>
                        <polygon className="st5" points="278,135.8 279.3,134.5 278.6,132.8 282,135.3 			"/>
                    </g>
                </g>
            </g>
            <g className="st3">
                <g>
                    <line className="st4" x1="168.4" y1="197.2" x2="168.7" y2="110.9"/>
                    <g>
                        <polygon className="st5" points="168.4,199.3 167.1,196.2 168.4,196.8 169.7,196.3 			"/>
                    </g>
                </g>
            </g>
            <text transform="matrix(1 0 0 1 141.0503 237.1169)" className="st5 st18 st19">TRANSPARENTANDO</text>
            <text transform="matrix(1 0 0 1 145.2241 243.0544)" className="st5 st18 st20">LA GESTIÓN MUNICIPAL</text>
        </svg>
    );
}

export default GraficaComponent;