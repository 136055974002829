import React from 'react';
import { Switch,Route } from 'react-router-dom';
import MainView from './views/main/MainView';

/*
import Home from './views/home/Home';
import HomeDesarrolloSustentable from './views/municipio/desarrollo-sustentable/Home-desarrollo-sustentable';
import HomeSecretariaGeneral from './views/municipio/secretaria-general/home-secretaria-general';
import SecretariaDesarrolloHumano from './views/municipio/secretaria-desarrollo-humano/secretaria-desarrollo-humano';
import SecretariaServiciosAlCiudadano from './views/municipio/secretaria-servicios-ciudadano/secretaria-servicios-ciudadano';
//import SecretariaCulturaTurismo from './views/municipio/secretaria-cultura-turismo/secretaria-cultura-turismo';
import SecretariaProteccionCiudadano from './views/municipio/secretaria-proteccion-ciudadano/secretaria-proteccion-ciudadano';
import SecretariaGobernabilidad from './views/municipio/secretaria-gobernabilidad/secretaria-gobernabilidad';
import SecretariaInfraestructraTerritorial from './views/municipio/secretaria-infraestructura-territorial/secretaria-infraestructura-territorial';
import SecretariaPlanificacion from './views/municipio/secretaria-planificacion/secretaria-planificacion';
import SecretariaAsuntosJuridicos from './views/municipio/secretaria-asuntos-juridicos/secretaria-asuntos-juridicos';
import SecretariaAdministrativaFinanciera from './views/municipio/secretaria-administrativa-financiera/secretaria-administrativa-financiera';
import SecretariaSaludComponent from './views/municipio/secretaria-salud/SecretariaSaludComponent';

import Cultura from './views/cultura/Cultura';
import Prensa from './views/prensa/Prensa';
import SaludComponent from './views/salud/SaludComponent';
import SubalcaldiaComponent from './views/subalcaldia/SubalcaldiaComponent';
import ContactosComponent from './views/contactos/ContactosComponent';
import TraficoVialidadComponent from './views/trafico-vialidad/TraficoVialidadComponent';

import CiudadInteligente from './views/otras-secciones/ciudad-inteligente/CiudadInteligente';
import AppsComponent from './views/otras-secciones/apps/AppsComponent';
import GacetaComponent from './views/otras-secciones/gaceta/GacetaComponent';
import InformeGestionComponent from './views/otras-secciones/informe-gestion/InformeGestionComponent';
import MiPlanoComponent from './views/otras-secciones/mi-plano/MiPlanoComponent';
import ProveedoresComponent from './views/otras-secciones/proveedores/ProveedoresComponent';
import DireccionUrbanismoComponent from './views/otras-secciones/direccion-urbanismo/DireccionUrbanismoComponent';
import LiteraturaComponent from './views/cultura/views/LiteraturaComponent';
import ConcursosMunicipalesComponent from './views/cultura/views/ConcursosMunicipalesComponent';

//import EmprendedurismoComponent from './views/otras-secciones/emprendedurismo/EmprendedurismoComponent';
import EmprendedurismoComponent2 from './views/otras-secciones/emprendedurismo/EmprendedurismoComponent2';
import AuditoriaInternaComponent from './views/otras-secciones/auditoria-interna/AuditoriaInternaComponent';
import DefensoriaNinesAdolescencia from './views/otras-secciones/DefensoriaNinesAdolescencia/DefensoriaNinesAdolescencia';
import SlimView from './views/otras-secciones/Slim/SlimView';

import SemView from './views/otras-secciones/sem/SemView';

import InfoTramitesView from './views/otras-secciones/info-tramites/infotramitesView';
import BiografiaComponent from './views/home/secciones/BiografiaComponent';
*/
import Notfound from './views/not-found/Notfound';


function Router(){    
    return(
        <Switch>
            <Route exact path="/unidad/:child" component={MainView}/>            
            <Route exact path="/:main" component={ MainView }/>
            <Route exact path="/:main/categoria/:id_categoria" component={MainView}/>
            <Route exact path="/:main/:id" component={MainView}/>
            <Route exact path="/unidad/:child/:encrypted_id/show" component={MainView}/>
            <Route exact path="/" component={ MainView }/>        
            {/* <Route exact path="/apps" component={ AppsComponent }/>

            <Route exact path="/secretaria-desarrollo-sustentable" component={ HomeDesarrolloSustentable }/>
            <Route exact path="/secretaria-general" component={ HomeSecretariaGeneral }/>
            <Route exact path="/secretaria-desarrollo-humano" component={ SecretariaDesarrolloHumano }/>
            <Route exact path="/secretaria-servicios-ciudadano" component={ SecretariaServiciosAlCiudadano }/>
            
            <Route exact path="/secretaria-salud" component={ SecretariaSaludComponent }/>            
            <Route exact path="/secretaria-proteccion-ciudadano" component={ SecretariaProteccionCiudadano }/>           
            <Route exact path="/secretaria-gobernabilidad" component={ SecretariaGobernabilidad }/>           
            <Route exact path="/secretaria-infraestructura-territorial" component={ SecretariaInfraestructraTerritorial }/>           
            <Route exact path="/secretaria-planificacion" component={ SecretariaPlanificacion }/>           
            <Route exact path="/secretaria-asuntos-juridicos" component={ SecretariaAsuntosJuridicos }/>           
            <Route exact path="/secretaria-administrativa-financiera" component={ SecretariaAdministrativaFinanciera }/>            

            <Route exact path="/cultura" component={ Cultura }/>
            <Route exact path="/cultura-literatura" component={ LiteraturaComponent }/>
            <Route exact path="/cultura-concursos-municipales" component={ ConcursosMunicipalesComponent }/>

            <Route exact path="/noticias/categoria/:id_categoria" component={Prensa}/>
            <Route exact path="/noticias/:id" component={Prensa}/>
            <Route exact path="/noticias" component={Prensa}/> */}
            {/*
                false?
                <Route exact path="/:child/biografia" component={BiografiaComponent}/>:null
            */
            }
            {/* <Route exact path="/gaceta" component={GacetaComponent}/>
            <Route exact path="/informe-gestion" component={InformeGestionComponent}/>
            <Route exact path="/subalcaldias" component={SubalcaldiaComponent}/>
            <Route exact path="/contactos" component={ContactosComponent}/>
            <Route exact path="/mi-plano" component={MiPlanoComponent}/>
            <Route exact path="/traficoyvialidad" component={TraficoVialidadComponent}/>
            <Route exact path="/salud" component={SaludComponent}/>
            <Route exact path="/proveedores" component={ProveedoresComponent}/>
            <Route exact path="/tramites-gamc" component={CiudadInteligente}/>
            <Route exact path="/direccion-urbanismo" component={DireccionUrbanismoComponent}/>
            <Route exact path="/auditoria-interna" component={AuditoriaInternaComponent}/>
            <Route exact path="/emprendedurismo" component={EmprendedurismoComponent2}/> */}
            {/*
                true?
                <Route exact path="/info-tramites" component={InfoTramitesView}/>:null
            */
            }
            {/*
                false?
                <Route exact path="/sem" component={SemView}/>:null
            */
            }
            {/*
                false?
                <Route exact path="/dna" component={DefensoriaNinesAdolescencia}/>:null
            */
            }
            {/*
                false?
                <Route exact path="/slim" component={SlimView}/>:null
            */
            }
            {/* <Route component={ Notfound }/> */}
        </Switch>
    );
}

export default Router;