import React,{useRef,useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Servicios from './components/servicios';
import Prevencion from './components/prevencion';
import Autorizacion from './components/autorizacion';
import Encuentranos from './components/encuentranos';

import CustomPortadaComponent from '../../../components/genericos/portadas/portada-material-ui/CustomPortadaComponent';
import {GLOBAL} from '../../../global';

const useStyles = makeStyles(theme => ({
    root:{
        flexGrow:1
    },    
}));

const dataPortada = {
    title:'DEFENSORÍA DE LA NIÑEZ Y ADOLESCENCIA',
    subtitle:'',
    description:[
        'Somos parte del Gobierno Autónomo Municipal de Cochabamba, llamados por ley para garantizar y restituir los derechos de niñas, niños y adolescentes. Trabajamos para prevenir todo tipo de violencia y brindar un apoyo legal, psicológico y social.'
    ],
    background:GLOBAL.assetsURL+'/images/defensoria-nines-adolescencia/portada.jpg',
    logo:{
        title:'',
        img:null,
    }    
}

const DefensoriaNinesAdolescenciaComponent = () => {    
    const classes = useStyles(); 
    useEffect(() => {
       window.scrollTo(0, 0);
    }, []);
    return(
        <React.Fragment>
            {false?<CustomPortadaComponent data={dataPortada} descriptionstyle={{backgroundColor:'rgba(0,0,0,0.2)',padding:'10px'}}/>:null}
            <Grid container justify='center'>
                <Grid item xs={12} sm={11} md={10} lg={9} xl={8}>
                    <Servicios />
                    <Prevencion />
                    <Autorizacion />
                    <Encuentranos />
                </Grid>
            </Grid>
        </React.Fragment>               
    );
}
export default DefensoriaNinesAdolescenciaComponent;
