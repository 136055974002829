import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Parallax} from 'react-parallax';
import convert from 'htmr';
import {GLOBAL} from '../../global';

/*
const data2 = {
        mainContainerStyle:null,
        containerStyle:{
            paddingTop: 0,
            paddingBottom: 5,
        },
        parallax:{
            enabled:false,
            bgimg:'/img/secciones/bg-default4-01.png',
            blur:0,
            strength:200
        },
        text:'<p>Para mayor información click <a href="https://simat.cochabamba.bo">aquí</a></p>'
    };
*/

const useStyles = props => makeStyles( theme => ({    
    customMargin:{
        paddingTop:theme.spacing(props.containerStyle && (props.containerStyle.paddingTop || props.containerStyle.paddingTop===0)?props.containerStyle.paddingTop:5),
        paddingBottom:theme.spacing(props.containerStyle && (props.containerStyle.paddingBottom || props.containerStyle.paddingBottom===0)?props.containerStyle.paddingBottom:5),
        paddingLeft:theme.spacing(1),
        paddingRight:theme.spacing(1)
    }
}));

const MyComponent = (props) => {    
    const {data={}} = props;
    const classes = useStyles(data)();
    //console.log(data);
    return(
        <Grid container justify="center">
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>                                        
                {convert(data.text)}
            </Grid>
        </Grid>
    );
}

const HtmlComponent = (props) => {
    const {data={}} = props;
    //return <pre>{JSON.stringify(data)}</pre>
    return data.parallax.enabled?
    <Parallax blur={data.parallax.blur?data.parallax.blur:0} bgImage={`${GLOBAL.assetsBack}${data.parallax.bgimg}`} bgImageAlt="Background image" strength={data.parallax.strength?data.parallax.strength:0}>
        <MyComponent data={data}/>
    </Parallax>:<div style={data.mainContainerStyle}><MyComponent data={data}/></div>
}

export default HtmlComponent;