import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Divider from '@material-ui/core/Divider';
import {Parallax} from 'react-parallax';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { GLOBAL } from '../../global';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,      
      backgroundColor:theme.palette.quaternary.main
    },    
    customContaimer:{
      color:theme.palette.sextary.light,
      fontSize:theme.typography.body1.fontSize
    },
    customTitle:{
        textAlign:'center',
        color:theme.palette.primary.main
        //color:'white'

    },
    customSubTitle:{
        color:'#ffffff',
        //paddingBottom:theme.spacing(10),
        textAlign:'center',            
    },
  }));
/*
  const data = {    
    "style":null,
    "title":{
        style: null,
        data:"Distritos"
    },
    "description": {
        "style":null,
        "data":"Distritos y Organizaciones Territoriales de Base(OTB)"
    },
    "items":[
        {
            "title":"Distro 1",
            "subtitle":"Organizaciones Territoriales de Base(OTB)",
            "items":[
                "Otb nro 1",
                "Otb nro 2",
                "Otb nro 3"
            ]
        },
        {
            "title":"Distro 2",
            "subtitle":"Organizaciones Territoriales de Base(OTB)",
            "items":[
                "Otb nro 1",
                "Otb nro 2",
                "Otb nro 3",
                "Otb nro 3",
                "Otb nro 3",
                "Otb nro 3"
            ]
        }
    ]
  }
*/
const DesplegableComponent = (props) => {
    const {data={}} = props;
    const classes = useStyles();
    console.log("DesplegableComponent data=",data);
    return(
        <Box py={5} className={classes.root}>
            <Grid container justify='center'>
                <Grid item xs={12} sm={12} md={10} lg={8} xl={7}>
                    <Box textAlign="justify" mb={3}>
                        {
                            data && data.title && data.title.data?
                            <Typography className={classes.customTitle} variant="h3" style={data.title.style?data.title.style:null}>{data.title.data}</Typography>:null
                        }
                        {
                            data && data.description && data.description.data?
                            <Typography className={classes.customSubTitle} variant="h6" style={data.description.style?data.title.style:null}>{data.description.data}</Typography>:null
                        }
                    </Box>
                    <Box>
                        {
                            data && data.items && Array.isArray(data.items)?
                            data.items.map((item,index)=>(
                                <ExpansionPanel key={`j${index}`}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header">
                                        <Typography variant="subtitle1" align="center">{item.title}</Typography>
                                    </ExpansionPanelSummary>
                                    <Divider/>
                                    <ExpansionPanelDetails>                                    
                                        <List subheader={<ListSubheader>{item.subtitle}</ListSubheader>}>
                                            {
                                                item.items && Array.isArray(item.items)?
                                                item.items.map((element,ind)=>(
                                                    <ListItem key={`item${ind}`}>
                                                        <ListItemText id="itemotb" primary={`${ind+1}.- ${element}`} />                                            
                                                    </ListItem>
                                                )):null
                                            }
                                        </List>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            )):null
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
export default DesplegableComponent;