import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import { Button, CardActions } from '@material-ui/core';
import QRCode from "react-qr-code";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: "100%",
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    customAction:{
        marginBottom:theme.spacing(3)
    }
    
}));

const CardServicios = (props) => {
    const { data,url='https://cochabamba.bo',loading=false } = props;
    const classes = useStyles();   
    const canvasRef = useRef();

    const handleCanvas = () => {
        let s = new XMLSerializer().serializeToString(canvasRef.current);
        let encodedData = btoa(s);
        //props.onHandleChange(props.data,encodedData);
        props.onHandleChange(data,encodedData);
    };

    return (
        <>
            <Card className={classes.root}>                
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {data.descripcion_area}
                    </Typography>

                    <Divider className={classes.customDivider} />
                    <List>
                        <ListItem aria-label="delete">
                            <ListItemIcon>
                                <PersonIcon className={classes.customIconColor} />
                            </ListItemIcon>
                            <ListItemText primary="Representante" secondary={loading ? <Skeleton /> : `${data.nombres} ${data.apellido_paterno}`}/>
                        </ListItem>                    
                        <ListItem>
                            <ListItemIcon>
                                <PhoneIcon className={classes.customIconColor} />
                            </ListItemIcon>
                            <ListItemText primary="Contactos" secondary={loading ? <Skeleton /> : props.data.contacto}/>
                        </ListItem>                    
                    </List>
                    <Box alignContent="center" textAlign="center" className={classes.customAction}>
                        <Divider/>                
                        <Box>                    
                            <QRCode ref={canvasRef} value={url} style={{maxWidth:'200px'}}/>
                        </Box>
                        <Divider/>                        
                    </Box>
                </CardContent>
                <CardActions>
                    <Button variant='contained' size="large" color="primary" fullWidth onClick={()=>handleCanvas()}>Generar tarjeta</Button>
                </CardActions>
            </Card>            
        </>
    );
}

export default CardServicios;