import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import cyan from "@material-ui/core/colors/cyan";
import pink from "@material-ui/core/colors/pink";
import yellow from "@material-ui/core/colors/yellow";
import lightgreen from "@material-ui/core/colors/lightGreen";
import green from "@material-ui/core/colors/green";
import lightBlue from "@material-ui/core/colors/lightBlue";
import { GLOBAL } from "../../../../../../global";
// import imgdonde from "../../images/casaCruzRoja.png";
// import imgrecuerda from "../../images/niños3.png";
// import imgrecuerda1 from "../../images/niños.png";
// import imgcumplimineto from "../../images/niños2.png";
import "./style.css";

// colores
const cian = cyan["600"];
const secondary = pink["A200"];
const rosadofuerte = pink["700"];
const rosadoclaro = pink["300"];
const amarillo = yellow["800"];
const verdeclaro = lightgreen["500"];
const verde = green["500"];
const celeste = lightBlue["500"];

// Pequeños Contenedores
const section = {
  height: "100%",
  backgroundColor: "#fff",
};
const sectioncrian = {
  height: "100%",
  backgroundColor: cian,
};
const sectionrosadoclaro = {
  height: "100%",
  backgroundColor: rosadoclaro,
};
const sectionceleste = {
  height: "100%",
  backgroundColor: celeste,
};
const sectionverde = {
  height: "100%",
  backgroundColor: verde,
};
const Cuadros = () => {
  return (
    <div>
      <Box bgcolor={secondary} color="primary.contrastText" p={1} fontSize={24} fontWeight="fontWeightBold" textAlign="center">
        ¿Qué hacer en caso de violencia a niñas, niños y adolescentes?
        </Box>
      <div className="quehacerInfo">
        <Grid container layout={"row"} spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div style={sectioncrian} bgcolor={cian}>
                  <Box textAlign="center" bgcolor={cian} color="primary.contrastText" p={1}>
                    <h3>¿QUIÉNES PUEDEN Y DEBEN DENUNCIAR?</h3>
                    <p>
                      Niña, niños, adolescente, padre madre, cuidador/a, tuto/a, profesor/a, director/a, médico, funcionario
                      público o cualquiera otra persona que conoce el hecho de violencia.
                      </p>
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div style={section}>
                  <Box bgcolor={rosadofuerte} color="primary.contrastText" p={1} textAlign="center">
                    <div className="numeracion">
                      <p className="text1">1</p>
                    </div>
                    <p>
                      Si ha visto o conoces alguna niña, niño o adolescente que sufre agresión física (golpes), psicológica
                      (insultos, gritos o humillaciones), sexual (relaciones sexuales obligadas), explotación (le obligan a trabajar
                      o a realizar otra actividades para tener un beneficio económico) o negligencia (no le cuidan o no cumplen con
                      sus necesidades)
                      </p>
                    <h3>DENUNCIA</h3>
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <div style={section}>
                  <Box bgcolor={verdeclaro} color="primary.contrastText" textAlign="center" p={1}>
                    <div className="containerRecuerda">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                          <img src={`${GLOBAL.assetsBack}/img/secciones/defensoria-nines-adolescencia/niños3.png`} width="100%" alt="Recuerda" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <h2>RECUERDA</h2>
                          <h3>¡DENUNCIAR ES RESPONSABILIDAD DE TODOS!</h3>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <img src={`${GLOBAL.assetsBack}/img/secciones/defensoria-nines-adolescencia/niños.png`} width="100%" alt="Recuerda2" />
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div style={sectionrosadoclaro}>
                  <Box bgcolor={rosadoclaro} color="primary.contrastText" p={1} textAlign="center">
                    <div className="numeracion">
                      <p className="text5">5</p>
                    </div>
                    <p>
                      Cumplimiento y restitución de los derechos de las niñas, niños y adolescentes
                      </p>
                    <img src={`${GLOBAL.assetsBack}/img/secciones/defensoria-nines-adolescencia/niños2.png`} width="69%" alt="Cumplimiento" />
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div style={sectionceleste}>
                  <Box bgcolor={celeste} color="primary.contrastText" p={1} textAlign="center">
                    <div className="numeracion">
                      <p className="text4">4</p>
                    </div>
                    <h3>¿QUÉ HARÁ LA DNA?</h3>
                    <p>
                      Si constituyeun DELITO: Se realizaladenunciaal Ministerio Público (Fiscalía) o ante el Juez o Jueza
                      Público en materia de Niñez y Adolescencia para aplicar medidas de protección hasta llegar a la sentencia. LA
                      DNA HACE SEGUIMIENTO A LO LARGO DE TODO EL PROCESO Si NO constituye delito: la DNA interviene de manera
                      inmediata. Orienta, asesora y acompaña.
                      </p>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box bgcolor={amarillo} color="primary.contrastText" p={1} textAlign="center">
                  <div className="contenedorDonde">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <div className="numeracion">
                          <p className="text2">2</p>
                        </div>
                        <h3>¿DONDE?</h3>
                        <p>Defensoría de la niñez y adolescencia (DNA)</p>
                        <p>Otra instancias: FELCV, FELCC, FISCALIA</p>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <img src={`${GLOBAL.assetsBack}/img/secciones/defensoria-nines-adolescencia/casaCruzRoja.png`} width="100%" alt="Donde" />
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={sectionverde}>
                  <div className="contenedorDNA">
                    <Box bgcolor={verde} color="primary.contrastText" p={1} textAlign="center" height="100%">
                      <div className="numeracion">
                        <p className="text3">3</p>
                      </div>
                      <h3>RESPONSABILIDADES DE LA DNA</h3>
                      <p>
                        Brindar servicios GRATUITOS Garantizaryrestituir losderechosdeniñas,niños y adolescentes Prevenir toda
                        forma de violencia Brindar una atención legal, psicológica y social Orientada a las niñas, niños y
                        adolecentes sobre sus derechos y responsabilidades.
                        </p>
                    </Box>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default Cuadros;
