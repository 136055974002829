import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import FontAwesome from 'react-fontawesome';
import Typography from '@material-ui/core/Typography';
import convert from 'htmr';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    //maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  customIconFab:{
    color:theme.palette.secondary.main
}
}));

const Lista = (props) => {
  const classes = useStyles();

  return (
    <List className={classes.root}>
        {
            Array.isArray(props.data)?
            props.data.map((item,index)=>(
                <div key={index}>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <FontAwesome name={item.icono} size="2x" className={classes.customIconFab}/>
                        </ListItemAvatar>
                        <ListItemText
                            primary={String(item.titulo).toUpperCase()}
                            secondary={
                              <div>
                                {convert(item.descripcion?item.descripcion:'<p></p>')}
                              </div>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />      
                </div>
            )):null
        }      
    </List>
  );
}

export default Lista;