import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {Carousel} from 'react-responsive-carousel';
import { withRouter } from 'react-router-dom';
import {makeStyles,useTheme} from '@material-ui/core/styles';
import clsx from  'clsx';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import convert from 'htmr';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {GLOBAL} from '../../../global';
import Helper from '../../../Helper';

const useStyles = makeStyles(theme => ({    
    root: {
        flexGrow: 1
    },
    customTitle:{
        color:'#ffffff',
        //textAlign:'left'
    },
    customDescription:{
        color:'#ffffff',
        //textAlign:'left'
    },

    paper: {      
      height: "100%",      
      boxSizing: "border-box"
    },
    gridItem: {
      boxSizing: "border-box"      
    },    
    
    customBgToText:{        
        backgroundImage:`url(${GLOBAL.assetsBack}/img/secciones/smart-city/ornaments1.png)`,
        backgroundRepeat: 'no-repeat',        
        backgroundAttachment: 'fixed',
        backgroundSize:'35%',
        backgroundPosition: '0% 100%'
        //backgroundPosition: 'bottom left'        
    }
}));

const CarouselTextWithImg = (props) => {
    const { data=null } = props;
    const classes = useStyles();
    const theme = useTheme();
    //const matches = useMediaQuery('(min-width:600px)');
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const {primary, secondary} = theme.palette.text;

    const fusionObj = (obj1,obj2) => {
        if(obj1 && obj2){return {...obj1,...obj2};}
        if(obj1 && !obj2){return obj1;}
        if(!obj1 && obj2){return obj2;}
        return {};
    }

    return (
        <Box pt={matches?0:5} style={data && data.mainStyle?{...data.mainStyle}:{}} className='carousel-outer'>
            <Carousel centerMode centerSlidePercentage={100} emulateTouch infiniteLoop useKeyboardArrows autoPlay={true} showThumbs={false} stopOnHover interval={5000} dynamicHeight={false} showIndicators={false} showStatus={false}>
                {
                    data && data.items && Array.isArray(data.items)?data.items.map((item,index) => (
                        <div key={`item${index}`} className={classes.row}>
                            <Grid container style={data && data.mainStyle?{...data.mainStyle}:{}}>
                                <Grid item xs={12} md={6} className={clsx(classes.gridItem,classes.customBgToText)}>
                                    <Box className={classes.paper}>
                                        <Grid container align = "center" justify = "center" alignItems = "center" style={{height:'100%'}}>
                                            <Grid item xs={11} sm={10} lg={8}>
                                                {
                                                    item.text?
                                                    convert(item.text):null
                                                }                                            
                                                <Box my={2} className={classes.cutomButtonsContainer} style={{textAlign:matches?"left":"center"}}>
                                                {
                                                    item.links && Array.isArray(item.links)?item.links.map((link,ind)=>(
                                                        <Button key={`link${ind}`} onClick={()=>Helper.checkLink(props,link)} variant="contained" color={link && link.color?link.color:null}>{link.label}</Button>
                                                    )):null
                                                }
                                                </Box>
                                            </Grid>
                                        </Grid>                                        
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} className={classes.gridItem}>
                                    <Box className={classes.paper}>                                        
                                        <img src={`${GLOBAL.assetsBack}${item.img}`} alt="" />                                        
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    )):null
                }
            </Carousel>
        </Box>
    );    
}

export default withRouter(CarouselTextWithImg);