import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import FondAwesome from 'react-fontawesome';
import { Card,CardHeader,CardContent,IconButton,Box } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Skeleton from '@material-ui/lab/Skeleton';

import {GLOBAL} from '../../../../global';

//import CampoBuscar from '../../../../../components/genericos/CampoBuscar';
import CampoBuscar from '../../../../components/genericos/CampoBuscar';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    minHeight: 480,
    maxHeight: 480,
  },
  inline: {
    display: 'inline',
  },
  colorAvatar:{
      backgroundColor:theme.palette.secondary.main
  },
  avatar: {
    margin: 10,
  },
  customHeader:{
      backgroundColor:theme.palette.primary.main      
  },
  customIconList:{
      color:theme.palette.quinary.main
  }
}));

const ListaComponent = (props) => {
    const { loading = false } = props;
    //******** <CONTROL SEARCH FIELD> *******/
    //console.log("props.data=",props.data);
    const [txtSearch,setTxtSearch] = React.useState('');
    const handleChange = (event) => {
        setTxtSearch(event.target.value);
    }
    const handleClickToClear = () => {
        setTxtSearch('');
    };
    //******** </CONTROL SEARCH FIELD> *******/
    const onHandleClick = (item) =>{
        //props.onHandleItem
        if(props.itemSelected != item){
            props.onHandleItem(item);
        }else{
            props.onHandleItem(null);
        }
    }
    const computedSearchField = (items) => {
        try {
            return items.filter(element => String(element.nombre).toUpperCase().indexOf(String(txtSearch).toUpperCase()) != -1);            
        } catch (error) {
            return [];            
        }        
    }    
    const classes = useStyles();    
    return (
        <>        
        <Card>            
            <CardHeader
                    avatar={
                    <Avatar aria-label="recipe" className={classes.colorAvatar}>
                        {props.icono}
                    </Avatar>
                    }
                    className={classes.customHeader}
                    title={<Typography variant="h6" style={{color:'white'}}>{props.header.title}</Typography>}
                    subheader={<Typography variant="subtitle2" style={{color:'white'}}>{props.header.subtitle}</Typography>}
                />
            <CardContent>
                {
                    !loading?
                    <CampoBuscar
                        placeholder = {props.header.searchplaceholder}
                        txtSearch = {txtSearch}
                        handleChange = {handleChange}
                        handleClickToClear = {handleClickToClear}/>:
                        <Typography component="div" variant="h3">
                          <Skeleton />
                        </Typography>
                }
                <br/>
                <List className={classes.root}>
                    {
                        loading?
                        (
                            Array.from(new Array(4)).map((item,index) => (
                                <Box display="flex" alignItems="center" key={index}>
                                    <Box margin={1}>
                                        <Skeleton variant="circle">
                                          <Avatar />
                                        </Skeleton>                                  
                                    </Box>
                                    <Box width="100%">                                  
                                        <Skeleton width="100%">
                                          <Typography>.</Typography>
                                        </Skeleton>                                  
                                    </Box>
                                </Box>
                                ))
                        ):
                        (
                            computedSearchField(props.data).map(element => (
                                <div key={element.nro}>
                                    <ListItem alignItems="flex-start" button onClick={() => onHandleClick(element)} selected={element==props.itemSelected?true:false}>
                                        <ListItemAvatar>
                                            <FondAwesome className={classes.customIconList} name="folder-open" size="2x"/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={String(element.nombre).toUpperCase()}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        className={classes.inline}
                                                        color="textPrimary"
                                                    >                                
                                                    </Typography>
                                                    {element.descripcion.toString().toUpperCase().substring(0,25)}
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </div>
                            ))
                        )
                    }
                </List>
            </CardContent>
        </Card>
        </>
    );
}
ListaComponent.propTypes = {
    loading: PropTypes.bool,
}

export default ListaComponent;