import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { blue } from '@material-ui/core/colors';
import { withRouter } from 'react-router-dom';

import { GLOBAL } from '../../../global';
import Helper from '../../../Helper';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,      
    },
    margin: {
       margin: theme.spacing(0),
    },
    customModalTitleStyle:{
       //backgroundColor:'#e8f4fd',
       padding:theme.spacing(1,1),
       textAlign:'right',
       color:"rgb(13, 60, 97)"       
    },
    customColorText:{
        color:"rgb(13, 60, 97)"
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    customImg:{
        maxWidth:'200px'
    },
    customTitle:{
        color:"rgb(13, 60, 97)",
        textDecoration:'underline'
    },
    customPadding:{
        //height:theme.spacing(2),
        //backgroundImage:'linear-gradient(to right, rgb(88,190,208) , rgb(244,69,86))',
        backgroundColor:theme.palette.primary.main,
        color:'#FFFFFF',
    },
  }));

function ModalComunicadoComponent(props){
    const { data={
        "component":"ImgLinkComponent",
        "footer":true,
        "data":{
            "img":"/img/secciones/home/destacado-vamos-cochabamba.jpg",
            "url":"https://vamos.cochabamba.bo",
            "type":"externo"
        }
    }} = props;
    const [open, setOpen] = React.useState(props.show?true:false);
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    return(        
        <Dialog
            open={open}
            onClose={handleClose}
            >
            <DialogTitle className={classes.customModalTitleStyle}>
                <IconButton aria-label="delete" className={classes.margin} onClick={handleClose} size="small">
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>                   
            <DialogContent className={classes.customModalStyle} style={{padding:'0px'}}>                
                {
                    data && data.component === "ImgLinkComponent"?
                    <Box align="center" onClick={()=>Helper.checkLink(props,data.data)}>
                        <DialogActions style={{padding:'0px'}}>
                            <img src={`${GLOBAL.assetsBack}${data.data.img}`} style={{width:'100%',cursor:'pointer'}}/>
                        </DialogActions>
                    </Box>:null
                }
            </DialogContent>
            {
                data.footer?
                <DialogActions className={classes.customPadding}>
                    <Typography variant="caption">Alcaldía de Cochabamba</Typography>
                </DialogActions>:null
            }
        </Dialog>        
    );
}
export default withRouter(ModalComunicadoComponent);