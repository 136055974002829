import React,{useEffect,useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid,Button,TextField,Paper,Typography,Box } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReCAPTCHA from "react-google-recaptcha";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RoomIcon from '@material-ui/icons/Room';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';
import Divider from '@material-ui/core/Divider';
import FondAwesome from 'react-fontawesome';

import { useForm } from "react-hook-form";
import Axios from 'axios';
import {GLOBAL} from '../../global';

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex"        
    },
    customMainContainer:{
        margin:theme.spacing(10,1)
    },
    customDataStyle: {
        width: '100%',    
        backgroundColor: theme.palette.background.paper,
    },
    customWith:{
        width:'100%',
        margin:theme.spacing(2,0),
    },
    customPadding:{
        padding:theme.spacing(2,2)
    },
    customPaper:{
        backgroundColor:theme.palette.common.white,
        padding:theme.spacing(5,1)
    },
    whatsapp:{
        color:'#f50057',
        //margin:'10px'
    },
  }));

  const dataPortada = {
    bgImg:GLOBAL.assetsURL+'/images/contact/contact_banner.jpg',
    logoImg:null,
    style:{
        title:{
            textAlign:'center',
            color:'white',
            backgroundColor:'rgba(212,32,35,0.5)',
        },
        description:{
            textAlign:'justify',
            backgroundColor:'rgba(60, 0, 0, 0.3)',            
        }
    },
    title:'CONTÁCTANOS',
    description:[
        'Nuestro municipio recibe tus comentarios, y está abierto a tus inquietudes y problemas que esperamos resolver lo antes posible.'
    ],
    btnData:{
        label:'CONTINUAR',
        href:''
    }
}

function ContactosComponent(){    
    const { register, handleSubmit,formState,errors, reset } = useForm({mode:'onChange'});
    const [loading,setLoading] = React.useState(false);
    const [data,setData] = React.useState(null);
    const [error,setError] = React.useState(false);    
    const [human,setHuman] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    
    const captcha = useRef(null);
    const onChange = (value) => {        
        if(value){            
            setHuman(value);
        }else{            
            setHuman(null);
        }
    }

    useEffect(() => {
       window.scrollTo(0, 0)
    }, [])

    const onSubmit = data => {
        //console.log("data=",data);
        setLoading(true);  
        let data2Send = {
            nombre:data.nombre,
            asunto:data.asunto,
            email:data.email,
            message:data.mensaje,
            token:human
        }

        Axios.request({
            method: "post",
            url: `${GLOBAL.apiURL}/api/enviaremail`,
            data: data2Send            
        })
        .then(function (response) {
            //console.log(response);
            setError(null);
            setData(response.data);
        })
        .catch(function (error) {
            setData(null);
            setError(true);
        })
        .then(function () {
            setLoading(false);            
            setOpen(true);
            reset();
            setHuman(null);
            captcha.current.reset();
        });        
    };
    const handleClose = (event, reason) => {
        //console.log("reason=",reason);
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    //if(data || error){alert("Se produjo un error en el envio de los datos")}
    if(data && data.data && data.data.status){alert("Mensaje enviado correctamente")}
    return(
        <>            
            <Grid container className={classes.customMainContainer}>
                <Grid item xs={12} sm={1} md={1} lg={2} xl={3}></Grid>
                <Grid item xs={12} sm={10} md={10} lg={8} xl={6}>
                    <Paper elevation={0} className={classes.customPaper}>
                        <Typography variant="h4" align="center">PARA CONSULTAS COMUNÍCATE CON NOSOTROS:</Typography>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.customPadding}>                                
                                <List className={classes.customDataStyle}>
                                    <ListItem>
                                        <ListItemIcon>
                                        <RoomIcon color="secondary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Plaza Principal 14 de Septiembre No  s0210, esquina calles Baptista y Gral Achá."/>
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemIcon>
                                            <MailOutlineIcon color="secondary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="infogamc@cochabamba.bo"/>
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemIcon>
                                            <PhoneIcon color="secondary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="151"/>
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemIcon>
                                            <FondAwesome className={classes.whatsapp} size={'2x'} name="whatsapp"/>
                                        </ListItemIcon>
                                        <ListItemText primary="61561515"/>
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemIcon>
                                            <FondAwesome className={classes.whatsapp} size={'2x'} name="whatsapp"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Transparencia 61680094"/>
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemIcon>
                                            <LanguageIcon color="secondary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="www.cochabamba.bo"/>
                                    </ListItem>
                                </List>                                
                            </Grid>                            
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.customPadding}>                            
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <TextField                                        
                                        id="nombre"
                                        name="nombre"
                                        label="Nombre (*)"
                                        //helperText="Requerido"
                                        className={classes.customWith}
                                        //value={input.nombre}
                                        //onChange={handleInputChange}
                                        inputRef={register({
                                            required:true,
                                            minLength:3,
                                            maxLength:50
                                        })}
                                        error={errors.nombre?true:false}
                                        />
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="Email"
                                        //helperText="Requerido"
                                        className={classes.customWith}
                                        //value={input.email}
                                        //onChange={handleInputChange}
                                        inputRef={register({
                                            required:false,
                                            pattern:{
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            }
                                        })}
                                        error={errors.email?true:false}
                                        />
                                    <TextField
                                        id="asunto"
                                        name="asunto"
                                        label="Asunto (*)"
                                        //helperText="Requerido"
                                        className={classes.customWith}
                                        //value={input.asunto}
                                        //onChange={handleInputChange}
                                        inputRef={register({
                                            required:true,
                                            minLength:3,
                                            maxLength:50
                                        })}
                                        error={errors.asunto?true:false}
                                        />
                                    <TextField                                        
                                        id="mensaje"
                                        name="mensaje"
                                        //value={input.mensaje}
                                        label="Mensaje (*)"
                                        className={classes.customWith}
                                        multiline
                                        rows={4}
                                        //defaultValue=""
                                        variant="outlined"
                                        inputRef={register({
                                            required:true,
                                            minLength:10,
                                            maxLength:1500
                                        })}
                                        error={errors.mensaje?true:false}
                                        />
                                        <Box mb={1.5}>
                                            <ReCAPTCHA
                                                ref={captcha}
                                                sitekey={RECAPTCHA_KEY}
                                                onChange={onChange}
                                            />
                                        </Box>
                                    {
                                        !loading?
                                        <Button disabled={!formState.isValid || !human} variant="contained" color="secondary" onClick={() => handleSubmit(onSubmit)()}>Enviar consulta</Button>:
                                        <CircularProgress color="secondary" />
                                    }                                    
                                </form>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={1} md={1} lg={2} xl={3}></Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }} 
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}>
                    <Alert onClose={handleClose} severity={data && data.status?"success":"error"}>
                        {
                            !error && data && !data.status?                            
                            <Typography>{data.message}</Typography>:null
                        } 
                        {
                            !error && data && data.data && !data.status?
                            <Typography>Los datos no fueron enviados, vuelva a intentarlo más tarde</Typography>:null
                        }                                                
                        {
                            !error && data && data.status?
                            <Typography>Los datos fueron enviados correctamente</Typography>:null
                        }
                        {
                            error && !data?
                            <Typography>Se produjo un error, vuelva a intentarlo más tarde</Typography>:null
                        }
                    </Alert>
            </Snackbar>
        </>
    );
}

export default ContactosComponent;