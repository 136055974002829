import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
//import CardMedia from '@material-ui/core/CardMedia';
//import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import Toolbar from '@material-ui/core/Toolbar';
//import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';

import Skeleton from '@material-ui/lab/Skeleton';

import {GLOBAL} from '../../../global';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    cursor:'pointer',
    height:'100%',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width:'100%'
  },
  content: {
    flex: '1 0 auto',    
  },
  cover: {
    width: 250,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
 customToolBar:{
   paddingLeft:theme.spacing(0),
   paddingRight:theme.spacing(0)
 },
 customToolBar2:{
   padding:theme.spacing(0)
 },
 large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  CustomAlign:{
    textAlign:'center',
    margin:theme.spacing(1,1)
  }
}));

export default function CardGaceta(props) {
  const { loading = false } = props;

  const classes = useStyles();
  const theme = useTheme();

  const onHandleClick = () => {
    props.onHandleClick(props.data);
  }

  return (
    <Card className={classes.root} onClick={onHandleClick}>
      {/*
      <Toolbar variant="dense" className={classes.customToolBar}>        
        {<Avatar alt="Remy Sharp" src={GLOBAL.assetsURL+'/images/gaceta/if_document.png'} className={classes.large} />}
        <ImportContactsIcon fontSize="large" color="primary"/>        
      </Toolbar>
      */}
      <Toolbar variant="dense" className={classes.customToolBar}>
        <Box className={classes.CustomAlign}>
          {
            loading?            
              <Skeleton variant="circle" width={40} height={40} />:
              <Avatar alt="Remy Sharp" src={GLOBAL.assetsURL+'/images/gaceta/if_document.png'} className={classes.large} />           
          }
          <Typography  variant="caption" display="block" gutterBottom>{loading?<Skeleton/>:props.data.tipo}</Typography>
        </Box>
      </Toolbar>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography variant="button" display="block" gutterBottom>
            {loading?<Skeleton width={200}/>:props.data.nombre}
          </Typography>
          <Typography  variant="caption" display="block" gutterBottom>
            {loading?<Skeleton width={200}/>:props.data.resumen}
          </Typography>
        </CardContent>
      </div>
      <Toolbar variant="dense" className={classes.customToolBar2}>
        <Box className={classes.CustomAlign}>          
          {
            loading?
              <Skeleton variant="circle" width={40} height={40} />:
              <GetAppIcon color="secondary" />          
          }
          <Typography  variant="caption" display="block" gutterBottom>{loading?<Skeleton />:props.data.fecha}</Typography>
        </Box>
      </Toolbar>
    </Card>
  );
}
CardGaceta.propTypes = {
  loading: PropTypes.bool,
};
